import React from "react";
//import Global from '../../Shared/Vars'
import Support from './Support'
import './SupportScreen.scss'

import {Link} from 'react-router-dom'

export default class SupportScreen extends React.Component {

  constructor(props) {
    super(props); 
    this.state= {
      isShow : false,
      language : this.props.language,
      title : "",
      message : "",
    } 
    this.show = this.show.bind(this)
    this.send = this.send.bind(this)
  }

  show() {
    this.setState( { isShow:true  } )
  }

  send() {
    console.log( 'mailto:contact@garlican.com?subject='+this.state.title+'t&body='+this.state.message )
    window.open( 'mailto:contact@garlican.com?subject='+this.state.title+'t&body='+this.state.message );
  }


  componentWillMount() {
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  
  render() {
      return (
        <div className={['terms-container'].join(' ') }>

            <div className={['support-frame','white-color-text', 'frame-color-2'].join(' ') } ></div>

            <div className={['support-content','dark-color-text'].join(' ') } >
              <div className={['support-container','dark-color-text'].join(' ') } > 
                  <div className={['support-container-title','frame-color-text'].join(' ') } > { Support[this.props.language]['header'] } </div>
                  
                  <input 
                    onChange={ (e)=>this.setState({title : e.target.value}) }
                    className={['support-container-input', 'frame-color-2-border' ].join(' ') } 
                    placeholder={ Support[this.props.language]['title_placeholder'] }
                  />
                  <textarea 
                    onChange={ (e)=>this.setState({message : e.target.value}) }
                    className={['support-container-textarea', 'frame-color-2-border' ].join(' ') } 
                    placeholder={ Support[this.props.language]['message_placeholder'] }
                  />

                  <div
                    onClick={ (e)=>this.send() } 
                    className={['support-container-button', 'frame-color-2', 'white-color-text', 'frame-color-3-hover' ].join(' ') } >
                      {  Support[this.props.language]['send']  }
                  </div>

              </div>
            </div>

            <br/>
            <br/>
            <br/>

        </div>
      )

  }
}






