import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { Modal, Image, Button, OverlayTrigger, Tooltip, Dropdown, DropdownButton } from 'react-bootstrap';
//import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  /*faBowlRice*/ faChevronRight, faFilter, faInfoCircle,   } from '@fortawesome/free-solid-svg-icons'

import flags from '../../public/flags.js';
import Emoji from 'react-emojis';

import service from '../../public/service'

//import logoB from '../../public/img/garlican_blue_logo.png'
const ranks = ["A", "B", "C", "D", "E", "F"]

class Picker extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          value : [],
          total : 0,
          selectedRegion : null,
        };
        this.search = this.search.bind( this )
        this.ping = this.ping.bind( this )
    }

    componentDidMount() {
      this.ping()
    }

    componentWillUnmount() {

    }

    UNSAFE_componentWillReceiveProps(props) {
    }

    search() {
      var _obj = {}
      for( var i in this.props.pickeroptions ) {
        if( this.props.pickeroptions[i].key !== "initial_capital" ) {
          var obj = JSON.parse( JSON.stringify( this.props[  this.props.pickeroptions[i].key + "_rank_range"  ] ) )
        } else {
          var obj = JSON.parse( JSON.stringify( this.props[  this.props.pickeroptions[i].key + "_range" ] ) )
        }
        if('min' in obj || 'max' in obj) {
          var nobj={}
          if( this.props.pickeroptions[i].type !== 0 ) {
            nobj.min = Math.ceil( this.state.total * ((100-obj.max+1))/100 ) 
            if( obj.min>11 ) {
              nobj.max = Math.ceil( this.state.total * ((100-obj.min))/100 ) 
            } 
            _obj[ this.props.pickeroptions[i].key + "_rank_range" ] = nobj
          } else {
            nobj.min = obj.min
            if( obj.max !== this.props.pickeroptions[i].range[0] ) {
              nobj.max = obj.max
            }
            _obj[ this.props.pickeroptions[i].key + "_range" ] = nobj
          }
        }
      }

      if( this.state.selectedRegion) {
        _obj.region = this.state.selectedRegion
      }

      this.props.searchPortfolios(_obj)
      //this.props.updateValue( { showFilter : false } )

    }

    ping() {
      service.getPortfoilioList(  { win_rate_rank_max : 1 } ).then(
        (res) => {
          if( res.list.length > 0 ) {
            this.setState({
              total : res.list[0].total_portfolios
            })
          }
        }
      )
    }

    render() {
      return (
        <Modal
            onHide={() => {
              //Array(5).fill().map( (v,k)=>{return 1}  )
              //this.props.updateValue( { showFilter : false } )
            }}
            size="sm"
            backdropClassName="Picker-Backdrop"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="Picker-Container"
            className={['Picker-Frame'].join(" ")} 
            style={
              {
                width : Math.max((document.getElementById("right")?document.getElementById("right").offsetWidth:320),320) + "px"
              }
            }
            show={this.props.show} >
          <Modal.Header className={['Picker-Header'].join(" ")}  closeButton={false}> 
            <div className={['Picker-Header-Frame'].join(" ")}>
              <FontAwesomeIcon icon={ faFilter } className={["Fa"].join(" ")} />
              <div className={['Picker-Header-Title'].join(" ")} > { this.props.gi18n[this.props.language]["picker"] }  </div>
            </div>

            <FontAwesomeIcon icon={ faChevronRight } className={["Back"].join(" ")} onClick={
              (e) => {
                this.props.updateValue( { showFilter : false } )
              }
            } />

          </Modal.Header>
          <Modal.Body className={['Picker-Body'].join(" ")} >
            <div className={ ['Holder'].join(' ')}>
              <div className={ ['Title'].join(' ')}> 
                <div className={ ['Text'].join(' ')}>{ this.props.gi18n[this.props.language]['region'] } </div>
              </div>
              <DropdownButton className={["Regions"].join(" ")} title={ 
                              <div className={["Scope"].join(" ")} >
                                <div className={['Region'].join(" ") }>  
                                        { this.state.selectedRegion &&
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                (
                                                  this.state.selectedRegion?
                                                  (
                                                    flags[this.state.selectedRegion]
                                                  ):
                                                  flags['usa']
                                                )
                                              }
                                          />
                                        }
                                </div>
                                { this.state.selectedRegion?this.state.selectedRegion.toUpperCase():this.props.gi18n[this.props.language]["all"] }
                              </div>
                            } >
                              {
                                [{region : "all"}].concat(  JSON.parse( JSON.stringify( this.props.regions) ) ).map( (v,k) => {
                                  return(
                                    <Dropdown.Item 
                                      className={["Line"].join(" ")}
                                      eventKey={v.region} 
                                      key={k} 
                                      onSelect={ (e)=> {
                                        var key = (e!=="all")?e:null
                                        this.setState({ selectedRegion : key })

                                    } } > 
                                      <div className={['Region'].join(" ") }>  
                                        { v.region !== "all" &&
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                v.region?
                                                flags[v.region]:
                                                flags['hkg']
                                              }
                                          />
                                        }
                                      </div>
                                      { (v.region in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v.region]:v.region } 
                                    </Dropdown.Item>
                                  )
                                } )
                              }
                              
              </DropdownButton>

            </div>


            {
              this.props.pickeroptions.map((v,k)=> {

                return(
                  <div key={k} className={ ['Holder'].join(' ')} >
                    <div className={ ['Title'].join(' ')}> 
                      <div className={ ['Text'].join(' ')}>{ this.props.gi18n[this.props.language][v.value] } </div>
                      { (v.type === 1) &&
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 250 }}
                          overlay={<Tooltip id="button-tooltip-2" className={["Over-Table"].join(" ")}>{ this.props.getRankInfo() }</Tooltip>}
                        >
                          <FontAwesomeIcon icon={ faInfoCircle } className={ ["Fa"].join(" ") }/>
                        </OverlayTrigger>  
                      }
                    </div>
                    {
                      (v.type === 1 ) && 
                        <div className={ ['Box-Frame'].join(' ')}>
                          {
                            ranks.map( (val,key) => {
                              var maxvalue = (100 - 15*key)
                              var minvalue = (100 - 15*(key+1) + 1)

                              var _val = this.props[ v.key + "_rank_range" ]
                              var _c_min = ("min" in _val)?_val['min']:100
                              var _c_max = ("max" in _val)?_val['max']:0

                              var selected = ( maxvalue <= _c_max ) && ( minvalue >= _c_min )
                              var exact = ( maxvalue === _c_max ) && ( minvalue === _c_min )

                              return(
                                <div className={ ['Box-Holder'].join(' ')} key={key}>

                                  <div className={ ['Box-Title'].join(' ')}>{  val }</div>
                                  <div className={ ['Box', selected?"selected":""].join(' ')} onClick={
                                    (e) => {
                                      var _obj = {}
                                      if( !selected ) {
                                        _val.max= Math.max( _c_max, maxvalue    )
                                        _val.min= Math.min( _c_min, minvalue    )
                                        _obj[ v.key + "_rank_range" ] = _val
                                      } else {
                                        if( !exact) {
                                          if( _c_max >= maxvalue  ) {
                                            _val.max= maxvalue
                                          }
                                          if( _c_min <= minvalue  ) {
                                            _val.min= minvalue
                                          }
                                        } else {
                                          _val = {}
                                        }
                                        _obj[ v.key + "_rank_range" ] = _val
                                      }

                                      this.props.updateValue(_obj)
                                    }
                                  }> </div>
                                </div>
                              )
                            })
                          }
                        </div>
                    }

          
                    {
                      (v.type === 0 ) && 
                        <div className={ ['Box-Frame', "Type0"].join(' ')}>
                          {
                            ( ( "min" in this.props[(v.key + "_range")] ) || ( "max" in this.props[(v.key + "_range")] ) ) &&
                              <div className={ ['Range'].join(' ') } >  
                                  { 
                                    ( 
                                      "$" + 
                                      (( "min" in this.props[(v.key + "_range")] )?this.props[(v.key + "_range")].min.toLocaleString("en-US"):v.range[0])
                                      + " - " + 
                                      "$" + 
                                      (( "max" in this.props[(v.key + "_range")] )?this.props[(v.key + "_range")].max.toLocaleString("en-US"):v.range[0] )
                                      + ( (this.props[(v.key + "_range")].max!==v.range[0])?"":"+") 
                                    )
                                  }
                              </div>
                          }
                          {
                            Array( Math.floor( (v.range[1]-v.range[0])/v.step ) ).fill(0).map( (val,key) => {
                              var max = Math.floor( (v.range[1]-v.range[0])/v.step ) -1

                              var maxvalue = ( v.range[0] + v.step*(key+1))
                              var minvalue = ( v.range[0] + v.step*key)
                              var _val = this.props[ v.key + "_range" ]
                              var _c_min = ("min" in _val)?_val['min']:v.range[1]
                              var _c_max = ("max" in _val)?_val['max']:v.range[0]
                              var selected = ( maxvalue <= _c_max ) && ( minvalue >= _c_min )
                              var exact = ( maxvalue === _c_max ) && ( minvalue === _c_min )
                              return(
                                <div className={ ['Box-Holder'].join(' ')} key={key}>

                                  { ((key % 5) === 0 ) &&
                                    <div className={ ['Box-Title'].join(' ')}>{  "$" + minvalue.toLocaleString("en-US")   }</div>
                                  }

                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip className={[""].join(" ")}>{ "$" + minvalue.toLocaleString("en-US") + " - " + "$" + maxvalue.toLocaleString("en-US") + ( (key!==max)?"":"+") }</Tooltip>}
                                  >
                                    <div className={ ['Box', selected?"selected":""].join(' ')} onClick={
                                      (e) => {
                                        var _obj = {}
                                        if( !selected ) {
                                          _val.max= Math.max( _c_max, maxvalue    )
                                          _val.min= Math.min( _c_min, minvalue    )
                                          _obj[ v.key + "_range" ] = _val
                                        } else {
                                          if( !exact) {
                                            if( _c_max >= maxvalue  ) {
                                              _val.max= maxvalue
                                            }
                                            if( _c_min <= minvalue  ) {
                                              _val.min= minvalue
                                            }
                                            
                                          } else {
                                            _val = {}
                                          }
                                          _obj[ v.key + "_range" ] = _val

                                        }
                                        //console.log( _obj )
                                        this.props.updateValue(_obj)
                                      }
                                    }> </div>
                                  </OverlayTrigger>
                                </div>
                                
                              )
                            })
                          }
                        </div>
                    }

                  </div>
                )
              })
            }
          </Modal.Body>


          <Modal.Footer className={ ['Picker-Footer'].join(' ') }>
            <Button 
              className={ ['search'].join(' ') }
                onClick={(e)=>{
                  this.search()
            }}>{ this.props.gi18n[this.props.language]['search'] }</Button>

            <Button 
              className={ ['reset'].join(' ') }
              onClick={(e)=>{
                this.props.resetFilter()
            }}>{ this.props.gi18n[this.props.language]['reset_filter'] }</Button>
          </Modal.Footer>
        </Modal>
      )
    }
}


export default Picker;


/*
              {
                this.props.logosrc ?
                <Image className={['Picker-Header-Logo'].join(" ")} src={this.props.logosrc} /> :
                <FontAwesomeIcon icon={ faBowlRice } className={['Picker-Header-Logo'].join(" ")} />   
              }

                                  <div className={ ['Picker-Switch'].join(' ')} >
                      <InputRange
                        step={ v.step }
                        draggableTrack={true}
                        formatLabel={ (value) => v.label.replace("<value>", value)  }
                        minValue={ v.range[0] }
                        maxValue={ v.range[1] }
                        value={ {
                          min:this.props[v.key+"_range"].min?this.props[v.key+"_range"].min:v.range[0], 
                          max:this.props[v.key+"_range"].max?this.props[v.key+"_range"].max:v.range[1]
                        } }
                        onChange={ (value) => {
                          var obj = {}
                          obj[ v.key+"_range" ] = value
                          this.props.updateValue(obj)
                        }} 
                        onChangeComplete={
                          (value) => {
                            this.props.searchPortfolios()
                          }
                        }/>
                        
                    </div>
*/
