import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faShoppingCart ,faTimes, faMinus  } from '@fortawesome/free-solid-svg-icons'

import TInsert from '../insert'
import List from '../list'

class Action extends Component {

    constructor(props) {
        super(props)

        var title = {}
        title.action =   props.gi18n[ props.language ][ 'd_action' ]
        title.symbol = props.gi18n[ props.language ][ 'd_symbol' ]
        title.price = props.gi18n[ props.language ][ 'd_price' ]
        title.board_lot = props.gi18n[ props.language ][ 'd_boardlot' ]
        title.total_cost = props.gi18n[ props.language ][ 'd_total_cost' ]
        title.timestamp = props.gi18n[ props.language ][ 'd_timestamp' ]
        title.buy = props.gi18n[ props.language ][ 'd_buy' ]
        title.change = null

        this.state = {
            buy_value : props.buy_value,
            sell_value : props.sell_value,
            title : title,
        }


    }


    componentDidMount() {    
    }

    shouldComponentUpdate(props, state) {
        return true
    }

    componentDidUpdate(props, state){
        if( this.props.buy_value !== state.buy_value ) {
            this.setState( {
                buy_value : this.props.buy_value,
            })
        } else if( this.props.sell_value !== state.sell_value ) {
            this.setState( {
                sell_value : this.props.sell_value,
            })
        }
    }

    componentWillUnmount() {
    }

    getCT(fg) {
        var count = 0
        fg.map( (v,k) => {
            count += v.action.length
        } )
        return count
    }

    render() {
        //console.log("b")
        return (
            <div className={["Action"].join(" ")} >

                <div className={["Top"].join(" ")}>
                    <div className={['Frame'].join(" ")}> </div>
                    <div className={["Banner"].join(" ")}>
                        <div className={['Ttitle'].join(" ")}>
                            { this.props.gi18n[ this.props.language][ "trader_action"].replace( "<num>", this.getCT(  this.props.forwardGroup  ) )  }
                        </div>
                    </div>
                    <div className={['TGlance'].join(" ")} >
                        {
                            this.props.board[ "action" ].map( (v,k) => {
                                    return(
                                        <div className={ ['Wrapper'] } key={k}>
                                            <div className={ ['Title'] }> {  this.props.gi18n[ this.props.language ][ "t_" + v ] } </div>
                                            <div className={ ['Value', (this.state[v]>0)?"Credit":( (this.state[v]<0)?"Debit":"" ) ].join(" ") }> { 
                                                (v==="change")?(this.state[v] + "%"):("$"+Math.abs(this.state[v]).toLocaleString("en-US") )
                                                } 
                                            </div>
                                        </div>
                                    )
                            } )
                        }
                        <div className={['WFrame'].join(" ")} >
                            {
                                [120, 500, 200, 60, 90, 90, 300, 75, 275].map( (v, k) => {
                                    var x = ( Math.random() * 100)
                                    return(
                                        <div
                                            key={k} 
                                            className={['Circle'].join(" ")} 
                                            style={{
                                                left : ( Math.random() * 100) + "%",
                                                top : ( Math.random() * 100) + "%",
                                                width : v + "px",
                                                height : v + "px",
                                                zindex : k
                                            }}
                                        > 
                                        </div>
                                    )
                                } )
                            }
                        </div>
                    </div>
                </div>

                

                <div className={ [ "Panel" ].join(" ") }>
                    <div className={['switch'].join(" ")}>
                        <div className={['btn'].join(' ')}  onClick={ (e)=>{
                            this.props.updateValue({
                                isAdd : !this.props.isAdd
                            })
                            }}>
                            <FontAwesomeIcon  icon={  !this.props.isAdd?faPlus:faMinus  } className={['Fa'].join(' ')}  />
                            <div className={['Name'].join(' ')} > 
                                { !this.props.isAdd?this.props.gi18n[this.props.language]['t_insert']:this.props.gi18n[this.props.language]['t_hide'] } 
                            </div>
                        </div>
                        <div className={['btn'].join(' ')} onClick={ (e)=>{
                            this.props.order()
                            }}>
                            <FontAwesomeIcon  icon={faShoppingCart} className={['Fa'].join(' ')} />
                            <div className={['Name'].join(' ')}> { this.props.gi18n[this.props.language]['t_trade'] } </div>
                        </div>
                    </div>
                    <TInsert 
                        isAdd={ this.props.isAdd} 
                        group={this.props.group} 
                        insert={this.props.insert} 
                        isTLogin={this.props.isTLogin}
                        {...this.props} />
                </div>   

                <div className={ [ "Container" ].join(" ") }>
                    <List key={"label"} obj={this.state.title} title={true} {...this.props} />

                    { (this.props.forwardGroup.length > 0) &&
                        this.props.forwardGroup.map(
                            (v,k) => {
                                if( v.action.length > 0) {
                                    return (
                                        v.action.map( (v1, k1) => {
                                            //v1.strategy_id = v1.group
                                            v1.group = v.group
                                            v1.buy = v1.entryprice
                                            v1.board_lot = v1.boardlot
                                            v1.unit = Math.abs(  v1.decision  )
                                            if( v1.entryprice ) {
                                                v1.change = ((v1.price - v1.entryprice)/v1.entryprice)
                                            } else {
                                                v1.change = 0
                                            }
                                            return (
                                                <List 
                                                    {...this.props} 
                                                    key={ k+"_" +k1 } 
                                                    obj={ v1 } 
                                                    index={ k1 } 
                                                    group={ this.props.forwardGroup }
                                                    gindex={ k }
                                                    isActive={ true } 
                                                    type={"action"}
                                                />
                                            )
                                        } )
                                    )
                                } else {
                                    return null
                                }
                            }
                        )
                    }
                    { (this.props.forwardGroup.length === 0) &&
                        <div className={ [ "No-Msg" ].join(" ") }> { "NO ACTION IS FOUND." } </div>
                    }
                    { (this.props.forwardGroup.reduce( (sumv,v) =>sumv+v.action.length, 0) === 0 ) &&
                        <div className={ [ "No-Msg" ].join(" ") }> { "NO ACTION IS FOUND." } </div>
                    }
                </div>

            </div>
        )
    }
}

export default Action;

//.reduce((partialSum, a) => partialSum + a, 0);
