import React, { Component } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import {  faRocket, faFireFlameCurved, faBowlRice} from '@fortawesome/free-solid-svg-icons'
import {  Image } from 'react-bootstrap';

import './index.scss';

import IMG1 from "../../public/img/1.png"
import IMG2 from "../../public/img/2.png"
import IMG3 from "../../public/img/3.png"
import IMG4 from "../../public/img/4.png"

const CONTENT = [
  { 
    key : "robo" , 
    title : "Your Trusted Robo-advisor" , 
    message : "A trusted partner to analyze and automate trade at the best time for you.",  
    image : IMG1
  },
  { 
    key : "farm" , 
    title : "Trade by Bot-Folio" , 
    message : "Set up your Bot-Folio to manage and create your robot investment portfolio.",  
    image : IMG2
  },
  { 
    key : "trade" , 
    title : "One Click Trading" , 
    message : "Trade in one click",  
    image : IMG3
  },
  { 
    key : "create" ,  
    title : "Create and Manage your Bot-Folio" , 
    message : "DIY your own bot-folio. Test it and manage it. Master your works!",  
    image : IMG4
  },
]

var interval = null
var i = 0

class Loading extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          selected : null,
        };
    }

    componentDidMount() {
      if( !interval  ) {
        interval = setInterval(
          ()=> {
            //var i = Math.random()
            //i = Math.floor( i * CONTENT.length )
            //i = Math.min( i , CONTENT.length -1 )
            

            this.setState({
              selected : i % CONTENT.length
            })
            i += 1
          }, 2000
        )
      }
    }

    componentWillUnmount() {
      clearInterval(interval)
      interval  = null
      i = 0
    }

    render() {
      if(this.props.showLoading) {
        return (
          <div className={ ["Loading"].join(" ") }>
            {
              CONTENT.map( (v,k) => {
                return (
                  <div className={ ["Column", (k===3)?"Last":"" ].join(" ") } key={k} >
                    <div className={ ["Holder", (this.state.selected===k)?"selected":""].join(" ") }>
                      <Image className={ ["Image"].join(" ") } src={ v.image } />
                      <div className={ ["Title"].join(" ") }  > { v.title } </div>
                      <div className={ ["Message"].join(" ") }  > {v.message} </div>
                    </div>
                  </div>
                )
              } )
            }

            <div className={ ["Loading-Message" ].join(" ") }>
              {
                this.props.hasLogo &&
                this.props.logosrc && 
                <div className={ ["L-Holder" ].join(" ") }>
                  <Image className={ ["Logo" ].join(" ") } src={ this.props.logosrc } />
                  <div className={ ["Text" ].join(" ") }> { this.props.gi18n[this.props.language]['loading'] } </div>
                </div>
              }
            </div>
            </div>
        )
      } else {
        return null
      }
    }
}


export default Loading;

/*
        <Modal key={102}
													onHide={ (e)=>console.log("Loading")  }
													show={  this.state.showLoading  }
													className={ ['app-Loading'].join(' ') }>
													<Modal.Header className={ ['app-Loading-Header'].join(' ') }>
														{
															this.state.logosrc &&
															<Image className={['app-Loading-Header-Logo'].join(" ")} src={ this.state.logosrc } />
														}
														{
															!this.state.logosrc &&
															<FontAwesomeIcon icon={faBowlRice} className={['app-Loading-Header-Logo'].join(" ")} />
														}
														<div className={['app-Loading-Header-Title'].join(" ")} > 
															{ 
																(this.state.language in this.state.gi18n)?
																this.state.gi18n[ this.state.language ]['title']:
																"Garlican"
															}  
														</div>
													</Modal.Header>
													<Modal.Body className={ ['app-Loading-Frame'].join(' ') } >
														{
															Array.from({length: 8}, (_, i) => i + 1).map( 
																(v,k) => {
																	return(
																		<Spinner className={ ['app-Loading-Spinner'].join(' ') } key={k} animation="grow" variant="primary" />
																	)
																}
															)
														}
													</Modal.Body>
													<Modal.Footer className={ ['app-Loading-Word'].join(' ') }>
														{ this.state.gi18n[this.state.language]['loading'] }
													</Modal.Footer>
				</Modal>
*/