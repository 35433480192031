import React, { Component } from 'react';
import './index.scss';

import service from '../public/service'
//import logoB from '../public/img/garlican_blue_logo.png'

import { OverlayTrigger, Tooltip, Button,DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faRobot , faRocket, faChevronRight, faChevronLeft, faBullhorn, faCartArrowDown, faCog, faPenToSquare } from '@fortawesome/free-solid-svg-icons'

import gservice from '../Garlican/GarlicanLibrary/gservice'
import Api from '../Garlican/GarlicanLibrary/apis'
import Filter from './Filter'
import Botfolio from './Botfolio'
import Detail from './Detail'
import TradeLog from './TradeLog'
import TemplateList from './TemplateList'
import TemplateDetail from './TemplateDetail'

import GarlicanReport from '../Garlican/GarlicanReport';
import CreatePanel from './CreatePanel'

import Login from './Login'

import Trader from '../Components/Trader2'
//import { timeHours } from 'd3';


var icons = [
  { key : 'botfolio', value : 'my_botfolio', icon : faRocket },
  { key : 'template', value : 'my_template', icon : faPenToSquare },
]

var next = false

var tmp_code = `cond1=close > 0  #Buying in any price
condition_long = (cond1)
#####EXIT CONDITION#####
up_value=0.10 #止賺(%)
down_value=0.05 #止損(%)
upper_profit_exit = (close >= (entryprice*( 1+up_value) ) ) #止賺賣出
lower_profit_exit = (close <= (entryprice*( 1-down_value)) ) #止損賣出
condition_short =  (upper_profit_exit | lower_profit_exit) 
`
var input = {
  down_value: {name: "止損(%)", value: "0.05", line: 5},
  up_value: {name: "止賺(%)", value: "0.1", line: 4}
}

const MAX_NUMBER_OF_SYMBOLS = 50

class Manager extends Component {
    constructor(props) {
        super(props)
        this.state = { 
          obj_length : 0,
          filter_length : 0,
          icons : [],
          list : props.list,
          selected : "botfolio",
          //selectedBoard : 'botfolio',

          showFilter : false, 


          selectedBotfolio : {},
          selectedStrategy : {},
          originalBotfolio : {},

          selectedIndex : 0,
          isLogin : props.isLogin,
          //tree : tree["botfolio"],
          code : tmp_code,
          name : "",
          description : "",
          //filters : {},
          filters : [],
          input : input,
          tradelog : [],
          useTemplate : false,
          showReport : false,
          hasError : false,
          tmp_code : tmp_code,
          tmp_input : input,
          showCreate : false,
          isCreate : true,
          readonly : false,
          isOwner : true,
          report : {},
          read_sym : {},
          symbol_list : [],
          original_symbol_list : [],
          filterconfig : {},
        };
        this.updateParent = this.updateParent.bind(this)
        this.selectIcon = this.selectIcon.bind(this)
        this.getList = this.getList.bind(this)
        this.filter = React.createRef()
        this.detail = React.createRef()
        this.templatelist = React.createRef()

        this.create = this.create.bind(this)
        this.get = this.get.bind(this)
        
        this.generate = this.generate.bind(this)
        this.backtest = this.backtest.bind(this)
        this.forwardtest = this.forwardtest.bind(this)
        this.createNewBotfolio = this.createNewBotfolio.bind(this)
        this.abjustFilter = this.abjustFilter.bind(this)
        this.transfromFilter = this.transfromFilter.bind(this)
        this.updateBotfolio = this.updateBotfolio.bind(this)
        this.removeBotfolio = this.removeBotfolio.bind(this)
        this.successCreate = this.successCreate.bind(this)

        this.addStrategy = this.addStrategy.bind(this)
        this.removeStrategy = this.removeStrategy.bind(this)
        this.save = this.save.bind(this)
        this.getReport = this.getReport.bind(this)
        this.run = this.run.bind(this)

        this.updateSaveBotfolio = this.updateSaveBotfolio.bind(this)
        this.update = this.update.bind(this)
        this.check = this.check.bind(this)

        this.updateTemplateList = this.updateTemplateList.bind(this)
        this.saveTemplate = this.saveTemplate.bind(this)
        this.updateTemplate = this.updateTemplate.bind(this)
        
        this.getFeatures = this.getFeatures.bind(this)
        this.error = this.error.bind(this)

        this.getFilterConfigs = this.getFilterConfigs.bind(this)

    }

    componentDidMount() {
      document.title = this.props.gi18n[this.props.language]['manager_title']
      var _this = this
      if (this.props.isLogin) {
        this.getFilterConfigs( this.props )
        this.setState({
          icons : icons,
          //selectedBoard : 'botfolio',
          showFilter : false,
          select : "botfolio",
          //tree : tree["botfolio"],
        })
        if( this.props.list.length > 0 ) {
          _this.get(this.props)
        }
        
      } else {

        this.setState({
          icons : [ icons[0] ],
          //selectedBoard : 'botfolio',
          showFilter : false,
          select : "botfolio",
          //tree : tree["botfolio"],
        })
      }
    }

    componentWillUnmount() {
    }

    //UNSAFE_componentWillReceiveProps(props) {
    componentDidUpdate(props, state) {
      if( this.state.isLogin !== props.isLogin ) {
        var obj = {}
        obj.isLogin = props.isLogin
        obj.icons = [ icons[0] ]
        if (props.isLogin) {
          obj.icons = icons
        } 
        if( this.state.list.length === 0 ) {
          obj.list = props.list
        }
        if( props.isLogin === true ) {
          this.getFilterConfigs(props)
        }
        this.setState(obj)
      }
    }

    getFilterConfigs(props) {
      var config = {}
      var _this = this
      function _updates(ct) {
        var region = props.regions[ct].region
        Api.getFilterConfig( "config" , region ).then(
          (res) => {
            config[region] = res
            ct += 1
            if( ct == props.regions.length ) {
              _this.props.updateValue({showLoading : false})
              _this.setState({  filterconfig : config  })
            } else {
              _updates(ct)
            }
          }
        )
      }
      _this.props.updateValue({showLoading : true})
      _updates(0)
    }

    getFeatures(gp, cb) {
      console.log("FEATURES")
      var region = gp.region?gp.region:"usa"
      var strategy_list = gp.strategy_list
      var features = {}, filter = {}, buy={}, sell={}
      var code = "", lines = [], ids = [], _buys = []
      for (var i in strategy_list) {
        try {
          if( parseFloat( gp.partition[ strategy_list[i].id ], 10 ) > 0 ) {
            for (var f in strategy_list[i].filters ) {
              var key = strategy_list[i].filters[f].key
              if( (key === "basic") || (key === "cashflow") || (key === "debt") || (key === "profit")    ) {
                key = "finstat_" + key
              }
              var parent = this.state.filterconfig[region][ key  ]
              var plang = parent.lang
              if(   parent.type === "object"  ) {
                for (var j in parent.value ) {
                  if(  parent.value[j].key === strategy_list[i].filters[f].type   ) {
                    var obj = {}
                    var clang = parent.value[j].lang  
                    obj.parent = key
                    obj.sub = parent.value[j].key
                    var lang = {}
                    for( var l in clang ) {
                      lang[l] = plang[l] + " - " + clang[l]
                    }
                    obj.lang = lang
                    if( !( strategy_list[i].id in filter ) ) {
                      filter[  strategy_list[i].id ] = { name : strategy_list[i].name, value:{} }
                    }
                    filter[  strategy_list[i].id ].value[key] =  obj 
                  }
                }
              } else if ( parent.vtype === "string" ) {
                var obj = {}                
                obj.parent = key
                obj.lang = plang
                if( !( strategy_list[i].id in filter ) ) {
                  filter[  strategy_list[i].id ] = { name : strategy_list[i].name, value:{} }
                }
                filter[  strategy_list[i].id ].value[key] =  obj 
              }
            }
            var _l = gp.strategy_list[i].code.split("\n")
            lines.push( _l.length-1 )
            code += (gp.strategy_list[i].code + "\n")
            buy[ strategy_list[i].id ] = { name : strategy_list[i].name, value : {} }
            sell[ strategy_list[i].id ]= { name : strategy_list[i].name, value : {} }
            ids.push( strategy_list[i].id ) 
            var bscode = gp.strategy_list[i].code.split( gservice.split_cond  )
            _buys.push( bscode[0].split("\n").length)

          }
        } catch(e) {

        }
      }
      var func_params = [], pattern_match={}
      for(var k in window.func) {
        if( window.func[k].display ) {
          if( k.indexOf("pattern") < 0 ) {
            func_params.push(k)
          } else {
            var value = "pattern_" + window.func[k].args[0].toLowerCase()
            var obj = {}
            pattern_match[value] = k
            func_params.push(k)
          }
        }
      }
      Api.getTreemapNewFromCode( code ).then(
        (res) => {
          var idx = 0, sumline=0
          for( var i in res.treeobj ) {
            if( parseInt(i, 10) > (sumline+parseInt( lines[idx], 10)) ) {
              idx += 1
              sumline  += lines[idx]
            }
            for( var j in res.treeobj[i].function ) {
              var key = Object.keys( res.treeobj[i].function[j] )
              var _obj = {}, _key = ""
              if( key[0] !== "pattern" ) {
                _key = key[0]
              } else {
                var value = "pattern_" + res.treeobj[i].inputs[0].replace(/'/g,"").replace(/"/g,"")
                _key = pattern_match[value.toLowerCase()]  
              }
              //console.log( _key )
              _obj = (_key in window.func)?window.func[  _key  ].lang:{}
              if( func_params.includes(  _key ) ) {
                if( _buys[idx] >= parseInt(i, 10)  ) {
                  buy[  ids[idx]   ].value[ _key ] = _obj
                } else {
                  if( (ids[idx] in sell) ) {
                    sell[  ids[idx]   ].value[ _key ] = _obj 
                  }
                  
                }
              }
            }
            
          }
          features = {
            filter : filter,
            buy : buy,
            sell : sell,
          }
          //console.log(  features )
          if(cb) {
            cb( features )
          }
        }
      ).catch( 
        (e) => {
          features = {
            filter : filter,
            buy : buy,
            sell : sell,
          }
          if(cb) {
            cb( features )
          }
        }
      )
      //Api.getFilterTable( _filters, "all", null, this.state.selectedTable  ).
      
    }

    updateParent(obj) {      
      /*
      if( 'selectedBotfolio' in obj ) {
        if( !obj['selectedBotfolio'].id  ) {
          if( this.detail.current ) {
            this.detail.current.setState({
              obj : null
            })
          }
        }
      }
      */
      this.setState( obj )
    }

    selectIcon( v ) {
      //var tr = tree[ v.key ]
      var obj = {
        selected : v.key,
        showFilter : false,
        //selectedBoard : v.key,
        //selectedBoard : tr[0].key,
        //selectedIndex : 0,
        //obj_length : 0,
        //filter_length : 0,
        //tree : tr,
      }
      obj.selectedBotfolio = {}
      obj.selectedStrategy = {}
      this.setState(obj)
      //console.log( tree[this.state.selected] )
    }

    getList(props) {
      props = props?props:this.props
      if( props.isLogin ) {
        service.getPortfoilioList( {user_id : "me"} ).then(
          (res) => {
            this.setState({
              page : res.page,
              list : res.list 
            })
          }
        )
      }
    }

    get(props) {
      if( props.isLogin ) {
        var code = this.state.code

        var filters = null
        if( !this.props.hasFilter ) {

          filters = [{ key : "symbol", "value:" : this.state.strategy_list }]

        }
        var a = gservice.createStrategyObject( code, "tempstrategy" , "", {}, filters)

        a.id = "temp_1"
        
        a.filters = [ {key: 'data', type: 'symbol', opr: 'in', value:'0700.HK'} ]


        var name = gservice.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
        var temp = props.list.filter( (v,k) => {
          return v.name === name
        } )
        if( temp.length === 0 ) {
          var gp = gservice.createGroupObject( null, name , "" )
          gp.strategy_list = [a]
          gp.partition = { "temp_1" : "100" }
          gp.input = { "temp_1" : {} }
          this.create(gp, null, null, true )
        } else {
          service.getPortfoilioById( temp[0].id ).then(  
            (res) => {
              this.setState({
                //selectedBotfolio : res,
                selectedStrategy : res.strategy_list[0],
                code : tmp_code,
              })
            }
          ) 
        }
      }
    }

    abjustFilter(filter, isCreate, region="hkg") {
      var filters = filter?filter:this.state.filters
      filters = JSON.parse( JSON.stringify( filters ) )
      filters = Object.values( filters )
      for (var i in filters) {
        try {
          var key = filters[i].key
          if( filters[i].key === "constituent" ) {
            filters[i].type = "type"
          }
          if( filters[i].type === "value" ) {
            filters[i].key = "data"
          }

          if( filters[i].key === "symbol" ) {
            filters[i].key = "data"
          }

          if( isCreate ) {
            if(  filters[i].type === "symbol") {
              filters[i].key = "symbol"
              if( 'parent' in filters[i] ) {
                delete filters[i]['parent']
              }
              if( 'table' in filters[i] ) {
                delete filters[i]['table']
              }
            } 
          }

          if( (filters[i].key === "cashflow") || (filters[i].key === "basic") || (filters[i].key === "debt") || (filters[i].key === "profit") )  {
            key = "finstat_" + filters[i].key
          } 

          if(  key in this.state.filterconfig[ region ] ) {
            var p = this.state.filterconfig[ region ][ key ]
            if( 'parent' in p ) {
              filters[i].parent = p.parent
            }
          }

          filters[i].value = filters[i].value.join(",")
        }
        catch(e) {

        }
      }
      return filters
    }

    transfromFilter(filter) {
      //console.log( filter )
        var _filters = {}
        var filters = JSON.parse( JSON.stringify(filter) )
        for (var f in filters) {
          try {
            var _v = filters[f].value.split(",")
            if( _v.length > 1 ) {
              filters[f].value = _v
            }
          }
          catch(e) {
            //console.log(e)
          }
          if( filters[f].key === "constituent" ) {
            filters[f].table = "constituent"
            filters[f].type = "constituent"
            _filters[  "constituent" ] = filters[f]
          } else {
            if( filters[f].type === "symbol" ) {
              filters[f].table = "data"
              filters[f].key = "sector"
              _filters[  filters[f].type  ] = filters[f]
            } else if ( filters[f].key === "symbol" ) {
              filters[f].table = "history"
              filters[f].key = "symbol"
              filters[f].type = "symbol"
              filters[f].opr = "in"
              filters[f].vtype = "string"
              _filters[  filters[f].type  ] = filters[f]
            } else {
              _filters[  filters[f].type  ] = filters[f]
            }
          }
        }
        return _filters
    }

    create(v, filters, cb, isInitial) {
      var b = JSON.parse( JSON.stringify(v)  )
      var g = JSON.parse( JSON.stringify(v) )
      var oldKey = Object.keys(  b.partition  )
      var _this = this

      var _e = this.check(g)    
      if( _e ) {
        this.props.updateValue({
          showAlert : true,
          alertMessage : _e,
          showLoading : false,
        })
        return
      } else { 
        this.props.updateValue({
          showLoading : true
        })
      }

      delete g.input
      delete g.partition
      g.input = {}
      g.partition = {}
      //g.access_mode = "private"

      function _call(newObj) {
        newObj.name = g.name
        newObj.description = g.description
        newObj.initial_capital = g.initial_capital
        newObj.transaction_fee = g.transaction_fee
        //newObj.access_mode = "private"
        service.updatePortfolio( g.id,newObj ).then(
          (r) => {
            g.input = newObj[ 'input' ]
            g.partition = newObj[ 'partition' ]
            service.getPortfoilioById( g.id ).then(
              (res) => {
                _this.props.updateValue({
                  showLoading : false
                })

                var obj = {
                  
                  selectedStrategy : res.strategy_list[0],
                  code : tmp_code
                }

                if(isInitial) {
                  obj.selectedBotfolio = res
                }

                _this.setState(obj)
                if(cb)
                  cb( res, filters )
              }
            )
          }
        )
      }
      function _create(s, id,  newObj ) {
        var strat = {}
        strat.portfolio_id = id
        //if( this.props.hasFilter ) {
          var keys = ["name", "description", "code", "filters", "strategy_template_id" ]
        //} else {
        //  var keys = ["name", "description", "code", "symbol", "strategy_template_id" ]
        //}
        for(var k in keys) {
          if( g.strategy_list[s][ keys[k] ] !== null ) {
            strat[ keys[k] ] = g.strategy_list[s][ keys[k] ]
          }
        }
        service.createStrategy( strat ).then(
          (response) => {
            var oid = oldKey[s]
            newObj.input[ response.id ] = b.input[ oid ]	
            newObj.partition[ response.id ] = b.partition[ oid ]
            g.strategy_list[ s ].id = response.id
            g.strategy_list[ s ].portfolio_id = id
            g.strategy_list[ s ].user_id = service.user.user_id
            s+=1
            if( s === g.strategy_list.length ) {
              _call( newObj )
            } else {
              _create(s, id, newObj)
            }
          }
        ).catch( (e)=> {
          _this.props.updateValue({
            showSelection : false,
            showLoading : false
          })
        })	
        
      }

      service.createPortfolio( g ).then(
        (res)=> {
          g.user = service.user
          g.id = res.id
          var newObj ={ input : {}, partition : {} }
          _create(0, res.id, newObj)
        }
      ).catch( (e)=> {

        var obj = {
          header : "Name must be unique",
          body : (("This Botflio <name> is already exist. Please change to another name").replace("<name>", g.name) )
        }
          _this.props.updateValue({
            showLoading : false,
            showAlert : true,
            alertMessage : obj
          })

      })     
    
    }

    generate(initial , fee) {
      var botfolio = JSON.parse(  JSON.stringify( this.state.selectedBotfolio  ) )
      var bot = JSON.parse(  JSON.stringify( this.state.selectedStrategy   ) )
      if( this.state.useTemplate ) {

      } else {
        bot.code =this.state.code
        delete bot['strategy_template_id']
      }
      var filters = JSON.parse(  JSON.stringify( this.state.filters  ) )
      filters = this.abjustFilter( filters, true )

      bot.filters = filters
      botfolio.initial_capital = parseFloat( initial , 10)
      botfolio.transaction_fee = parseFloat( fee , 10)
      for (var i in botfolio.strategy_list){
        if( botfolio.strategy_list[i].id === bot.id ) {
          botfolio.strategy_list[i] = bot
          botfolio.input[ bot.id ] = this.state.input
          //botfolio.filters[ bot.id ] = this.state.filters
          break
        } 
      }
      delete botfolio['user']
      return botfolio
    }

    backtest(  initial , fee , update, botfolio, include_dividends=false ,demo=false) {
      if( !this.state.hasError ) { 
            botfolio = botfolio?botfolio:this.generate(initial , fee)
            var _e = this.check(botfolio)    
            if( _e ) {
              this.props.updateValue({
                showAlert : true,
                alertMessage : _e,
                showLoading : false,
              })
              return
            }
            this.props.updateValue( {
              showLoading : true
            })

            Api.backtestingS(botfolio, fee, "usa", include_dividends, demo).then(
              (res) => {
                var obj = {}
                if(typeof(res) === 'object' ) {
                  if(! ('message' in res) ) {
                    if( res.length > 0 ) {
                      if( 'msg' in res[0]) {
                        if( res[0]['msg'] === 'excceeded limitation' ) {
                        }
                      } else {
                        obj = {
                          showReport : true,
                          report : res,
                        }
                        if( update) {
                          obj.readonly = false
                          //obj.selectedBotfolio = botfolio
                        } else {
                          obj.readonly = true
                        }
                      }
                    }
                  } else {
                    obj = {

                    }
                  }
                } else {
                  obj = {

                  }
                }
                this.setState( obj )
                this.props.updateValue( {
                  showLoading : false,
                })
              }
            ).catch( (e) => {

              this.props.updateValue( {
                showLoading : false,
                //showAlert : true,
                //alertMsg :  (this.props.language in this.props.gi18n)?this.props.gi18n[ this.props.language ]['error_filters']:"Too many or no symbol are/is found.(No more than 200)" ,
              })
              
            } )
      } 
    }

    forwardtest( read_sym, initial , fee, region="hkg", demo=false  ) {
      if( !this.state.hasError ) {
        var symbol = [], _o = {}
        for(var f in read_sym) {
          for( var i in read_sym[f] ) {
            _o[ read_sym[f][i]  ] = true
          }
        }
        symbol = Object.keys( _o )
        var botfolio = this.generate(initial , fee)

        var _e = this.check(botfolio)    
        if( _e ) {
          this.props.updateValue({
            showAlert : true,
            alertMessage : _e
          })
          return
        }

        var n_filter = [], _of={}
        _of.key = "data"
        _of.type = "symbol"
        _of.opr = "in"
        _of.value = symbol.join(",")
        n_filter.push( _of )
        if( botfolio.strategy_list.length > 0  ) {
          botfolio.strategy_list[0].filters = n_filter
        }
        this.props.updateValue({
          showLoading : true
        })
        Api.forwardtestingS( botfolio, {} , 0, region, demo ).then(
          (res) => {
              var fsyms = res.value.map( (v,k) => {
                return v.symbol 
              })
              if( this.filter.current ) {
                this.filter.current.updateFilterValue( { forward : fsyms } )
              }
              this.props.updateValue({
                showLoading : false
              })
          }
        ).catch( (e) => {
          this.props.updateValue({
            showLoading : false
          })
        } )
      }
    }

    createNewBotfolio( obj ) {
      if( !this.state.hasError ) {
        var p = JSON.parse( JSON.stringify( this.state.selectedBotfolio ) )
        var filters = JSON.parse( JSON.stringify( this.state.filters ) )
        filters = this.abjustFilter( filters, true)

        if( p.strategy_list.length > 0 ) {
          p.strategy_list[0].filters = filters
        } else {
          p.strategy_list = []

          //if( this.props.hasFilter ) {
            var strat = gservice.createStrategyObject( null, null , null, null )
          //} else {
            //var filters= [ { key : "symbol", value : JSON.parse(  JSON.stringify( this.state.original_symbol_list) )  }]
            //var strat = gservice.createStrategyObject( null, null , null, null, filters )
          //}
          p.strategy_list.push( strat )
        }
        var sid = p.strategy_list[0].id
        if( !this.state.useTemplate ) {
          p.strategy_list[0].name = "Strategy 1"
          p.strategy_list[0].description = "No description"
          p.strategy_list[0].code = this.state.code
          p.input = {}
          p.input[sid] = this.state.input
          delete p.strategy_list[0].strategy_template_id
        }
        p.partition = {}
        p.partition[sid] = "100"
        p.name = obj.name
        p.description = obj.description
        p.initial_capital = parseFloat( obj.initial_capital, 10)
        p.transaction_fee = parseFloat( obj.transaction_fee, 10)

        this.create(p, null, this.successCreate )
      }
    }

    successCreate() {
      this.props.getBotfolioList( true )
      var _obj = {
        header : "Success",
        body : "Succesfully created a Botfolio.",
      }
      this.props.updateValue( {
        showAlert : true,
        alertMessage : _obj,
      } )

      this.setState({
        selected : "botfolio",
        //selectedBoard : 'botfolio',
        showFilter : false,
        //tree : tree["botfolio"],
      })
    }

    updateBotfolio( type, value ) {
      var botfolio = JSON.parse(  JSON.stringify( this.state.selectedBotfolio )  )
      if( typeof(type) === "object" ) {
        for( var i in type) {
          botfolio[ type[i] ] = value[i]
        }
      } else {
        botfolio[ type ] = value
      }
      //console.log( "In" )
      this.setState({
        selectedBotfolio : botfolio
      })

    }

    removeBotfolio( id ) {
      //console.log(id)
      //console.log( this.props )
      service.deletePortfolio(id).then(
        (res) => {
          this.setState( {
            selectedBotfolio : {},
            selectedStrategy : {}
          } )
          this.props.getBotfolioList(true)
        }
      )
    }

    addStrategy(strat) {
      var botfolio = JSON.parse( JSON.stringify( this.state.selectedBotfolio ) )
      botfolio.strategy_list.push(strat)
      botfolio.input[  strat.id ] = strat.input
      botfolio.partition[ strat.id ] = "0"
      this.setState({
        selectedBotfolio : botfolio
      })
    }

    removeStrategy(strat) {
      var botfolio = JSON.parse( JSON.stringify( this.state.selectedBotfolio ) )
      for (var i in botfolio.strategy_list) {
        if( botfolio.strategy_list[i].id === strat.id ) {
          botfolio.strategy_list.splice( i, 1 )
          delete botfolio.input[  strat.id ]
          delete botfolio.partition[ strat.id ] 
          break
        }
      }
      this.setState({
        selectedBotfolio : botfolio
      })

    }

    getReport(type) {
      this.props.updateValue({  showLoading : true  })
      var id = this.state.selectedBotfolio.id
      var rid = this.state.selectedBotfolio[ type + "_report"].id
      //console.log(  this.state.selectedBotfolio  )
      if( type === 'backtest'  ) {
        if( this.state.selectedBotfolio.subscription  ){
          id = this.state.selectedBotfolio.subscription.obj.id
          rid = this.state.selectedBotfolio.subscription.obj[ type + "_report"].id
        } 
      }

      service.getReport( id , rid  ).then(
        (res) => {
          this.setState({
            report : res.trade_list,
            showReport : true,
            readonly : true,
          })
          this.props.updateValue({  showLoading : false  })
        }
      ).catch( (e) => {
        this.props.updateValue({  showLoading : false  })
      })
    }

    save() {
      //console.log( this.state.selectedBotfolio )
      this.updateSaveBotfolio()
      //this.props.getBotfolioList(true)
    }

    updateSaveBotfolio() {
      //this.props.updateValue({  showLoading : false  })
      var b = JSON.parse(  JSON.stringify( this.state.selectedBotfolio )  )
      service.updatePortfolio(  b.id, b ).then(
        (res) => {
          this.props.getBotfolioList(true)
          //this.props.updateValue({  showLoading : true  })
        }
      ).catch( (e) => {
        //this.props.updateValue({  showLoading : false  })
      })
    }

    run(  asset_list, region="hkg", demo=false ) {
      var params = {}
      for (var i in asset_list) {
          asset_list[i].unit = 1
          if(  "unit" in  asset_list[i] ) {
            asset_list[i].strategy = asset_list[i].strategy_id.toString()
            //console.log( asset_list[i]   )
            if(  !(asset_list[i].symbol in params)  ){
              params[ asset_list[i].symbol ] = {}
            }
            if(  !( asset_list[i].strategy_id in params[ asset_list[i].symbol ])  ) {
              params[ asset_list[i].symbol ][   asset_list[i].strategy_id   ] = {}
            }
            if(  "unit" in  asset_list[i] ) {
              var d = '27070740', duration = 0
              if( asset_list[i].last_purchase_time ) {
                d = service.formatDate( asset_list[i].last_purchase_time, null, true  )
                d = d.getTime()/1000
                var now = new Date()
                now = now.getTime()/1000
                duration = (now - d)
              }
              params[ asset_list[i].symbol ][ asset_list[i].strategy] = {}
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['betsizeonhold'] = asset_list[i].unit.toString()
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['betsize'] =  asset_list[i].unit.toString()
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['shortbetsize'] = asset_list[i].unit.toString()
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['onstock'] = asset_list[i].unit.toString()
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['marketposition'] = asset_list[i].unit.toString()
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['onmarket'] = asset_list[i].unit.toString()
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['entryprice'] = asset_list[i].price.toString()
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['entrytime'] = d/60/60/24
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['last_purchase_time'] = asset_list[i].last_purchase_time
              params[ asset_list[i].symbol ][ asset_list[i].strategy]['barssinceentry'] = duration.toString()
            }
          }
      }
      var strategy = {}, partition = 100
      var group = JSON.parse(   JSON.stringify( this.state.selectedBotfolio )   )
      service.getPortfoilioById( group.id  ).then(
        (res) => {
          for (var s in res.strategy_list) {
            strategy[ res.strategy_list[s].id ] =res.strategy_list[s]
            partition -= parseFloat(  res.partition[ res.strategy_list[s].id ] , 10  )
          }
          if( partition > 0) {
            strategy['cash'] = { id:'cash', name:this.props.gi18n[ this.props.language ]['cash']}
          }
          var _obj = {}, transaction_fee = 0
          _obj.showLoading = true
          _obj.tpage = "action"
          this.props.updateValue(_obj)
          Api.forwardtestingS( res, params, parseFloat(transaction_fee, 10), region, demo ).then(
            (res) => {
              var obj = {}
              obj['action'] = []
              if( typeof(res) !== "string" ) {
                if( 'value' in res ) {
                  for (var s in res['value']) {
                    res['value'][s]['tmp_decision'] = res['value'][s]['decision']
                    res['value'][s]['strategy_id'] = res['value'][s]['group']
                	  res['value'][s]['action'] = (res['value'][s]['decision']>0)?"buy":"sell"
                  }
                  obj['action'] = res['value']
                } 
              }
              obj['group'] = group
              obj['onhold'] = asset_list 
              var valid = true
              var fg = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
              for( var i in fg) {
                if( fg[i].group.id === obj.group.id ) {
                  fg[i] = obj
                  valid = false
                  break
                }
              }
              if( valid ) {
                fg.push( obj )
              }

              this.props.updateValue({
                showTrader : true,
                forwardGroup : fg,
                showLoading : false,
                tpage : "action",
              })
            }
          ).catch( (e) => {
            this.props.updateValue({
              showLoading : false,
              showAlert : true,
              alertMessage : {
                header :  this.props.gi18n[this.props.language]['error'] ,
                body : this.props.gi18n[this.props.language]['backtesting_error']
              },
            })
          } )

        }
      )
    }

    update() {

      
      var s = JSON.parse(  JSON.stringify( this.state.selectedStrategy ) )
      var f = JSON.parse(  JSON.stringify( this.state.filters ) )
      s.code = this.state.code    
      s.filters = f

      var _this = this
      var o = JSON.parse(  JSON.stringify( this.state.originalBotfolio  )  )
      var b = JSON.parse(  JSON.stringify( this.state.selectedBotfolio )  )

      for ( var i in b.strategy_list ) {
        if(  b.strategy_list[i].id === s.id  ) {
          b.strategy_list[i] = s
          this.treemaping( s.code )
          break
        }
      }
      var _e = this.check(b)    
      if( _e ) {
        this.props.updateValue({
          showAlert : true,
          alertMessage : _e
        })
        return
      }
      if(  JSON.stringify( o ) !== JSON.stringify(  b )  ){
        this.props.updateValue({
          showLoading : true
        })
        var a = {}
        var ids = this.state.originalBotfolio.strategy_list.map( (v,k)=>{
          a[ v.id ] = v
          return v.id
        } )
        var updates = []
        for(var i in b.strategy_list ) {
          if(  ids.includes(  b.strategy_list[i].id  ) ){
            if(  JSON.stringify( a[b.strategy_list[i].id ] ) !== JSON.stringify( b.strategy_list[i] )  ) {
              updates.push(  { type : "update", value : b.strategy_list[i] } ) 
            }
          }
        }
        var nids = this.state.selectedBotfolio.strategy_list.map( (v,k)=>{
          return v.id
        } )

        function _update(b) {
          service.updatePortfolio(  b.id, b ).then(
            (res) => {
              b.input[ s.id  ] = _this.state.input
              _this.setState({
                selectedBotfolio : b,
                originalBotfolio : b,
              })
              _this.props.updateValue({
                showLoading : false,
                showAlert : true,
                alertMessage : {
                  header : "Update success",
                  body : "Updated"
                }
              })
              _this.props.getBotfolioList(true) 
            }
          ).catch( (e) => {
            _this.props.updateValue({
              showLoading : false
            })
          } )
        }
  
        function _call( idx, updates , b ) {
          var _oid = updates[idx].value.id
          if(  updates[idx].type === "update" ) {
            service.updateStrategy( updates[idx].value.id, updates[idx].value ).then(
              (res) => {
                if( idx === updates.length-1 ) {
                  setTimeout( (e) => {
                    _update(b)
                  } , 300)
                  
                } else {
                  _call(  idx+1, updates, b )
                }
              }
            ).catch( (e) => {
              _this.props.updateValue({
                showLoading : false
              })
            } )
          } 
        }
  
        if( updates.length > 0 ) {
            _call(0, updates, b)
        } else {
          if(  JSON.stringify( o ) !== JSON.stringify(  b )  ){
            _update( b )
          }
        }
      }
      

    }

    check(b) {
      var obj = {}
      b = JSON.parse( JSON.stringify(b) )
      var _p = 0
      for (var i in b.partition) {
        _p += parseInt( b.partition[i] , 10 )
      }
      if( (_p > 100) || ( _p < 0 ) || ( !_p )  ) {
        obj.header = "Partition"
        obj.header = this.props.gi18n[ this.props.language ]['partition']
        obj.body = this.props.gi18n[ this.props.language ]['error_partition']
        return obj
      }
      //strategy name not null
      //Filter is empty
      for (var i in b.strategy_list) {
        try {
          if(  b.strategy_list[i].id in b.partition  ) {
            if(  parseInt( b.partition[ b.strategy_list[i].id  ] ) > 0   ){
              if(  (b.strategy_list[i].name === "") || (!b.strategy_list[i].name) ) {
                obj.header = this.props.gi18n[ this.props.language ]['strategy_name']
                obj.body = this.props.gi18n[ this.props.language ]['error_strategy_name']
                return obj
              } else if (b.strategy_list[i].filters)  {
                if (b.strategy_list[i].filters.length === 0)  {
                  //if(  !b.strategy_list[i].strategy_template_id ) {
                  obj.header = this.props.gi18n[ this.props.language ]['error']
                  obj.body = this.props.gi18n[ this.props.language ]['error_filter']
                  return obj
                  //}
                }
              }
            }
          }
        } catch(e) {
            obj.header = this.props.gi18n[ this.props.language ]['error']
            obj.body = this.props.gi18n[ this.props.language ]['error_filter']
            return obj
        }
        
      }
      //group name not null
      if(  ( b.name === "" ) || ( !b.name ) ) {
        obj.header = this.props.gi18n[ this.props.language ]['group_name']
        obj.body = this.props.gi18n[ this.props.language ]['error_botfolio_name']
        return obj
      }
      

      return null
    }

    treemaping(code, cb, response) {
      //NEED FIXES
      var _this = this
      this.props.updateValue({ showLoading : true })
      Api.getTreemapNewFromCode(code).then(
        (res) => {
          if( 'message' in res ) {
            this.error( this.props.gi18n[this.props.language]['error_msg'] )
            _this.setState({ showLoading : false })
          } else{ 
            var a = gservice.reshape(   res['treeobj'], null, this.error  )
            //var _input = 
            var obj = {
              //code : res.code,
              input : res['input'],
              //selectedBoard : 'buy',
            }
            if( a ) {
              obj.hasError = false
            } else {

            }
            if( this.filter.current ) {
              this.filter.current.error()
            }
            _this.setState( obj )
            if(cb) {
              response['input'] = res['input']
              cb( response )
            }
          }
          _this.props.updateValue({ showLoading : false })
        } 
      ).catch(
        (err)=>{
          this.error( this.props.gi18n[this.props.language]['error_msg']  )
          _this.props.updateValue({ showLoading : false })
        }
      )
    }

    updateTemplateList() {
      if( this.templatelist.current ) {
        this.templatelist.current.getMyTemplates()
      }
    }

    updateTemplate() {
      //console.log(t)
      var t = JSON.parse( JSON.stringify( this.state.selectedStrategy ) )
      //var _f = this.abjustFilter( this.state.filters, true )
      //console.log( this.state.input )
      t.code = this.state.code
      t.input = this.state.input
      //t.filters = _f
      t.filters = JSON.parse( JSON.stringify( this.state.filters ) )
      this.treemaping( t.code, this.saveTemplate, t )
      //console.log( t )
      //this.saveTemplate( t )

    }

    saveTemplate(t) {
      this.props.updateValue({
        showLoading : true
      })
      t = t?t:JSON.parse( JSON.stringify( this.state.selectedStrategy ) )
      t.code = this.state.code
      t.input = this.state.input
      t.filters = JSON.parse( JSON.stringify( this.state.filters ) )

      service.updateTemplate(t.id, t).then(
        (res) => {
          this.updateTemplateList()
          this.props.updateValue({
            showLoading : false
          })
          this.setState({
            selectedStrategy : t,
          })
        }
      ).catch(
        (e) => {
          this.props.updateValue({
            showLoading : false
          })
          this.setState({
            selectedStrategy : t,
          })
        }
      )
      
    }

    error(msg) {
      if( this.filter.current ) {
        this.filter.current.error(msg)
      }
    }

    render() {
      if ( this.props.isLogin && (Object.keys( this.state.filterconfig ).length > 0) ) {
        return (
          <div className={['GManager'].join(" ") }>
            <CreatePanel 
              regions={this.props.regions}
              logosrc={ this.props.logosrc }
              symbol_list={ this.state.symbol_list }
              original_symbol_list= {  this.state.original_symbol_list }
              tmp_code={this.state.tmp_code}
              tmp_input={this.state.tmp_input}
              isCreate={this.state.isCreate}
              selectedBotfolio={this.state.selectedBotfolio}
              originalBotfolio={this.state.originalBotfolio}
              updateValue={this.props.updateValue} 
              updateParent={this.updateParent} 
              updateBotfolio={this.updateBotfolio} 
              language={ this.props.language } 
              gi18n={this.props.gi18n} 
              showCreate={this.state.showCreate} 
              isLogin={this.props.isLogin} 
              transfromFilter={this.transfromFilter}
              getBotfolioList={this.props.getBotfolioList}/>	

            <div className={['Left'].join(" ") }>
              <div className={['Control'].join(" ") }>
                { this.props.isLogin &&
                  <OverlayTrigger
                        placement={"right"}
                        overlay={
                          <Tooltip bsPrefix={"TP"}>
                            <strong>{  this.props.gi18n[this.props.language][ "trader" ]  }</strong>
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon 
                          icon={ faCartArrowDown} 
                          onClick={
                            (e) => {
                             
                              this.props.updateValue({
                                showTrader : !this.props.showTrader
                                //showTrader : false,
                              })
                            }
                          }
                          className={['icon', 'Cart' ].join(' ')} >
                        </FontAwesomeIcon>
                  </OverlayTrigger>
                }
                { this.props.isLogin &&
                  <div className={['Break'].join(" ") }></div>
                }

                { this.state.icons.map( (v,k) => {
                  return(
                    <OverlayTrigger
                      key={k}
                      placement={"right"}
                      overlay={
                        <Tooltip bsPrefix={"TP"}>
                          <strong>{  this.props.gi18n[this.props.language][ v.value ]  }</strong>
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon 
                        icon={ v.icon } 
                        onClick={
                          (e) => {
                            this.selectIcon(v)
                          }
                        }
                        className={['icon', (this.state.selected===v.key)?"selected":""  ].join(' ')} >
                      </FontAwesomeIcon>
                    </OverlayTrigger>
                  )
                } )
                }
              </div>
            </div>

            { 
              (this.state.selected==="botfolio")  && 
              <div className={['Middle', ((this.state.selected==="botfolio") && (this.state.showFilter === false)   )?"appear":"" ].join(" ") }>
                <Botfolio 
                  {...this.props} 
                  getFeatures={this.getFeatures}
                  updateParent={this.updateParent} 
                  list={this.props.list} 
                  selectedBotfolio={this.state.selectedBotfolio} 
                  removeBotfolio={this.removeBotfolio} />
              </div>
            }
            { 
              (this.state.selected==="botfolio")  && 
              <div className={['Middle2', (( (  this.state.showFilter === false ) && (this.state.selected==="botfolio") && ( "id" in this.state.selectedBotfolio ) )?"appear":"") ].join(" ") }>
                <Detail 
                  {...this.props} 
                  transfromFilter={this.transfromFilter}
                  getFeatures={this.getFeatures}
                  updateSaveBotfolio={ this.updateSaveBotfolio }
                  updateParent={this.updateParent} 
                  selectedBotfolio={this.state.selectedBotfolio} 
                  selectedStrategy={this.state.selectedStrategy} 
                  updateBotfolio={this.updateBotfolio} 
                  tmp_code={this.state.tmp_code} 
                  tmp_input={this.state.tmp_input} 
                  addStrategy={this.addStrategy} 
                  removeStrategy={this.removeStrategy} 
                  backtest={this.backtest}
                  getReport={this.getReport}
                  filter={this.filter}
                  ref={this.detail} />
              </div>
            }
            { 
              (this.state.selected==="botfolio")  && 
              <div className={['Middle3', (( (  this.state.showFilter === false ) && (this.state.selected==="botfolio") && ( "id" in this.state.selectedBotfolio )  )?"appear":"") ].join(" ") }>
                <TradeLog 
                  {...this.props} 
                  run={this.run}
                  updateParent={this.updateParent} 
                  selectedBotfolio={this.state.selectedBotfolio} 
                  selectedStrategy={this.state.selectedStrategy} 
                  tradelog={this.state.tradelog} />
              </div>
            }   

            { 
              (this.state.selected==="template")  && 
              <div className={['Middle', ( (this.state.selected==="template") &&  (this.state.showFilter === false)  )?"appear":"" ].join(" ") }>
                <TemplateList
                  {...this.props} 
                  ref={this.templatelist}
                  selectedStrategy={this.state.selectedStrategy}
                  transfromFilter={this.transfromFilter}
                  updateParent={this.updateParent} 
                  symbol_list={this.state.symbol_list}
                  original_symbol_list={ this.state.original_symbol_list }
                />
              </div>
            }

              <div className={['Middle2', (( (this.state.selected==="template" &&  this.state.showFilter === false) && (this.state.selectedStrategy.id))?"appear":"") ].join(" ") }>
                <TemplateDetail 
                  {...this.props}
                  code={this.state.code}
                  input={this.state.input}
                  filters={this.state.filters}
                  backtest={ this.backtest }
                  abjustFilter={ this.abjustFilter }
                  transfromFilter={this.transfromFilter}
                  updateTemplateList={this.updateTemplateList}
                  updateParent={this.updateParent} 
                  selectedStrategy={this.state.selectedStrategy} 
                  saveTemplate={this.saveTemplate}
                  ref={this.detail} />
              </div>


            <div className={['Right'].join(" ") }>
              { /*this.props.hasFilter &&*/ (
                  ( (this.state.selected==="botfolio") && (  this.state.showFilter === true ) ) ||
                  ( (this.state.selected==="template") && (  this.state.showFilter === true ) )
                ) && 
                <Filter 
                  {...this.props} 
                  //tree={ this.state.tree }
                  update={this.update}
                  saveTemplate={this.saveTemplate}
                  filterconfig={ this.state.filterconfig }
                  symbol_list={ this.state.symbol_list }
                  original_symbol_list={  this.state.original_symbol_list }  
                  maxNumberSyms={ MAX_NUMBER_OF_SYMBOLS }
                  updateParent={this.updateParent} 
                  list={this.props.list} 
                  selected={this.state.selected}
                  isFilter={  false } 
                  getList={this.getList} 
                  selectedBotfolio={this.state.selectedBotfolio} 
                  selectedStrategy={this.state.selectedStrategy} 
                  abjustFilter={ this.abjustFilter }
                  selectIcon={this.selectIcon}  
                  ref={this.filter} 
                  code={ this.state.code } 
                  useTemplate={this.state.useTemplate} 
                  name={this.state.name} 
                  description={this.state.description} 
                  filters={this.state.filters} 
                  input={this.state.input} 
                  backtest={this.backtest} 
                  forwardtest={this.forwardtest}
                  createNewBotfolio={this.createNewBotfolio} 
                  transfromFilter={ this.transfromFilter }
                />
              }
            </div>

            { this.props.language in this.props.gi18n && 
						  <GarlicanReport 
                {...this.props}
                showReport={this.state.showReport} 
                report={this.state.report} 
                group={this.state.selectedBotfolio} 
                isOwner={ this.state.isOwner } 
                readonly={ this.state.readonly }
                type={"backtest"} 
                save={this.save}
                editGroup={this.updateBotfolio}
                updateValue={ this.updateParent } 
              />
	  				}


            <Trader {...this.props} ref={this.props.trader_ref} showTrader={ this.props.showTrader  } draggable={true} host={'manager'} />



          </div>
        )
      } else {
          return (
              <Login {...this.props} />
          )
      }

    }
}


export default Manager;



