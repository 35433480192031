import React, { Component } from 'react';
import './index.scss';

import { Button,Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  } from '@fortawesome/free-solid-svg-icons'
import gservice from '../../../Garlican/GarlicanLibrary/gservice'
import Api from '../../../Garlican/GarlicanLibrary/apis'
import service from '../../../public/service';


class Simple extends Component {

    constructor(props) {
      super(props)
      this.state = {

      }

      this.create_block = this.create_block.bind(this)

    }

    componentDidMount() {
      //console.log( this.props.code ) 

      var Sk = window.Sk
      //console.log( Sk )
      //var parse = Sk.parse("none", this.props.code);
      //console.log( parse )
      //var ast = Sk.astFromParse(parse.cst, "none", parse.flags);
      //console.log( ast )
      var lines = this.props.code.split("\n")
      for ( var i in lines ) {
        var parse = Sk.parse("none", lines[i]);
        var ast = Sk.astFromParse(parse.cst, "none", parse.flags);
        this.convert( ast )
      }


      var ele = this.create_block( "ast_Raw", 0, {
        "TEXT": this.props.code
      })
      console.log(ele)
      /*
      Api.getTreemapNewFromCode( this.props.code   ).then(
        (res) => {
          var tp = res.treeobj
          var rsp = gservice.reshape( tp )
          //var compGp = gservice.createCompGp(  this.props.code, tp, rsp  )
          var response = gservice.returnObj( tp, this.props.code, true  )
          var _res = response['response']
          var line = parseInt(response['line'], 10)
          var ro = { }
          for (var i in _res) {
            ro[ parseInt(i, 10) ] = true

          }
          var lg = gservice.getLineGroup( tp, line, this.props.code, res, {lines : ro } )
          //console.log(lg)
          for (var i in lg.group ) {
            var obj = lg.group[i]
            response = gservice.getLineComp(i, obj, rsp, tp, true )
            console.log( response )
          }

        }
      )
      */

    }

    componentWillUnmount() {
    }

    componentDidUpdate(props, state) {
     
    }

    create_block(type, lineNumber, fields, values, settings, mutations, statements) {
      var newBlock = document.createElement("block"); // Settings
    
      newBlock.setAttribute("type", type);
      newBlock.setAttribute("line_number", lineNumber);
    
      /*
      for (var setting in settings) {
        var settingValue = settings[setting];
        newBlock.setAttribute(setting, settingValue);
      } // Mutations
    
    
      if (mutations !== undefined && Object.keys(mutations).length > 0) {
        var newMutation = document.createElement("mutation");
    
        for (var mutation in mutations) {
          var mutationValue = mutations[mutation];
    
          if (mutation.charAt(0) === '@') {
            newMutation.setAttribute(mutation.substr(1), mutationValue);
          } else if (mutationValue != null && mutationValue.constructor === Array) {
            for (var i = 0; i < mutationValue.length; i++) {
              var mutationNode = document.createElement(mutation);
              mutationNode.setAttribute("name", mutationValue[i]);
              newMutation.appendChild(mutationNode);
            }
          } else {
            var _mutationNode = document.createElement("arg");
    
            if (mutation.charAt(0) === '!') {
              _mutationNode.setAttribute("name", "");
            } else {
              _mutationNode.setAttribute("name", mutation);
            }
    
            if (mutationValue !== null) {
              _mutationNode.appendChild(mutationValue);
            }
    
            newMutation.appendChild(_mutationNode);
          }
        }
    
        newBlock.appendChild(newMutation);
      } // Fields
     */
    
      for (var field in fields) {
        var fieldValue = fields[field];
        var newField = document.createElement("field");
        newField.setAttribute("name", field);
        newField.appendChild(document.createTextNode(fieldValue));
        newBlock.appendChild(newField);
      } // Values
    
    /*
      for (var value in values) {
        var valueValue = values[value];
        var newValue = document.createElement("value");
    
        if (valueValue !== null) {
          newValue.setAttribute("name", value);
          newValue.appendChild(valueValue);
          newBlock.appendChild(newValue);
        }
      } // Statements
    */
    
      if (statements !== undefined && Object.keys(statements).length > 0) {
        for (var statement in statements) {
          var statementValue = statements[statement];
    
          if (statementValue == null) {
            continue;
          } else {
            for (var _i3 = 0; _i3 < statementValue.length; _i3 += 1) {
              // In most cases, you really shouldn't ever have more than
              //  one statement in this list. I'm not sure Blockly likes
              //  that.
              var newStatement = document.createElement("statement");
              newStatement.setAttribute("name", statement);
              newStatement.appendChild(statementValue[_i3]);
              newBlock.appendChild(newStatement);
            }
          }
        }
      }
    
      return newBlock;
    };

    convert (node, parent) {
      var functionName = 'ast_' + node._astname;
    
      //if (this[functionName] === undefined) {
      //  throw new Error("Could not find function: " + functionName);
      //}
    
      //node._parent = parent;
      //return this[functionName](node, parent);
      console.log( functionName )
    };

    render() {    
      return(
        <div className={['Simple' ].join(" ")} >


        </div>
      )
    }
}


export default Simple;

