import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Button, Tabs, Tab, Dropdown, DropdownButton, OverlayTrigger, Tooltip, Image  } from 'react-bootstrap'

//import logoB from '../../public/img/garlican_blue_logo.png'

import service from '../../public/service'
import Api from '../../Garlican/GarlicanLibrary/apis'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faShoppingCart , faPlusSquare, faMinusSquare, faHandHoldingDollar, faCartShopping, faRssSquare, faBowlRice, faTimes, faMinus  } from '@fortawesome/free-solid-svg-icons'

import Draggable from 'react-draggable'; // The default
import { Resizable } from 'react-resizable';

//import Docker from './component/docker'
//import TLogin from './component/tlogin'
//import TSummary from './component/tsummary'
//import TInsert from './component/insert'

import Onhold from './component/onhold'
import Action from './component/action'


import sptrader from './service/sp'


const MIN_WIDTH = 320
const MIN_HEIGHT = 500

const selections = {
  onhold : [
    { key : 'onhold' , value : 't_onhold'},
    { key : 'all' , value : 'all'},
    { key : 'public' , value : 'ispublic'},
    { key : 'private' , value : 'isprivate'},
    { key : 'subscribed' , value : 'isPurchase'},
  ],
  summary : [
    { key : 'onhold' , value : 't_onhold'},
    { key : 'action' , value : 't_actions'},
    { key : 'all' , value : 'all'},
    { key : 'public' , value : 'ispublic'},
    { key : 'private' , value : 'isprivate'},
    { key : 'subscribed' , value : 'isPurchase'},
  ],
  action : [
    { key : 'onhold' , value : 't_onhold'},
    { key : 'action' , value : 't_actions'},
    { key : 'all' , value : 'all'},
    { key : 'public' , value : 'ispublic'},
    { key : 'private' , value : 'isprivate'},
    { key : 'subscribed' , value : 'isPurchase'},
  ]
}

const board = {
  onhold:[
    'original',
    'current',
    'change'],
  summary : ['buy_value', 'sell_value'],
  action : ['buy_value', 'sell_value'],
}

class Trader extends Component {

    constructor(props) {
        super(props)
        var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		    var obj  = storage.getItem("garlican@spobj")
        if(obj) {
          if( obj.length > 0 ) {
            sptrader.obj = JSON.parse( obj )
          }
        }
        var isTLogin = false
        var tabs = this.getTab(isTLogin)

        this.state = {
          height :  (this.props.host === "manager")?(window.innerHeight-50):(!this.props.disable?(window.innerHeight-35):window.innerHeight  ),
          width: (this.props.host === "manager")?320:(window.innerWidth) ,
          show : false,
          group : [],
          oriGroup : [],
          page : 0,
          isTLogin : isTLogin,
          //username : "APIA2",
          //password : "1234",
          username : "",
          password : "",
          symbols : [],
          showLogin : false,
          selected : null,
          selectedTab : props.page?props.page:tabs[0].key,
          tabs : tabs,
          fg : [],
          price : {},
          isReading : false,
          isAdd : false,
          sporders : [],

          sp_pending : {},
          showOnhold : true,
          showNohold : true,
          //selectedSort : 0,

          original : 0,
          current : 0,
          change : 0,
          buy_value : 0,
          sell_value : 0,

          trader_updates : [],
          props: service.checkProps(props),
          prices : {},

          forwardGroup : props.forwardGroup
        }
        this.handleStart = this.handleStart.bind( this )
        this.handleDrag = this.handleDrag.bind( this )
        this.handleStop = this.handleStop.bind( this )
        this.onresize = this.onresize.bind(this)
        //this.pop = this.pop.bind(this)
        this.show = this.show.bind(this)
        this.updateValue = this.updateValue.bind(this)
        this.login = this.login.bind(this)

        this.initialize = this.initialize.bind(this)
        this.updateRealPrice = this.updateRealPrice.bind(this)
        this.getPrice = this.getPrice.bind(this)
        this.updateList = this.updateList.bind(this)
        this.updateGroup = this.updateGroup.bind(this)
        this.moveToAction = this.moveToAction.bind(this)
        this.getSymbol = this.getSymbol.bind(this)
        this.updatePriceFromState = this.updatePriceFromState.bind(this)

        this.selectKey = this.selectKey.bind(this)
        this.getTab = this.getTab.bind(this)
        this.onClose = this.onClose.bind(this)
        this.insert = this.insert.bind(this)

        this.order = this.order.bind(this)

        this.mcupdate = this.mcupdate.bind(this)
        this.mcmessage = this.mcmessage.bind(this)
        this.getSPOrders = this.getSPOrders.bind(this)

        this.updateRealTrade = this.updateRealTrade.bind(this)
        this.run = this.run.bind(this)

        this.readListByType = this.readListByType.bind(this)
        this.updateRealTradeById = this.updateRealTradeById.bind(this)
        this.deleteOrder = this.deleteOrder.bind(this)

        //this.sort = this.sort.bind(this)
        this.getGroup = this.getGroup.bind(this)
        this.calc = this.calc.bind(this)

        this.getOnholdComp = this.getOnholdComp.bind(this)
        this.getActionComp = this.getActionComp.bind(this)
    }

    componentDidMount() {
      window.document.trader = this
      var _this = this
      if(  this.props.disable   ) {
        if( window.opener !== null ) {
          window.opener.call_app( "page" , "call_parent" , "Connected"   )  
          if( this.state.selectedTab === 'onhold' ) {
            this.selectKey( this.state.selectedTab, this.props.forwardGroup )
          }

          
          window.addEventListener("beforeunload", (ev) => {  
              ev.preventDefault();
              window.opener.call_app( "page" , "call_parent" , "Disconnected"   )
              window.opener.call_app( "page" , "unpop" , ""   )
              return ev.returnValue = 'Are you sure you want to close?';
          });
        } else {
          window.location.href = window.location.origin
        }
      } else {
        this.selectKey( this.state.selectedTab, this.props.forwardGroup )
      }  
      window.addEventListener("resize" , (e) => {
        //var w = Math.max(window.innerWidth, MIN_WIDTH)
        //var h = Math.max(window.innerHeight, MIN_HEIGHT)
        _this.setState({
          width : window.innerWidth,
          height : window.innerHeight
        })
      } ) 
    }

    shouldComponentUpdate(props, state) {
      var _p = service.checkProps(props)
      if( JSON.stringify( _p ) !== JSON.stringify( state.props )  ) {
        if( props.showTrader ) {
          console.log(  "CHANGE : PARENT"  )
          var o = { props : _p}
          this.setState( o )
          if(  JSON.stringify( _p.forwardGroup ) !== JSON.stringify( state.props.forwardGroup ) ) {
            var page = props.tpage?props.tpage:this.state.selectedTab
            if( page === 'onhold' ) {
              this.selectKey( this.state.selectedTab,  props.forwardGroup ,true )
            } else {
              this.selectKey( page, props.forwardGroup, true )
            }
          }          
          return true
        }  else {
          if( (props.showTrader !== state.props.showTrader) ) {
            var o = { props : _p}
            this.setState( o )
            return true
          } else if ( (state.width !== this.state.width) || (state.height !== this.state.height)   ) {
            return true
          } else {
            return false
          }
        }
      } else {
        if( props.showTrader ) {
          //console.log( JSON.stringify( state) )
          //console.log( JSON.stringify(this.state) )
          if( JSON.stringify(this.state) !== JSON.stringify(state) ) {
            console.log(  "CHANGE : CHILDREN"  )
            return true
            //return false
          } else {
            //console.log(  "NO CHANGE"  )
            return false
          }
        } else {
          return false
        }
      }

      
    }

    componentDidUpdate( props, state ) {
      //console.log( "IN")
      /*
      var _this = this, lock = true
      lock = false
        //setTimeout( (e)=> {
          if( props.showTrader ) {
            if( !lock ) {
              var page = props.tpage?props.tpage:_this.state.selectedTab
              if( page === 'onhold' ) {
                _this.selectKey( _this.state.selectedTab,  props.forwardGroup ,true )
              } else {
                _this.selectKey( page, props.forwardGroup, true )
              }
              var obj = {}
              _this.setState( obj )
            }

          } else { 
            if( _this.state.fg.length !== 0 ) {
              _this.initialize()
            }
          }
      //}, 500 )   
      */   
      if( JSON.stringify(props.forwardGroup) !== JSON.stringify(state.forwardGroup) ) {
        this.calc( state.group, props.forwardGroup  )
        this.setState({
          forwardGroup : props.forwardGroup
        })
      }

    }

    getTab( BOOL ) {
      var tabs = []
      if( !BOOL) {
        tabs = [
          {key : 'onhold', value: "onhold", icon : faHandHoldingDollar},
          {key : 'action' , value : 'action', icon : faCartShopping },
        ]
      } else {
        tabs = [
          {key : 'onhold', value: "onhold", icon : faHandHoldingDollar},
          {key : 'summary' , value : 'summary', icon : faRssSquare},
          {key : 'action' , value : 'action', icon : faCartShopping},
          
        ]
      }
      return tabs
    }

    show(msg) {
      alert(msg)
    }

    readList(obj, list, type) {
  
      /*
      service.getAssetList(obj).then(
        (res) => {
          var list = res.list
            var seen = new Set();
            var symbols = list.filter(el => {
              const duplicate = seen.has(el.symbol);
              seen.add(el.symbol);
              return !duplicate;
            });
            symbols = [...seen]
            for (var i in list) {
              list[i].buy = list[i].price 
              list[i].change = 0
              list[i].board_lot = list[i].quantity
              //MAKE DECSISION
              list[i].unit = 1
            }
            var group = [
              { group: {name : 'all'} , list : list, onhold : [] }
            ]

            if ( this.state.selectedTab==="onhold") {
              this.setState({
                page : obj.page,
              })
              for (var i in group) {
                var symbols = this.getSymbol( group[i].list )              
                if( !this.state.isTLogin  ) {   
                  if( symbols.length > 0 ) {       
                    this.getPrice( symbols, group[i].group, group[i].list, i, type )
                  } else {
                    this.setState({
                      isReading : false,
                      group : group,
                    })
                  } 
                } else {
                  if( symbols.length > 0 ) {
                    this.updateRealPrice(symbols, group[i].group, group[i].list, i, type)
                  } else {
                    this.setState({
                      isReading : false,
                      group : group,
                    })
                  }
                }
              }
            }
          //}
        }
      )
      */
    }

    componentWillUnmount() {
      /*
      if( window.opener !== null ) {
        window.opener.call_app( "page" , "test" , "CLOSE"   )
      } else {
        //console.log( this.state.list )
        //this.setState( {
        //  list : []
        //})
      }*/
    }

    handleStart(e) {
      //console.log(e)
    }

    handleDrag(e) {

    }

    handleStop(e) {

    }

    onresize(event, {element, size, handle}) {
      //console.log( element )
      //console.log(size)
      if( !this.props.disable ) {
        this.setState({width: Math.max(size.width, MIN_WIDTH), height: Math.max(size.height, MIN_HEIGHT)});
      }
    }

    updateValue(obj) {
      //obj.trader_updates = Object.keys( obj )
      this.setState(obj)
    }

    getPrice( symbols, group, lists, index, type) {
      var _this = this
      var gp = JSON.parse( JSON.stringify( this.state.group ) )
      var prices = JSON.parse( JSON.stringify( _this.state.price ) )
      var boardlot = {}
      var obj = {}
      if( symbols.length > 0 ) {
        var syms = []
        for (var idx in symbols) {
          for(var j in symbols[idx]) {
            var region = "usa"
            if( symbols[idx][j].indexOf(".HK") > -1 ) {
              region = "hkg"
            }
            if ( !(region in obj)  ) {
              obj[region] = []
            }
            obj[region] = obj[region].concat( symbols[idx][j] )
            syms = syms.concat( symbols[idx][j] )
          }
        }

        if( syms.length > 0 ) { 
          Api.getprices(  obj , "latest"  ).then(
            (res) => {
              if( res.value.length > 0 ) {
                for (var i in res.value) {
                  prices[ res.value[i].symbol ] = res.value[i].close
                  //prices[ res.value[i].symbol ].boardlot = (res.value[i].region==="usa")?1:prices[ res.value[i].symbol ].boardlot
                  boardlot[ res.value[i].symbol ] = (res.value[i].region==="usa")?1:res.value[i].boardlot
                }
              }
              for (var idx in symbols) {
                var value = _this.updatePriceFromState( lists[idx], prices, boardlot )
                var list = value.list
                var updates = value.updates
                var obj = { group : group[idx], list : list, type : type }
                if( index[idx] ) {
                  gp[ index[idx] ] = obj
                } else {
                  gp.push( obj )
                }
              }
              if ( _this.state.selectedTab===type) {
                _this.setState({
                  isReading : false,
                  group : gp,
                  oriGroup : gp,
                  prices : prices
                })  
                setTimeout( 
                  (e) => {
                    //_this.sort( _this.state.selectedSort ) 
                    _this.calc( gp )
                  }, 300
                ) 
              }
              
            }
          )
        } else {
          _this.setState({
            isReading : false,
            group : gp,
            oriGroup : gp,
          }) 
          //setTimeout( 
          //  (e) => {
          //    _this.sort( _this.state.selectedSort ) 
          //  }, 300
          //) 
        }


      } else {
        /*_this.setState({
          isReading : false,
          group : gp,
          oriGroup : gp,
          //selectedSort : 0,
          prices : prices
        }) 
        setTimeout( 
          (e) => {
            _this.sort( _this.state.selectedSort ) 
          }, 300
        ) 
        */
      }
    }

    updateRealPrice(symbols, group, lists, index, type) {
      var _this = this
      var gp = JSON.parse( JSON.stringify( this.state.group ) )
      var prices = JSON.parse( JSON.stringify( _this.state.price ) )
      if( symbols.length > 0 ) {
        var syms = []
        for (var idx in symbols) {
          for(var j in symbols[idx]) {
            syms = syms.concat( symbols[idx][j] )
          }
        }

        if( syms.length > 0 ) { 
          sptrader.price( syms  ).then(
            (res) => {
              if( res.data.prodList.length > 0 ) {
                for (var i in res.data.prodList) {
                  prices[ res.data.prodList[i].prodCode ] = res.data.prodList[i].lastPrice
                }
              }
              for (var idx in symbols) {
                var value = _this.updatePriceFromState( lists[idx], prices )
                var list = value.list
                var updates = value.updates
                var obj = { group : group[idx], list : list, type : type }
                if( index[idx] ) {
                  gp[ index[idx] ] = obj
                } else {
                  gp.push( obj )
                }
              }
              if ( _this.state.selectedTab===type) {
                _this.setState({
                  isReading : false,
                  group : gp,
                  oriGroup : gp,
                  prices : prices
                }) 
                setTimeout( 
                  (e) => {
                    //_this.sort( _this.state.selectedSort ) 
                    _this.calc( gp )
                  }, 300
                ) 
                
              }
            }
          )
        } else {
          gp = []
          for (var idx in symbols ) {
            var list = lists[idx]
            var obj = { group : group[idx], list : list, type : type }
            gp.push( obj )
          }
          //console.log(gp)
          _this.setState({
            isReading : false,
            group : gp,
            oriGroup : gp,
            //selectedSort : 0,
          }) 
          //setTimeout( 
          //  (e) => {
          //    _this.sort( _this.state.selectedSort ) 
          //  }, 300
          //) 
        }


      } else {
        gp = []
        for (var idx in symbols ) {
          var list = lists[idx]
          var obj = { group : group[idx], list : list, type : type }
          gp.push( obj )
        }
        _this.setState({
          isReading : false,
          group : gp,
          oriGroup : gp,
          //selectedSort : 0,
          prices : prices
        }) 
        //setTimeout( 
        //  (e) => {
        //    _this.sort( _this.state.selectedSort ) 
        //  }, 300
        //) 
      }

    }

    updatePriceFromState(list, prices, bl) {
      var updates = []
      for (var i in list) {
        if (   list[i].symbol in prices  ) {
          list[i].price = prices[ list[i].symbol  ]
          list[i].change =  ( prices[ list[i].symbol  ] - list[i].buy ) / list[i].buy 
          if( bl ) {
            if( list[i].board_lot !== bl[ list[i].symbol  ] ) {
              //console.log( list[i].symbol + " : " +  list[i].board_lot + " to " +  bl[ list[i].symbol  ] )
              list[i].new_bl = bl[ list[i].symbol  ]
              updates.push( JSON.parse(  JSON.stringify(list[i]) ) )
            } else {
              //console.log( list[i].symbol + " : " +  list[i].board_lot + " eq " +  bl[ list[i].symbol  ] )
            }
          }
        }
      }
      return { list : list, updates : updates }
    }

    login(username, password) {
      sptrader.login( username, password ).then(
        (res) => {
          if( "data" in res) {
            sptrader.obj = res.data
            
            
            //var tabs = this.getTab( true )
            //this.setState({
            //  isTLogin : true,
            //  tabs : tabs
            //})
            sptrader.marginCheckerOpen( this.mcupdate, this.mcmessage )

            var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		        storage.setItem("garlican@spobj", JSON.stringify(res.data) )

            var group = JSON.parse(  JSON.stringify(this.state.group)  )
            //console.log( group )
            
            var gps = [], syms = [], lists =[], indexs= []
            for (var i in group) {
              var symbols = this.getSymbol( group[i].list )
              syms.push( symbols )
              lists.push( group[i].list )
              gps.push( group[i].group )
              indexs.push( i )
              //this.updateRealPrice(symbols, group[i].group , group[i].list, i)
            }

            this.updateRealPrice(syms, gps , lists, indexs)
            //this.updateRealPrice( 0, group[0] )
            
          }
        }
      )
    }

    logout() {
      sptrader.logout().then(
        (res) => {
          //console.log(res)
          sptrader.marginCheckerClose()
          this.setState({
            isTLogin : false
          })
        }
      )
    }

    initialize() {
      this.setState({
        symbols : {},
        group : [],
        oriGroup : [],
        selectedSort : 0,
        page : 0,
        show : false,
        selectedTab : this.state.tabs[0].key,
        fg : [],
        isReading : false,
      })
    }

    updateList( gindex ,index, type, value, isRemove) {
      //NEED FIX
      
      var group = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
      //var _group = JSON.parse(  JSON.stringify( this.state.group ) )
      //var _gp = _group[gindex].group
      //console.log( _gp )
      //for (var i in group) {
      //  if( group[i].group.id === _gp.id  ) {
      //    gindex = i
      //    break;
      //  }
      //}

      //var _tp = group[gindex].type?group[gindex].type:this.state.selectedTab
      var _tp = "action"

      var list = group[gindex][ _tp ]
      if( !isRemove) {
        for (var i in type) {
          list[index][ type[i] ] = value[i]
        }
      } else {
        list.splice( index, 1 )
      }
      

      group[gindex][ _tp ] = list


      this.props.updateValue( {
        //tpage : this.state.selectedTab,
        forwardGroup : group
      } )
    }

    moveToAction( gindex , obj , type ) {
      var fg = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
      var _group = JSON.parse(  JSON.stringify( this.state.group ) )
      var _gp = _group[gindex].group
      for (var i in fg) {
        if( fg[i].group.id === _gp.id  ) {
          gindex = i
          break;
        }
      }

      if( type !== "summary" ) {
        var o = fg[gindex]
      } else {
        
        if( gindex in fg ) {
          var o = fg[gindex]
        } 
      }
         
      if( type === "summary" ) {
        obj.asset_type = "real"
        obj.real = true
      } else {
        obj.asset_type = "paper"
        obj.real = false
      }

      
      obj = JSON.parse(  JSON.stringify( obj ) )
      obj.action = "sell"
      obj.type = "action"
      obj.entrytime = obj.timestamp
      obj.boardlot = obj.board_lot
      obj.entryprice = obj.buy
      obj.group = obj['strategy_id']
      obj.decision = -obj.unit
      obj.tmp_decision = -obj.unit
      var valid = true
      if( !( "action" in o ) ) {
        o.action = []
      } else {
        for ( var j in o.action ) {
          if( (o.action[j]["symbol"] === obj.symbol) && (o.action[j]["strategy_id"] === obj.strategy_id)  ) {
            valid = false
            break
          }
        }
      }


      if(valid) {  
        o.action.push(  obj   )
        fg[gindex] = o
        this.props.updateValue({
          forwardGroup : fg
        })
      }

    }

    updateGroup(gindex, value, isRemove) {
      var fg = []
      //var group = JSON.parse(  JSON.stringify( this.state.group ) )
      var group = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
      if( isRemove ) {
        group.splice( gindex, 1 )
      } else {
        var o = group[ gindex ]
        if( o.type === "action" ) {
          group[ gindex ][o.type] = value
        }
        //group[ gindex ] = value
        //if(  )
      }
      fg = group
      //this.setState( {
      //  group : group,
      //  fg : fg
      //} )

  
      this.props.updateValue( {
        tpage : this.state.selectedTab,
        forwardGroup : group
      } )
    }

    readListByType( fg, k) {
      //var gp = JSON.parse( JSON.stringify( this.state.group ) )
      //var gp = JSON.parse( JSON.stringify( this.props.forwardGroup ) )
      var syms = [], lists=[], indexs=[], gps = []
      for (var j in fg) {
        var symbols = []
        var list = JSON.parse(JSON.stringify(  fg[j][k] ) )
        for( var i in list) {
          if( list[i].symbol !== "cash" ) {
            symbols.push( list[i].symbol )
            list[i].buy = Math.round( list[i].price * 1000)/1000
            list[i].change = 0
            list[i].action = "buy"
            list[i].timestamp = list[i].last_purchase_time  
            //list[i].group = fg[j].group
          }
        }
        var o = {}
        symbols = [...new Set(symbols)];
        symbols = symbols.filter(
          (a)=>{
            return a!=="cash"
          }
        )    

        syms.push( symbols )
        lists.push( list )
        indexs.push( j ) 
        gps.push( fg[j].group )
      }
      if ( !this.state.isTLogin ) {
        this.getPrice( syms, gps, lists , indexs ,k  )
      } else {
        this.updateRealPrice( syms, gps, lists , indexs ,k )
      }
    }

    selectKey(k, fg, override) {
      if( override || (( k !== this.state.selectedTab) || ( this.state.group.length === 0 )) ) {
        var obj = {
          //isReading : true,
          //selectedTab : k,
          //showNohold : true,
          //showOnhold : true,
        }
        //if(fg) {
        //  obj.fg = fg
        //}
        this.setState({
          selectedTab : k,
        })
        if( k !== "action" ) {
          this.readListByType(fg, k)
        } 
        //this.setState(obj)
        /*
        if( service.user ) {
          if ("user_id" in service.user) {
            fg = ((fg)?fg:JSON.parse(JSON.stringify(this.props.forwardGroup)))
            switch( k ) {
              case "onhold":
                this.readListByType(fg, k)
                break;
              case "action": 
                for (var j in fg) {
                  var symbols = []
                  var list = JSON.parse( JSON.stringify(  fg[j].action  ) )
                  var onhold = JSON.parse(JSON.stringify(  fg[j].onhold ) )
                  var onholds = {}
                  for (var _k in onhold ) {
                    if( onhold[_k]['symbol'] !== "cash" ) {
                      if( !(onhold[_k]['strategy'] in onholds) ) {
                        onholds[ onhold[_k]['strategy'] ] = {}
                      }
                      onholds[   onhold[_k]['strategy']  ][ onhold[_k]['symbol']  ] = onhold[_k]['unit']
                    }
                  }
                  for( var i in list) {
                    symbols.push( list[i].symbol )
                    if( list[i].decision > 0 ) {
                      list[i].buy = Math.round( list[i].price * 1000)/1000
                      list[i].change = 0
                    } else if( list[i].decision < 0 ) {
                      list[i].buy = Math.round( list[i].entryprice * 1000)/1000
                      list[i].change = Math.round((list[i].price - list[i].entryprice)*100/list[i].entryprice)/100
                    }
                    list[i].board_lot = list[i].boardlot
                    //list[i].unit = list[i].betsize
                    list[i].action = ((list[i].decision > 0)?"buy":'sell')
                    list[i].timestamp = list[i].date  
                    list[i].unit = Math.abs(  list[i].decision  ) 
                    list[i].onhold = 0
                    if (  !list[i]['group'] ) {
                      list[i]['group'] = list[i]['straregy']
                    }
                    if(  list[i].group ) {
                      if(  list[i]['group'].toString() in onholds ) {
                        if(  list[i]['symbol'] in onholds[list[i]['group']] ) {
                          list[i].onhold = onholds[ list[i]['group'].toString() ][ list[i]['symbol'] ]
                        }
                      }    
                    }
                  }
                  fg[j].list = list
                  fg[j].type = k
                }
                
                this.setState({
                  group : fg,
                  oriGroup : fg,
                  isReading : false,
                }) 
                var _this = this
                setTimeout( 
                  (e) => {
                    _this.sort( _this.state.selectedSort ) 
                    _this.calc( fg )
                  }, 600
                ) 
                //this.sort( this.state.selectedSort ) 
                break;
              case "summary":
                this.readListByType(fg, k)
                break;            
              default:
                this.setState({
                  isReading : false,
                })
                break;
                //this.initialize()
            }
          }
        }
        */
      }
    }

    calc(gp, fg) {
      try {
        var current = 0, original = 0, change = 0, buy = 0, sell = 0
        var _gp = JSON.parse(  JSON.stringify( gp )  )
        fg = fg?fg:this.props.forwardGroup
        var key = (this.state.selectedTab === "onhold")?"list":this.state.selectedTab
        _gp = _gp.filter( (v,k) => (v[key].length > 0 )  )
        if( this.state.selectedTab === "action" || this.state.selectedTab === "summary" ) {
          /*
          for(var i in _gp) {
            var _list = ("action" in _gp[i])?_gp[i].action:_gp[i].list
            for( var j in _list ) {
              if( _list[j].decision > 0 ) {
                buy -= (  _list[j].price * _list[j].boardlot * Math.abs( _list[j].decision )  )
              } else {
                sell += (  _list[j].price * _list[j].boardlot * Math.abs( _list[j].decision )  )
              }
            }
          }
          */
        } else {
          for(var i in _gp) {
            var _list = ("action" in _gp[i])?_gp[i].action:_gp[i].list
            for( var j in _list ) {
              current += ( _list[j].price * _list[j].board_lot * _list[j].unit )
              original += ( _list[j].buy * _list[j].board_lot * _list[j].unit )
            }
          }
          change = (( this.state.selectedTab==="onhold")?((original>0)?((current - original)/original):0):0)
          

          for(var i in fg) {
            var _list = ("action" in fg[i])?fg[i].action:fg[i].list
            if( _list.length > 0 ) {    
              for( var j in _list ) {
                if( _list[j].decision > 0 ) {
                  buy -= (  _list[j].price * _list[j].boardlot * Math.abs( _list[j].decision )  )
                } else {
                  sell += (  _list[j].price * _list[j].boardlot * Math.abs( _list[j].decision )  )
                }
              }
            }
          }

        }

        this.setState({
          change : Math.round(change*10000)/100,
          current : Math.round(current*100)/100,
          original : Math.round(original*100)/100,
          buy_value : Math.round(buy*100)/100,
          sell_value : Math.round(sell*100)/100,
        })
      } catch(e) {

      }
    }

    onClose() {
      console.log("close")
      this.props.updateValue({
        showTrader:false,
        tpage : null,
      })
      this.initialize()
      sptrader.marginCheckerClose()
      this.setState({
        isTLogin : false,
      })
    }

    getSymbol( lt ) {
      var symbols = []
      for (var i in lt) {
        symbols.push( lt[i].symbol )
      }
      symbols = [...new Set(symbols)]
      return symbols
    }

    insert(o) {
      var  _this = this
      var botfolio = JSON.parse(  JSON.stringify(  o.botfolio.group ) )
      var region = botfolio.region?botfolio.region:"usa"
      if ( !o.botfolio.id  ) {
          o.botfolio = this.props.dummy
          o.bot = this.props.dummy.strategy_list[0]
          var fg = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
          for (var i in fg) {
            if( fg[i].group.id === this.props.dummy.id  ) {
                fg[i].group = this.props.dummy
                this.props.updateValue( {
                    forwardGroup : fg,
                })
                break
            }
          }

      }
        function _update(o, price, _this) {
          //if( o.symbol in _this.props.board_lot ) {
            var _o = {
              symbol : o.symbol,
              price : price.close,
              buy : price.close,
              change : 0,
              decision : 1,
              tmp_decision :1,
              boardlot : price.region==="usa"?1:price.boardlot,
              unit : 1,
              action : "buy",
              asset_type : _this.state.isTLogin?"real":"paper",
              real : _this.state.isTLogin?true:false,
              strategy_id : o.bot.id,
              group : o.bot.id,
              portfolio : o.botfolio.id
            }
            var fg = JSON.parse(  JSON.stringify( _this.props.forwardGroup )  )
            if(  o.botfolio.id   ) {              
              var obj = {}
              for (var i in fg) {
                if( parseInt(fg[i].group.id, 10) === parseInt(o.botfolio.id, 10)  ) {
                  fg[i].action.push( _o )
                  _this.props.updateValue({
                    forwardGroup : fg
                  })
                  break
                }
              }
            } else {
              _o.group = -1
              _o.portfolio = -1
              var index = -1
              var obj = {}
              obj.group = {
                name : _this.props.gi18n[_this.props.language]['t_no_manage'] ,
                id : -1
              }
              obj.onhold = []
              obj.action = []
              for (var i in fg) {
                if( fg[i].group.id === -1 ) {
                  index = i
                  obj.group = fg[i].group
                  obj.onhold = fg[i].onhold
                  obj.action = fg[i].action
                  break
                }
              }
              obj.action.push( _o )
              if( index === -1 ) {
                fg.splice( 0, 0, obj )
              } else {
                fg[index] = obj
              }
              _this.props.updateValue({
                forwardGroup : fg
              })
            }
          //}
        }        
        if ( _this.state.isTLogin ) {
          sptrader.price( [o.symbol]  ).then(
            (res) => {
              var obj = {}
              obj.price = res.data.prodList[0].lastPrice
              _update(o, obj, _this)
            }
          )
        } else {
          var obj = {}
          obj[region] = [o.symbol]
          Api.getprices(  obj , "latest"  ).then(
            (r1) => {
              o = JSON.parse( JSON.stringify(o) )
              _update(o, r1.value[0], _this)
            }
          )
        }


    }

    order() {
      var fg = JSON.parse(  JSON.stringify(  this.props.forwardGroup  )  )
      var pending = JSON.parse( JSON.stringify( this.state.sp_pending ) )
      var botfolios = {}
      try {
        var trader_list = [], real_trade = []
        for( var i in fg) {
          var _ra = []
          for( var j in fg[i]["action"] ) {
                var obj = {}
                if( fg[i]["action"][j].decision > 0 ) {
                  obj['action'] = "buy"
                } else {
                  obj['action'] = "sell"
                }
                if( fg[i]["action"][j].real ) {
                  obj['asset_type'] = "real"
                } else {
                  obj['asset_type'] = "paper"
                }
                //if( fg[i]["action"][j].real ) {
                obj['temp_id'] = (i + "|" + j)
                //}
                obj['portfolio_id'] = fg[i]['group'].id
                botfolios[fg[i]['group'].id] = fg[i]['group']
                obj['price'] = fg[i]["action"][j].price
                obj['unit'] = Math.abs(fg[i]["action"][j].decision)
                obj['board_lot'] = fg[i]["action"][j].boardlot
                //obj['strategy_id'] = parseInt( fg[i]["action"][j].group , 10)
                obj['strategy_id'] = parseInt( fg[i]["action"][j].strategy_id , 10)
                obj['symbol'] = fg[i]["action"][j].symbol
                if( obj['portfolio_id'] ) {
                  trader_list.push( obj )
                }
                if(  fg[i]["action"][j].real   ) {
                  real_trade.push( obj )
                }
                _ra.push( j )
          }
          for (var k = _ra.length -1; k >= 0; k--)
            fg[i]["action"].splice(_ra[k],1);
        }
    
        this.props.updateValue({
          showLoading  : true
        })

        //console.log( trader_list )
        if( trader_list.length > 0 ) {
          service.createTradeOrders( { orders : trader_list } ).then(  
            (res) => {
              var list = res.list
              var _m = {}, pids = {}
              for (var k in list) {
                var _name = list[k].portfolio_id + "|" + list[k].strategy_id + "|" + list[k].symbol
                pids[ list[k].portfolio_id ] = true
                _m[ _name  ] = list[k]
              }
              var tickets = [], _this = this, _nObj = []
              for( var idx in real_trade) {
                var id = null
                if(  real_trade[idx].portfolio_id  ) {
                  id = real_trade[idx].portfolio_id + "|" + real_trade[idx].strategy_id + "|" + real_trade[idx].symbol
                  id = _m[ id ].id
                  var _o = {
                    buysell : ((real_trade[idx].action==="buy")?"B":"S"),
                    clOrderId : id,
                    //condType : "condType",
                    //orderType : "orderType",
                    priceInDec : real_trade[idx].price,
                    prodCode : real_trade[idx].symbol,
                    //validType : "validType",
                    qty : real_trade[idx].unit * real_trade[idx].board_lot ,
                    object : real_trade[idx]
                  }
                  tickets.push( _o )
                }
              }
  
              function _call() {
                var obj = {}
                obj.header = ""
                obj.body = _this.props.gi18n[ _this.props.language ]['submit_success']
                for( var j in _nObj) {
                  pending[  _nObj[ j ].order_number   ] = _nObj[ j ]
                }
                //obj.sp_pending = pending
                _this.props.updateValue( {
                  showAlert : true,
                  alertMessage : obj,
                  showLoading  : false,
                })
                _this.setState({
                  group : [],
                  oriGroup : [],
                  selectedSort : 0,
                  sp_pending : pending
                })

                setTimeout( (e) => {
                  _this.props.rebuildAsset()
                } , 300)
              }

              function _order( t, idx ) {
                var _o = t[idx]
                sptrader.addorder( _o ).then( 
                  (res) => {
                    if( 'data' in res ) {
                      _o.order_number = res['data'].accOrderNo
                      _nObj.push( _o )
                      if(  idx === t.length-1  ) {
                        _call(  )
                      } else {
                        _order( t, idx+1 )
                      }
                    } else {
                      this.setState({
                        isTLogin : false
                      })
                    }
                    
                  }
                ).catch( 
                  (e) => {
                    var obj = {}
                    obj.header = "Failed to Login"
                    obj.body = "Failed to Login"
                    this.props.updateValue({
                      isTLogin : false,
                      showAlert : true,
                      alertMessage : obj
                    })
                  }
                )
              }

              if(  tickets.length === 0  ) {
                _call()
              } else {
                _order( tickets , 0 )
              }

              //service.createReport(  ).then


              if( Object.keys(  pids  ).length > 0 ) {
                var pkeys = Object.keys(  pids  )
                function _update( index ) {
                  var pid = pkeys[index]
                  var obj = {
                    initial_capital : botfolios[pid].initial_capital,
                    transaction_fee : botfolios[pid].transaction_fee,
                    report_type : "forwardtest",
                  }
                  service.createReport( pid, obj ).then( 
                    (r1) => {
                      obj.report_type = "realtrade"
                      service.createReport( pid, obj ).then( 
                        (r2) => {
                          if( pkeys.length  > index+1  ) {
                            _update(  index+1  )
                          }
                        }
                      )
                    }
                  ).catch( (e) => {

                  })
                }
                _update(0)
              }

            }
          ).catch(  (e) => {
            this.props.updateValue({
              showLoading  : false
            })
          })
        } else {
          this.props.updateValue({
            showLoading : false,
            showAlert : true,
            alertMessage : {
              header :  this.props.gi18n[this.props.language]['t_no_trade_record'] ,
              body : this.props.gi18n[this.props.language]['t_no_trade_record']
            },
          })
        }          
      }
      catch(e) {
        this.props.updateValue({
            showLoading  : false
        })
      }




    }

    mcupdate(BOOL) {
      var tabs = this.getTab(BOOL)
      if( BOOL ) {
        //console.log("WHY")
        this.getSPOrders()

        /*var group = JSON.parse(  JSON.stringify(this.state.group)  )
        var gps = [], syms = [], lists =[], indexs= []
        for (var i in group) {
          var symbols = this.getSymbol( group[i].list )
          syms.push( symbols )
          lists.push( group[i].list )
          gps.push( group[i].group )
          indexs.push( i )
        }

        console.log("WHY")
        console.log(group)
        this.updateRealPrice(syms, gps , lists, indexs)*/
        //console.log("WHY")
        this.selectKey( "onhold" , this.props.forwardGroup, true )
      } else {

      }
      this.setState( {
        isTLogin : BOOL,
        tabs : tabs,
      })
      
    }

    mcmessage(obj) {
      var o = JSON.parse(  obj.data  )
      //console.log( this.state.sp_pending )
      //console.log( o )
      //console.log( this.state.group )
/*
SMAPLE o
accNo: "APIA2"
accOrderNo: 40
aeCode: "APIAD1"
buySell: "B"
counterBroker: ""
dataMask: 32
event: "subscribe"
orderId: ""
orderNo: -1097802017310010800
prodCode: "0023.HK"
recNo: 2891253
sysId: "FPIUAT2_S"
tradeDateInYMD: 20211115
tradeId: ""
tradeNo: 1
tradePriceInDec: 12.3
tradeQty: 200
tradeTime: 1636942187
*/

      if( o.dataMask === 32 ) {
        if( o.accOrderNo in this.state.sp_pending  ) {
          var pending = JSON.parse(  JSON.stringify( this.state.sp_pending )   )
          var id = this.state.sp_pending[ o.accOrderNo ].clOrderId
          service.updateTradeOrderById(  id , o ).then( 
            (res) => {
              delete pending[ o.accOrderNo ]
              this.setState({
                sp_pending : pending
              })
              console.log("UPdated")
              this.props.getOnholdAsset(true)
            }
          )
        }
      } /*else if( o.dataMask === 8 ) {
        if( o.accOrderNo in this.state.sp_pending  ) {

        }
      } */

      //dataMask === 8 PENDING
      //dataMask ===32 DONE TRADE

    }

    updateRealTradeById( id, obj ) {
      service.updateTradeOrderById(  id , obj  ).then( 
        (res) => {
          console.log(res)
        }
      )
    }

    updateRealTrade(obj) {
      var matches = [], test = {}
      for ( var i in this.props.onhold) {
        for ( var j in this.props.onhold[i] ) {
          if( this.props.onhold[i][j].detail.length > 0  ) {
            for ( var k in this.props.onhold[i][j].detail ) {
              matches.push(  this.props.onhold[i][j].detail[k].order_number    )
            }
          }
        }
      }

      obj = obj.map(  (v,k) => {
        v.order_number = v['accOrderNo'].toString()
        v.order_id = parseInt(  v.clOrderId , 10 )
        return v
      } )
      

      obj = obj.filter(  (v,k) => {
        return (v.order_number !== null)
      } )

      
      var test = {}
      for ( var i in obj) {
        if(  !(obj[i].symbol in test)  ){
          test[  obj[i].symbol  ] = obj[i].decision
        } else {
          test[  obj[i].symbol  ] += obj[i].decision
        }
        if( test[  obj[i].symbol  ] === 0  ) {
          delete test[  obj[i].symbol  ]
        }
      }
      test = Object.keys( test )
      obj = obj.filter( (v,k) => {
        var cond = test.includes(  v.symbol  )
        return (!(matches.includes( v.order_number ))) && cond
      } ) 


      if( obj.length > 0 ) {
        service.updateTradeOrders(  obj  ).then( 
          (res) => {
            //console.log(res)
            //this.props.getOnholdAsset()
          }
        )
      }
      


      //console.log( this.props.onhold )


    }

    getSPOrders() {
      console.log( "getSPOrders NEED CHECK" )
      sptrader.getWorkingOrderReport ().then(
        (res)=> {
          if( res['data'] ) {
              var pending = res['data']["recordData"]
              var _pend = {}
              for (var i in pending) {
                if( pending[i].prodCode in  this.props.board_lot) {
                  var obj = {}
                  obj['symbol'] = pending[i].prodCode
                  obj['price'] = pending[i].orderPrice
                  obj['buy'] = pending[i].orderPrice
                  obj['change'] = 0
                  obj['unit'] = pending[i].tradedQty/this.props.board_lot[pending[i].prodCode] 
                  obj['board_lot'] = this.props.board_lot[pending[i].prodCode] 
                  obj['asset_type'] = "real"
                  obj['real'] = true
                  obj['action'] = (pending[i].buySell==="B")?"buy":"sell"
                  obj['decision'] = (pending[i].buySell==="B")?obj['unit']:(-obj['unit'])
                  pending[i].object = obj
                  _pend[  pending[i].prodCode ] = pending[i]
                }
              }
              //console.log( pending )
              this.setState({
                sp_pending : _pend
              })
          } else {
            this.setState( {
              isTLogin : false,
            })
          }
        }
      )

      //Done
      sptrader.getHistoryOrder().then(
      //sptrader.getDoneTrade().then(
      //sptrader.getWorkingOrderReport ().then(
        (res) => {
          //console.log(   res)
/*
STATUS
0 : Sending
1 : Working
2 : Inactived
3 : Pending
4 : Adding
5 : Changing
6 : Deleting
9 : Traded
*/ 
          if( res.data ) {
            //var m = [0,3,4,9]
            //var r = res.data.recordData.filter( (v,k) => {
            //  return m.includes(v.status)  
            //})

            var r = res.data.recordData.filter( (v,k) => {
              return v.status === 9 
            }) 

            res.data.recordData = r
            
            this.updateSPOrders(res)
          } else {
            this.setState( {
              isTLogin : false,
            })
          }
          
        }
      )

    }

    updateSPOrders( res ) {
      var fg = JSON.parse( JSON.stringify( this.props.forwardGroup ) )
      var _arr = []
      for(var j in fg) {
        var obj = {}
        for (var k in fg[j].onhold ) {
          obj.gindex = j
          obj.index = k
          obj.value = fg[j].onhold[k]
          _arr.push(obj )
        }
      }
      if( "data" in res ) {
        if(  "recordData" in res['data']  ) {
          if(  res['data']["recordData"].length > 0  ) {

            var mt = [0,1,2,3,4,5,6,9]                
            var list = res['data']['recordData'], trade_list = [], need_updates=[], open=[]
            list = list.filter( (e)=>{
              return mt.includes(e.status)
            })
            var prices = JSON.parse( JSON.stringify( this.state.price ) )
            var symbols = list.map((v,k) => { return v['prodCode'] } )
            symbols = new Set( symbols )
            symbols = Array.from( symbols )
            sptrader.price( symbols ).then(
              (response)=> {      
                if( response.data.prodList.length > 0 ) {
                  for (var i in response.data.prodList) {
                    prices[ response.data.prodList[i].prodCode ] = response.data.prodList[i].lastPrice
                  }
                }

                for (var i in list) {
                  var tr = list[i].tradedQty
                  if( tr > 0  ) {
                    var  obj = {}
                    obj['symbol'] = list[i]['prodCode']
                    obj['action'] = (list[i]['buySell'] === "B")?"buy":"sell"
                    obj['decision'] = (list[i]['buySell'] === "B")?1:-1
                    obj['board_lot'] = this.props.board_lot[ list[i]['prodCode'] ]
                    var q =  list[i]['ordTradeQty']?list[i]['ordTradeQty']:tr
                    obj['unit'] = q/obj['board_lot']
                    obj['last_purchase_time'] = list[i]["tradeTimeStr"]?list[i]["tradeTimeStr"]:list[i]["timeStampStr"]
                    obj['price'] = prices[ list[i]['prodCode'] ]
                    obj['buy'] = list[i]['avgPrice']?list[i]['avgPrice']:list[i]['orderPrice'] //orderPrice
                    obj['status'] = list[i]['status']
                    obj['accOrderNo'] = list[i]['accOrderNo']
                    obj['change'] = (prices[ list[i]['prodCode'] ] - obj['buy'])/obj['buy']
                    obj['asset_type'] = "real"
                    obj['real'] = true
                    obj['clOrderId'] = list[i]['clOrderId']
                    trade_list.push( obj )
                    if(  list[i]['clOrderId']   ) {
                      need_updates.push(  obj  )
                    } else {
                      open.push(obj)
                    }

                  }
                }

                //console.log( trade_list )
                if( trade_list.length === 0 ) {
                  for (var i in fg) {
                    fg[i].summary = []
                  }
                }  else {
                  //console.log(  need_updates )
                  this.updateRealTrade( need_updates )
                  this.setState( {
                    prices : prices,
                    sporders : res
                  })
                  
                  var valid = false, index = 0, gp = {} 
                  for (var i in fg) {
                    if( fg[i].group.id === -1 ) {
                      fg[i].summary = fg[i].summary.concat(open)
                      gp = fg[i]
                      index = i
                      valid = true
                      break
                    }
                  }

                  if( !valid ) {
                    gp = { 
                      "group" : 
                        {"name" : this.props.gi18n[this.props.language]['t_no_manage'] , id  : -1 }, 
                      "summary" : open, 
                      "action" : [] , 
                      "onhold" : [] 
                    }
                    fg.splice( index, 0, gp )
                    //console.log( gp )
                  }

                  var valids = []
                  for( var i in need_updates ) {
                    var valid = true
                    for ( var j in fg ) {
                      for ( var k in fg[j].summary  ) {
                        if( fg[j].summary[k].symbol === need_updates[i].symbol  ) {
                          for ( var l in fg[j].summary[k].detail  ) {
                            if(  fg[j].summary[k].detail[l].order_number.toString() === need_updates[i]['accOrderNo'].toString()  ){
                              valid = true
                              break
                            } else {
                              valid = j+"|"+k
                            }
                          }
                        }
                      }
                    }
                    valids.push( valid )
                  }

                  var  k = -1, removes = []
                  for( var i in valids) {
                    if( valids[i] !== true ) {
                      var idx = valids[i].split("|")
                      var _o = JSON.parse(  JSON.stringify( fg[ parseInt(idx[0] , 10 ) ].summary[ parseInt(idx[1] , 10 ) ] ) )
                      removes.push( _o )
                      fg[ parseInt(idx[0] , 10 ) ].summary[ parseInt(idx[1] , 10 ) ] = null
                    }
                  }
                
                  for ( var j in fg ) {
                    fg[ j ].summary = fg[ j ].summary.filter(  
                        (v,k) => {
                          return v !== null
                        }
                      )
                  }
                  //console.log(fg)
                }

                this.props.updateValue({
                  forwardGroup : fg
                })



              } 
            )
          } else {
            for (var i in fg) {
              fg[i].summary = []
            }
            this.props.updateValue({
              forwardGroup : fg
            })
          }
        }
      }
    }

    run(  idx ) {
      //var gp = JSON.parse(   JSON.stringify( this.state.group )   )
      var gp = JSON.parse(   JSON.stringify( this.props.forwardGroup )   )
      var asset_list = gp[idx]
      asset_list.onhold = asset_list.onhold?asset_list.onhold:asset_list.list
      var params = {}
      for (var i in asset_list.onhold) {
          if(  "unit" in  asset_list.onhold[i] ) {
            asset_list.onhold[i].strategy = asset_list.onhold[i].strategy_id.toString()
            if(  !(asset_list.onhold[i].symbol in params)  ){
              params[ asset_list.onhold[i].symbol ] = {}
            }
            if(  !( asset_list.onhold[i].strategy_id in params[ asset_list.onhold[i].symbol ])  ) {
              params[ asset_list.onhold[i].symbol ][   asset_list.onhold[i].strategy_id   ] = {}
            }
            if(  "unit" in  asset_list.onhold[i] ) {
              var d = '27070740', duration = 0
              if( asset_list.onhold[i].last_purchase_time ) {
                d = service.formatDate( asset_list.onhold[i].last_purchase_time, null, true  )
                d = d.getTime()/1000
                var now = new Date()
                now = now.getTime()/1000
                duration = (now - d)
              }
              
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy] = {}
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['betsizeonhold'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['betsize'] =  asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['shortbetsize'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['onstock'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['marketposition'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['onmarket'] = asset_list.onhold[i].unit.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['entryprice'] = asset_list.onhold[i].buy?asset_list.onhold[i].buy.toString():asset_list.onhold[i].price.toString()
              //params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['entryprice'] = asset_list.onhold[i].buy.toString()
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['entrytime'] = d/60/60/24
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['last_purchase_time'] = asset_list.onhold[i].last_purchase_time
              params[ asset_list.onhold[i].symbol ][ asset_list.onhold[i].strategy]['barssinceentry'] = duration.toString()
            }
          }
      }
      //console.log( params )
      var strategy = {}, partition = 100
      var group = asset_list.group

      service.getPortfoilioById( group.id  ).then(
        (res) => {
          for (var s in res.strategy_list) {
            strategy[ res.strategy_list[s].id ] =res.strategy_list[s]
            partition -= parseFloat(  res.partition[ res.strategy_list[s].id ] , 10  )
          }
          if( partition > 0) {
            strategy['cash'] = { id:'cash', name:this.props.gi18n[ this.props.language ]['cash']}
          }
          var _obj = {}, transaction_fee = 0
          _obj.showLoading = true
          _obj.tpage = "action"
          this.props.updateValue(_obj)

          Api.forwardtestingS( res, params, parseFloat(transaction_fee, 10), this.props.scope, this.props.demo ).then(
            (res) => {
              var obj = {}
              obj['action'] = []
              var _obj = {}
              if( typeof(res) !== "string" ) {
                if( 'value' in res ) {
                  if( res['value'].length > 0 ) {
                    for (var s in res['value']) {
                      res['value'][s]['tmp_decision'] = res['value'][s]['decision']
                      res['value'][s]['strategy_id'] = res['value'][s]['group']
                      res['value'][s]['action'] = (res['value'][s]['decision']>0)?"buy":"sell"
                    }
                    obj['action'] = res['value']
                  } else {
                    _obj.alertMessage = {
                      header :  this.props.gi18n[this.props.language]['no_sym'] ,
                      body : this.props.gi18n[this.props.language]['no_sym_msg']
                    }
                    _obj.showAlert = true
                  }
                } 
              }
              
              obj['group'] = group
              obj['onhold'] = asset_list.onhold 
              obj['summary'] = []

              var valid = true
              var fg = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
              for( var i in fg) {
                if( fg[i].group.id === obj.group.id ) {
                  fg[i] = obj
                  valid = false
                  break
                }
              }

              //console.log( fg )

              if( valid ) {
                fg.push( obj )
              }
              _obj.forwardGroup = fg
              _obj.showLoading = false
              _obj.tpage = "action"
              _obj.selectedTab = 'action'

              this.props.updateValue(_obj)
            }
          ).catch( (e) => {
            this.props.updateValue({
              showLoading : false,
              showAlert : true,
              alertMessage : {
                header :  this.props.gi18n[this.props.language]['error'] ,
                body : this.props.gi18n[this.props.language]['backtesting_error']
              },
            })
          } )

        }
      )
    }

    deleteOrder( obj) {
      var o = this.state.sp_pending[ obj.symbol ]
      console.log(o)
      sptrader.deleteorder( o ).then(
        (res) => {
          var pending = JSON.parse( JSON.stringify(this.state.sp_pending) )
          delete pending[ obj.symbol ]
          this.setState({
            sp_pending : pending
          })
        }
      )
    }

    getGroup(index) {
      if( this.props.host === "client" ) {
        if(index !== null) {
          if(index > -1 ) {
            //console.log( this.props.forwardGroup[ index ].group )
            this.props.updateValue({
              showPortfolio : (window.innerWidth > 560),
              selectedPortfolio : this.props.forwardGroup[ index ].group
            })
          }
        } else {
          this.props.updateValue({
            showPortfolio : false,
            selectedPortfolio : null,
          })
        }
      } else {
        if(index !== null) {
          this.props.updateValue({
            showPortfolio : false,
            selectedPortfolio : this.props.forwardGroup[ index ].group,
          })
        } else{
          this.props.updateValue({
            showPortfolio : false,
            selectedPortfolio : null,
          })
        }
      }

    }

    getOnholdComp() {
      return (
        <Onhold 
            {...this.props} 
            board={board}
            selections={ selections } 
            selectedTab={this.state.selectedTab}
            change={this.state.change}
            original={this.state.original}
            current={this.state.current}
            showOnhold={this.state.showOnhold}
            isReading={this.state.isReading}
            selected={this.state.selected}
            isTLogin={this.state.isTLogin}
            group={ this.state.group }
            selectedSort={this.state.selectedSort}
            showLogin={this.state.showLogin}
            prices={ this.state.prices }
            
            selectKey={this.selectKey}
            updateList={this.updateList}
            deleteOrder={this.deleteOrder}
            updateValue={this.updateValue}
            updateGroup={this.updateGroup}
            moveToAction={this.moveToAction}
            getGroup={this.getGroup}
            run={this.run}
            //sort={this.sort}
        />
      )
    }

    getActionComp() {
      return (
        <Action 
            {...this.props} 
            board={board}
            selections={ selections }

            selectedTab={this.state.selectedTab}
            buy_value={this.state.buy_value}
            sell_value={this.state.sell_value}
            showOnhold={this.state.showOnhold}
            isReading={this.state.isReading}
            selected={this.state.selected}
            isTLogin={this.state.isTLogin}
            group={ this.state.group }
            //selectedSort={this.state.selectedSort}
            showLogin={this.state.showLogin}
            isAdd={ this.state.isAdd }
            
            selectKey={this.selectKey}
            updateList={this.updateList}
            deleteOrder={this.deleteOrder}
            updateValue={this.updateValue}
            updateGroup={this.updateGroup}
            moveToAction={this.moveToAction}
            getGroup={this.getGroup}
            run={this.run}
            order={this.order}
            //sort={this.sort}
            insert={ this.insert }
        />
      )
    }

    comp() {
      return (
        <div 
          style={
            this.props.draggable?{width: this.state.width + 'px', height: this.state.height + 'px'}:{}
          } 
          className={ [ 
              "trader2", 
              (this.props.draggable?"draggable":""),
              this.props.draggable?(this.props.showTrader?"trader-show":"trader-hide"):'trader-show', 
              this.props.draggable?(this.props.disable?"trader-full":""):"" 
          ].join(" ") } >
                { this.props.draggable &&
                  <div className={ [ "handle" ].join(" ") } >
                    <div className={ [ "hframe" ].join(" ") } > </div>
                    {
                      this.props.logosrc &&
                      <Image className={['logo'].join(" ")} src={ this.props.logosrc } />
                      
                    }
                    { !this.props.logosrc &&
                      <FontAwesomeIcon icon={ faBowlRice } className={['logo'].join(" ")} />
                    }
                    <div className={['name'].join(" ")} > 
                      { 
                                  (this.props.language in this.props.gi18n)?
                                  this.props.gi18n[ this.props.language ]['title']:
                                  "Garlican"
                      } 
                    </div>

                    { !this.props.disable && <FontAwesomeIcon  icon={faTimes} className={['close', 'icon'].join(' ')} onClick={ (e)=>{
                        this.onClose()
                      }}/> 
                    }
                  
                  </div>
                }

                  <div className={ [ (this.state.width >= 700)?"content-half":"content-full", "content" ].join(" ") }>              
                    <div className={[ this.state.isReading?"load":"unload" ] }> </div>
                    
                    { (this.state.width <= 640) &&
                      <div className={ [ "record-nav" ].join(" ") }>           
                        <Tabs 
                          onSelect={ (e) => this.selectKey(e) }
                          activeKey={ this.state.selectedTab }
                          defaultActiveKey={  this.state.tabs[0].key  } 
                          id="trader-tab">
                          { this.state.tabs.map( (v, k) => {

                            var ln = this.props.forwardGroup, ct=0 
                            for (var i in ln ) {
                              if( v.key  in ln[i] ) {
                                if( v.key in ln[i]) {
                                  ct += ln[i][ v.key ].length
                                }
                              }
                            }
                            
                            return (
                              <Tab 
                                key={k} eventKey={v.key} 
                                title={   
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip bsPrefix={"TP"}>
                                        <strong>{ this.props.gi18n[ this.props.language][ "trader_" +v.value].replace( "<num>", ct )  }</strong>
                                      </Tooltip>
                                    }
                                  >
                                    <div className={['Title-Wrapper'].join(" ")} >
                                      <FontAwesomeIcon className={['Icon'].join(" ")} icon={ v.icon } />
                                      { v.key === this.state.selectedTab &&
                                        <div className={['Name'].join(" ")}>
                                          { this.props.gi18n[ this.props.language][ "trader_" +v.value].replace( "<num>", ct ) } 
                                        </div>
                                      }
                                    </div>
                                  </OverlayTrigger>
                                } 
                              >
                                <div className={ [ "record-content" ].join(" ") }>

                                  { (this.state.selectedTab === "onhold") &&
                                    this.getOnholdComp()
                                  }

                                  { (this.state.selectedTab === "action") &&
                                    this.getActionComp()
                                  }


                                </div>


                              </Tab>
                              )
                            } )
                          }
                        </Tabs>
                      </div>
                    }
                    { (this.state.width > 640) &&
                      <div className={ [ "record-half" ].join(" ") }>
                          <div className={ [ "Element" ].join(" ") }>
                              { 
                                this.getOnholdComp()
                              }
                          </div>
                          <div className={ [ "Element" ].join(" ") }>
                              { 
                                this.getActionComp()
                              }
                          </div>
                      </div>
                    }


                  </div>

                    
                </div> 
      )
    }

    render() {
      if( Object.keys(this.props.gi18n).length > 0  ) {   
        if( !this.props.draggable ) {
          return (
                this.comp()           
          )
        } else {
          return(
            <Draggable
              handle={".hframe"}
              axis="both"
              defaultPosition={{x: (!this.props.disable?0:0), y: (!this.props.disable?0:0)}}
              position={  null }
              scale={1}
              disabled={ this.props.disable }
              onStart={this.handleStart}
              onDrag={this.handleDrag}
              onStop={this.handleStop}>
              <Resizable height={this.state.height} width={this.state.width} onResize={ (e,{element, size, handle} )=>  this.onresize( e,{element, size, handle}  ) }>
                { this.comp() }
              </Resizable>
            </Draggable>
          )
        }
      } else {
        return null
      }
    } 
}


export default Trader;



/*
<div className={ [ "order"  ].join(" ") }>
                          {  ( (this.state.width <= 700)  ) &&
                            <div className={ [ "btn-holder"  ].join(" ") } >
                              <DropdownButton 
                                title={ this.props.gi18n[this.props.language][ selections[this.state.selectedTab][ this.state.selectedSort  ].value]  }
                                className={['Sort'].join(" ")} >
                                {
                                  selections[this.state.selectedTab].map( (v,k) => {

                                    //console.log( selections[this.state.selectedTab] )
                                      return(
                                        <Dropdown.Item 
                                          className={['Item'].join(" ")} 
                                          key={k} 
                                          onClick={
                                            (e) => {
                                              this.sort( k )
                                            }
                                          }
                                          active={  this.state.selectedSort === k }
                                          eventKey={v.key} > 
                                            { this.props.gi18n[this.props.language][v.value] } 
                                        </Dropdown.Item>
                                      )
                                  } )
                                }
                              </DropdownButton>
                              { this.props.trader &&
                                <Button 
                                  onClick={ (e)=> {
                                    if( !this.state.isTLogin ) {
                                      this.setState({
                                        showLogin : !this.state.showLogin
                                      })
                                    } else {
                                      this.logout()
                                    }
                                  } }
                                  className={ [   "login"  ].join(" ") }> 
                                  {
                                    !this.state.isTLogin?(this.state.showLogin?this.props.gi18n[this.props.language]["t_hide"]:this.props.gi18n[this.props.language]["trade_login"]):this.props.gi18n[this.props.language]["logout"]
                                  } 
                                </Button>
                              }
                            </div>
                          }
                          {
                            this.state.isTLogin && 
                            <TSummary login={this.login} updateValue={this.updateValue} {...this.props} trader_updates={this.state.trader_updates}/>
                          }
                          { this.props.trader && !this.state.isTLogin && ( (this.state.width > 700) || ( (this.state.width <= 700) && this.state.showLogin )  ) &&
                            <TLogin {...this.props} username={this.state.username} password={this.state.password} updateValue={this.updateValue} login={this.login} trader_updates={this.state.trader_updates} />
                          }
                    </div>
*/