import React, { Component, } from 'react';
import ReactDOM from 'react-dom';
import Emoji from 'react-emojis';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCaretDown, faCaretUp, faRocket, faFireFlameCurved, faMinus} from '@fortawesome/free-solid-svg-icons'
import service from '../../public/service'

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

//import { VictoryChart,VictoryArea, VictoryAxis, VictoryScatter} from 'victory';
//import gcolor from '../../public/style.scss';
import gcolor from '../../public/color.js';
import flags from '../../public/flags';

class PortfolioList extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          showBoard : false,
          //data : [],
          //isOwner : service.isOwner(  this.props.obj.user.user_id   ),
          isOwner : false,
        };        
        this.getRank = this.getRank.bind(this)
        //this.getNumberFormat = this.getNumberFormat.bind(this)
    }

    componentDidMount() {
      var showBoard = this.props.obj.backtest_report?( (Object.keys(this.props.obj.backtest_report).length > 0)?true:false ):false
      this.setState( { 
        showBoard : showBoard,
        isOwner : service.isOwner(  this.props.obj.user.user_id   ),
      } )
    }

    UNSAFE_componentWillReceiveProps(props) {

      var showBoard = props.obj.backtest_report?( (Object.keys(props.obj.backtest_report).length > 0)?true:false ):false
      this.setState({
        showBoard : showBoard,
        isOwner : service.isOwner(  props.obj.user.user_id   ),
      })
    }

    componentWillUnmount() {

    }

    getRank(score) {

      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      
      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }

      var s = "F-"
      var maxColor = hexToRgb( gcolor.gnFrameColor )
      var minColor = hexToRgb( gcolor.gnAccentColor )

      var obj = { r: 0, g:0, b:0}
      for (var c in obj) {
        obj[c] = Math.round( (maxColor[c] - minColor[c] ) * score/100 + minColor[c] )
      }

      var color = rgbToHex( obj.r, obj.g, obj.b )

      for( var i in service.score_scale ) {
        if(  score > service.score_scale[i].score ) {
          s = service.score_scale[i].rank
          break;
        }
      }
      
      return { rank : s, color : color }
    }
    


    render() {
        return (
          <div 
            className={['Portfolio-List',  this.props.selectedPortfolio?((this.props.selectedPortfolio.id === this.props.obj.id)?"selected":"noselected"):""  ].join(" ")} onClick={ (e)=>{ 
              try {
                //if(e.target.className) {
                  
                 // if( e.target.className.indexOf('remove') === -1 ) {
                    if (this.props.selectedPortfolio) {
                      if (this.props.selectedPortfolio.id !== this.props.obj.id) {
                        this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                      } else {
                        this.props.updateParentValue({ selectedPortfolio : null, showPortfolio :false, purchased :null  }) 
                      }
                    } else {
                      this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                    }
                  }
                //} else {
                //  this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                //}
              //}
              catch(e) {
                console.log(e)
              }
            } }>
              <div className={['Left' ,
                (!this.props.obj.subscription_id &&
                this.props.obj.backtest_report &&
                this.props.obj.backtest_report.overdraft)?"overdraft":"",
                (this.props.obj.backtest_report &&this.props.obj.backtest_report.profit > 0)?'gain':"",
                (this.props.obj.backtest_report &&this.props.obj.backtest_report.profit < 0)?'lose':'',
              ].join(" ")}>

              </div>

              <div className={['Right'].join(" ")}>
                <div className={['First'].join(" ") }>
                  <div className={['Upper'].join(" ") }>
                    <div className={['Region'].join(" ") }>  
                      {
                        <Emoji className={['Emoji'].join(" ") } 
                            emoji={
                              this.props.obj.region?
                              flags[this.props.obj.region]:
                              flags["usa"]
                            }
                        />
                      }
                    </div>
                    <div className={['Name'].join(" ") }>
                      { this.props.obj.name } 
                    </div>
                  </div>
                  <div className={['Lower'].join(" ") }>
                    <div className={['Holder'].join(" ") }>
                      {
                        /*
                        <div className={['Original'].join(" ") }>
                          <div className={['Label'].join(" ") }>{ this.props.gi18n[this.props.language]['initial'] + " : "}</div>
                          <div className={['Value'].join(" ") }>
                            {
                              (this.props.obj.backtest_report &&this.props.obj.backtest_report.overdraft)?
                              this.props.gi18n[ this.props.language ]['invalid_botfolio']:
                              ("$" + ((this.props.obj.backtest_report)? service.getNumberFormat(this.props.obj.backtest_report.initial_capital):service.getNumberFormat(this.props.obj.initial_capital)))
                            }
                          </div>
                        </div>
                        */
                      }
                      <div className={[
                          'Current',
                          (this.props.obj.backtest_report && this.props.obj.backtest_report.profit > 0)?'gain':"",
                          (this.props.obj.backtest_report && this.props.obj.backtest_report.profit < 0)?'lose':'',
                          (this.props.obj.backtest_report &&this.props.obj.backtest_report.overdraft)?"overdraft":"",
                        ].join(" ") }>
                        <div className={['Label'].join(" ") }> { this.props.gi18n[this.props.language]['current_value'] } </div>
                        <div className={['Value'].join(" ") }>
                          { this.props.obj.backtest_report?("$" + Math.round((this.props.obj.backtest_report.profit + this.props.obj.backtest_report.initial_capital)).toLocaleString("en-US")):"--"  }
                        </div>

                        <FontAwesomeIcon icon={
                          this.props.obj.backtest_report?(
                              (this.props.obj.backtest_report.profit > 0)?faCaretUp:(
                                (this.props.obj.backtest_report.profit < 0)?faCaretDown:faMinus
                              )
                          ):faMinus
                        } className={ ["Fa"].join(" ") } />
                        <div className={['Percent'].join(" ") }>
                          {
                            this.props.obj.backtest_report?
                            (Math.abs(Math.round(this.props.obj.backtest_report.profit/this.props.obj.backtest_report.initial_capital * 10000)/100) + "%" ):
                            "--%"
                          }
                        </div>
                      </div>
                      
                      


                    </div>
                  </div>
                </div>
                
                <div className={['Second'].join(" ") }>
                 {   
                    this.props.scores.map( (v,k) => {
                      var rank = "--"
                      if( this.props.obj[v.key] !== 0 ) {
                        var score = (this.props.obj.total_portfolios - (this.props.obj[v.key]-1))/this.props.obj.total_portfolios * 100
                        rank = this.getRank( score )
                      } else {
                        rank = { rank : rank, color : gcolor.gnGreyColor }
                      }
                        return(
                          <OverlayTrigger
                            key={k} 
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2"> 
                              {  this.props.gi18n[this.props.language][ v.value  ] } 
                            </Tooltip>} >
                            <div className={['box', 'score' ].join(" ")} 
                              style={ { backgroundColor : (rank.color+"FF") } }>
                              <div className={[ "Title" ].join(" ")}> {  this.props.gi18n[this.props.language][ v.value  ] }  </div>
                              <div className={[ "Value" ].join(" ")}> { rank.rank } </div>
                            </div>
                          </OverlayTrigger>

                        )
                    } )
                  }
                </div>


                
              </div>



          </div>
        )
    }
}


export default PortfolioList;




/*
<div className="loop-wrapper">
                  <div className="galaxy">
                      <div className="bg"></div>
                      <div className="stars-back"></div>
                      <div className="stars-middle"></div>
                      <div className="stars-front"></div>
                      <div className="bg center"></div>
                  </div>
                  <div className="hill"></div>
                  <div className="sun"></div>
                  <div className="rock"></div>
                  <div className={ ["Rocket"].join(" ") } >
                    <FontAwesomeIcon icon={faRocket} className={ ["Body"].join(" ") } />
                    <FontAwesomeIcon icon={faFireFlameCurved} className={ ["Flame"].join(" ") } />
                  </div>
                </div> 
*/