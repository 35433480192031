import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Emoji from 'react-emojis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCaretDown, faCaretUp, faMinus, faStar, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import service from '../../public/service'

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { VictoryChart, VictoryTheme , VictoryPolarAxis, VictoryLabel, VictoryArea, VictoryTooltip, VictoryGroup } from 'victory';
//import gcolor from '../../public/style.scss';
import gcolor from '../../public/color.js';
import flags from '../../public/flags.js';

/*const gd = [
  {  x: "risk_rate" , y: 0 },
  {  x: 'win_rate' , y: 0 },
  {  x: "quantity_rate" , y: 0 },
  {  x: "profit_rate" , y: 0  },
]*/
const gd = [
  {
    "risk_rate" : 0,
    'win_rate' : 0,
    "quantity_rate" : 0 ,
    "profit_rate" : 0 
  },
  {
    "risk_rate" : 50,
    'win_rate' : 50,
    "quantity_rate" : 50 ,
    "profit_rate" : 50 
  },
  {
    "risk_rate" : 25,
    'win_rate' : 25,
    "quantity_rate" : 25 ,
    "profit_rate" : 25 
  },
  {
    "risk_rate" : 75,
    'win_rate' : 75,
    "quantity_rate" : 75 ,
    "profit_rate" : 75 
  },
  {
    "risk_rate" : 100,
    'win_rate' : 100,
    "quantity_rate" : 100 ,
    "profit_rate" : 100 
  },
]

const frame = {
  "risk_rate" : 100,
  'win_rate' : 100,
  "quantity_rate" : 100 ,
  "profit_rate" : 100 
}


class PortfolioCard extends Component {

    constructor(props) {
        super(props)


  


        this.state = { 
          showBoard : false,
          data : this.processData( gd.reverse() ),
          cdata : this.processData( gd.reverse() ),
          //maxima : frame,
          maxima : frame,
          //isOwner : service.isOwner(  this.props.obj.user.user_id   ),
          isOwner : false,
        };        
        this.getRank = this.getRank.bind(this)
        this.setCompare = this.setCompare.bind(this)
        this.compareGD = this.compareGD.bind(this)
        //this.getNumberFormat = this.getNumberFormat.bind(this)
    }

    componentDidMount() {
      //console.log( this.props.obj )
      var obj = {}
      var showBoard = this.props.obj.backtest_report?( (Object.keys(this.props.obj.backtest_report).length > 0)?true:false ):false  
      var _gd = this.compareGD( this.props.obj )

      obj = { 
        data : this.processData(_gd.reverse() ),
        showBoard : showBoard,
        isOwner : service.isOwner(  this.props.obj.user.user_id   ),
      }

      if( this.props.compare ) {
        obj.cdata = this.compareGD( this.props.compare )
      }

      this.setState(  obj  )
    }

    UNSAFE_componentWillReceiveProps(props) {

      /*
      if( props.overview_updates.includes('cdata') ) {
        var showBoard = props.obj.backtest_report?( (Object.keys(props.obj.backtest_report).length > 0)?true:false ):false
        var obj = {
          showBoard : showBoard,
          isOwner : service.isOwner(  props.obj.user.user_id   ),
        }
        if(  props.compare ) {
          obj.cdata = this.compareGD( props.compare )
        }
        this.setState( obj )
      }
      */
      
    }

    componentWillUnmount() {
    }

    compareGD( c ) {
      var _gd = gd
      if( c ) {
        /*
        _gd = [
          {  x: "risk_rate" , y: Math.round( (c['total_portfolios'] - c['sharpe_ratio_rank'] + 1)/c['total_portfolios']*10000)/100 },
          {  x: 'win_rate' , y: Math.round( (c['total_portfolios'] - c['win_rate_rank'] + 1)/c['total_portfolios']*10000)/100 },
          {  x: "quantity_rate" , y: Math.round( (c['total_portfolios'] - c['turnover_rate_rank'] + 1)/c['total_portfolios']*10000)/100 },
          {  x: "profit_rate" , y: Math.round( (c['total_portfolios'] - c['profit_rate_rank'] + 1)/c['total_portfolios']*10000)/100  },
        ]
        */
       _gd = [
          {
            'risk_rate' : Math.round( (c['total_portfolios'] - c['sharpe_ratio_rank'] + 1)/c['total_portfolios']*10000)/100 ,
            'win_rate' : Math.round( (c['total_portfolios'] - c['win_rate_rank'] + 1)/c['total_portfolios']*10000)/100 ,
            "quantity_rate" :  Math.round( (c['total_portfolios'] - c['turnover_rate_rank'] + 1)/c['total_portfolios']*10000)/100,
            'profit_rate' : Math.round( (c['total_portfolios'] - c['profit_rate_rank'] + 1)/c['total_portfolios']*10000)/100
          },
          {
            "risk_rate" : 50,
            'win_rate' : 50,
            "quantity_rate" : 50 ,
            "profit_rate" : 50 
          },

          {
            "risk_rate" : 25,
            'win_rate' : 25,
            "quantity_rate" : 25 ,
            "profit_rate" : 25 
          },
          {
            "risk_rate" : 75,
            'win_rate' : 75,
            "quantity_rate" : 75 ,
            "profit_rate" : 75 
          },
          frame,
          
       ]
      }
      return _gd
    }

    getRank(score) {

      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      
      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }

      var s = "F-"
      var maxColor = hexToRgb( gcolor.gnFrameColor )
      var minColor = hexToRgb( gcolor.gnAccentColor )

      var obj = { r: 0, g:0, b:0}
      for (var c in obj) {
        obj[c] = Math.round( (maxColor[c] - minColor[c] ) * score/100 + minColor[c] )
      }

      var color = rgbToHex( obj.r, obj.g, obj.b )

      for( var i in service.score_scale ) {
        if(  score > service.score_scale[i].score ) {
          s = service.score_scale[i].rank
          break;
        }
      }
      
      return { rank : s, color : color }
    }
    
    setCompare(obj) {
      var _obj = {}
      //console.log( obj )  
      if( this.props.compare) {
        if ( obj.id === this.props.compare.id ) {
          obj  = null
          for( var i in this.props.pickeroptions) {
            if( this.props.pickeroptions[i].key !== 'initial_capital'  ) {
              _obj[ this.props.pickeroptions[i].key+"_range"  ] = {}
            }
          }
        } else {
          for( var i in this.props.pickeroptions) {
            if( this.props.pickeroptions[i].key !== 'initial_capital'  ) {
              _obj[ this.props.pickeroptions[i].key+"_range"  ] = { min : obj[ this.props.pickeroptions[i].key ]  ,max : this.props.pickeroptions[i].range[1] }
            }
          }
        }
      } else {
        for( var i in this.props.pickeroptions) {
          if( this.props.pickeroptions[i].key !== 'initial_capital'  ) {
            _obj[ this.props.pickeroptions[i].key+"_range"  ] = { min : obj[ this.props.pickeroptions[i].key ]  ,max : this.props.pickeroptions[i].range[1] }
          }
        }
      }
      //console.log( _obj )
      _obj.compare = obj
      /*
          profit_rate_range : {},
          win_rate_range : {},
          sharpe_ratio_range : {},
          turnover_rate_range : {},
      */
      this.props.updateValue( _obj )
    }

    processData(data) {
      const maxByGroup = this.getMaxima(data);
      const makeDataArray = (d) => {
        return Object.keys(d).map((key) => {
          //return { x: key, y: d[key] / maxByGroup[key] };
          return { x: key, y: d[key]};
        });
      };
      return data.map((datum) => makeDataArray(datum));
    }

    getMaxima(data) {
      const groupedData = Object.keys(data[0]).reduce((memo, key) => {
        memo[key] = data.map((d) => d[key]);
        return memo;
      }, {});
      return Object.keys(groupedData).reduce((memo, key) => {
        memo[key] = Math.max(...groupedData[key]);
        return memo;
      }, {});
    }

    render() {
        var valid = this.props.obj.backtest_report?(!this.props.obj.subscription_id && this.props.obj.backtest_report && !this.props.obj.backtest_report.overdraft):false
        var overdraft = this.props.obj.backtest_report?this.props.obj.backtest_report.overdraft:false
        var gain = this.props.obj.backtest_report?(this.props.obj.backtest_report.profit>0):false
        var lost = this.props.obj.backtest_report?(this.props.obj.backtest_report.profit<0):false
        var purchased = (this.props.obj.subscription_id!==null) || this.props.obj.hasPurchased
        var onhold = (this.props.obj.id in this.props.onhold)?(this.props.onhold[this.props.obj.id].length):0
        var recent = (this.props.obj.forwardtest_report || this.props.obj.realtrade_report)

        var percent = (this.props.obj.backtest_report?(Math.abs(Math.round(this.props.obj.backtest_report.profit/this.props.obj.backtest_report.initial_capital * 10000)/100)):0)

        return (
          <div 
            className={[
                'Portfolio-Card',  
                this.props.selectedPortfolio?((this.props.selectedPortfolio.id === this.props.obj.id)?"selected":"noselected"):""  
            ].join(" ")} 
            onClick={ (e)=>{ 
              try {

                var cond1 = e.target.className?true:false
                var cond2 = (typeof(e.target.className)==="object")?false:true

                if(    cond1 && cond2   ) {
                  if( (e.target.className.indexOf('remove') === -1) || (e.target.className.indexOf('Star') === -1) ) {
                    if(this.props.selectedPortfolio) {
                      if(  this.props.obj.id !== this.props.selectedPortfolio.id ) {
                        this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                      } else {
                        this.props.updateParentValue({ selectedPortfolio : null, showPortfolio :false, purchased :this.props.purchased  }) 
                      }
                    } else {
                      this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                    }
                  }
                } else {
                  if(this.props.selectedPortfolio) {
                    if(  this.props.obj.id !== this.props.selectedPortfolio.id ) {
                      this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                    } else {
                      this.props.updateParentValue({ selectedPortfolio : null, showPortfolio :false, purchased :this.props.purchased  }) 
                    }
                  } else {
                    this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                  }
                }
              }
              catch(e) {

              }
            } }>



            <div className={['Upper',
              ].join(" ")}>

              { 
                <div className={ ['name'].join(' ') }>  { this.props.obj.name }  </div>
                
              }
              
              <div className={['Region']}>
                    {
                        <Emoji className={['Emoji'].join(" ") } 
                            emoji={
                              this.props.obj.region?
                              flags[ this.props.obj.region ]:
                              flags['usa']
                            }
                        />
                      }
              </div>

            </div>




            <div className={['Middle'].join(' ') }>
              <div className={[
                'Left',
                (!overdraft)?(
                  valid?
                  (  
                    gain?
                      'Gain':
                      (
                        lost?
                        'Lost':"Normal"
                      )
                  ):"Overdraft"
                ):'Overdraft' 
              ].join(' ') }> 
                <div className={['Initial-Holder'].join(' ')}>
                  <div className={['Title'].join(' ')}> 
                    { this.props.gi18n[this.props.language][ "current_value" ] } 
                  </div>
                  <div className={['Value'].join(' ')}>
                    {
                      overdraft?
                      this.props.gi18n[this.props.language]['invalid_botfolio'] 
                      :
                      ("$" + 
                        (
                          (this.props.obj.backtest_report)? 
                            ((this.props.obj.backtest_report.initial_capital + this.props.obj.backtest_report.profit).toLocaleString("en-US")):
                            this.props.obj.initial_capital.toLocaleString("en-US")
                        )  
                      ) 
                    }
                  </div>
                </div>
                <div className={['Initial-Holder'].join(' ')}>
                    <div className={['Title'].join(' ')}> 
                      { this.props.gi18n[this.props.language][ "profit" ] } 
                    </div>
                    <div className={['Value'].join(' ')}>
                      {
                        overdraft?
                        this.props.gi18n[this.props.language]['invalid_botfolio'] 
                        :
                        (
                          (  
                            this.props.obj.backtest_report? 
                              ((this.props.obj.backtest_report.profit > 0)?"+":( (this.props.obj.backtest_report.profit < 0)?"-":"" ) ):"--"
                          )
                          +
                          "$" + 
                          (
                            (this.props.obj.backtest_report)? 
                              ((  Math.abs(this.props.obj.backtest_report.profit)  ).toLocaleString("en-US")):
                              "--"
                          )  
                        ) 
                      }
                    </div>

                    <div className={['Value', "Percent"].join(' ')}>
                      {
                        overdraft?
                        "( -- %)"
                        :
                        (
                          (  
                            this.props.obj.backtest_report? 
                              ((this.props.obj.backtest_report.profit > 0)?"( +":( (this.props.obj.backtest_report.profit < 0)?"( -":"( " ) ):"( "
                          )
                          +
                          (
                            (this.props.obj.backtest_report)? 
                              (   Math.abs(   Math.round(this.props.obj.backtest_report.profit/this.props.obj.backtest_report.initial_capital * 10000)/100   ) + "%)"  ):
                              "-- % )"
                          )  
                        ) 
                      }
                    </div>

                </div>
              </div>

              <div className={['Right'].join(' ') }>        
                <div className={['Chart'].join(' ')}>
                  <div className={['Cover'].join(' ')}> </div>
                  { (this.state.data.length > 0) &&
                              
      <VictoryChart polar
          theme={VictoryTheme.material}
          domain={{ y: [ 0, 100 ] }}
        >
          <VictoryGroup colorScale={
            [  
              gcolor.gnFrameColor3, 
              gcolor.gnFrameColor, 
              gcolor.gnFrameColor3, 
              gcolor.gnFrameColor,
              gcolor.gnAccentColor, 
          ]
          }
            
          >
            {this.state.data.map((data, i) => {
              return <VictoryArea 
                key={i} 
                data={data}
                style={  { data: { 
                  fillOpacity:  (i>3)?0.5:( ((i % 2) ==1)?0.5:0.25 ) , 
                  strokeWidth: (i>3)?3:0,
                  data : {
                    stroke : (i>3)?gcolor.gnAccentColor2:gcolor.gnAccentColor2,
                  }
                } 
              }}
              />;
            })}
          </VictoryGroup>
        

          {
                                              Object.keys(this.state.maxima).map((key, i) => {
                                                try{
                                                  var _data = this.state.data[4]
                                                  var obj = this.getRank( _data[i].y )
                                                  var score = obj.rank
                                                }
                                                catch(e) {
                                                  var obj = this.getRank( 0 ).rank
                                                  var score = obj.rank
                                                }
                                                return (
                                                  <VictoryPolarAxis key={i} 
                                                    dependentAxis
                                                    className={ ['Radar'].join(' ') }  
                                                    style={{
                                                        axisLabel: { padding: 5, fontSize : 12.5, fill : gcolor.gnFrameColor },
                                                        tickLabels: { fontSize :0, fill : gcolor.gnFrameColor },
                                                        axis: { stroke: gcolor.gnWhiteColor+"33" },
                                                        
                                                        grid: { stroke: gcolor.gnFrameColor3, strokeWidth: 0.25, opacity: 0.0 }
                                                    }}                           
                                                    axisLabelComponent={
                                                      <VictoryLabel {...this.props}
                                                        padding
                                                        style={
                                                          [
                                                            { fill: gcolor.gnFrameColor, fontSize: 20 },
                                                            { fill: gcolor.gnWhiteColor, fontSize: 25 }
                                                          ]
                                                        }
                                                        backgroundPadding={[
                                                          0,
                                                          { left: 5, right: 10 },
                                                        ]}
                                                        backgroundStyle={[
                                                          { fill: "white", opacity: 0 },
                                                          { fill: obj.color, opacity: 1, rx: "0.5rem"  },
                                                        ]}
                                                      />
                                                    }
                                                    labelPlacement={ ((i+1)%2==0)?"perpendicular":"vertical"  }
                                                    axisValue={i + 1} 
                                                    tickValues={[25, 50, 75, 100]}
                                                    label={ 
                                                          [
                                                            this.props.gi18n[this.props.language][key].replace("level", "")
                                                            ,
                                                            (score?( ""+ score+""):"") 
                                                          ]                                                      
                                                      }
                                                      
                                                  />
                                                );
                                              })
        }
        
                                          <VictoryPolarAxis
                                            theme={VictoryTheme.material}
                                            labelPlacement="parallel"
                                            tickValues={[25, 50, 75, 100]}
                                            tickFormat={ (k)=>"" }
                                            style={{
                                                  axis: { stroke: "none" },
                                                  grid: { stroke: gcolor.gnFrameColor3+"00", opacity: 0.0 }
                                            }}
                                          />


      </VictoryChart>


                  }
                </div>
              </div>
            </div>

            
            <div className={['Lower'].join(' ') }>
                <div className={['More'].join(' ') }>
                  <div className={['Text'].join(' ') }>{ this.props.gi18n[ this.props.language ]["more"]  }</div>
                  <FontAwesomeIcon icon={faChevronRight} className={['Fa'].join(' ') } />
                </div>
            </div>


          </div>
        )
    }
}


export default PortfolioCard;




/*
<div className={['Upper',
                (!overdraft)?(
                  valid?
                  (  
                    gain?
                      'Gain':
                      (
                        lost?
                        'Lost':"Normal"
                      )
                  ):"Overdraft"
                ):'Overdraft' 
              ].join(" ")}>


              { 
                <div className={ ['name'].join(' ') }>  { this.props.obj.name }  </div>
                
              }
              <div className={['Initial-Holder']}>
                <div className={['Title'].join(' ')}> 
                  { this.props.gi18n[this.props.language][ "current_value" ] } 
                </div>
                <div className={['Value'].join(' ')}>
                  {
                    overdraft?
                    this.props.gi18n[this.props.language]['invalid_botfolio'] 
                    :
                    ("$" + 
                      (
                        (this.props.obj.backtest_report)? 
                          ((this.props.obj.backtest_report.initial_capital + this.props.obj.backtest_report.profit).toLocaleString("en-US")):
                          this.props.obj.initial_capital.toLocaleString("en-US")
                      )  
                    ) 
                  }
                </div>
              </div>
              
              <div className={['Region']}>
                    {
                        <Emoji className={['Emoji'].join(" ") } 
                            emoji={
                              this.props.obj.region?
                              flags[ this.props.obj.region ]:
                              flags['usa']
                            }
                        />
                      }
              </div>
            </div>




<div className={['Chart'].join(' ')}>
                <div className={['Cover'].join(' ')}> </div>
                { (this.state.data.length > 0) &&
                                    <VictoryChart 
                                      polar
                                      width={300}
                                      height={300}
                                      style={{
                                        pointerEvents: 'none',
                                      }}
                                      className={['svgClass', 'Diagram'].join(' ')}
                                      animate={{
                                        duration: 1000,
                                        onLoad: { duration: 500 }
                                      }}>
                                        <VictoryArea data={this.state.data}
                                          className={['svgClass'].join(' ')}
                                          style={{ data: { fill: gcolor.gnAccentColor+"17", stroke: gcolor.gnAccentColor, strokeWidth: 1.00,pointerEvents: 'none',  } }}/>

                                        { this.props.compare &&
                                          <VictoryArea data={this.state.cdata}
                                            className={['svgClass'].join(' ')}
                                            style={{ data: { fill: gcolor.gnBadgeColor+"17", stroke: gcolor.gnBadgeColor, strokeWidth: 1.00,pointerEvents: 'none',  } }}/>
                                        }

                                        {
                                            Object.keys(this.state.maxima).map((key, i) => {
                                              var score = this.getRank( this.state.data[i].y ).rank
                                              return (
                                                <VictoryPolarAxis key={i} 
                                                  dependentAxis
                                                  className={ ['Radar'].join(' ') }  
                                                  style={{
                                                      axisLabel: { padding: 5, fontSize : 12.5, fill : gcolor.gnFrameColor },
                                                      tickLabels: { fontSize :0, fill : gcolor.gnFrameColor },
                                                      axis: { stroke: gcolor.gnFrameColor3 },
                                                      grid: { stroke: gcolor.gnFrameColor3, strokeWidth: 0.25, opacity: 0.25 }
                                                  }}                           
                                                  tickLabelComponent={
                                                      <VictoryLabel labelPlacement="vertical" />
                                                  }
                                                  labelPlacement={ ((i+1)%2==0)?"perpendicular":"vertical"  }
                                                  axisValue={i + 1} 
                                                  tickValues={[25, 50, 75, 100]}
                                                  label={ 
                                                      (
                                                        this.props.gi18n[this.props.language][key] 
                                                        + 
                                                        (score?( "\n("+ score+")"):"") 
                                                      )
                                                    }
                                                    
                                                />
                                              );
                                            })
                                        }
                                        <VictoryPolarAxis
                                          theme={VictoryTheme.material}
                                          labelPlacement="parallel"
                                          tickValues={[25, 50, 75, 100]}
                                          tickFormat={ (k)=>"" }
                                          style={{
                                                axis: { stroke: "none" },
                                                grid: { stroke: gcolor.gnFrameColor3, opacity: 0.25 }
                                          }}
                                        />


                                    </VictoryChart>
                }
              </div>
*/