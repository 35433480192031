import React, { Component } from 'react';
import './index.scss';

//import service from '../../public/service'
//import logoB from '../../public/img/garlican_blue_logo.png'

import { Button,Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faFileCode } from '@fortawesome/free-solid-svg-icons'
import gservice from '../../../Garlican/GarlicanLibrary/gservice'

//import { Typeahead } from 'react-bootstrap-typeahead';
//import 'react-bootstrap-typeahead/css/Typeahead.css';

import Api from '../../../Garlican/GarlicanLibrary/apis'
import service from '../../../public/service';
//import { server } from 'websocket';
//import { symbol } from 'd3-shape';

//import Query from '../Query'

import IDE from '../IDE'
import Template from '../Template'
import Apis from '../../../Garlican/GarlicanLibrary/apis';


class Code extends Component {

    constructor(props) {
      super(props)
      this.state = {
        isLogin : this.props.isLogin,
        templates : [],
        page : 0,
        showModal : false,
        selectedTemplate : {},
        showTemplate : false,
        isError : false,
        error_msg : "",
      }
      this.selected = this.selected.bind(this)
      this.callBack = this.callBack.bind(this)      
      this.updateCode = this.updateCode.bind(this)
      this.onBlur = this.onBlur.bind(this)
      this.treemaping = this.treemaping.bind(this)
      this.error = this.error.bind(this)
    }

    componentDidMount() {

      /*
      Apis.getTreemapNewFromCode( this.props.code   ).then(
        (res) => {
          var tp = res.treeobj
          var rsp = gservice.reshape( tp )
          //var compGp = gservice.createCompGp(  this.props.code, tp, rsp  )

          //console.log( compGp )
          var response = gservice.returnObj( tp, this.props.code, true  )
          var _res = response['response']
          var line = parseInt(response['line'], 10)
          var ro = { }
          for (var i in _res) {
            ro[ parseInt(i, 10) ] = true

          }
          var lg = gservice.getLineGroup( tp, line, this.props.code, res, {lines : ro } )
          //console.log(lg)
          for (var i in lg.group ) {
            var obj = lg.group[i]
            response = gservice.getLineComp(i, obj, rsp, tp, true )
            console.log( response )
          }

        }
      )*/

      //var keys = Object.keys(  window.func  )
      //console.log(  JSON.stringify( keys ) )
      /*
      var o = {
        "defined_variables":["closed","closem","closew","closey","highd","highm","highw","highy","lowd","lowm","loww", "opend","openm","openw","openy"],
        "base" : ["ceil","floor","round","range", "lag", "highest", "lowest","first","last","max_index","min_index"],
        "transfrom" : ["exp","ln","sqrt"],
        "math" : ["sum"],
        "xcross" : ["crossover","crossunder"],      
        "overlap" : ["bband","dema","ema","ht_trendline","kama","avg","midpoint","midprice","parobolicsar", "t3", "tema", "trima", "wma"  ],
        "momentum" : ["adx","adxr","apo", "aroon","balance_of_power","cmo", "cci", "dirmovement", "macd", "moneyflowindex", "minus_di","minus_dm", "momentum","ppo","rsi","stoch","stochf","stochrsi","trix","ultimateoscillator","willr" ],
        "volume" : ["ad", "adosc", "obv" ],
        "volalility" : ["avgtruerange","normalizedavgtruerange", "truerange"],
        "statistic" : ["beta","cor","cov","linear_forecast","linear_slope","linear_intercept", "quantile","median","std","timeseries_forecast","var" ],
        "pattern" : ["pattern_0","pattern_1","pattern_2","pattern_3","pattern_4","pattern_5","pattern_6","pattern_7","pattern_8","pattern_9","pattern_10","pattern_11","pattern_12","pattern_13","pattern_14","pattern_15","pattern_16","pattern_17","pattern_18","pattern_19","pattern_20","pattern_21","pattern_22","pattern_23","pattern_24","pattern_25","pattern_26","pattern_27","pattern_28","pattern_29","pattern_30","pattern_31","pattern_32","pattern_33","pattern_34","pattern_35","pattern_36","pattern_37","pattern_38","pattern_39","pattern_40","pattern_41","pattern_42","pattern_43","pattern_44","pattern_45","pattern_46","pattern_47","pattern_48","pattern_49","pattern_50","pattern_51","pattern_52","pattern_53","pattern_54","pattern_55","pattern_56","pattern_57","pattern_58","pattern_59","pattern_60"]
      }
      */
      

    }

    componentWillUnmount() {
    }

    componentDidUpdate(props, state) {
      //console.log( props ) 
      var obj = {}
      //if( !("id" in this.props.selectedBotfolio ) ) {
      //  if( props.list.length > 0 ) {
      //    if( props.isFilter ) {
      //      //this.get(props)
      //    }
      //  }
      //}

      if(   (props.isLogin !== state.isLogin) && (props.isLogin)  ) {
        obj.isLogin = props.isLogin
        this.setState( obj )
      }
      

    }

    selected(v) {
      /*
      var syms = []
      var seen = new Set()
      for( var k in this.props.read_sym) {
        if( syms.length == 0 ) {
          syms = this.props.read_sym[k]
        } else {
          var _sym1 = new Set( this.props.read_sym[k] )
          var _sym2 = new Set( syms )

          //syms = syms.concat( this.props.read_sym[k] )
          syms = [ ... _sym1 && _sym2 ]
        }
      }
      syms = syms.filter(el => {
        const duplicate = seen.has(el);
        seen.add(el);
        return !duplicate;
      });
      syms = [...seen]
      */

      //if( (syms.length > 0) && (syms.length < 300) ) {

        var _this = this
        var p = JSON.parse( JSON.stringify( this.props.selectedBotfolio  ) )
      
        v = JSON.parse( JSON.stringify( v ) )
        v.input = this.props.input

        this.props.updateValue({
          showLoading : true,
        })
        if( p.strategy_list ) {

          service.getTemplateById(  v.id ).then(
            (response) => {
              v = response

              service.deleteStrategy(p.strategy_list[0].id  ).then(
                (res) => {
                  delete p.partition[p.strategy_list[0].id ]
                  delete p.input[p.strategy_list[0].id ]
                  delete p.strategy_list[0]
                  p.input = {}
                  p.partition = {}
                  //v.strategy_template_id = v.id 
                  v.portfolio_id = p.id
                  service.createStrategy(  v   ).then( (response) => {
                    
                    //var fobj = {filters : [ { key : 'data', type : "symbol", opr: "in" , value : syms.join(",") } ] }
                    var fobj = {filters : v.filters }
                    service.updateStrategy( response.id  ,fobj).then(
                      (res) => {
                        p.input[ response.id ] = (v.input?v.input:{})
                        p.partition[ response.id ] = "100"
                        service.updatePortfolio( p.id, p ).then(  (res) => {
                          service.getPortfoilioById( p.id ).then(
                            (res) => {
                              this.props.updateParent({
                                selectedBotfolio : res,
                                useTemplate : true,
                              })
                              //_this.forwardtest( res )
                              _this.props.updateValue({
                                showLoading : false,
                              })
                              
                            }
                          )
                        } )
                      }
                    )

                      

                  
                  } )
                }
              ).catch( (e) => {
                console.log(e)
                _this.props.updateValue({
                  showLoading : false,
                })
              })


            }
          )

        }
  

      //} else {
      //  var obj = {}
      //  obj.header = ""
      //  obj.body = "No filter is found"
      //  this.props.updateValue({
      //    showAlert : true,
      //    alertMessage : obj,
      //  })
      //}

    }

    callBack(b, filters) {
      var fobj = { filters : filters  }
      var sid = b.strategy_list[0].id
      service.updateStrategy( sid, fobj ).then(
        (res) => {
          this.props.getList()
          this.props.selectIcon( { key : "botfolio" } )
        }
      )
    }

    updateCode( obj ) {
      this.setState( obj )
    }
  
    onBlur(e, type) {
      var code = ""
      
      if( type === 1 ) {
        code = this.props.code.split( gservice.split_cond )[1]
        if( encodeURI(e).substr( (encodeURI(e).length-3) , 3) !== "%0A" ) {
          e =  e + "\n"
        }
        code = (e + gservice.split_cond + code)
      } else if( (type === 2) || (type === 3) ) {
        code = this.props.code.split( gservice.split_cond )[0]
        if( encodeURI(code).substr( (encodeURI(code).length-3) , 3) !== "%0A" ) {
          code =  code + "\n"
        }
        code = (code + gservice.split_cond + e)
      }

      //console.log(code)

      

      this.props.updateParent( {
        code : code,
      } ) 

      
      
      /*
      this.treemaping(code, type)
      */
    }

    treemaping(code, type) {
      var _this = this
      this.setState({
        isError : false,
        error_msg : "",
      })
      this.props.updateValue({ showLoading : true })
      Api.getTreemapNewFromCode(code).then(
        (res) => {
          if( 'message' in res ) {
            this.error( this.props.gi18n[this.props.language]['error_msg'] )
            //var obj = {
            //  code : code,
            //}
            //this.props.updateParent( obj ) 
            _this.props.updateValue({ showLoading : false })
          } else{ 
            var a = gservice.reshape(   res['treeobj'], null, this.error  )
            var obj = {
              //code : res.code,
              input : res['input'],
              selectedBoard : type,
            }
            if( a ) {
              obj.hasError = false
            } else {
              //obj.hasError = true
            }
            _this.props.updateParent( obj )  
          }
          _this.props.updateValue({ showLoading : false })
        } 
      ).catch(
        (err)=>{
          this.error( this.props.gi18n[this.props.language]['error_msg']  )
          //var obj = {
          //  code : code,
          //}
          //this.props.updateParent( obj ) 
          _this.props.updateValue({ showLoading : false })
        }
      )
    }

    error(e) {
      //this.props.updateParent( {
        //hasError : true,
      //}
      //)
      if(e) {
        this.setState( {
          isError : true,
          error_msg : e,
        })
      } else {
        this.setState( {
          isError : false,
          error_msg : null,
        })
      }
    }

    render() {    
      return(
        <div className={['Code', this.props.show?"show":"" ].join(" ")} >
          { this.props.selected !== "template" &&
            <div className={['CPanel'].join(" ")}>
              <Button onClick={ (e)=>{
                if( !this.props.useTemplate ) {
                  this.setState({ showTemplate : !this.state.showTemplate })
                } else {
                  this.props.updateParent({
                    useTemplate : false,
                  })
                  this.setState({ showTemplate : !this.state.showTemplate })
                }
              } }> 
                <FontAwesomeIcon icon={ faCopy } className={ ['fa'].join(" ") } />
                <span>{ !this.state.showTemplate?this.props.gi18n[this.props.language]["pick_botfolio"]:this.props.gi18n[this.props.language]["back_customized_code"] }</span>
              </Button>

              <Button className={['pdf'].join(" ")} onClick={
                (e) => {
                  var url = window.location.origin + "/sources/garlican_language_instruction.pdf"
                  window.open( url )
                }
              }>
                <FontAwesomeIcon icon={ faFileCode } className={ ['fa'].join(" ") } />
                <span>{  this.props.gi18n[this.props.language]["t_instruction"]  }</span>
              </Button>
            </div>
          }

          { this.state.isError && 
              <div className={['Error'].join(" ")} >  
                <div className={['Message'].join(" ")}> { this.state.error_msg } </div>
              </div>
          }

          { 
            <IDE {...this.props} 
              selectedIndex={ this.props.selectedIndex }
              showTemplate={!this.state.showTemplate} 
              code={this.props.code} 
              changeCode={this.changeCode } onBlur={this.onBlur} />
          }

          { !this.state.showTemplate && this.props.useTemplate &&
            <div className={["message"].join(" ")} >  { "Using a strategy bot" }  </div>
          }

            <Template {...this.props} 
              //showboard={this.props.showboard} 
              showTemplate={ this.state.showTemplate }
              code={this.props.code} 
              create={this.create} 
              callBack={this.callBack} 
              selected={this.selected} 
              getStrategy={this.getStrategy} 
              selectedTemplate={this.state.selectedTemplate} 
              name={this.state.name} 
              description={this.state.description} 
              input={this.props.input} 
              updateCode={this.updateCode}  />
        </div>
      )
    }
}


export default Code;

//<Query {...this.props} showboard={this.props.showboard}  />


/*
# Aurther : Garlican Team
# Create at : 2020-12

#CHIPS
maxbetsize = 1000
chip = 200
_bs = floor(chip/price_per_lot)
betsize = _bs

#DEFINES
h10 = Highest(high, 10)
h30 = Highest(high, 30)
h60 = Highest(high, 60)
l10 = Lowest(low, 10)
l30 = Lowest(low, 30)
l60 = Lowest(low, 60)
DH200 = Highest(high, 200)
DL200 = Lowest(low, 200)

RSI30 = RSI(close, 30)

EMA50  = EMA(close, 50)
EMA150 = EMA(close, 150)
EMA200 = EMA(close, 200)

CH60 = Highest(close, 60)
CH30 = Highest(close, 30)
CH10 = Highest(close, 10)

CL60 = Lowest(close, 60)
CL30 = Lowest(close, 30)
CL10 = Lowest(close, 10)

ov = OBV()
wcp = weightedclose

# CALCULATIONS
ch = ((close < h60) & (close < h30) & (close < h10) & ( h10>(h60/1.1) ) & ( h10<(h60*1.1) ) & (h60 != h10)  )
cl = ((close > l60) & (close > l30) & (l10>l30) & (l30>l60) & (l60 != l30) )
s1 = ( (EMA150 > EMA200) & (ch & cl) ) * 10
s2 = ( (EMA50  > EMA150) & (ch & cl) ) * 10
s3 = ( close > DL200 *1.3) * 10
s4 = ( close > DH200 /0.8) * 10
s5 = (RSI30 > 0.5) * 5
s6 = ( EMA(ov, 10) > EMA(ov,30) ) * 7.5
s7 = ( EMA(ov,  5) > EMA(ov,10) ) * 7.5
s8 = ((std(wcp, 30) < std(wcp, 60)*1.2)&ch&cl) * 10
s9 = ((std(wcp, 10) < std(wcp, 30)*1.2)&ch&cl) * 10
s10 = (((CH60 - CL60) > (CH30 - CL30)*1.2 ) & ch & cl) * 10
s11 = (((CH30 - CL30) > (CH10 - CL10)*1.2 ) & ch & cl) * 10
s = s1 + s2 + s3 + s4 + s5 + s6 + s7 + s8 + s9 + s10 + s11


condition_long = (s > 40) & ( crossover(s, s[1])  ) & ( close > CL10[5] ) & ( close < CH30 )


# Aurther : Garlican Team
# Create at : 2020-12

EC5  = EMA(close, 5)
EC10 = EMA(close, 10)

cond1 = (( crossunder(s, s[1])  ) & ( rsi(close, 5) > 70 )) 
cond2 = (( crossunder(s, s[1])  ) & ( EC10 > EC5 ))


up_value=0.15 #止賺(%)
#down_value=0.15 #止損(%)
upper_profit_exit = (close >= (entryprice*( 1+up_value) ) ) #止賺賣出
#lower_profit_exit = (close <= (entryprice*( 1-down_value)) ) #止損賣出
#condition_short =  (upper_profit_exit | lower_profit_exit)
#time_to_stop = barssinceentry >= 20 #CUT AFTER 20 BARS
time_to_stop = false

condition_short =  cond1 | cond2 | upper_profit_exit | time_to_stop 






*/