import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faArrowCircleUp, faArrowCircleDown, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import service from '../../public/service'

import { Modal, Button } from 'react-bootstrap';

import gcolor from '../../public/color.js';
import language from './language.js'

var plans={
  "prod_MkuYdVBObHLl3V" : {
    "name" : language['name'],
    "items" : [
      { "key" : "Marketplace Access Right" , 
        "href" : window.location.origin,
        "children" : [
        { "key" : "Share Botfolio" , "value" : "yes" },
        { "key" : "Support HKG market" , "value" : "yes" },
        { "key" : "Support USA market" , "value" : "yes" },
        { "key" : "Purchase Botolfio" , "value" : "yes" },
      ]   },
      { "key" : "Create Trading Botfolio" , 
        "href" : window.location.origin + "/manager",
        "children" : [
        { "key" : "Create Boitfolio" , "value" : "Unlimited" },
        { "key" : "Create Templates" , "value" : "Unlimited" },
        { "key" : "Run backtesting report" , "value" : "Unlimited" },
        { "key" : "Run forwarding report" , "value" : "Unlimited" },
        { "key" : "Publish Botfolio on Marketplace" , "value" : "Unlimited" },
        { "key" : "Revenue sharing" , "value" : "Yes" }
      ]   },
      { "key" : "Notifications" , 
        "href" : window.location.origin + "/automate",
        "children" : [
        { "key" : "Hourly-based notifications" , "value" : "Unlimited" },
        { "key" : "Whatsap/ SMS content" , "value" : "Full" },
        { "key" : "Daily Report" , "value" : "Yes" },
      ]   }
    ],
  },
  "free" : {
    "name" : "deMI Regular",
    "items" : [
      { "key" : "Marketplace Access Right" , 
        "href" : window.location.origin,
        "children" : [
        { "key" : "Share Botfolio" , "value" : "yes" },
        { "key" : "Support HKG market" , "value" : "yes" },
        { "key" : "Support USA market" , "value" : "yes" },
        { "key" : "Purchase Botolfio" , "value" : "yes" },
      ]   },
      { "key" : "Create Trading Botfolio" , 
      "href" : window.location.origin + "/manager",
        "children" : [
        { "key" : "Create Boitfolio" , "value" : "2" },
        { "key" : "Create Templates" , "value" : "0" },
        { "key" : "Run backtesting report" , "value" : "10/day" },
        { "key" : "Run forwarding report" , "value" : "5/day" },
        { "key" : "Publish Botfolio on Marketplace" , "value" : "1" },
        { "key" : "Revenue sharing" , "value" : "No" }
      ]   },
      { "key" : "Notifications" , 
        "href" : window.location.origin + "/automate",
        "children" : [
        { "key" : "Hourly-based notifications" , "value" : "1/day" },
        { "key" : "Whatsap/ SMS content" , "value" : "Partial" },
        { "key" : "Daily Report" , "value" : "No" },
      ]   }
    ],
  }
}


class Stripe extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          items : [],
          plans : [],
        };        
        this.createCheckout = this.createCheckout.bind(this)
        this.create = this.create.bind(this)
    }


    componentDidMount() {
      service.getAllPrices().then(
        (res) => {
          var obj = res.list
          obj.splice( 0,0, { "product_id" : "free" } )
          //console.log( obj )
          this.setState({
            items : obj,
          })
        }
      ).catch( (e) => {
      })

      var plans = this.create(this.props.language)

      this.setState({
        plans : plans
      })
    }

    create(lang) {
      var plans={
        "prod_MkuYdVBObHLl3V" : {
          "name" : language['professional_name'][lang],
          "items" : [
            { "key" : language["marketplace_access_right"][lang] , 
              "href" : window.location.origin,
              "children" : [
              { "key" : language['share_botfolio'][lang] , "value" : language['yes'][lang] },
              { "key" : language['suppport_hk_market'][lang] , "value" : language['yes'][lang]  },
              { "key" : language['suppport_us_market'][lang] , "value" : language['yes'][lang]  },
              { "key" : language['purchase_botfolio'][lang] , "value" : language['yes'][lang]  },
            ]   },
            { "key" : language["create_trading_botfolio"][lang] , 
              "href" : window.location.origin + "/manager",
              "children" : [
              { "key" : language["create_botfolio"][lang] , "value" : language["unlimited"][lang] },
              { "key" : language["create_templates"][lang] , "value" : language["unlimited"][lang] },
              { "key" : language["run_backtesting_report"][lang] , "value" : language["unlimited"][lang] },
              { "key" : language["run_forwardtesting_report"][lang] , "value" : language["unlimited"][lang] },
              { "key" : language["publish_on_marketplace"][lang] , "value" : language["unlimited"][lang]  },
              { "key" : language["revenue_sharing"][lang]  , "value" : language["yes"][lang] }
            ]   },
            { "key" : language["notification"][lang] , 
              "href" : window.location.origin + "/automate",
              "children" : [
              { "key" : language["intra_day"][lang] , "value" : language["unlimited"][lang] },
              { "key" : language["messager"][lang] , "value" : language["full"][lang] },
              { "key" : language["dayend_report"][lang] , "value" : language["yes"][lang] },
            ]   }
          ],
        },
        "free" : {
          "name" : language['regular_name'][lang],
          "items" : [
            { "key" : language["marketplace_access_right"][lang]  , 
              "href" : window.location.origin,
              "children" : [
                { "key" : language['share_botfolio'][lang] , "value" : language['yes'][lang] },
                { "key" : language['suppport_hk_market'][lang] , "value" : language['yes'][lang]  },
                { "key" : language['suppport_us_market'][lang] , "value" : language['yes'][lang]  },
                { "key" : language['purchase_botfolio'][lang] , "value" : language['yes'][lang]  },
            ]   },
            { "key" : language["create_trading_botfolio"][lang]  , 
            "href" : window.location.origin + "/manager",
              "children" : [
              { "key" : language["create_botfolio"][lang] , "value" : "2" },
              { "key" : language["create_templates"][lang]  , "value" : "0" },
              { "key" : language["run_backtesting_report"][lang] , "value" : ("10/" + language["day"][lang]) },
              { "key" : language["run_forwardtesting_report"][lang] , "value" : ("5/" + language["day"][lang]) },
              { "key" : language["publish_on_marketplace"][lang] , "value" : "1" },
              { "key" : language["revenue_sharing"][lang]  , "value" : language["no"][lang]  }
            ]   },
            { "key" : language["notification"][lang], 
              "href" : window.location.origin + "/automate",
              "children" : [
              { "key" : language["intra_day"][lang]  , "value" : ("1/" + language["day"][lang]) },
              { "key" : language["messager"][lang]  , "value" : language["partial"][lang] },
              { "key" : language["dayend_report"][lang]  , "value" : language["no"][lang] },
            ]   }
          ],
        }
      }
      return plans
    }

    componentWillUnmount() {
        
    }

    createCheckout(price_id) {
        service.createCheckout(price_id).then(
          (res) => {
            //console.log(res)
            window.location.href = res.url
          }
        ).catch( (e) => {
          //console.log(e)
          
        } )
    }

    render() {
        return(
          <Modal 
            size={"lg"}
            show={this.props.isPayment}
            centered={true}
            dialogClassName={"Stripe-Dialog"}
            contentClassName={"Stripe-Modal"}
            onHide={() => {
              this.props.updateValue({
                isPayment : !this.props.isPayment
              })
            }}
          >
            <Modal.Header  className={ ['Stripe-Header'].join(" ") } >
              <Modal.Title id="example-modal-sizes-title-lg">
                { "Upgrade" }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={ ['Stripe-Wrapper'].join(" ") }>
              {
                this.state.items.map((v,k) => {
                  return(
                    <div className={ ['Stripe-Holder'].join(" ") } key={k} >
                      { v.amount &&
                        <div className={ ['Stripe-Price'].join(" ") } key={k} onClick={
                          (e) => {
                            if(v.id) {
                              this.createCheckout( v.id )
                            }
                          }
                        }>
                          <div className={ ['Currency'].join(" ")}> { /*v.currency.toUpperCase()*/  (v.currency in language)?language[ v.currency ][this.props.language]:v.currency.toUpperCase()  } </div>
                          <div className={ ['Text'].join(" ")}>{ Math.round(v.amount/100) }</div>
                          <div className={ ['Smaller'].join(" ")}>{ (v.amount/100 -  Math.round(v.amount/100)).toFixed(2).substring( 1,5  ) }</div>
                        </div>
                      }

                      { !v.amount &&
                        <div className={ ['Stripe-Price', "Free"].join(" ") } key={k} >
                          <div className={ ['Text'].join(" ")}>{ language["free"][this.props.language] }</div>
                        </div>
                      }

                      { v.product_id in plans &&
                        <div className={['Stripe-Scheme'].join(" ")}>
                          <div className={['Title'].join(" ")}> { this.state.plans[ v.product_id ].name  } </div>
                          <div className={['Plan'].join(" ")}>
                            {
                              this.state.plans[v.product_id ].items.map(
                                (v1, k1) =>{
                                  return(
                                    <div className={['Section'].join(" ")} key={k1} >
                                      <a className={['Stitle', v.id?"":"Free"].join(" ")} href={v1.href} > {v1.key}  </a>
                                      {
                                        v1.children.map( (v2, k2) => {
                                          return(
                                            <div className={['List'].join(" ")} key={k2} >
                                              <div className={['Desc'].join(" ")} > { v2.key } </div>
                                              <div className={['Val', v.id?"":"Free"].join(" ")} > { v2.value} </div>
                                            </div>
                                          )
                                        } )
                                      }
                                    </div>
                                  )
                                }
                              )
                            }
                          </div>
                        </div>
                      }
                        { (service.user) &&
                        <Button
                          disabled={ !v.id || (service.user.groups?(service.user.groups.includes("professional")):false)    }
                          className={["Pay-btn", v.id?"":"Free"].join(" ")}
                          onClick={ (e) => {
                            if(v.id) {
                              this.createCheckout( v.id )
                            }
                          } }
                          > {
                              v.id?
                              ((service.user.groups&&service.user.groups.includes("professional"))?language["purchased"][this.props.language]:language["purchase"][this.props.language])
                              :
                              language["free"][this.props.language]
                            } 
                          </Button>  
                          }
                    </div>
                  )
                })
              }
            </Modal.Body>
          </Modal>

        )
    }
    /*
              <Button
              style={
                {
                  zIndex : 9999,
                  position : "absolute",
                  top: "10px",
                  left : "10px",
                  width: "100px",
                  height: "100px",
                  background: "brown"
                }
              } 
              onClick={ (e) => {
                this.createCheckout( this.state.price_id )
              } }
              className={["btn"].join(" ")}> {"CLICK"} </Button>  
    */
}


export default Stripe;



