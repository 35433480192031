import React from "react";
import Privacy from './Privacy'
import './PrivacyScreen.scss'

import {Link, useHistory} from 'react-router-dom'

export default class PrivacyScreen extends React.Component {

  constructor(props) {
    super(props); 
    this.state= {
      isShow : false,
      language : this.props.language
    } 
    this.show = this.show.bind(this)
  }

  show() {
    this.setState( { isShow:true  } )
  }


  componentWillMount() {
    //console.log("HAHA")
    this.props.updateValue({
      showUser : false,
    })
    //console.log(  Router.DefaultRoute )
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  
  render() {
      return (
        <div className={['terms-container'].join(' ') }>


            <div className={['terms-header'].join(' ') }> { Privacy[this.props.language]['header']  }  </div>
            <div className={['terms-content'].join(' ') }> { Privacy[this.props.language]['first_message']  }  </div>
            <div className={['terms-content2'].join(' ') }> { Privacy[this.props.language]['notification']  }  </div>

            {
              
              Privacy[this.props.language]['promise_content'].map( i => {
                return(
                  <div key={i['title']}>
                    <div className={['terms-sub-header'].join(' ') }>{i['title']}</div>
                    <div className={['terms-content'].join(' ') }> { i['content']  }  </div>
                    { 
                        i['sub_content'].map(j => {
                          return <li className={['terms-sub-content'].join(' ')} key={j} > { j }  </li>
                        })
                    }
                    { (i['further'] !== "") && <div className={['terms-content'].join(' ') }> { i['further']  }  </div>}
                    { 
                        i['further_list'].map(j => {
                          return <li className={['terms-sub-content'].join(' ')} key={j}> { j }  </li>
                        })
                    }
                    { (i['last_statement'] !== "") && <div className={['terms-content'].join(' ') }> { i['last_statemment']  }  </div>}
                  </div>
                )
              })

            }


            <br/>
            <br/>
            <br/>

        </div>
      )

  }
}






