import React, { Component } from 'react';
import './index.scss';

import { FormControl, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight,faChevronUp, faChevronDown, faTimes ,faSearch, faFilter, faRobot, faCircleCheck, faPlay, faExpand, faTableColumns, faDownload } from '@fortawesome/free-solid-svg-icons'
//import gservice from '../../Garlican/GarlicanLibrary/gservice'

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Api from '../../Garlican/GarlicanLibrary/apis'
import service from '../../public/service';

import Code from './Code'
//import Create from './Create'
import Roadmap from './Roadmap'

//import Blocks from './Blockly'
import Simple from './Simple'


import * as XLSX from "xlsx";


var tree = [
    { key : 'filter', value : 'filter', icon : faFilter  },
    { key : 'buy', value : 'buy', icon : faRobot },
    { key : 'sell', value : 'sell', icon : faRobot },
    { key : 'update', value : 'update', icon : faCircleCheck },
]

//var menus = []
var currentSelectedBoard = null

const numberScale = [
	{ key : "default", value : 1 , display : '' , short : "" },
	{ key : "thousand", value : 1000 , display : ',000' , short : "K"},
	{ key : "million" , value : 1000000 , display : ',000,000' , short : "M"},
	{ key : "billion" , value :1000000000 , display : ',000,000,000' , short : "B"},
	{ key : "trillion" , value :1000000000000 ,display : ',000,000,000,000', short : "T" },
]

const NUM_MATCH = [
  {key : "t", value : 1000000000000},
  {key : "b", value : 1000000000},
  {key : "m", value : 1000000},
  {key : "w", value : 10000},
  {key : "k", value : 1000 }
]

const MAX_FILTERS = 10

const MILLION = 1000000

const stringConfig = {
	'number' : [
		{ 'key' : 'gt' , 'value' : '>',  'type' : 'number'},
		//{ 'key' : 'eq' , 'value' : '=',  'type' : 'number' },
		{ 'key' : 'lt' , 'value' : '<',  'type' : 'number'},
		{ 'key' : 'ge' , 'value' : '>=', 'type' : 'number'},
		{ 'key' : 'le' , 'value' : '<=', 'type' : 'number'},
		//{ 'key' : 'ne' , 'value' : '!=', 'type' : 'number'},
		{ 'key' : 'btw' , 'value' : 'btw', 'type' : 'array'},
	],
	'string' : [
		//{ 'key' : 'eq' , 'value' : '=' , 'type' : 'string'},
		{ 'key' : 'in' , 'value' : 'in', 'type' : 'array'},
		{ 'key' : 'nin' , 'value' : 'nin', 'type' : 'array'},
		
	]
}

const icons = [
  { key : 'template', value : 'use_template', icon : faFilter },
  { key : 'buy', value : 'long_strategy', icon : faRobot },
  { key : 'sell', value : 'short_strategy', icon : faRobot },
]

const RANGE = 50
var tmptable = {}
class Filter extends Component {

    constructor(props) {
        super(props)
        var tables = {}
        var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
        var table  = storage.getItem("@garlican/tables")
        //var table = null
        //storage.setItem("@garlican/tables", null)
        var _d =  service.formatDate()

        var initial_region = props.regions[0].region, hasFilter = true, demo = false
        if(  this.props.selected === "template" ) {
          var s = this.props.regions.filter(  (v,k) => (v.region === props.selectedStrategy.region) )
        } else {
          var s = this.props.regions.filter(  (v,k) => (v.region === props.selectedBotfolio.region) )
        }
        if( s.length > 0 ) {
          hasFilter = s[0].filter
          demo = s[0].demo
        }

        if( table !== "null" ) {

          try {
            var table = JSON.parse(  table  )
            if( table.date === _d ) {
              tables = table.tables          
              if( !(true===false) ) {
                if( table.tables.history ) {
                  var history= table.tables.history
                  history.sort( (a,b)=>( parseFloat(b.marketcap,10) - parseFloat(a.marketcap,10) ) )
                  table.tables.history = history
                }
              }
            } else {
              //storage.setItem("@garlican/tables", null)
              tables = {}
            }
            for (var i in props.regions) {
              if( !(props.regions[i].region in tables) ) {
                tables[ props.regions[i].region ] = {}
              }
            }
          } catch (e) {
            storage.setItem("@garlican/tables", null)
          }


        } else {
          for (var i in props.regions) {
            tables[ props.regions[i].region ] = {}
          }
        }

        tmptable = JSON.parse( JSON.stringify( tables ) )

        currentSelectedBoard = props.selectedBoard

        this.state = { 
          selectedStrategy : {},
          board_filters : [],
          obj : [],
          display : [],
          max : 0,
          page : 0,
          lang : {},
          asccending : true,
          tables : tables,
          selectSortCat : null,
          selectedTable : 'sector',
          show : false,
          read_sym : {},
          showboard : false, 
          icons : icons,
          forward : [],
          showContentMenu : null,
          isFilter : true,
          showSymbols : props.isFilter,

          menus : [],
          hasFilter : hasFilter,
          region : (props.selectedBotfolio.region?props.selectedBotfolio.region:props.selectedStrategy.region) ,
          demo : demo,
          simple : true,

          selectedIndex : 0,
          selectedMenu : null,

          searchText : "",
          isFull : false,
          hasResult : false,
        };
        this.getTable = this.getTable.bind(this)
        this.getFilterTable = this.getFilterTable.bind(this)
        this.sort = this.sort.bind(this)
        this.sortby = this.sortby.bind(this)
        this.page = this.page.bind(this)

        this.search = this.search.bind(this)
        this.filter = this.filter.bind(this)
        this.searchFilter = this.searchFilter.bind(this)

        this.read = this.read.bind(this)
        this.updateFilterValue = this.updateFilterValue.bind(this)

        this.showBoard = this.showBoard.bind(this)
        this.getObj = this.getObj.bind(this)
        this.refresh = this.refresh.bind(this)

        this.error = this.error.bind(this)

        this.code = React.createRef()

        this.noFilter = this.noFilter.bind(this)
        this.initial = this.initial.bind(this)
        this.getNoFilterKey = this.getNoFilterKey.bind(this)

        this.search = this.search.bind(this)
        this.insertFilter = this.insertFilter.bind(this)
        this.updateFilter = this.updateFilter.bind(this)
        this.removeFilter = this.removeFilter.bind(this)


        this.abjustNumber = this.abjustNumber.bind(this)

        this.exportScreener = this.exportScreener.bind(this)

        //if( !state.hasFilter && (symbol_list.length > 0) ) {
        //  //console.log( original_symbol_list )
        //  this.props.updateParent({ 
            
        //    original_symbol_list : original_symbol_list,
        //    symbol_list : symbol_list,
        //  })
        //}
        var _this = this
        setTimeout(  (e) => {
          _this.initial()
        }, 300)
    }

    componentDidMount() {
      //var a = this.props.selectedRegion
    }

    initial() {
      this.setState({
        isFilter : this.props.isFilter,
      } )
      var _this = this
      if(  this.props.selected === "template" ) {
        var filters = JSON.parse( JSON.stringify(  this.props.filters  ) )
        var obj = {}
        obj.isFilter = this.props.isFilter
        obj.selectedStrategy = this.props.selectedStrategy
        obj.region = this.props.selectedStrategy.region
        obj.menus = this.configMenu( this.state.hasFilter,  this.props.selectedStrategy.region )
        obj.selectedMenu = obj.menus[0].key
        this.setState(obj)
      } else {  
        var filters = JSON.parse( JSON.stringify(  this.props.filters  ) )
        var obj = {}
        obj.isFilter = this.props.isFilter
        obj.selectedStrategy = this.props.selectedStrategy
        obj.region = this.props.selectedBotfolio.region

        obj.menus = this.configMenu( this.state.hasFilter,  this.props.selectedBotfolio.region )
        obj.selectedMenu = obj.menus[0].key
        //console.log( this.props.selectedStrategy )
        this.setState(obj)
        /*
        setTimeout( ()=>{
          if( _this.props.selectedStrategy.filters.length > 0 ) {
            if( _this.state.hasFilter ) {
              var _filters = Object.values( _this.props.filters )
              _this.getFilterTable(filters, _filters[  _filters.length-1 ].key, _this.state.region )
            } else {
              _this.noFilter( filters,  _this.state.region, _this.state.demo )
            }
          } else {
            _this.getFilterTable( filters, null, _this.state.region  )
          } 
        }, 300 )
        */
      }
    }

    componentWillUnmount() {
      this.setState({
        hasResult : false,
      })
    }

    UNSAFE_componentWillReceiveProps(props) {
    //componentDidUpdate( props, state ) {
      var obj = {}
      if(  ['buy', 'sell', 'create'].includes( props.selectedBoard )  ) {
        if( props.selectedBoard === currentSelectedBoard) {
        } else {
          currentSelectedBoard = props.selectedBoard
        }
      }

      var _this = this
      if( props.isFilter ) {
        obj.icons = [icons[0]]
      } else {
        obj.icons = icons
      }
      
      //console.log( props.selected )

      this.setState(obj)
      if ( !props.isFilter ) {
        if( "id" in props.selectedStrategy ) {
          var obj = {}
          if( props.isFilter !== this.state.isFilter ) {
              obj = {
                region : props.selectedBotfolio.region?props.selectedBotfolio.region:props.selectedStrategy.region,
                isFilter : props.isFilter,
                showSymbols : props.isFilter,
                read_sym : {},
                selectSortCat : null,
                obj : [],
                forward : [],
              }
              this.setState(obj)
          } 
          if( props.selectedStrategy.id !== this.state.selectedStrategy.id ) {
            obj.isFilter = props.isFilter
            obj.selectedStrategy = props.selectedStrategy
            obj.region = props.selectedBotfolio.region?props.selectedBotfolio.region:props.selectedStrategy.region
            this.setState(obj)
            //var filters = JSON.parse( JSON.stringify(    props.selectedStrategy.filters  )  )
            var filters = JSON.parse( JSON.stringify(    props.filters  )  )


            if( this.state.hasFilter ) {
              filters.push(  { 'key' : "sector" , 'type':'type', 'opr' : "=" , 'value' : "*" }  )
            }
            setTimeout( ()=>{
              if( props.selectedStrategy.filters.length > 0 ) {
                var _filters = Object.values( props.filters )
                if ( this.state.hasFilter ) {
                  _this.getFilterTable(filters, _filters[  _filters.length-1 ].key, _this.state.region)
                } else {
                  _this.noFilter(  filters,  _this.state.region, _this.state.demo )
                }

              } else {
                _this.getFilterTable( filters, null, _this.state.region  )
              } 
            }, 300 )
          }
        }
      } else {
        if( props.isFilter !== this.state.isFilter ) {
          this.setState({
            tables : JSON.parse( JSON.stringify( tmptable )),
            isFilter : props.isFilter,
            showSymbols : props.isFilter,
            read_sym : {},
            selectSortCat : null,
            selectedStrategy : {},
            obj : [],
            forward : [],
            display : [],
          } )
          setTimeout( ()=>{
            if( this.state.hasFilter ) {
              _this.refresh()
            } else {
              _this.noFilter(null,  _this.state.region, _this.state.demo )
            }
          }, 300 )
          
        }
      }

    }

    configMenu(hasFilter,region="hkg", search=null) {
      
      var _menu = []
      var validations = [
        'sector', 'marketcap', 'basic','cashflow', 'debt', 'profit',
        'summaryprofile','summarydetail', "defaultkeystatistics", "financialdata",
        'balancesheet', 'cashflowstatement', 'incomestatement'
      ]
      if( !hasFilter ) {
        validations = validations.concat(['history'])
        //validations = ["histoy"]
      }

      //console.log( )
      //console.log( this.props.filterconfig[region] )

      for (var k in validations) {
        
        var val = validations[k]
        if(  ['basic','cashflow', 'debt', 'profit'].includes( val ) ) {
          val = "finstat_" + val
        }
        if( val in this.props.filterconfig[region] ) {  

          var o = JSON.parse(  JSON.stringify( this.props.filterconfig[region][val] ) )
          var abj_value = o.value
          if( hasFilter ) {
            if( search && (search !== "") ) {
              abj_value = abj_value.filter( (v,k) => { 
                return (JSON.stringify(v.lang).toLowerCase().indexOf( search.toLowerCase() ) > -1) 
              }   )
            }
          } else {
            //abj_value = abj_value.map(  (v,g)=>{ return  { value : v, lang:{eng : v} }  } )
            abj_value = [ { value : abj_value, lang:{eng : "Symbol"} , vtype : "string" , key : "symbol" } ] 
          }

          _menu.push( { 
              key : val, 
              value : abj_value, 
              advice : o.advice,
              lang : o.lang
          } )
        }


      }
      //console.log(  this.props.filterconfig[region] )
      //console.log(  _menu )
      return _menu
      
    }

    noFilter(filter, region="hkg", demo=false) {
      var key = 'history',  board_filters=[]
      var _this = this

      function _call(res, save) {
        var tables = JSON.parse( JSON.stringify( _this.state.tables ))
        tables[region][ key ] =  res      
        var display = []
        if( filter ) {
          if( "symbol" in filter) {
            var values = filter['symbol'].value
            display = JSON.parse( JSON.stringify( res )  )
            display = res.filter(  (v,k) => values.includes( v.symbol )  )
            display = display.slice( 0, RANGE )
            res = display
          }  else {
            display = JSON.parse(  JSON.stringify( res ) )
            display = display.slice(  0, RANGE   )
          }
        }


        board_filters = _this.getNoFilterKey( res[0] , original_symbol_list   )

        var _obj = {
          obj : res,
          page : 0,
          max : Math.floor( res.length/RANGE ),
          lang : {},
          tables : tables,
          selectedTable : key,
          board_filters : board_filters,
          show : false, 
        }
        if( display.length > 0 ) {
          _obj.display = display
        }

        _this.setState( _obj )
      
        _this.sortby( "marketcap", null, null, res )

        if( !filter  ) {
          var symbol_list = [], original_symbol_list = []
          for (var s in res) {
            original_symbol_list.push( res[s].symbol )
            if( s < _this.props.maxNumberSyms) {
              symbol_list.push( res[s].symbol )
            } 
          }
          _this.props.updateParent({
            symbol_list : symbol_list,
            original_symbol_list : original_symbol_list,
          }) 
        }

        _this.props.updateValue({
          showLoading : false,
        })

        if(save) {
          var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
          var _d =  service.formatDate()
          var oj = { "date" : _d , "tables" : tables }
          storage.setItem("@garlican/tables", JSON.stringify(oj)  )
          tmptable = JSON.parse( JSON.stringify( tables ) )
        }

      }

      if( !filter ) {
        this.props.updateValue({
          showLoading : true,
        }) 
        if( !("history" in this.state.tables[region] ) ) {
          var f = [{ 'key' : 'history' , 'type':'type', 'opr' : "=" , 'value' : "*" }]
            Api.getFilterTable( f, "all", null, key , null, true, region, demo).then(
              (res) => {
                if( typeof(res) === "object" ) {         
                  var _t = []
                  var _keys = Object.keys( res )
                  var index = res[ _keys[0] ]
                  for (var i in index) {
                    var o = {}
                    for (var j in _keys) {
                      if( _keys[ j ] === "marketcap" ) {
                        o[  _keys[j] ] = res[ _keys[j] ][i] / MILLION
                      } else {
                        o[  _keys[j] ] = res[ _keys[j] ][i]
                      }
                    }
                    _t.push(  o  )
                  }
                  res = _t              
                  _call( res, true )
                }
              }
            ).catch( (e)=>{
                this.props.updateValue({
                  showLoading : false,
                  show : false, 
                })
            } )
        } else {
          var res = JSON.parse(  JSON.stringify(  this.state.tables[region]['history'] ) )
          _call( res )
        }
      } else {
        var res = JSON.parse( JSON.stringify(  this.state.tables[region]['history'] ) )
        _call(res)
      }
    }

    getNoFilterKey( res, original_symbol_list) {
      var _this = this
      var board_filters = []
      var keys = Object.keys( res )
      for( var i in keys ) {
        if( !['date'].includes( keys[i] ) ) {
          var obj = {}
          if( keys[i] !== "symbol" ) {
            obj.vtype = "number"
            obj.value = [ 0, 999999999999999 ]
          } else {
            obj.vtype = "string"
            obj.value = original_symbol_list
          }
          obj.key = keys[i]
          obj.lang = {}
          for (var k in this.props.languages) {
            obj.lang[   this.props.languages[k].key ] =  (this.props.languages[k].key in _this.props.gi18n)?(  (keys[i] in _this.props.gi18n[ this.props.languages[k].key ])?_this.props.gi18n[ this.props.languages[k].key ][ keys[i] ]:keys[i]    ):keys[i]
          }
          board_filters.push(  obj  )
        }
      } 
      return board_filters
    }

    refresh() {
      var tables = JSON.parse( JSON.stringify( this.state.tables ) )
      var initial_key = ""
      if( this.state.region === "hkg" ) {
        var filter = [
          { 'key' : 'constituent' , 'type':'type', 'opr' : "=" , 'value' : "*" }
        ]
        initial_key  = 'constituent'
      } else {
        var filter = [
          { 'key' : 'summaryprofile' , 'type':'country', 'opr' : "=" , 'value' : "*" }
        ]
        initial_key  = 'summaryprofile'
      }
      var _this = this
      function _call() {
        var filterconfig = JSON.parse(  JSON.stringify(_this.props.filterconfig[ _this.state.region  ] ) )
        //var keys = Object.keys( filterconfig )
        var key = _this.state.menus[0].key
        var type = filterconfig[ key ].value[0].key
        //var type = this.state.menus[0].key
        var filter = [
          //{ 'key' : 'sector' , 'type':'type', 'opr' : "=" , 'value' : "*" }
          { 'key' : key , 'type': type, 'opr' : "=" , 'value' : "*" }
        ]

        //_this.getTable( filter, "sector", null, _this.state.region, _this.state.demo )
        _this.getTable( filter, key , null, _this.state.region, _this.state.demo )
        
      }
      if( tables[this.state.region][ initial_key ]) {
        _call()
      } else {
          Api.getFilterTable( filter, "all", null, null, null, true, this.state.region, this.state.demo ).then(
            (res) => {
              var _t = []
              var _keys = Object.keys( res )
              var index = res[ _keys[0] ]
              for (var i in index) {
                var o = {}
                for (var j in _keys) {
                  o[  _keys[j] ] = res[ _keys[j] ][i]
                }
                _t.push(  o  )
              }
              res = _t 
              if( typeof(res) === "object" ) {  
                tables[this.state.region][ 'constituent' ] = res
              }
              var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
              var _d =  service.formatDate()
              var oj = { "date" : _d , "tables" : tables }
              storage.setItem("@garlican/tables", JSON.stringify(oj)  )
              tmptable = JSON.parse( JSON.stringify( tables ) )
              _call()
            }
          )
      }
    }

    read(k, res, _this, region="hkg") {
      if(  (k === 'basic') || (k === "cashflow") || (k === 'debt') || (k === 'profit') ) {
        k = "finstat_" + k
      }
      var lang = {}
        if( k !== 'marketcap') {
          for (var j in _this.props.filterconfig[region][k]['value'] ) {
            try {
              if( 'lang' in  _this.props.filterconfig[region][k]['value'][j] ) {
                lang[_this.props.filterconfig[region][k]['value'][j]['key'] ] = _this.props.filterconfig[region][k]['value'][j]['lang']
              }
              for (var z in _this.props.filterconfig[region][k]['value'][j].value ) {
                try{
                  if( 'lang' in  _this.props.filterconfig[region][k]['value'][j].value[z] ) {
                    lang[  _this.props.filterconfig[region][k]['value'][j].value[z]['key']  ] = _this.props.filterconfig[region][k]['value'][j].value[z]['lang']
                  }
                } catch(e) {
                
                }
              } 
            }
            catch(e) {

            }
          } 

        } else {
          lang[ _this.props.filterconfig[region][k].type ] = _this.props.filterconfig[region][k]['lang']
        }
        
        if( res ) {
          var _res = []
          
          for (var i in res) {
            var valid =true
            if( "trade_status" in res[i] ) {
              if( res[i].trade_status !== "N" ) {
                valid = false
              }
              delete res[i].trade_status
            }
            if( "type" in res[i] ) {
                delete res[i].type
            }
            if( "symbol" in res[i] ) {
              res[i].sname = _this.props.sym_names[  res[i]['symbol'] ]
              if( res[i].sname ) {
                res[i].sname.value = res[i].sname.eng
              }
            }

            var _date = ""
            if('date' in res[i]) {
              _date = res[i]['date'].substring(2,10)
            }
            if( (res[i]['symbol'] in _this.props.sym_names  ) ) {
              var keys = Object.keys( res[i] )
              for (var j in keys) {
                if( ( res[i][ keys[j] ] in lang )  ) {
                    if( (res[i][ keys[j] ] !== null) ) {
                      var a = res[i][ keys[j] ]
                      res[i][ keys[j] ] = lang[  res[i][ keys[j] ]  ] 
                      res[i][ keys[j] ].value = a
                    } else {
                      valid = false
                    } 
                } else {
                  if( (res[i][ keys[j] ] === _date) && (keys[j] !== 'date')  ) {
                    delete res[i][ keys[j] ]
                  } 
                }

                if( k!=="marketcap" ) {
                  if( !(keys[j] in lang) ) {
                      if( (keys[j] !== 'symbol') && (keys[j] !== 'date') && (keys[j] !== 'sname') && (keys[j] !== 'name')  ) {
                        delete res[i][ keys[j] ]
                      }
                  }
                }

              }
              if( valid ) {
                _res.push( res[i] )
              }
            }
            
          }
          res = _res
        } 
      
      return { obj : res, lang : lang }
    }

    getTable(filter, key, nosym, region="hkg", demo=false) {
      var filterconfig = JSON.parse(   JSON.stringify( this.props.filterconfig[region] )  )
      this.props.updateValue({
        showLoading : true,
      })
      if( this.state.selectedTable in this.state.tables[region] ) {
        var res = JSON.parse( JSON.stringify ( this.state.tables[region][this.state.selectedTable] ) )
        var read_sym = this.filter( res, this.props.filters, null, true  )
        var syms = []
        for (var s in read_sym) {
          if( read_sym[s].length > 0 ) {
            if( syms.length === 0 ) {
              syms  = read_sym[s]
            } else {
              syms = syms.filter( (v,k) => {
                return read_sym[s].includes(v)
              })
            }
          }
        }
      }
      if( !key) {
          key = this.state.selectedTable?this.state.selectedTable:filter[0]['key']
      }

      if(  (key === 'basic') || (key === "cashflow") || (key === 'debt') || (key === 'profit') ) { 
        var board_filters = filterconfig[ "finstat_" + key ].value
      } else if ( key === "marketcap") {
        //var board_filters = filterconfig[ key ].value
        var board_filters = [ filterconfig[ key ].value[0] ]
      } else if ( key === "history") {
        //var board_filters = filterconfig[ key ].value
        var board_filters = [ filterconfig[ key ].value[0] ]   
      } else if ( ['summaryprofile','summarydetail', "defaultkeystatistics", "financialdata",'balancesheet', 'cashflowstatement', 'incomestatement'].includes(key) ) {
        //'summaryprofile','summarydetail', "defaultkeystatistics", "financialdata",'balancesheet', 'cashflowstatement', 'incomestatement'   
        var board_filters = filterconfig[key].value
        if(key === "summaryprofile" ) {
          var symbol = filterconfig['symbol']
          //var o = { key : "symbol" , table : symbol.table , vtype : "string", value : symbol.value , lang : symbol.lang }
          var o = { key : "symbol" , table : key , vtype : "string", value : symbol.value , lang : symbol.lang }
          board_filters.push( o  )
        }
      } else {
        var board_filters = filterconfig[key].value
        //var constituent = filterconfig['constituent']
        //var o = { key : "constituent" , table : constituent.table , vtype : constituent.vtype, value : constituent.value , lang : constituent.lang }
        if(region == "hkg") {
          var constituent = filterconfig['constituent']
          var o = { key : "constituent" , table : constituent.table , vtype : constituent.vtype, value : constituent.value , lang : constituent.lang }
        } else {
          if(region == "usa") {
            var _default = filterconfig['summaryprofile']
            var o = { key : "summaryprofile" , table : _default.table , vtype : _default.vtype, value : _default.value , lang : _default.lang }
          }
        }
        
        board_filters.push( o  )
        var symbol = filterconfig['symbol']
        var o = { key : "symbol" , table : symbol.table , vtype : symbol.vtype, value : symbol.value , lang : symbol.lang }
        board_filters.push( o  )
      }
      
      if( key in this.state.tables[region]  ) {
        
        var res = JSON.parse( JSON.stringify ( this.state.tables[region][key] ) )


        var _o = this.read( key, res, this, region )
        if(nosym) {
          var nojb = this.filter( _o['obj'], filter )
        } else {
          var nojb = this.filter( _o['obj'], filter, syms )
        }
        if( key !== "sector" ) {
          if( nojb.length > 0 ) {
            var a = Object.keys(nojb[0])
            board_filters = board_filters.filter( (v,k) =>{
              return a.includes( v.key  )
            } )
          }
        }     
        this.setState({
          obj : nojb,
          page : 0,
          max : Math.floor( nojb.length/RANGE ),
          lang : _o['lang'],
          selectedTable : key,
          board_filters : board_filters,
          show : false, 
        })
        var _this = this
        //setTimeout( () =>{
          _this.sortby( "symbol", null, null, nojb )
          _this.props.updateValue({
            showLoading : false,
          }) 
          _this.props.updateParent({
            obj_length  : nojb.length,
            filter_length : Object.keys(this.props.filters).length
          })
        //}, 300 )
        
      } else {
        var dk = this.state.hasFilter?key.replace("finstat_",""):'history'
        
        Api.getFilterTable( filter, "all", null, dk , null, true, region, demo).then(
          (res) => {
            if( typeof(res) === "object" ) {           
              var _t = []
              var _keys = Object.keys( res )
              var index = res[ _keys[0] ]
              for (var i in index) {
                var o = {}
                for (var j in _keys) {
                  o[  _keys[j] ] = res[ _keys[j] ][i]
                }
                _t.push(  o  )
              }
              
              res = _t              
              var tables = this.state.tables
              tables[region][ key ] = JSON.parse( JSON.stringify( res ) )
              var _o = this.read( key, res, this, region )
              
              if( _o['obj'].length > 0 ) {
                var a = Object.keys(_o['obj'][0])
                board_filters = board_filters.filter( (v,k) =>{
                  return a.includes( v.key  )
                } )
              }

              if(nosym) {
                var nobj = this.filter( _o['obj'], this.props.filters  )
              } else {
                var nobj = this.filter( _o['obj'], this.props.filters, syms  )
              }
              this.setState({
                obj : nobj,
                page : 0,
                max : Math.floor( nobj.length/RANGE ),
                lang : _o['lang'],
                tables : tables,
                selectedTable : key,
                board_filters : board_filters,
                show : false, 
              })
              var _this = this
                _this.sortby( "symbol", null, null, nobj )
                _this.props.updateValue({
                  showLoading : false,
                }) 
                var f = filter.filter( (v,k) => {
                  return v.value !== "*"
                } )
                //console.log(f)
                _this.props.updateParent({
                  obj_length  : nobj.length,
                  filter_length : Object.keys(this.props.filters).length
                })
              var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
              var _d =  service.formatDate()
              var oj = { "date" : _d , "tables" : tables }
              storage.setItem("@garlican/tables", JSON.stringify(oj)  )
              tmptable = JSON.parse( JSON.stringify( tables ) )
            }
          }
          ).catch( (e)=>{
            this.props.updateValue({
              showLoading : false,
              show : false, 
            })
          }  )
      }


    }

    getFilterTable(filter, key, region="hkg") {
      /*
      var filterconfig = JSON.parse(   JSON.stringify( this.props.filterconfig[region] )  )
      if( !key) {
        key = this.state.selectedTable?this.state.selectedTable:filter[0]['key']
      }
      if( region == "hkg" ) {
        if( key === "constituent") {
          key = "sector"
        }
      } else if (region == "usa") {
        if (key === "symbol") {
          key = "summaryprofile"
        }
      }

      if(  (key === 'basic') || (key === "cashflow") || (key === 'debt') || (key === 'profit') ) { 
        var board_filters = filterconfig[ "finstat_" + key ].value
      } else if ( key === "marketcap") {
        var board_filters = filterconfig[ key ].value
      } else if ( ['summaryprofile','summarydetail', "defaultkeystatistics", "financialdata",'balancesheet', 'cashflowstatement', 'incomestatement'].includes(key) ) {
      
        var board_filters = filterconfig[key].value
        if(key === "summaryprofile" ) {
          var symbol = filterconfig['symbol']
          var o = { key : "symbol" , table : symbol.table , vtype : "string", value : symbol.value , lang : symbol.lang }
          board_filters.push( o  )
        }

      } else {

        var board_filters = []
        if(region == "hkg") {
          board_filters = filterconfig[key].value
          var constituent = filterconfig['constituent']
          var o = { key : "constituent" , table : constituent.table , vtype : constituent.vtype, value : constituent.value , lang : constituent.lang }
          board_filters.push( o  )
          var symbol = filterconfig['symbol']
          var o = { key : "symbol" , table : symbol.table , vtype : "string", value : symbol.value , lang : symbol.lang }
          board_filters.push( o  )
          
        } else {
          if(region == "usa") {
            var board_filters = filterconfig['summaryprofile'].value
            var symbol = filterconfig['symbol']
            var o = { key : "symbol" , table : symbol.table , vtype : "string", value : symbol.value , lang : symbol.lang }
            board_filters.push( o  )
          }

        }
        
      }
      */
      
      var _o = this.read( key, null, this, region )


      this.setState({
        lang : _o['lang'],
        //selectedTable : key,
        //board_filters : board_filters,
        show : false, 
      })
    }

    sort(obj, key, asc=true) {
      obj = obj.sort( (p,f) => {
        if( asc ) {
          if( typeof(p[key]) !== "object" ) {
            if(  isNaN(p[key]) === true ) {
              return ( p[key] > f[key] )?1:(( p[key] < f[key] )?-1:0)
            } else {
              return ( parseFloat(p[key], 10) > parseFloat(f[key], 10) )?1:-1
            }
          } else {
            return ( parseFloat(p[key].value, 10) > parseFloat(f[key].value, 10) )?1:-1
          }
        } else {
          if( typeof(p[key]) !== "object" ) {
            if(  isNaN( p[key] ) === true ) {
              return (f[key] < p[key] )?1:(( p[key] > f[key] )?-1:0)
            } else {
              return ( parseFloat(p[key], 10) < parseFloat(f[key], 10) )?1:-1
            }
          } else {
            return ( parseFloat(p[key].value, 10) < parseFloat(f[key].value, 10) )?1:-1
          }
        }
      }  )
      return obj
    }

    sortby( key, asc=true, forward, obj ) {        
      obj = obj?obj:JSON.parse(  JSON.stringify( this.state.obj )  )
      if( !forward) {
        obj = this.sort( obj , key, asc )
      } else {
        obj = obj.sort((x, y) => forward.indexOf(y.symbol) - forward.indexOf(x.symbol)   )
      }
      var display = JSON.parse(  JSON.stringify( obj ) )
      display = display.slice(  0, RANGE   )
      var obj = {
        selectSortCat : key,
        obj : obj,
        display : display,
        asccending : asc,
      }
      if( forward) {
        obj.forward = forward
      }
      
      this.setState(obj)
      
      
    }

    page( next , from ) {
      var display = JSON.parse(  JSON.stringify( this.state.obj ) )
      if( from === "first" ) {
        display = display.slice(  0, RANGE   )
        this.setState({
          page : 0,
          display : display
        })
      } else if( from === "last" ) {
        display = display.slice(  (this.state.max)*(RANGE), ( (this.state.max+1) * RANGE)   )
        this.setState({
          page : this.state.max,
          display : display
        })
      } else {
        if( next ) {
          if( this.state.page !== this.state.max ) {
            var page = this.state.page + 1
            display = display.slice(  (page)*(RANGE), ( (page+1) * RANGE)   )
            this.setState({
              page : page,
              display : display
            })
          }
        } else {
          if( this.state.page !== 0 ) {
            var page = this.state.page -1
            display = display.slice(  (page)*(RANGE), ( (page+1) *RANGE)   )
            this.setState({
              page : page,
              display : display
            })
          }
        }
      }

      

    }

    getFilterParameters( f, region="hkg" ) {
      var v = {}
      if( f.parent ) {
        var o = {}
        if( f.parent === 'finstat' ) {
          o = this.props.filterconfig[region][ f.parent+ "_" + f.key ].value
        } else {
          o = this.props.filterconfig[region][ f.parent ].value
        }
        for (var i in o) {
          if( o[i].key === f.type ) {
            v = o[i]
            if( v.vtype === "number" ) {
              v.numberScale = numberScale
              v.selectedNumberScale = numberScale[1]
            }
            break
          }
        }
      } else {
        if( f.key === 'history' ) {
          v = this.props.filterconfig[region]['symbol']
        } else {
          v = this.props.filterconfig[region][f.key]
        }
        if( v.vtype === "number" ) {
          v.numberScale = numberScale
          v.selectedNumberScale = numberScale[1]
        }
      }
      return v
    }
  
    search(filters, region="hkg") {
        var _filters = []
        for(var f in filters){
          var k = JSON.parse(  JSON.stringify(  filters[f] ) )
          if( typeof(k.value) === "object" ) {
            k.value = k.value.join(",")
          }
          _filters.push(  k   )
        }
        if ( this.state.hasFilter ) {
          var obj = this.read( this.state.selectedTable , this.state.tables[region][   this.state.selectedTable   ], this, region)['obj']
        } else {

          var obj = JSON.parse( JSON.stringify(  this.state.tables[region][   this.state.selectedTable   ]  ) )

        }


        var nobj = this.filter( obj, filters )


        var display = JSON.parse( JSON.stringify( nobj ) )
        display = display.slice( 0, RANGE )

        var obj = {
          obj : nobj,
          display : display,
          page : 0,
          max : Math.floor( nobj.length/RANGE ),
        }


        this.setState( obj )
        var f = []

        if( _filters.length > 0 ) {
          f = _filters.filter( (v,k) => {
            return v.value !== "*"
          } )
        }


        var _obj = {
          obj_length  : nobj.length,
          filters : filters,
          filter_length : f.length
        }
        
        if ( !this.state.hasFilter ) {
          if( nobj.length > 0 ) {
            _obj.symbol_list = nobj.map( (v,k) => v.symbol )
          }
        }


        this.props.updateParent( _obj )



    }

    searchFilter(filters, key, region="hkg") {
     // var filterconfig = JSON.parse(   JSON.stringify( this.props.filterconfig[region] )  )
      var _this = this
      this.props.updateValue( {
        showLoading : true
      })

      /*
      if(  (key === 'basic') || (key === "cashflow") || (key === 'debt') || (key === 'profit') ) { 
        var board_filters = filterconfig[ "finstat_" + key ].value
      } else if ( key === "marketcap") {
        var board_filters = filterconfig[ key ].value
      } else if ( ['summaryprofile','summarydetail', "defaultkeystatistics", "financialdata",'balancesheet', 'cashflowstatement', 'incomestatement'].includes(key) ) {
        var board_filters = filterconfig[ key ].value
        if(key === "summaryprofile" ) {
          var symbol = filterconfig['symbol']
          //var o = { key : "symbol" , table : symbol.table , vtype : "string", value : symbol.value , lang : symbol.lang }
          var o = { key : "symbol" , table : key , vtype : "string", value : symbol.value , lang : symbol.lang }
          board_filters.push( o  )
        }
      } else {
        var board_filters = filterconfig[key].value
        var constituent = filterconfig['constituent']
        var o = { key : "constituent" , table : constituent.table , vtype : constituent.vtype, value : constituent.value , lang : constituent.lang }
        board_filters.push( o  )
        var symbol = filterconfig['symbol']
        var o = { key : "symbol" , table : symbol.table , vtype : symbol.vtype, value : symbol.value , lang : symbol.lang }
        board_filters.push( o  )
      }
      */

      var _filters =  this.props.abjustFilter( filters )

      key = key.replace("finstat_", "")
    
      Api.getFilterTable( _filters, "all", null, key, null, true, region  ).then(
        (res) => {
          if( typeof(res) === "object" ) {           
            var _t = []
            var _keys = Object.keys( res )
            var index = res[ _keys[0] ]
            for (var i in index) {
              var o = {}
              for (var j in _keys) {
                o[  _keys[j] ] = res[ _keys[j] ][i]
              }
              _t.push(  o  )
            }
            res = _t              
            var tables = this.state.tables
            tables[region][ key ] = JSON.parse( JSON.stringify( res ) )
            var _o = this.read( key, res, this, region )
            //if( _o['obj'].length > 0 ) {
            //  var a = Object.keys(_o['obj'][0])
            //  board_filters = board_filters.filter( (v,k) =>{
            //    return a.includes( v.key  )
            //  } )
            //}
            
            
            //var nobj = _this.filter( _o['obj'], this.props.filters  )
            var nobj = _o['obj']
                      
            this.setState({
              obj : nobj,
              lang : _o['lang'],
              hasResult : true,
              //selectedTable : key,
              //board_filters : board_filters,
              show : false, 
            })
            this.props.updateValue( {
              showLoading : false
            })
            this.props.updateParent({
              obj_length  : nobj.length,
              filter_length : _filters.length
            })
          }
        }
      ).catch(
        (e) => {
          console.log(e)
          this.props.updateValue( {
            showLoading : false
          })
        }
      )

    }
    
    filter( res, filters, syms, isSym ) {
      var df = JSON.parse(  JSON.stringify(res) )
      var read_sym = JSON.parse(  JSON.stringify( this.state.read_sym )  )

      if(  !syms  ) {
        for( var f in filters) {
          if( filters[f].key === this.state.selectedTable ) {
            df = JSON.parse(  JSON.stringify(res) )
            if( filters[f].value !== "*" ) {
              if(  ((typeof( filters[f].value ) === "object") && ( filters[f].value.length === 0 )) || ((  typeof( filters[f].value ) === "string") && (  filters[f].value === ""  ))    ) {
              } else {  
                if( filters[f].opr === "in" ) {
                  df = df.filter( (v,k) => {
                    if(  filters[f].type !== "symbol"  ) {
                      return filters[f].value.includes( v[  filters[f].type  ].value  )
                    } else {
                      return filters[f].value.includes( v[  filters[f].type  ]  )
                    }
                  }  )
                } else if( filters[f].opr === "nin" ) {
                  df = df.filter( (v,k) => {
                    if(  filters[f].type !== "symbol"  ) {
                      return !filters[f].value.includes( v[  filters[f].type  ].value  )
                    } else {
                      return !filters[f].value.includes( v[  filters[f].type  ]  )
                    }
                  }  )
                } else if ( filters[f].opr === ">" ) {
                  df = df.filter( (v,k) => {
                    return parseFloat( v[  filters[f].type  ], 10) > parseFloat(filters[f].value, 10)
                  }  )
                } else if ( filters[f].opr === ">=" ) {
                  df = df.filter( (v,k) => {
                    return  parseFloat(v[  filters[f].type  ], 10) >= parseFloat(filters[f].value, 10)
                  }  )
                } else if ( filters[f].opr === "<" ) {
                  df = df.filter( (v,k) => {
                    return parseFloat(v[  filters[f].type  ], 10) < parseFloat(filters[f].value, 10)
                  })
                } else if ( filters[f].opr === "<=" ) {
                  df = df.filter( (v,k) => {
                    return parseFloat(v[  filters[f].type  ], 10)  <= parseFloat(filters[f].value, 10)
                  }  )
                } else if ( filters[f].opr === "btw" ) {
                  var vals = []
                  try {
                    vals = filters[f].value.split(",")
                  }
                  catch(e) {
                    vals = filters[f].value
                  }
                  df = df.filter( (v,k) => {
                    return ( parseFloat(v[  filters[f].type  ], 10)  >= parseFloat(vals[0], 10) ) && ( parseFloat(v[  filters[f].type  ], 10)  <= parseFloat(vals[1], 10) )
                  }  )
                } 
              }

            }
            var _sym = df.map( (v,k) => {
              return v.symbol
            })
            read_sym[ filters[f].type ] = _sym
          } else if( filters[f].key === "constituent" )  {
            var tables = JSON.parse(  JSON.stringify( this.state.tables )  )

            var _df = ("constituent" in tables[this.state.region])?tables[this.state.region]["constituent"]:tables[  Object.keys(tables)[0]  ]
            
            var csym = _df.filter( (v,k) => {
              if(  filters[f].opr === "in" ) {
                return( filters[f].value.includes(  v.type )   ) 
              } else if(  filters[f].opr === "nin" ) {
                return( !filters[f].value.includes(  v.type )   ) 
              }
            } )
            csym = csym.map( (v,k) => {
              return v.symbol
            } )
            read_sym['constituent'] = csym
          }
        }
        syms = []
        for (var s in read_sym) {
          if( read_sym[s].length > 0 ) {
                if( syms.length === 0 ) {
                  syms  = read_sym[s]
                } else {
                  syms = syms.filter( (v,k) => {
                    return read_sym[s].includes(v)
                  })
                }
          }
        }
        if( syms.length > 0 ) {
          df = JSON.parse(  JSON.stringify(res) )
          df = df.filter( (v,k) => {
            return syms.includes(v.symbol)
          } )
        }
      } else {
        if( syms.length > 0 ) {
          df = df.filter( (v,k) => {
            return syms.includes( v[  'symbol'  ]  )
          } )
        }
      }

      this.setState( { read_sym : read_sym  } )
      if( !isSym) {  
        return df
      } else {
        return read_sym
      }
    }

    updateFilterValue(obj) {
      if( "forward" in obj ) {
        this.sortby( null, true, obj.forward )
      } else {
        this.setState( obj )
      }
    }

    showBoard( BOOL ) {
      if( BOOL !== null) {
        this.setState({
          showboard : BOOL,
        })
      } else {
        this.setState({
          showboard : !this.state.showboard,
        })
      }
    }

    sortUp(v, v1, BOOL) {
      var f = {}
      f.key = this.state.selectedTable
      f.type = v1
      if( isNaN(v[v1]) !== false ) {
        if(!BOOL) {
          f.opr = "in"
        } else {
          f.opr = "nin"
        }
        f.value = [ v[v1].value ]
      } else {
        if(!BOOL) {
          f.opr = ">="
        } else {
          f.opr = "<="
        }
        f.value =  v[v1]
      }
      var filters = JSON.parse( JSON.stringify( this.props.filters ) )
      filters[v1] = f
      this.search( filters, this.state.region )
    }

    remove(v, v1) {
      var filters = JSON.parse( JSON.stringify( this.props.filters ) )
      delete filters[v1]
      var read_sym = JSON.parse( JSON.stringify( this.state.read_sym ) )
      delete read_sym[v1]
      this.setState({
        read_sym : read_sym
      })
      var _this = this

      setTimeout( (e) => {
        _this.search( filters, _this.state.region )
      }, 300)
      
    }

    getObj(name) {
      return this.state[name]
    }

    error(e) {
      if( this.code.current ) {
          this.code.current.error(e)
      }
    }

    search() {
      var region = this.state.region
      var menu = this.configMenu( true, this.state.region, this.state.searchText )
      this.setState( { menus : menu } )
    }

    insertFilter(v, key) {
      var _filters = JSON.parse(  JSON.stringify( this.props.filters ) )
      if( _filters.length < MAX_FILTERS ) {
        if( v.key === "constituent" ) {
          var _key = v.key
          var _type = "type"
        } else if (v.key ==="symbol") {
          var _key = "data"
          var _type =  v.key
        } else {
          var _key = key
          var _type  = v.key
        }
        var valid = _filters.filter( (v1, k1)=>{ return (  v1.key === _key && v1.type === _type   )   } )
        if( valid.length === 0 ) {
          var obj = {}
          obj.key = _key
          obj.type = _type
          //obj.key = (v.key!=="symbol")?key:"data"
        
          if( ['basic','cashflow', 'debt', 'profit'].includes( key ) )  {
            obj.parent = "finstat"
          }
          if( v.vtype === "string" ) {
            var o = v.value[0]
            if(  typeof( o ) === "object" ) {
              obj.value = o.key 
              obj.opr = stringConfig[ o.vtype?o.vtype:o.type ][0].value  
            } else {
              obj.value = o
              obj.opr = stringConfig[ v.vtype ][0].value  
            }
            //if( obj.type === "symbol" ) {

            //}

            
          } else {
            obj.opr = stringConfig[ "number" ][0].value  
            obj.value = "0"
          }
          _filters.push( obj )

          this.props.updateParent({
            filters : _filters,
          })
        }
      } else {
        this.props.updateValue({
          showAlert : true,  
          alertMessage : {
            header : "Maximum greater 10",
            body : "Excess number of filters."
          }
        })
      }

    }

    updateFilter( idx , key, value ) {
      var _filters = JSON.parse(  JSON.stringify(  this.props.filters )   )
      _filters[idx][key] = value
      this.props.updateParent({ 
        filters : _filters
      })
    }

    removeFilter(idx) {
      var _filters = JSON.parse(  JSON.stringify(  this.props.filters )   )
      _filters.splice( idx, 1 )
      this.props.updateParent({ 
        filters : _filters
      })
    }

    abjustNumber(v, isConvert, opr) {
      //NUM_MATCH
      if ( !isConvert ) {
        v = v.toLowerCase()
        if( opr === "btw" ) {
          v = v.split(",")
          if( v.length === 1) {
            v.splice(0,0,0)
          }
        } else {
          v = [v]
        }

        try {
          for ( var k in v) {
            for( var i in NUM_MATCH ) {
              if( v[k].indexOf( NUM_MATCH[i].key ) > -1  ) {
                v[k] = v[k].replace( NUM_MATCH[i].key,  ""    )
                v[k] = parseFloat(v[k], 10)
                v[k] = v[k] * NUM_MATCH[i].value
                v[k] = v[k].toString()
                break
              }
            }
          }
        } catch(e) {
          
        }
        v = v.join(",")
      } else {
        try {
          for( var i in NUM_MATCH ) {
            var k = v/NUM_MATCH[i].value
            if( k >= 1 ) {
              v = Math.ceil( k * 1000 )/1000 + NUM_MATCH[i].key.toUpperCase()
              break;
            }
          }
        } catch(e) {
            
        }
      }
      return v
    }

    exportScreener() {
      if( this.state.obj.length > 0 ) {
        var data = JSON.parse(  JSON.stringify(  this.state.obj   )  )
        data = data.map( (v,k) => {
            for (var i in v) {
                if( typeof(v[i]) === "object" ) {
                  v[i] = v[i][ this.props.language ]
                } else {
                  v[i] = v[i]
                }
            }
            return v
        } )
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "screener");
        var name = this.props.selectedBotfolio.name + "_screener.xlsx"
        XLSX.writeFile(workbook, name);
      }

    }

    render() {
      
      if( this.state.region ) {
        //console.log( this.props.filters )
        return (
          <div className={['Filter'].join(" ") } onClick={ (e) => { this.setState( { showContentMenu : null } ) } }>
             
            <div className={['navigator'].join(" ") }>
                <Roadmap 
                  tree={tree}
                  updateFilterValue={this.updateFilterValue}
                  selectedIndex={ this.state.selectedIndex }
                  {...this.props} 
                />
            </div>
            
            <div className={['main'].join(" ") }>
              {  
                true===true &&
                <div className={['Wrapper', [0].includes(this.state.selectedIndex)?"appear":"disappear"].join(" ") }>
                  <div className={['Panel', this.state.isFull?"Hide":""].join(" ") }>
                    <div className={['Lists'].join(" ") }>
                      <div className={['Top'].join(" ") }>
                          <div className={["Simple", this.state.simple?"Selected":""  ].join(" ")}
                            onClick={ (e)=>this.setState({ simple : !this.state.simple }) }
                          >  {"Detail"} </div>

                          <FormControl 
                            className={ ["Search"].join(" ") } 
                            value={ this.state.searchText } 
                            placeholder={ this.props.gi18n[ this.props.language ]['search_filter'] }
                            onChange={ (e)=>{
                              var obj = {  searchText : e.target.value }
                              if( e.target.value.length === 0  ) {
                                obj.menus = this.configMenu( true, this.state.region )
                              }
                              this.setState(obj) 
                            }}/>
                          <FontAwesomeIcon icon={faSearch} className={ ["Fa"].join(" ") } onClick={ (e)=>this.search() }/>
                      </div>
                      <div className={['Bottom'].join(" ") }>
                        {
                          this.state.menus.map( (v,k) => {
                            var values = JSON.parse( JSON.stringify( v.value ) ).filter( (v2,key)=>{ return this.state.simple?(v.advice?( v.advice.includes(v2.key) ):true):true   } )
                            if( k === 0 && this.state.hasFilter ) {
                              var lang = this.props.filterconfig[ this.state.region ]['symbol']
                              var o = {}
                              o.lang = this.props.filterconfig[ this.state.region ]['symbol']['lang']
                              o.key = "symbol"
                              o.vtype = "string"
                              o.value = this.props.filterconfig[ this.state.region ]['symbol']['value']
                              values.splice(0, 0, o )
                              if( this.state.region === "hkg" ) {
                                var o2 = {}
                                o2.lang = this.props.filterconfig[ this.state.region ]['constituent']['lang']
                                o2.key = "constituent"
                                o2.vtype = "string"
                                o2.value = this.props.filterconfig[ this.state.region ]['constituent']['value']
                                values.splice(1, 0, o2 )
                              }
                            }
                            
                            return(
                              <div className={["LHolder", (this.state.selectedMenu===v.key)?"Selected":"" ].join(" ")} 
                                onClick={ (e)=>{ this.setState({ selectedMenu : v.key }) } }
                                style={{
                                  height : (this.state.selectedMenu===v.key)?( "calc( 100% - " + (this.state.menus.length -1 )*50 + "px)"):"auto"
                                }}
                                key={k} >
                                  <div className={["Title"].join(" ")}> { 
                                    v.lang[this.props.language] + " (" + values.length  + ")" } 
                                    <FontAwesomeIcon className={["Fa"].join(" ")} icon={ (this.state.selectedMenu===v.key)?faChevronUp:faChevronDown  } />
                                  </div>
                                  <div className={["Container"].join(" ")} style={{
                                    height : (this.state.selectedMenu===v.key)?( "calc( 100% - " + this.state.menus.length*50 + "px - 2rem)"):"0px"
                                  }}>
                                    {
                                      values.map(
                                        (v2,k2) => {
                                          return (
                                            <OverlayTrigger
                                                key={k2}
                                                placement={"bottom"}
                                                overlay={
                                                  <Tooltip >
                                                    <strong>{ v2.lang[ this.props.language ]  }</strong>
                                                  </Tooltip>
                                                }
                                            >
                                              <div className={["Bgn"].join(" ")} key={k2} onClick={  (e) => {
                                                
                                                this.insertFilter( v2, v.key )
                                              }}> 
                                                {
                                                  v2.lang[ this.props.language ]
                                                }
                                              </div>
                                            </OverlayTrigger>
                                          )
                                        }
                                      )
                                    }
                                  </div>
                              </div>
                            )
                          } )
                        }
                      </div>
                    </div>
                    <div className={['Query'].join(" ") }>
                        <div className={["Top"].join(" ")}>
                            <div className={["Msg"].join(" ")}>  {"Filter(s) found : " + this.props.filters?this.props.filters.length:0 } </div>
                            { this.state.hasFilter &&
                              <div className={["Search"].join(" ")} onClick={ (e)=>{
                                this.searchFilter( this.props.filters, this.state.selectedMenu, this.state.region )
                              } }>
                                <FontAwesomeIcon icon={faPlay} className={['Fa'].join(" ") } />
                                <div className={["Text"].join(" ")}>
                                {
                                  this.props.gi18n[ this.props.language ]['search']
                                }
                                </div>
                              </div>
                            }
                        </div>

                        <div className={['Bottom'].join(" ") }>
                            {
                              this.props.filters.map( (v,k) => {
                                var f = JSON.parse( JSON.stringify( this.props.filterconfig ) )
                                f = f[ this.state.region ]

                                var _key = ((['basic','debt','cashflow','profit'].includes(v.key)?("finstat_"):"") + v.key)
                                var o = {}
                                if( _key === "data") {
                                  o = f[ 'symbol' ]
                                  o.vtype = "string"
                                } else if ( _key === "constituent" ) {
                                  o = f[ 'constituent' ]
                                  o.vtype = "string"
                                } else {
                                  o = f[_key]
                                }   
                                                       
                                var res = [], options = [], selected = [], vtype = "number", operators=stringConfig["number"]
                                var key_lang = {}, type_lang = {}, _selected = [],range_statement = []

                                if( o.value ) {
                                  key_lang = o.lang
                                  if( o.vtype === "object" || o.type === "object" ) {
                                    res = o.value.filter( (val,k)=>{ return val.key === v.type } )
                                    if( res.length === 1 ) {
                                      if( typeof( res[0] ) === "object" ) {
                                        type_lang = res[0].lang
                                        if(  res[0].vtype === "string" ) {
                                          vtype = "string"
                                          operators = stringConfig[vtype]
                                          res[0].value = res[0].value.map( (val,key) => {  
                                            val.name = val.lang[ this.props.language ]
                                            return val
                                          } )
                                          options = res[0].value
                                          _selected = v.value
                                          if( typeof(v.value) === "string" ) {
                                            _selected = v.value.split(",")
                                          }
                                          selected = options.filter( (val,k)=>{ return _selected.includes( (val.value?val.value:val)  ) } )
                                          
                                        }
                                      } else if( o.vtype === "string" ) {
                                        //console.log( "HERE?" )
                                        res = o.value
                                      }
                                    }
                                  } else if( o.vtype === "string" ){
                                      res = o.value
                                      vtype = "string"
                                      operators = stringConfig[vtype]
                                      options = res
                                      _selected = v.value.split(",")
                                      selected = options.filter( (val,k)=>{ return _selected.includes(val) } )
                                  }
                                }

                                if( vtype === "number" ) {
                                  
                                  if( res.length === 1 ) {
                                    if( res[0].value ) {
                                      if( res[0].value.length > 1 ) {
                                        var _s = this.abjustNumber( res[0].value[0], true  )
                                        var _n = this.abjustNumber( res[0].value[1], true  )
                                        range_statement.push( ("Select range between " + _s + " to " + _n ) )
                                        range_statement.push( "k(thousands)" )
                                        range_statement.push( "M(illions)" )
                                        range_statement.push( "B(illions)" )
                                        range_statement.push( "T(rillions)" )
                                      }
                                    }
                                  }
                                  
                                }

                                return(
                                  <div className={['List'].join(" ") } key={k} >
                                    <div className={['Upper'].join(" ") }>
                                      <div className={['Title'].join(" ") }> 
                                        { (k+1) + ". " }
                                        {key_lang[this.props.language] }
                                        <FontAwesomeIcon icon={faCaretRight} className={["Fa"].join(" ")} />
                                        {type_lang[this.props.language]} 
                                      </div>
                                      <FontAwesomeIcon icon={faTimes} className={["Remove"].join(" ")} onClick={ (e)=>this.removeFilter(k) }/>
                                    </div>
                                    <div className={['Middle'].join(" ") }>
                                      { v.opr &&
                                        <DropdownButton 
                                          className={['Opr']} 
                                          title={ ((v.opr in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v.opr]:v.opr)  } >
                                              {
                                                operators.map( (v1,k1) => {
                                                  return(
                                                    <Dropdown.Item 
                                                      eventKey={ v1.value }
                                                      key={k1}
                                                      onClick={ (e)=>{
                                                        this.updateFilter( k, "opr", v1.value )
                                                      } }
                                                    > 
                                                      { 
                                                        (v1.value in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v1.value]:v1.value  
                                                      } 
                                                    </Dropdown.Item>
                                                  )
                                                } )
                                              }
                                        </DropdownButton>
                                      }
                                    </div>
                                    <div className={['Lower'].join(" ") }>
                                      {
                                        (vtype === "string")&&
                                        <Typeahead
                                            id={ v.key }
                                            className={ ['Box1' ].join(" ") }
                                            labelKey={ "name" }
                                            multiple={  true }
                                            paginate={ true }
                                            clearButton={ false }
                                            onChange={(e)=>{ 
                                              var _values = e.map( (v4, k4)=> (v4.value?v4.value:v4) )
                                              //idx , key, value
                                              this.updateFilter( k, 'value' , _values.join(",") )
                                            } }
                                            selected={  selected  }
                                            options={  options }
                                            placeholder={ type_lang[ this.props.language ] }
                                        />
                                      }
                                      {
                                        (vtype === "number") &&
                                        <OverlayTrigger 
                                          placement={"top"}
                                          overlay={
                                          <Tooltip className={['hint'].join(" ")}>
                                            {
                                              range_statement.map( (v9, k9) => {
                                                if( k9 === 0) {
                                                  return <strong key={k9} className={["Msg"].join(" ")}>{  v9   }</strong>
                                                } else {
                                                  return <div key={k9} className={["Sub"].join(" ")}>{  v9   }</div>
                                                }
                                              } )
                                            } 
                                          </Tooltip>
                                        } >
                                          <FormControl className={["Box2"].join(" ")} 
                                            value={ v.value } 
                                            onChange={ (e)=> {
                                              var value = this.abjustNumber(  e.target.value, false, v.opr  )
                                              this.updateFilter( k, 'value' , value )
                                            }  } />
                                        </OverlayTrigger>

                                      }
                                    </div>
                                  </div>
                                )
                              } )
                            }
                        </div>  

                    </div>
                  </div>
                      
                  { this.state.hasFilter &&
                  <div className={['Result', this.state.isFull?"Full":""].join(" ") }  > 
                    <div className={['Control'].join(" ") }  > 
                        <FontAwesomeIcon 
                          onClick={ (e)=>this.setState({ isFull : !this.state.isFull }) }
                          icon={ this.state.isFull?faTableColumns:faExpand   } 
                          className={ ["Fa"].join(' ') } />
                        <FontAwesomeIcon 
                          onClick={ (e)=>this.exportScreener() }
                          icon={faDownload}
                          className={ ["Fa"].join(' ') }
                        />
                        { this.state.hasResult &&
                          <div className={["Count"].join(" ")}> { this.props.gi18n[this.props.language]['success_filter'].replace("<count>", this.state.obj.length   ) } </div>
                        }

                        
                    </div>
                    <div className={['Cont'].join(" ") }  > 
                          {
                            (this.state.obj.length > 0) &&
                            <div className={['list', "title"].join(" ")} > 
                                <div className={['box', "action" ].join(" ")} > </div>
                                {
                                  Object.keys(  this.state.obj[0]  ).filter( (v,k) => {
                                    if( this.state.simple ) {
                                      if( this.props.filterconfig[this.state.region][this.state.selectedMenu].advice ) {
                                        return (this.props.filterconfig[this.state.region][this.state.selectedMenu].advice.includes(v) ) || (['symbol','sname','date'].includes(v) )
                                      } else {
                                        return true
                                      }
                                    } else {
                                      return true
                                    }
                                  } ).sort((x, y) => ['symbol','sname','date'].reverse().indexOf(y) - ['symbol', 'sname','date'].reverse().indexOf(x)   ).map(
                                    (v1,k1) => {
                                      if(  (v1 in this.state.lang) || (v1 in this.props.gi18n[this.props.language])   ) {
                                        return(
                                          <div className={['box', v1==="symbol"?'symbol':"" ].join(" ")} key={k1} onClick={ (e)=>{
                                            this.sortby( v1 , !this.state.asccending )
                                          } } > 
                                            <span>{ (v1 in this.state.lang)?this.state.lang[v1][this.props.language]:( (v1 in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v1]:v1  )  }</span>
                                            {
                                              (this.state.selectSortCat === v1) &&
                                              <FontAwesomeIcon icon={ !this.state.asccending?faChevronDown:faChevronUp } className={['fa'].join(' ')}/> 
                                            }
                                          </div>
                                        )
                                      } else {
                                        return null
                                      }
                                    }
                                  )
                                }
                            </div>
                          }
                          { 
                            this.state.obj.map( (v,k) => {
                              //var keys = [ 'symbol', 'sname' ]
                              var keys = Object.keys(  v  ).filter( (v,k) => {
                                if( this.state.simple ) {
                                  if( this.props.filterconfig[this.state.region][this.state.selectedMenu].advice ) {
                                    return ((this.props.filterconfig[this.state.region][this.state.selectedMenu].advice.includes(v) ) || [ 'symbol', 'sname', 'date' ].includes(v) )
                                  } else {
                                    return true
                                  }
                                } else {
                                  return true
                                }
                              } )
                              var priors = [ 'symbol', 'sname', 'date' ].reverse()
                              
                              keys.sort((x, y) => priors.indexOf(y) - priors.indexOf(x)   );
                              keys = keys.filter( (v,k) => {
                                return ((v in this.state.lang) || (v in this.props.gi18n[this.props.language]))
                              } )
                              var scale = JSON.parse(  JSON.stringify(  numberScale ) )
                              scale.reverse()
                              var txt = "", val = 0,  _t = 0
                              var forward = JSON.parse( JSON.stringify(this.state.forward) ).reverse()
                              return (
                                <div className={['list',  this.state.showContentMenu?((this.state.showContentMenu.indexOf(k+"|") > -1)?"show":""):"" ].join(" ")} key={k}> 
                                  <div className={['box', "action" ].join(" ")} > 
                                    {
                                      (forward.includes( v.symbol ) ) && <span className={["buy"].join(" ")}>B</span>
                                    }
                                  </div>
                                  {
                                    keys.map(
                                      (v1,k1) => {
                                        if(  typeof(v[v1]) === "object"  ) {
                                          txt = v[v1][this.props.language]
                                        } else {
                                          if(  (v1 !== "date") && (v1 !== "symbol")  ) {
                                            try {
                                              if( isNaN(v[v1]) === false ) {
                                                val = Math.round((  parseFloat( v[v1] , 10) * 10000) )/10000
                                                txt = val.toLocaleString("en-US")  
                                                if( val !== 0 ) { 
                                                  for (var i in scale ) {
                                                    _t = val/scale[i].value
                                                    if( _t >= 1 ) {
                                                      txt = _t.toLocaleString("en-US")  + ((scale[i].key!=="default")?scale[i].short:"") 
                                                      break;
                                                    }  
                                                  }
                                                }
                                              } else {
                                                txt = v[v1]
                                              }
                                            } catch(e) {
                                              txt = v[v1]
                                            }
                                          } else {
                                            txt = v[v1]
                                          }
                                        }
                                        var title = (v1 in this.state.lang)?this.state.lang[v1][this.props.language]:( (v1 in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v1]:v1  )
                                        return(
                                          <div className={['box', (v1==="symbol")?"symbol":'' ].join(" ")} key={k1} onContextMenu={
                                            (e)=>{
                                              e.preventDefault();
                                              //if( (v1!=="symbol")  &&  (v1!=="sname") && (v1!=="date") ) {
                                              //  this.setState( {
                                              //    showContentMenu : k +"|" + k1
                                              //  } )
                                              //}
                                            }
                                          }> 
                                            { txt }
                                            { 
                                              (this.state.showContentMenu === (k+"|"+k1)) &&
                                                <div className={ ["menu",  (this.state.showContentMenu === (k+"|"+k1))?"show":"" ].join(" ") } >
                                                  <div className={ ["title"].join(" ") } onClick={ (e) => { this.remove(v, v1)} }> { title } </div>
                                                  <div className={ ["item"].join(" ") } onClick={ (e) => { this.remove(v, v1)} }> { this.props.gi18n[this.props.language]['t_nofilter']  } </div>
                                                  { (isNaN(v[v1]) === false) &&
                                                    <div className={ ["item"].join(" ") } onClick={ (e) => { this.sortUp(v, v1)} }  > { ">= " + txt } </div>
                                                  }
                                                  { (isNaN(v[v1]) === false) &&
                                                    <div className={ ["item"].join(" ") } onClick={ (e) => { this.sortUp(v, v1, true)} }> { "<= " + txt } </div>
                                                  }
                                                  { (isNaN(v[v1]) !== false) &&
                                                    <div className={ ["item"].join(" ") } onClick={ (e) => { this.sortUp(v, v1)} } > { this.props.gi18n[this.props.language]['t_eq'].replace( "<val>" ,  txt )  } </div>
                                                  }
                                                  { (isNaN(v[v1]) !== false) &&
                                                    <div className={ ["item"].join(" ") } onClick={ (e) => { this.sortUp(v, v1, true)} }> { this.props.gi18n[this.props.language]['t_neq'].replace( "<val>" ,  txt ) } </div>
                                                  }
                                                </div>
                                            
                                            }
                                          </div>

                                        )
                                      }
                                    )
                                  }
                                </div>
                              )
                            } )
                          }

                          {
                            (this.state.obj.length === 0) && this.state.hasResult &&
                            <div > { this.props.gi18n[this.props.language]['no_select'] } </div>
                          }
                    </div>
                    
                  </div>
                  }





                </div>
              }


              <div className={['Wrapper', [1,2,3].includes(this.state.selectedIndex)?"appear":"disappear"].join(" ") }>
                { true === true &&
                  <Code {...this.props} 
                    ref={this.code}
                    show={  [1,2,3].includes(this.state.selectedIndex)  }
                    showboard={this.state.showboard} 
                    read_sym={this.state.read_sym} 
                    list={this.props.list} 
                    updateFilterValue={this.updateFilterValue} 
                    getList={this.props.getList} 
                    filters={this.props.filters} 
                    code={this.props.code} 
                    selectedIndex={ this.state.selectedIndex }
                    useTemplate={ this.props.useTemplate } /> 
                }
                { /*true === true &&
                  <Blocks 
                    show={  [1,2,3].includes(this.state.selectedIndex)  } 
                    code={this.props.code} 
                    selectedIndex={ this.state.selectedIndex }
                  /> 
                  */
                  true === false && 
                  <Simple 
                    show={  [1,2,3].includes(this.state.selectedIndex)  } 
                    code={this.props.code} 
                    selectedIndex={ this.state.selectedIndex }
                  />
                }

              </div>

            </div>


            { (this.props.isFilter || ( !this.props.isFilter && ( this.props.selectedBoard === 'strategy' )   )) && 
            <div className={['controller'].join(" ") }>
              { 
                /*
                this.state.icons.map( (v,k) => {
                return(
                    <OverlayTrigger
                      key={k}
                      placement={"left"}
                      overlay={
                        <Tooltip >
                          <strong>{ v.value  }</strong>
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon className={['icon'].join(" ") } icon={v.icon} onClick={ (e)=> {
                        this.setState( {
                          showboard : !this.state.showboard
                        })
                      } } />
                    </OverlayTrigger>
                )
               } )
               */
              }
            </div>
            }

          </div>
        )
      } else {
        return null
      }
    }
}


export default Filter;
