import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

//import logoB from '../../../public/img/garlican_blue_logo.png'

import { OverlayTrigger, Tooltip  } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faTimes, faPlay  } from '@fortawesome/free-solid-svg-icons'

//import service from '../../../public/service'
//import Api from '../../../Garlican/GarlicanLibrary/apis'
//import sptrader from '../service/sp'

import List from '../list'
//import { concatTransformDependencies } from 'mathjs';

const keys = {
    onhold: ['original', 'current' , 'change'],
    summary: ['buy_value', 'sell_value'],
    action : ['buy_value', 'sell_value'],
}

class Docker extends Component {

    constructor(props) {
        super(props)
        var title = {}
        title.action =   props.gi18n[ props.language ][ 'd_action' ]
        title.symbol = props.gi18n[ props.language ][ 'd_symbol' ]
        title.price = props.gi18n[ props.language ][ 'd_price' ]
        title.board_lot = props.gi18n[ props.language ][ 'd_boardlot' ]
        title.total_cost = props.gi18n[ props.language ][ 'd_total_cost' ]
        title.timestamp = props.gi18n[ props.language ][ 'd_timestamp' ]
        title.buy = props.gi18n[ props.language ][ 'd_buy' ]
        title.change = null
        this.state = {
            title : title,
            //show : false,
            show : props.selectedPortfolio?(props.selectedPortfolio.id===props.vobj.group.id):false,
            original : 0,
            current : 0,
            change : 0,
            buy_value : 0,
            sell_value : 0,
            list : props.list,
            language : props.language,
            id : props.selectedPortfolio?props.selectedPortfolio.id:-1,
        }
        this.removeDocker = this.removeDocker.bind(this)
        this.calc = this.calc.bind(this)

        //this.calc( props.list ) 
        //console.log( props.list )
    }


    componentDidMount() {    
        this.calc( this.props.list ) 
    }

    componentDidUpdate(props, state) {
        if( ( JSON.stringify(state.list) !== JSON.stringify(props.list) ) || (props.language !== state.language)  ) {
            
            var title = { }
            title.action =   props.gi18n[ props.language ][ 'd_action' ]
            title.symbol = props.gi18n[ props.language ][ 'd_symbol' ]
            title.price = props.gi18n[ props.language ][ 'd_price' ]
            title.board_lot = props.gi18n[ props.language ][ 'd_boardlot' ]
            title.total_cost = props.gi18n[ props.language ][ 'd_total_cost' ]
            title.timestamp = props.gi18n[ props.language ][ 'd_timestamp' ]
            title.buy = props.gi18n[ props.language ][ 'd_buy' ]
            title.change = null

            //console.log( props.selectedPortfolio )
            //console.log( props.vobj.group.id )

            this.setState({
                language : props.language,
                list : props.list,
                title : title,
                show : props.selectedPortfolio?(props.selectedPortfolio.id===props.vobj.group.id):false,
            })
            var _this = this
            this.calc( props.list )   
        }  

        
        if( props.selectedPortfolio ) {

            if ( props.selectedPortfolio.id !== state.id ) {
                this.setState({
                    id : props.selectedPortfolio.id,
                    show : (props.selectedPortfolio.id===props.vobj.group.id),
                })
            }   
        } else {
            if( this.state.show || (this.state.id !== null) ) {
                this.setState({
                    id : null,
                    show : false,
                })
            }
        }
    }

    componentWillUnmount() {

    }

    calc(list) {
        //console.log( list )
        var original = 0, current = 0, change = 0, buy= 0, sell = 0

        if( this.props.type === "action" || this.props.type === "summary" ) {
            for(var i in list) {
                if( list[i].decision > 0 ) {
                    buy -= (  list[i].price * list[i].board_lot * Math.abs( list[i].decision )  )
                } else {
                    sell += (  list[i].price * list[i].board_lot * Math.abs( list[i].decision )  )
                }
            }
        } else {
            for(var i in list) {
                original += (  list[i].buy * list[i].board_lot * list[i].unit  )
                current += (  list[i].price * list[i].board_lot * list[i].unit  )
            }
            change =  (this.props.type==="onhold")?((original>0)?((current - original)/original):0):0
        }
        this.setState({
            change : Math.round(change*10000)/100,
            current : Math.round(current*100)/100,
            original : Math.round(original*100)/100,
            buy_value : Math.round(buy*100)/100,
            sell_value : Math.round(sell*100)/100,
        })

        //console.log(  "Current : " + current + " Original : " + original + " Change : " + change )

    }

    removeDocker() {
        this.props.updateGroup( this.props.gindex, null , true )
    }

    updateValue( obj ) {
        var _original = this.state.original + obj.original
        var _current = this.state.current + obj.current
        this.setState({
            original : _original,
            current : _current
        })
    }

    render() {
        if( !this.props.isReading ) {
            return (
                <div className={['trade-docker-2' ].join(" ")} >
                    <div className={['ttitle'].join(" ")} >
                        <div className={['Holder'].join(" ")} >
                            <FontAwesomeIcon  icon={  this.state.show?faChevronUp:faChevronDown  } className={['tarrow'].join(' ')} onClick={ (e)=>{
                                if( !this.state.show ) {
                                    this.props.getGroup( this.props.gindex )
                                } else {
                                    this.props.getGroup( null )
                                }
                                this.setState( {
                                    show : !this.state.show
                                } )
                            } }/>
                            <span className={['tname'].join(" ")} onClick={ (e)=>{
                                if( !this.state.show ) {
                                    this.props.getGroup( this.props.gindex )
                                } else {
                                    this.props.getGroup( null )
                                }
                                this.setState( {
                                    show : !this.state.show
                                } )
                            } }>
                                { this.props.botfolio.subscription_id &&
                                    <span className={['purchased'].join(" ")}> { "P" } </span>   
                                }
                                <span className={['name'].join(" ")}>
                                    {
                                        ((this.props.botfolio.id!==this.props.dummy.id)?this.props.botfolio.name:this.props.gi18n[this.props.language]['t_my_manage'])  + " (" + (this.props.list?this.props.list.length:0) + ")"    
                                    }
                                </span>
                            </span>
                            {  /*(this.props.type === "action") && ( (this.props.botfolio.id!==this.props.dummy.id) && (this.props.botfolio.id!==-1) ) &&*/
                                ( (this.props.botfolio.id!==this.props.dummy.id) && (this.props.botfolio.id!==-1) ) &&
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2"> { this.props.gi18n[this.props.language]['run_botfolio'].replace( "<botfolio>", this.props.botfolio.name)  } </Tooltip>}
                                >
                                    <FontAwesomeIcon  icon={  faPlay  } className={['trun', 'gp'].join(' ')} onClick={
                                        (e)=>{
                                            //RUN
                                            if( (this.props.type === "action") ) {
                                                this.props.run( this.props.gindex )
                                                this.setState({
                                                    show : true
                                                })
                                            } else {
                                                var _this = this
                                                //this.props.selectKey("action")
                                                setTimeout(
                                                    () => {
                                                        _this.props.run( _this.props.gindex )
                                                        _this.setState({
                                                            show : true
                                                        })
                                                    }, 500
                                                )
                                            }
                                        }
                                    }/>
                                </OverlayTrigger>

                            }
                            
                        </div>

                        <div className={['Board'].join(" ")} 
                            onClick={
                                (e) => {
                                    if( !this.state.show ) {
                                        this.props.getGroup( this.props.gindex )
                                    } else {
                                        this.props.getGroup( null )
                                    }
                                    this.setState( {
                                        show : !this.state.show
                                    } )
                                }
                            }
                        >
                            {
                                keys[this.props.type].map( (v,k) => {
                                    if(  !( (v === "original" || v==="change") && this.props.type === "action")  ) {
                                        return(
                                            <div className={ ['Wrapper'] } key={k}>
                                                <div className={ ['Title'] }> { this.props.gi18n[ this.props.language ][ "t_" + v ] } </div>
                                                <div className={ ['Value',
                                                    (
                                                        ( (v==="change") || (v==="current") || (v==='sell') || (v==='buy') )?( 
                                                            ( (this.state["change"]>0) || (v==='sell') )?"Gain":
                                                            ( (this.state["change"]<0) || (v==='buy' ) )?"Lost":""
                                                        ):""  
                                                    )  ].join(" ") }> { 
                                                    (v==="change")?(this.state[v] + "%"):("$"+this.state[v].toLocaleString("en-US") )
                                                } </div>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                } )
                            }
                        </div>
                    </div>
                    <div className={['tholder',this.state.show?"tshow":""].join(" ")} >
                        { (this.props.list.length > 0) &&
                            [
                                <List key={"label"} obj={this.state.title} title={true} {...this.props} />,
                                    this.props.list.map(  (v,k) => {
                                        if( ((this.props.type === "onhold") && (v.asset_type==="paper") ) ||  ((this.props.type === "summary") &&  (v.asset_type==="real") ) || (this.props.type === "action")    ) {
                                            //total_cost
                                            return(
                                                <List key={k} obj={v} index={k} {...this.props} isActive={ (this.props.gindex !== -2) } />
                                            )
                                        } else {
                                            return null
                                        }
                                    } )
                            ]
                        }
                        {
                            (this.props.list.length === 0) &&
                            <span className={['No-Action'].join(' ')}>    {  this.props.gi18n[this.props.language]['d_no_action'] }   </span>
                        }
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

//<FontAwesomeIcon  icon={faClone} className={['clone', 'icon'].join(' ')} onClick={ (e)=>this.props.pop() }/> 
export default Docker;
