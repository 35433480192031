import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
//import logoB from '../public/img/garlican_blue_logo.png'

import service from '../../public/service'
import { Link } from 'react-router-dom'

import { Modal, Image, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlRice  } from '@fortawesome/free-solid-svg-icons'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const login_frame = [
  { value : "username", type : 'email'  },
  { value : "password", type : 'password', length : 8  },
]

const retrieve_frame = [
  { value : "username", type : 'email'  },
  { value : "password", type : 'password', length : 8  },
  { value : "password", type : 'confirm_password', length : 8  },
]

const register_frame = [
  { value : "username", type : 'email'  },
  { value : "password", type : 'password', length : 8  },
  { value : "confirm_password" , type : 'password', length : 8 },
  { value : "phone", type : 'phone' },
  { value : "last_name",type : 'normal'  },
  { value : "first_name",type : 'normal'  }
]

const links = [
  {url : '/privacy', name : 'privacy'},
  {url : '/terms', name : 'terms'},
  {url : '/support', name : 'support'}
]

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          isLogin : !this.props.showSignup,
          username : "",
          password : "",
          confirm_password : "",
          email : "",
          first_name : "",
          last_name : "",
          phone : "",
        };

        window.document.login = this;
        this.forget = this.forget.bind(this)
        this.restore = this.restore.bind(this)
        this.comp = this.comp.bind(this)

        //console.log(props)
    }

    componentWillUnmount() {

    }



    change(key, value) {
      var obj = {}
      obj[key] = value
      this.setState(obj)
    }

    login() {
      service.login(this.state.username, this.state.password).then(
        (res) => {
          if ( !( res["detail"] ) ) {
            if ( !('password' in res) ) {
              service.token = res.access
              //service.getEjabberdToken().then(
              //  (r) => {

                  service.getUserProfile().then(
                    (user) => {
                      var tokens = res
                      service.user = user
                      
                      //console.log( service.user )
                      tokens.username = this.state.username
                      var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
                      storage.setItem("garlican@tokens", JSON.stringify(tokens) )
                      this.props.updateValue( { 
                        isLogin : true ,
                        roomname : this.state.username,
                        username : this.state.username,
                        jid : user.user_id,
                        token : res.access,
                        refresh_token : res.refresh,
                        showLogin : false,
                        showLoading : true,
                        selectedUser : user,
                        
                      })
                      //this.props.hasLogin( this.state.username, r.token )
                      this.props.hasLogin( this.state.username )
                  })



               // })
            } else {
              var obj = { header : 'Error', body : this.props.gi18n[  this.props.language ]['login_error'] }
              //alert(  this.props.gi18n[  this.props.language ]['login_error']   )
              this.props.openAlert(  obj  )
            }
        } else {
          var obj = { header : 'Error', body : this.props.gi18n[  this.props.language ]['login_error'] }
          //alert(  this.props.gi18n[  this.props.language ]['login_error']   )
          this.props.openAlert(  obj  )
        }

        }
      ).catch(
        (err) => {
          var obj = { header : 'Error', body : this.props.gi18n[  this.props.language ]['login_error'] }
          //alert(  this.props.gi18n[  this.props.language ]['login_error']   )
          this.props.openAlert(  obj  )
        }
      )
    }

    register() {
      //username, password, firstname, lastname, email

      if( service.validateEmail( this.state.username ) !== true  ) {
      //if( true !== true  ) {
        var obj = { header : this.props.gi18n[  this.props.language ]['error'] , body : this.props.gi18n[  this.props.language ]['username_error'] }
        this.props.openAlert(  obj  )
        return
      }

      if(  (this.state.password === this.state.confirm_password) && (this.state.first_name.length > 0) && (this.state.last_name.length > 0) && (this.state.password.length >= 8)  ) {
        service.register(this.state.username, this.state.password, this.state.first_name, this.state.last_name, "").then(
          (res) => {
            this.login( this.state.username, this.state.password )
            //this.props.get()
          }
        ).catch(
          (err) => {
            //console.log(err)
            var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['register_error'].replace("<email>", this.state.username) }
            this.props.openAlert(  obj  )
          }
        )
      } else if (this.state.password !== this.state.confirm_password) {
        //alert( this.props.gi18n[this.props.language]['password_unmatch_error'] )
        var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['password_unmatch_error'] }
        this.props.openAlert(  obj  )
        
      } else {
        //alert( this.props.gi18n[this.props.language]['length_unmatch_error'] )
        var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['length_unmatch_error'] }
        this.props.openAlert(  obj  )
      }
    }

    forget( username ){
      if( service.validateEmail( username ) !== true  ) {
        var obj = { header : this.props.gi18n[  this.props.language ]['error'] , body : this.props.gi18n[  this.props.language ]['username_error'] }
        this.props.openAlert(  obj  )
        return
      } else {
        service.forget(username).then( 
          (res) => {
            var obj = { header : this.props.gi18n[  this.props.language ]['resend'] , body : this.props.gi18n[  this.props.language ]['username_resend'].replace("<email>", this.state.username) }
            this.props.openAlert(  obj  )
          }
        )
      }

    }

    restore() {
      if( service.validateEmail( this.state.username ) !== true  ) {
        var obj = { header : this.props.gi18n[  this.props.language ]['error'] , body : this.props.gi18n[  this.props.language ]['username_error'] }
        this.props.openAlert(  obj  )
        return
      }
      if(  (this.state.password === this.state.confirm_password) && (this.state.password.length >= 8)  ) {
        service.restore(this.props.retrieve_key, this.state.username, this.state.password).then(
          (res) => {
            //this.login( this.state.username, this.state.password )
            var obj = { header : this.props.gi18n[  this.props.language ]['restore'], body : this.props.gi18n[  this.props.language ]['restore_successful'].replace("<email>", this.state.username) }
            this.props.openAlert(  obj  )
            this.props.updateValue({
              showLogin : true,
              retrieve_key : false
            })
          }
        ).catch(
          (err) => {
            console.log(err)
            var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['retore_error'].replace("<email>", this.state.username) }
            this.props.openAlert(  obj  )
          }
        )
      } else if (this.state.password !== this.state.confirm_password) {
        //alert( this.props.gi18n[this.props.language]['password_unmatch_error'] )
        var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['password_unmatch_error'] }
        this.props.openAlert(  obj  )
        
      } else {
        //alert( this.props.gi18n[this.props.language]['length_unmatch_error'] )
        var obj = { header : this.props.gi18n[  this.props.language ]['error'], body : this.props.gi18n[  this.props.language ]['length_unmatch_error'] }
        this.props.openAlert(  obj  )
      }
    }

    comp() {
      
      return(
        <div className={'LBody'}>
                    { this.state.isLogin && !this.props.retrieve_key &&
                      <div className={['Holder'].join(' ')}>
                        {
                        login_frame.map(  (v,k) => {
                          if (v.type!== "phone") { 
                            return (
                              <div className={['Wrapper'].join(' ')} key={k}>
                                <div className={['WLabel', (this.state[v.value]==="")?"show":"title"].join(" ")}> { this.props.gi18n[this.props.language][ v.value] } </div>
                                <Form.Control 
                                  type={ v.type }
                                  placeholder={  "" }
                                  value={ this.setState[v.value] } 
                                  className={ ['Value'].join( " " ) } 
                                  onChange={ (e)=>this.change( v.value  ,e.target.value) } />
                              </div>
                            )
                          } else {
                            <PhoneInput
                              country={'us'}
                              value={ this.setState[v.value] }
                              onChange={ (phone)=>this.change( v.value  ,phone) } 
                            />
                          }

                        } )
                      }
                      </div>
                    }
                    { !this.state.isLogin && !this.props.retrieve_key &&
                      <div className={['Holder'].join(' ')}>
                        {
                          register_frame.map(  (v,k) => {
                            if (v.type!== "phone") { 
                              return (
                                <div className={['Wrapper'].join(' ')} key={k}>
                                  <div className={['WLabel', (this.state[v.value]==="")?"show":"title"].join(" ")}> { 
                                    this.props.gi18n[this.props.language][  v.value] 
                                  } </div>
                                  <Form.Control 
                                    type={ v.type  }
                                    placeholder={ "" }
                                    value={ this.setState[v.value] } 
                                    className={ ['Value'].join( " " ) } 
                                    onChange={ (e)=>this.change( v.value  ,e.target.value) } />
                                </div>
                              )
                            } else {
                              return (
                                <div className={['Wrapper', "PWrapper"].join(' ')} key={k}>
                                  <div className={['PLabel', (this.state[v.value]==="")?"show":"title"].join(" ")}> { 
                                    this.props.gi18n[this.props.language][  v.value] 
                                  } </div>
                                  <PhoneInput
                                    country={'hk'}
                                    value={ this.setState[v.value] }
                                    onChange={ (phone)=>this.change( v.value  ,phone) } 
                                  />
                                </div>
                              )
                            }
                          } )
                        }
                      </div>
                    }

                    { this.props.retrieve_key &&
                      <Form>
                        {
                          retrieve_frame.map(  (v,k) => {
                            return (
                              <Form.Control key={k} 
                                type={ v.type  }
                                placeholder={ this.props.gi18n[this.props.language][v.value] }
                                value={ this.setState[v.value] } 
                                className={ ['Login-Input'].join( " " ) } 
                                onChange={ (phone)=>this.change( v.value  ,phone) } />
                            )
                          } )
                        }
                      </Form>
                    }

                    <div className={ ['Login-Stm-Frame', 'forget'].join( " " ) } onClick={()=>{
                      this.forget(  this.state.username )
                    }}>
                      {   this.props.gi18n[this.props.language]['password_forget']  }
                    </div>

                    <div className={ ['Login-Btn-Frame'].join( " " ) }>
                      { this.state.isLogin && !this.props.retrieve_key &&
                        [
                          <div key={0} className={ ['Login-Btn', 'btn'].join( " " ) } onClick={ (e)=>this.login() } > { this.props.gi18n[this.props.language]['login'] }</div>,
                          <div key={1} className={ ['Login-Btn', 'btn'].join( " " ) } onClick={(e)=>this.setState({isLogin : false})}>{this.props.gi18n[this.props.language]['to_register']}</div>
                        ]
                      }
                      { !this.state.isLogin && !this.props.retrieve_key &&
                        [
                          <div key={0} className={ ['Login-Btn', 'btn'].join( " " ) } onClick={ (e)=>this.register() } >{this.props.gi18n[this.props.language]['register']}</div>,
                          <div key={1} className={ ['Login-Btn', 'btn'].join( " " ) } onClick={(e)=>this.setState({isLogin : true})} >{this.props.gi18n[this.props.language]['back']}</div>
                        ]
                      }
                      { this.props.retrieve_key &&
                          <div className={ ['Login-Btn', 'btn'].join( " " ) } onClick={ (e)=>this.restore() } >{this.props.gi18n[this.props.language]['restore']}</div>
                      }
                    </div>

                    <div className={ ['Login-Link'].join( " " ) } >
                      {
                        links.map( (v,k) => {
                          return(
                            [ (k!==0)?(<span key={99 }>|</span>):null , 
                              <Link key={k} to={v.url} className={ ['Login-Link-a'].join( " " ) } >
                                { this.props.gi18n[this.props.language][v.name] }
                              </Link>
                            ]
                          )
                        } )
                      }
                    </div>

        </div>
      )
    }

    render() {
      if( !this.props.noModal ) {
        return (
          <Modal
              size="lg"
              show={this.props.showLogin}
              onHide={() => this.props.updateValue( {  showLogin  : false, retrieve_key : null  }  )}
              aria-labelledby="example-modal-sizes-title-lg"
              className={ ['Login-Holder'].join( " " ) }
          >
              <Modal.Header className={ ['Login-Title'].join( " " ) } closeButton>
                <div className={['Login-Title-Frame'].join(" ")}>
                  {
                    this.props.logosrc && 
                      <Image className={['Login-Title-Logo'].join(" ")} src={this.props.logosrc} />
                    
                  }
                  { !this.props.logosrc && 
                    <FontAwesomeIcon icon={faBowlRice } className={['Login-Title-Logo'].join(" ")} />
                  }
                  <div className={['Login-Title-Title'].join(" ")} >
                    {
                      (this.props.language in this.props.gi18n)?
                      this.props.gi18n[ this.props.language ]['title']:
                      "deMi"
                    }
                  </div>
                </div>
              </Modal.Header>
              
              <Modal.Body className={ ['Login-Body'].join( " " ) }>
                  <Modal.Title className={ ['Login-Body-Title'].join( " " ) }>
                    { this.props.retrieve_key?this.props.gi18n[this.props.language]['restore']:(this.state.isLogin?this.props.gi18n[this.props.language]['login']:this.props.gi18n[this.props.language]['register']) }
                  </Modal.Title>
                  
                  {
                    this.comp()
                  }
            </Modal.Body>
          </Modal>
        )
      } else {
        return (
          this.comp()
        )
      }
    }
}


export default Login;

//onHide={() => this.props.updateValue( {  showLogin  : false, retrieve_key : null  }  )}