import React, { Component } from 'react';
import './index.scss';

import { Button, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft  } from '@fortawesome/free-solid-svg-icons'
import gservice from '../../../Garlican/GarlicanLibrary/gservice'


import Api from '../../../Garlican/GarlicanLibrary/apis'
import service from '../../../public/service';
//import { tree } from 'd3';


class Roadmap extends Component {

    constructor(props) {
      super(props)
      this.state = {

      }   
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {
    }

    render() {
        return (
          <div className={['Roadmap' ].join(" ") }>  

              <div className={['Top' ].join(" ") }>  
                <Button className={['Btn' ].join(" ") } onClick={ (e)=>{
                    this.props.updateFilterValue({
                      selectedIndex : 0,
                    })
                    this.props.updateParent({
                      showFilter : false,
                    })
                  }
                }> 
                  <FontAwesomeIcon icon={ faChevronLeft } className={["Fa"].join(" ")} />
                  {
                    (this.props.selected === "template")?(this.props.selectedStrategy.name?this.props.selectedStrategy.name:"No Name"):
                    (this.props.selectedBotfolio.name?this.props.selectedBotfolio.name:"No name")  
                  }
                </Button>
              </div>
              
              <div className={['Bottom'].join(" ") }> 
                  {
                    this.props.tree.map( (v,k) => {
                        return (
                        <div 
                          onClick={ (e)=>{

                            
                            this.props.updateFilterValue({
                              selectedIndex : k,
                            })
                            if( k === 3 ) {
                              if (this.props.selected !== "template") {
                                this.props.update()
                              } else {
                                console.log( "Uodate Template" )
                                this.props.saveTemplate()
                              }
                              
                            }

                          } }
                          className={['Title', 
                            (k > this.props.selectedIndex)?"Not":"",
                            (k === this.props.selectedIndex)?"Selected":"",
                            (k === this.props.tree.length-1)?"Last":"",
                          ].join(" ") } key={ k }> 
                          <FontAwesomeIcon 
                            className={['Fa'].join(" ") } 
                            icon={ v.icon }
                          />
                          <div className={['Name'].join(" ") }>{  (k+1) + ":" + this.props.gi18n[ this.props.language ][ v.key ] }</div>
                        </div>
                        )
                      }
                    )
                  }
                  <div className={['Road'].join(" ") }></div>
                  <div className={['Road-Cover'].join(" ") } style={ { 
                    width : (100 * (this.props.selectedIndex+1)/(this.props.tree.length) ) + "%"
                  }} ></div>
              </div>


          </div>
        )

    
    }
}


export default Roadmap;



