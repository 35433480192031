import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import './index.scss';

//import logoW from '../Garlican/GarlicanRes/Img/garlic_logo_white.png'
//import logoB from '../public/img/garlican_blue_logo.png'
import service from '../public/service'
//import i18n from '../public/i18n'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faRocket, faUser, faBullhorn, faHeart, faShoppingBag, faBell, faBowlRice  } from '@fortawesome/free-solid-svg-icons'
import { Nav, Navbar, Image, NavDropdown } from 'react-bootstrap';

import { Link } from 'react-router-dom'


var icons = [
  {'icon' : faBullhorn, 'value' : '', 'text' : 'botfolio_market', type : 'normal' },
  //{'icon' : faRobot , 'value' : 'garlican', 'text' : 'create' , type : 'popup', hint : 'showSelection'  }
]

class Controller extends Component {

    constructor(props) {
        super(props)
        var _props = service.checkProps(props)
        this.state = { 
          icons : icons,
          props : _props,
        };
        this.click = this.click.bind(this)
        this.check = this.check.bind(this)
        this.showAutomate = this.showAutomate.bind(this)
        //this.check(props)
    }

    componentDidMount() {
      this.check(this.props)
    }

    shouldComponentUpdate(props, state) {
      return true
    }

    componentDidUpdate(props, state) {
      var _props = service.checkProps(props)

      if( JSON.stringify( _props ) !== JSON.stringify( state.props ) ) {
        this.setState({
          props : _props
        })
        this.check(props)
      }
      
    }

    componentWillUnmount() {

    }

    check(props) {
      if( props.isLogin ) {
        /*icons = [
          {'icon' : faNewspaper , 'value' : '', 'text' : 'portfolio', type : 'normal' },
          {'icon' : faNewspaper , 'value' : 'me', 'text' : 'myportfolio', type : 'myportfolio' },
          {'icon' : faNewspaper , 'value' : 'bookmark', 'text' : 'bookmark', type : 'bookmark' },
          {'icon' : faRobot , 'value' : 'garlican', 'text' : 'create' , type : 'popup', hint : 'showSelection'  }
        ]*/
        icons = [
          {'icon' : faBullhorn , 'value' : '', 'text' : 'botfolio_market', type : 'normal' },
          {'icon' : faRocket , 'text' : 'mycat', 'children' :  
            [
              {'icon' : faUser , 'value' : 'me', 'text' : 'myportfolio', type : 'myportfolio' },
              {'icon' : faShoppingBag , 'value' : 'purchased', 'text' : 'subscribed', type : 'purchased' },
              {'icon' : faHeart , 'value' : 'bookmark', 'text' : 'bookmark', type : 'bookmark' },
            ]
          },
          //{'icon' : faRobot , 'value' : 'garlican', 'text' : 'create' , type : 'popup', hint : 'showSelection'  }
        ]
      } else {
        icons = [
          {'icon' : faBullhorn , 'value' : '', 'text' : 'botfolio_market', type : 'normal' },
          //{'icon' : faRobot , 'value' : 'garlican', 'text' : 'create' , type : 'popup', hint : 'showSelection'  }
        ]
      }
      this.setState({ 
        props : props,
        icons : icons,
      });
    }

    click(type) {
      var obj= { 'type' : type }
      this.props.updateValue(obj)
    }

    showAutomate() {
      this.props.updateValue( {
        showAutomate : !this.props.ahowAutomate,
      })
    }

    render() {
        return (
          <div className={ ['Controller-Holder', (this.props.frame==="dark")?"dark":""  ].join( " " ) }    >
            
            <Navbar collapseOnSelect expand="lg" className={ ['Controller-Frame',(this.props.frame==="dark")?"dark":""].join( " " ) }   >
                <Link className={['Controller-Brand',(this.props.frame==="dark")?"dark":""].join(" ")} 
                  to={"/"} >
                  { 
                    this.props.logosrc && 
                    <Image className={['Controller-Logo',(this.props.frame==="dark")?"dark":""].join(" ")} src={this.props.logosrc} />
                  } 
                  { !this.props.logosrc && 
                    <FontAwesomeIcon icon={ faBowlRice } className={['Controller-Logo',(this.props.frame==="dark")?"dark":""].join(" ") } />
                  }
                  <div className={['Controller-Title',(this.props.frame==="dark")?"dark":"" ,this.props.manager?"manager":""].join(" ")} > 
                    <span className={["Name"].join(" ")}>
                      { 
																(this.props.language in this.props.gi18n)?
																this.props.gi18n[ this.props.language ]['title']:
																"Garlican"
											}   
                    </span>  
                    { this.props.manager &&
                      <span className={['beta'].join(" ")} >  { " Manager Beta" } </span>  
                    }
                  </div>
                </Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className={ ['Controller-Frame-Nav',(this.props.frame==="dark")?"dark":""].join( " " ) }>
                  
                  { !this.props.manager &&
                    <Nav>
                      { 
                        this.state.icons.map( (v,k) => {
                          var typ = this.props.type
                          typ = (typ==="/")?"":typ
                          
                          if(  v.children   ) {
                            var values = []
                            for (var i in v.children) {
                              values.push(  v.children[i].value  )
                            }
                            values = "|" + values.join("|") + "|"

                            return(
                              <div key={k} className={['Controller-Wrapper', 'nav-link' ,(this.props.frame==="dark")?"dark":""].join(' ')}>
                              <NavDropdown  title={ 
                                  <div className={['Controller-Wrapper', 'nav-link' ,(this.props.frame==="dark")?"dark":""].join(' ')} >
                                    <FontAwesomeIcon icon={ v.icon } className={['Controller-Icon',(this.props.frame==="dark")?"dark":""].join(' ')} />
                                    <div className={['Controller-Text',(this.props.frame==="dark")?"dark":""].join(' ')}>
                                      {this.props.gi18n[this.props.language][ v.text ] }
                                    </div>
                                  </div>
                              } >
                                {
                                  v.children.map(
                                    (v1,k1) => {
                                      return(
                                        <Link key={k1} to={  "/" + v1.value } className={['dropdown-item',(this.props.frame==="dark")?"dark":""].join(' ')} >
                                              <FontAwesomeIcon 
                                                icon={v1.icon} 
                                                className={['Controller-Icon',(this.props.frame==="dark")?"dark":""].join(' ')}
                                              />
                                              <div className={['Controller-Text',(this.props.frame==="dark")?"dark":""].join(' ')}>
                                                { this.props.gi18n[this.props.language][ v1.text ] }
                                              </div>
                                        </Link>
                                      )
                                    }
                                  )
                                }
                              </NavDropdown>
                              { (values.indexOf( "|" + typ + "|" ) > -1 ) &&
                                <div className={['Controller-Wrapper-Underline',(this.props.frame==="dark")?"dark":""].join(' ')} ></div>
                              } 
                              </div>
                            )
                          } else {
                            if( (v.type === 'normal') || (v.type === 'myportfolio') || (v.type === 'bookmark') ) {
                              return(
                                <Link key={k} className={['Controller-Wrapper', 'nav-link' ,(this.props.frame==="dark")?"dark":""].join(' ')} to={  "/" + v.value }>
                                  <FontAwesomeIcon 
                                    icon={v.icon} 
                                    className={['Controller-Icon',(this.props.frame==="dark")?"dark":""].join(' ')}
                                    />
                                    <div className={['Controller-Text',(this.props.frame==="dark")?"dark":""].join(' ')}>
                                      { this.props.gi18n[this.props.language][ v.text ] }
                                    </div>
                                    { (v.value === typ ) &&
                                      <div className={['Controller-Wrapper-Underline',(this.props.frame==="dark")?"dark":""].join(' ')}></div>
                                    }
                                </Link>
                              )
                            } else {
                              return(
                                <Nav.Link key={k} className={['Controller-Wrapper',(this.props.frame==="dark")?"dark":""].join(' ')} onClick={ 
                                  (e) => {
                                    if( this.props.isLogin ){
                                      var obj = {}
                                      obj[ v.hint ] = true 
                                      //obj['editedPortfolio'] = {}
                                      //obj['showSelection'] = true
                                      this.props.updateValue( obj )
                                    } else {
                                      this.props.updateValue( {showLogin : true, showSignup : false} )
                                    }
                                  }
                                }>
                                  <FontAwesomeIcon 
                                    icon={v.icon} 
                                    className={['Controller-Icon',(this.props.frame==="dark")?"dark":""].join(' ')}
                                    />
                                    <div className={['Controller-Text',(this.props.frame==="dark")?"dark":""].join(' ')}>
                                      { this.props.gi18n[this.props.language][ v.text ] }
                                    </div>
                                    { (v.value === typ ) &&
                                      <div className={['Controller-Wrapper-Underline',(this.props.frame==="dark")?"dark":""].join(' ')}></div>
                                    }
                                </Nav.Link>
                              )
                            }
                          }
                        } )
                      }
                    </Nav>
                  }
                  <Nav className={[ 'Controller-Panel', 'justify-content-end',(this.props.frame==="dark")?"dark":""].join(' ')}>
                    {
                        this.props.isLogin && (true === false) &&
                        <FontAwesomeIcon icon={faBell} className={ ['Bell', this.props.isNotify?"Shine":""].join(' ') } onClick={ (e)=>this.showAutomate() } />
                    }
                    <Nav.Link className={[(this.props.frame==="dark")?"dark":""].join(' ')}>
                      <div className={['Controller-Login-Panel', (this.props.frame==="dark")?"dark":""].join(' ')}>
                        { !this.props.isLogin && 
                          [
                            <div key={0} className={['Controller-Login-Panel-Text', (this.props.frame==="dark")?"dark":""].join(' ')} onClick={ (e)=>this.props.updateValue( {showLogin : true, showSignup : false} ) } >{ this.props.gi18n[this.props.language]['login'] }</div>,
                            <div key={1} className={['Controller-Login-Panel-Text', (this.props.frame==="dark")?"dark":""].join(' ')}>|</div>,
                            <div key={2} className={['Controller-Login-Panel-Text', (this.props.frame==="dark")?"dark":""].join(' ')} onClick={ (e)=>this.props.updateValue( {showLogin : true, showSignup : true} ) }>{ this.props.gi18n[this.props.language]['register'] }</div>
                          ]
                        }
                        { this.props.isLogin && 
                          <div className={['Controller-Login-Panel-User-Frame'].join(' ')} onClick={ (e)=>this.props.updateValue( {showUser : true} ) }>
                            { service.user &&
                              <Image variant="top" src={ service.user.avatar  } className={['Controller-Login-Panel-User-Pic',(this.props.frame==="dark")?"dark":""].join(' ')} />
                            }
                            { !service.user &&
                              <FontAwesomeIcon  icon={faUser} className={['Controller-Login-Panel-User-Pic',(this.props.frame==="dark")?"dark":""].join(' ')}/>
                            }
                            <div key={0} 
                              className={['Controller-Login-Panel-User-Text',(this.props.frame==="dark")?"dark":""].join(' ')} >
                              {  this.props.gi18n[this.props.language]['controller_greeting'].replace('<user>', service.user.first_name) }
                            </div>
                          </div>
                        }
                      </div>
                    </Nav.Link>
                    <Nav.Link className={[(this.props.frame==="dark")?"dark":""].join(' ')}>
                      <select className={['Controller-Option', (this.props.frame==="dark")?"dark":""].join(' ')} 
                        defaultValue = {this.props.language}
                        onChange={
                          (e) => {
                            this.props.updateValue( { language : e.target.value } )
                          }
                        }>
                        { 
                          this.props.languages.map( (v,k) => {          
                            return(      
                            <option key={k} value={v.key} > { v.name } </option>
                            )
                          } )
                        }
                      </select>   
                    </Nav.Link>

                  </Nav>
                </Navbar.Collapse>
                </Navbar>

          </div>
        )
    }
}


export default Controller;

