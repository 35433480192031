import React, { Component } from 'react';
import './index.scss';

import service from '../../public/service'
import logoB from '../../public/img/garlican_blue_logo.png'
import Emoji from 'react-emojis';

import { Button, Modal, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown,faMinusCircle, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'

import gservice from '../../Garlican/GarlicanLibrary/gservice'

import Api from '../../Garlican/GarlicanLibrary/apis'

//import gcolor from '../../public/style.scss';
import gcolor from '../../public/color.js';
import flags from '../../public/flags.js';


const scores = ['profit_rate', 'sharpe_ratio', 'turnover_rate', 'win_rate' ]


class Botfolio extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          list : [],
          showAlert : false,
          selected : null,
          type : "all",
          selectedRegion : null,
        };
        this.getRank = this.getRank.bind(this)
        this.filterList = this.filterList.bind(this)
    }

    componentDidMount() {
      var name = gservice.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
      var list = this.props.list.filter( (v,k) =>{
        return name !== v.name
      } )
      this.setState({
        list : list
      })
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {
      this.filterList( props, this.state.type )
      
    }

    getRank(v, type) {
      var score = Math.round(  ((v.total_portfolios-v[ type+"_rank" ]+1)/v.total_portfolios) * 10000)/100
      score = (score>100)?0:score
      var obj = {score : null, rank : null, color : null }
      var scale = JSON.parse( JSON.stringify( service.score_scale) )
      //scale.reverse()
      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }
      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }
      var maxColor = hexToRgb( gcolor.gnGreenColor )
      var minColor = hexToRgb( gcolor.gnRedColor )
      if( score > 0 ) {
        for (var s in scale ) {
          if( score >= scale[s].score ) {
            obj.score = score
            obj.rank = scale[s].rank
            var _obj = { r: 0, g:0, b:0}
            for (var c in _obj) {
              _obj[c] = Math.round( (maxColor[c] - minColor[c] ) * score/100 + minColor[c] )
            }
            var color = rgbToHex( _obj.r, _obj.g, _obj.b )
            obj.color = color
            break;
          }
        }
      } 
      //console.log(  v.name + "|" + type + "|" + v[ type+"_rank" ] + "|" + score + "|" + obj.rank )
      return obj
    }

    filterList( props ,type , value ) {
      var name = gservice.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
      var _list = [], obj = {}
      _list = props.list.filter( (v,k) =>{
        return name !== v.name
      } )
      function getRegion(_list, value) {
        _list = _list.filter( (v,k) =>{
          if( value!=="all" ) {
            return v.region === value
          } else {
            return true
          }
        } )
        return _list
      }

      if( type !== "region" ) {
        obj.type = type
      }
      if(  type === "public" ) {
        _list = _list.filter( (v,k) =>{
          return v.access_mode === "public"
        } )
      } else if(  type === "all" ) {
        _list =  _list
      } else if(  type === "onhold" ) {
        _list = _list.filter( (v,k) =>{
          if(this.props.onhold[v.id]) {
            return (this.props.onhold[v.id].length > 0 )
          } else {
            return false
          }
        } )
      } else if(  type === "purchased" ) {
        _list = _list.filter( (v,k) =>{
          return v.subscription_id !== null
        } )
      } else if(  type === "region" ) {
        obj.selectedRegion = (value !== "all")?value:null
        obj.type = "all"
        _list = getRegion(_list, value)
      } else {
        _list = _list.filter( (v,k) =>{
          return v.access_mode !== "public"
        } )
      }

      if( this.state.selectedRegion && (type !== "region") ) {
        _list = getRegion(_list, this.state.selectedRegion)
      }
      obj.list = _list
      this.setState( obj )
    }

    render() {
        return (
          <div className={['Botfolio'].join(" ") }>
          <Modal centered show={this.state.showAlert} onHide={ (e)=>{
            this.setState( {
              showAlert : false
            } )
          } }>
            <Modal.Body> { this.props.gi18n[this.props.language][ 'confirm_remove_portfolio' ] } </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={ (e)=>{
                this.setState({
                  selected : null,
                  showAlert : false
                })
              } }>
                { this.props.gi18n[this.props.language]['cancel'] }
              </Button>
              <Button variant="primary" onClick={ (e)=>{
                //console.log( this.state.selected )
                this.props.removeBotfolio( this.state.selected.id  )
                this.setState({
                  selected : null,
                  showAlert : false
                })
              }}>
                { this.props.gi18n[this.props.language]['remove'] }
              </Button>
            </Modal.Footer>
          </Modal>

            <div className={['panel'].join(" ") }>
              <ButtonGroup aria-label="Basic example" className={['btn-group'].join(" ") }>
                <Button variant="secondary" className={[ (this.state.type==="all")?"active":"" ].join(" ") } onClick={ (e)=>{ this.filterList(this.props,"all") } } >{ this.props.gi18n[this.props.language]['all'] }</Button>
                <Button variant="secondary" className={[ (this.state.type==="public")?"active":"" ].join(" ") } onClick={ (e)=>{ this.filterList(this.props,"public") } } >{ this.props.gi18n[this.props.language]['ispublic'] }</Button>
                <Button variant="secondary" className={[ (this.state.type==="private")?"active":"" ].join(" ") } onClick={ (e)=>{ this.filterList(this.props,"private") } } >{ this.props.gi18n[this.props.language]['isprivate'] }</Button>
                <Button variant="secondary" className={[ (this.state.type==="purchased")?"active":"" ].join(" ") } onClick={ (e)=>{ this.filterList(this.props,"purchased") } } >{ this.props.gi18n[this.props.language]['subscribed'] }</Button>
                <Button variant="secondary" className={[ (this.state.type==="onhold")?"active":"" ].join(" ") } onClick={ (e)=>{ this.filterList(this.props,"onhold") } } >{ this.props.gi18n[this.props.language]['on_market'] }</Button>
                
              </ButtonGroup>

              <div className={['Lower'].join(" ") }>
                <DropdownButton className={["Regions"].join(" ")} title={ 
                              <div className={["Scope"].join(" ")} >

                                <div className={['Region'].join(" ") }>  
                                    { this.state.selectedRegion &&
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                (
                                                  this.state.selectedRegion?
                                                  (
                                                    flags[ this.state.selectedRegion ]
                                                  ):
                                                  flags['usa']
                                                )
                                              }
                                          />
                                        }
                                </div>
                                { this.state.selectedRegion?this.state.selectedRegion:this.props.gi18n[this.props.language]['all'] }
                              </div>
                            }  >
                              {
                                [{region : "all"}].concat( JSON.parse( JSON.stringify(this.props.regions) )) .map( (v,k) => {
                                  return(
                                    <Dropdown.Item 
                                      className={["Line"].join(" ")}
                                      eventKey={v.region} 
                                      key={k} 
                                      onSelect={ (e)=> {
                                        var key = e
                                        this.filterList( this.props, "region", e )
                                        //if(e==="hkg") {
                                        //  key = "hkg"
                                        //}
                                        //this.setState({
                                          //region :  key.toLowerCase()
                                        //})
                                        //this.props.updateBotfolio( ['region'], [ key.toLowerCase() ] )
                                    } } > 
                                      <div className={['Region'].join(" ") }>  
                                        { v.region !== "all" &&
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                v.region?
                                                flags[v.region]:flags['usa']
                                              }
                                          />
                                        }
                                      </div>
                                      { (v.region in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v.region]:v.region } 
                                    </Dropdown.Item>
                                  )
                                } )
                              }
                              
                </DropdownButton>

                <Button className={["Insert"].join(" ")} onClick={ (e)=>{
                  this.props.updateParent({
                    showCreate : true,
                    selectedBotfolio : {},
                    isCreate : true,
                  })
                } } > 
                  <FontAwesomeIcon icon={faPlus} className={["Fa"].join(" ")} />
                  <div> {  this.props.gi18n[this.props.language]['create']  } </div>
                </Button>
              </div>
            </div>

            <div className={['holder'].join(" ") } id={"b_holder"}>
            { this.state.list.length > 0 &&
              this.state.list.map(  (v,k) => {
                return(
                  <div id={v.id} className={['list', (this.props.selectedBotfolio.id === v.id)?'selected':( this.props.selectedBotfolio.id?( (this.props.selectedBotfolio.id!==v.id)?"noselect":""):"")     ].join(" ") } key={k} onClick={ (e)=>{       
                    if( this.props.selectedBotfolio.id !== v.id ) {

                      /*
                      var obj = {
                        report_type : "realtrade",
                        initial_capital : v.initial_capital,
                        transaction_fee : v.transaction_fee,
                      }
                      */
                      //service.createReport( v.id , obj ).then(
                      //  (r) => {
                        var  _this = this
                          service.getPortfoilioById( v.id ).then(
                            (res) => {
                              function _update(res) {
                                _this.props.updateParent( {
                                  originalBotfolio : res,
                                  selectedBoard : 'detail',
                                  selectedBotfolio : res,
                                  selectedIndex : 1,
                                } )
                                var elem = document.getElementById("b_holder")
                                var child = document.getElementById(v.id)
                                elem.scrollTo({
                                  top : (child.offsetTop - 120) ,
                                  behavior : 'smooth'
                                })
                              }

                              if(  res.subscription_id === null   ) {
                                _update(res)
                              } else {
                                service.getPortfoilioById( v.subscription.portfolio_id ).then(
                                  (response) => {
                                    res.subscription.obj = response
                                    var keys = [
                                      'backtest_report', 
                                      'feature', 'profit_rate', 'profit_rate_rank', 'sharpe_ratio', 'sharpe_ratio_rank',
                                      'turnover_rate', 'turnover_rate_rank', 'win_rate', 'win_rate_rank',
                                      'initial_capital', 'transaction_fee', 'total_portfolios',
                                      'bookmark_count', 'view_count', 'partition', 'description'
                                    ]
                                    for(var k in keys) {
                                      res[  keys[k] ] = response[  keys[k]  ]
                                    }

                                    _update(res) 
                                  }
                                )
                              }


                            }
                          )
                      //  }
                      //)
                    } else {
                      this.props.updateParent( {
                        originalBotfolio : {},
                        selectedBotfolio : {},
                        selectedBoard : 'botfolio',
                        selectedIndex : 0,
                      } )
                    }
                  } } >
                    <div className={['panel'].join(" ") }>
                      
                      <div className={['Region'].join(" ") }>  
                        {
                          <Emoji className={['Emoji'].join(" ") } 
                              emoji={
                                v.region?
                                flags[v.region]:
                                flags['usa']
                              }
                          />
                        }
                      </div>

                      { v.subscription_id &&
                        <div className={['badge', 'purchased'].join(" ") }>  {  this.props.gi18n[this.props.language]['subscribed']   }  </div>
                      }
                      { !v.subscription_id && 
                        <div className={['badge', (v.access_mode==="public")?"public":"private"].join(" ") }>  {  (v.access_mode==="public")?this.props.gi18n[this.props.language]['public']:this.props.gi18n[this.props.language]['private']   }  </div>
                      }
                        <Button className={['btn'].join(" ") } onClick={ (e)=>{
                            this.setState({
                              showAlert : true,
                              selected : v
                            })
                          }  } >
                            <FontAwesomeIcon className={['fa'].join(" ") } icon={faTimes} />
                        </Button>
                    </div>
                    <div className={['upper'].join(" ") }> { v.name + " ("  + ( (v.id in this.props.onhold)?this.props.onhold[v.id].length:0) + ")" } </div>
                    <div className={['middle'].join(" ") }> 

                      <div className={["holder"].join(" ")}> 
                        <div className={["name"].join(" ")}>
                          { this.props.gi18n[this.props.language]['current_value'] }
                        </div>
                        <div className={["value",  v.backtest_report?((v.backtest_report.profit>0)?"up":( (v.backtest_report.profit<0)?"down":"" )):""  ].join(" ")}>
                          <div className={["row"].join(" ")} >
                            <span className={["Current"].join(" ")}>{ 
                              v.backtest_report?(
                                "$"+ (v.backtest_report.profit + v.backtest_report.initial_capital ).toLocaleString("en-US") 
                              ):"--"
                            }</span>
                            <span>{ 
                              " ("
                            }</span>
                            <span>
                              <FontAwesomeIcon 
                                className={['fa'].join(" ")} 
                                icon={  v.backtest_report?((v.backtest_report.profit>0)?faCaretUp:( (v.backtest_report.profit<0)?faCaretDown:faMinusCircle )):faMinusCircle   } />
                            </span>
                            <span>{ 
                              v.backtest_report?
                              (v.backtest_report?((Math.round(Math.abs(v.backtest_report.profit/v.backtest_report.initial_capital)*10000)/100) + "%"):"--%")
                              :""
                            }</span>
                            <span>{ 
                              ")"
                            }</span>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                )
              })
            }
            {
              this.state.list.length === 0 && 
              <div>{  this.props.gi18n[ this.props.language ]['no_botfolio']  }</div>
            }
            </div>
          </div>
        )
    }
}


export default Botfolio;
