import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCirclePlus, faCircleMinus, faCartShopping } from '@fortawesome/free-solid-svg-icons'

import { Dropdown, DropdownButton } from 'react-bootstrap'


const condType = [
  { key: 0, value : "None" },
  { key: 1, value : "Stop" },
  { key: 3, value : "Schedule Time" },
  { key: 4, value : "OCO Stop" },
  { key: 6, value : "Trail Stop" },
  { key: 7, value : "Combo Open" },
  { key: 9, value : "Combo Close" },
]

const orderType = [
  { key: 0, value : "Limit" },
  { key: 2, value : "Auction" },
  { key: 5, value : "Odd Lot" },
  { key: 6, value : "Market Order" },
]

const validType = [
  { key: 0, value : "TODAY" },
  { key: 1, value : "FAK" },
  { key: 2, value : "FOK" },
  { key: 3, value : "GTC" },
  { key: 4, value : "GTD" },
]

class List extends Component {

    constructor(props) {
        super(props)
        this.changeVolume = this.changeVolume.bind(this)
    }


    componentDidMount() {    

    }

    componentDidUpdate(props, state) {
      //console.log(props.selected)
    }

    componentWillUnmount() {

    }

    changeVolume(isAdd) {
      //console.log( this.props.obj )
      var unit = 0, decision=0, match = {unit : 0}

      if(  this.props.obj.action === "sell" ) {
        var o = JSON.parse( JSON.stringify( this.props.forwardGroup ))
        o = o[this.props.gindex]
        if( o.onhold ) {
            for( var i in o.onhold ) {
              if(  (o.onhold[i].symbol === this.props.obj.symbol) ) {
                match = o.onhold[i]
                break
              }
            }
        }
      }

      if( isAdd ) {
        unit = Math.abs( this.props.obj.unit) + 1
        decision = Math.round(Math.abs(this.props.obj.tmp_decision)/this.props.obj.tmp_decision) * unit
      } else {
          unit = Math.max(Math.abs( this.props.obj.unit) - 1, 1)
          decision = Math.round(Math.abs(this.props.obj.tmp_decision)/this.props.obj.tmp_decision) * unit
      }

      if(  this.props.obj.action === "sell" ) {
        if(  match.unit >= unit  ) {
          this.props.updateList( this.props.gindex, this.props.index, ["unit", 'decision'], [unit,decision ], false )
        }
      } else {
        this.props.updateList( this.props.gindex, this.props.index, ["unit", 'decision'], [unit,decision ], false )
      }
    
    
    }

    render() {
      try {
        if( this.props.obj.symbol !== "cash" ) {
          var onAction = false
          var o = JSON.parse( JSON.stringify( this.props.forwardGroup ))
          o = o[this.props.gindex]

          if( this.props.type==="onhold" ) {
            var _list = o.action
            if( _list ) {
              for( var i in _list ) {
                if(  (_list[i].symbol === this.props.obj.symbol) ) {
                  onAction = true
                  break
                }
              }
            }
          }
          return (
            <div className={['trade-record-2', this.props.title?"tc-title":""].join(" ")} >
              {
                (this.props.type === "action") && 
                <div className={['trade-name'].join(" ")}> {
                  this.props.gi18n[this.props.language]['portfolio'] + " : " +this.props.obj.group.name
                } </div>
              }
              <div className={['trade-list'].join(" ")}
                onClick={
                  (e) => {
                    if( this.props.title ) {
                      
                      if( this.props.index === this.props.selected ) {
                        this.props.updateValue({  selected : null })
                      } else {
                        this.props.updateValue({  selected : this.props.index })
                      }
                    }
                  }
                } 
              >
                { (this.props.type==="action") && (true === false) &&
                  <div 
                    className={[ 'box' , 'type', ,this.props.title?"":(this.props.obj.real?"real":"paper")   ].join(" ")}
                    onClick={ (e) => {
                      if( (this.props.type==="action") && (this.props.obj.action === "buy")   ) {
                        if( this.props.isTLogin) {
                          var real = this.props.obj.real?this.props.obj.real:false

                          var group = -1, portfolio=-1
                          if( !real ) {
                            group = this.props.dummy.strategy_list[0].id
                            portfolio = this.props.dummy.id
                          } 
                          this.props.updateList( this.props.gindex, this.props.index, ["real", 'group', 'portfolio'], [ !real, group, portfolio ], false )
                          
                        }
                      }
                    }  }
                  > 
                    { !this.props.title && ( this.props.type==="action" ) &&
                      <div className={[ ].join(" ")}> { this.props.obj.real?"實":"擬" } </div>
                    }
                  </div>
                }

                { (this.props.type==="action") && 
                <div 
                  className={[ 'box' , 'bs', this.props.title?"":((  (this.props.obj.action === "buy") && !onAction )?"buy":"sell"   ) ].join(" ")}
                  onClick={ (e) => {
                    if( this.props.type==="action" ) {
                      if( this.props.isTLogin) {
                        //var real = this.props.obj.real?this.props.obj.real:false
                        //this.props.updateList( this.props.gindex, this.props.index, ["real"], [!real], false )
                      }
                    }
                  }  }
                > 
                  <div className={[  ].join(" ")}> 
                    { this.props.title?"":( !onAction?((this.props.obj.action === "buy")?"B":"S"):"PS"  ) } 
                  </div>
                </div>
                }



                <div className={[ 'box', this.props.type ].join(" ")} > 
                  <div className={[ 'holder'].join(" ")} > 
                    <div className={[ 'price', 'symbol'  ].join(" ")}>
                      <div> { this.props.obj.symbol  } </div>
                    </div>
                    { (this.props.obj.symbol in this.props.sym_names) &&
                      <div className={['name'].join(" ")}> { this.props.sym_names[this.props.obj.symbol][this.props.language]  } </div>
                    }
                    
                  </div>
                </div>

                <div className={[ 'box', this.props.type ].join(" ")} > 
                  <div className={[ 'holder'].join(" ")} > 
                    <div className={[ 'price', (this.props.obj.change>0)?'up':((this.props.obj.change<0)?'down':"")  ].join(" ")}>
                        <div> 
                          { 
                            this.props.title?(this.props.obj.price?this.props.obj.price.toLocaleString("en-US"):""):this.props.obj.price
                          }
                        </div>
                        { ( ((this.props.type === "action") && (this.props.obj.action !== 'buy')) || (this.props.type !== "action")  ) &&
                          <div className={['smaller'].join(" ")}> 
                              {
                                (  this.props.obj.change!==null  )?("(" + (Math.round(this.props.obj.change*10000)/100).toLocaleString("en-US") + "%)"):""
                              } 
                          </div> 
                        }
                    </div>
                    { (((this.props.type === "action") && (this.props.obj.action !== 'buy')) || (this.props.type !== "action") ) &&
                      <div className={[ 'buy'].join(" ")}>
                        { 
                          //this.props.title?this.props.obj.buy.toLocaleString("en-US"):( this.props.obj.buy.toLocaleString("en-US"))  
                          //this.props.title?this.props.obj.timestamp:service.formatDate(this.props.obj.timestamp)
                          this.props.title?(this.props.obj.buy?("$"+this.props.obj.buy.toLocaleString("en-US")):""):this.props.obj.buy
                        } 
                      </div>
                    }
                  </div>
                </div>

                <div className={[ 'box', this.props.type ].join(" ")} > 
                  { (this.props.type === "action") && (!this.props.title) && this.props.isActive &&
                    <FontAwesomeIcon  icon={faCircleMinus} className={['arrow'].join(' ')} onClick={ (e)=>{
                      this.changeVolume(false)
                    } } />
                  }
                  <div className={[ 'holder'].join(" ")} > 
                    <div className={ ['total-cost'].join(" ") }>
                      {
                        this.props.title?this.props.obj.total_cost:( "$" + (Math.round(this.props.obj.price * this.props.obj.board_lot * this.props.obj.unit * 100)/100 ).toLocaleString("en-US")) 
                      }
                    </div>
                    { !this.props.title &&
                      <div className={ ['board_lot'].join(" ") }>
                      { 
                        (this.props.obj.board_lot*this.props.obj.unit).toLocaleString("en-US")  
                      } 
                      </div>
                    }
                  </div>
                  { (this.props.type === "action") && (!this.props.title) && this.props.isActive &&
                    <FontAwesomeIcon  icon={faCirclePlus} className={['arrow'].join(' ')} onClick={ (e)=>{
                      this.changeVolume(true)
                    } }/>
                  }
                </div>
            
                {
                  (this.props.type==="action") && 
                  <div className={[ 'box', "control", this.props.title?"title":''].join(" ")} onClick={ (e)=>{
                    //updateList( gindex ,index, type, value, isRemove)
                    this.props.updateList( this.props.gindex ,this.props.index, [], [], true)
                  } } >
                    { !this.props.title && this.props.isActive &&
                      <FontAwesomeIcon  icon={faTimes} className={['icon'].join(' ')} /> 
                    }
                  </div>
                }

                {
                  ( (this.props.type==="onhold") || (this.props.type==="summary") ) && this.props.isActive &&
                  <div className={[ 'box', "control", this.props.title?"title":''].join(" ")} onClick={ (e)=>{   
                    this.props.moveToAction( this.props.gindex, this.props.obj, this.props.type   )
                  } }>
                    { !this.props.title &&
                      <FontAwesomeIcon  icon={faCartShopping} className={['icon'].join(' ')} /> 
                    }
                  </div>
                }
                {
                  !this.props.isActive &&
                  <div className={[ 'box', "control", this.props.title?"title":''].join(" ")} onClick={ (e)=>{   
                      this.props.deleteOrder(  this.props.obj )
                    } }>
                      { !this.props.title &&
                        <FontAwesomeIcon  icon={faTimes} className={['icon'].join(' ')} /> 
                      }
                  </div>
                }

              </div>
              {
                !this.props.title && ((this.props.type==="action") && ( this.props.obj.real === true ) || !this.props.isActive ) &&
                <div className={['trade-board'].join(" ")}>
                  <div className={['title'].join(" ")}> { this.props.gi18n[this.props.language]['validtype'] } </div>
                  <DropdownButton
                          variant="outline-secondary"
                          title={  this.props.obj.validType?this.props.obj.validTypeName:"TODAY"     }
                          className={ ['dropdown'].join(' ') } 
                  >
                      {
                              validType.map((v, k) => {
                              return(
                                  <Dropdown.Item key={k} href="#"
                                    active={ (this.props.obj.validType === v.key)  }
                                    onClick={ (e)=>{
                                      this.props.updateList( this.props.gindex, this.props.index, ['validType', 'validTypeName'], [ v.key, v.value ], false )
                                    }}
                                  >{ v.value }</Dropdown.Item>
                                  )
                              })
                      }
                  </DropdownButton>
                </div>
              }

            </div>
          )
        } else {
        return null
        }
      } catch(e) {
        return null
      }
  }
}


export default List;



