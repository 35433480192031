var colors = {
    gnBgColor: window.colors?window.colors.gnBgColor:"#faf9f6",
    gnBlackColor : window.colors?window.colors.gnBlackColor:"#000000",
    gnDarkColor: window.colors?window.colors.gnDarkColor:"#354149",
    gnGreyColor: window.colors?window.colors.gnGreyColor:"#bababa",
    gnWhiteColor: window.colors?window.colors.gnWhiteColor:"#FFFFFF",
    gnLightColor : window.colors?window.colors.gnLightColor:"#F9F9F9",
    gnGreenColor : window.colors?window.colors.gnGreenColor:"#39C27F",
    gnRedColor :  window.colors?window.colors.gnRedColor:"#CE2929",
    gnDarkerColor : window.colors?window.colors.gnDarkerColor:"#222222",
    gnFrameColor: window.colors?window.colors.gnFrameColor:"#3F95F7",
    gnFrameColor2 : window.colors?window.colors.gnFrameColor2:"#344055",
    gnFrameColor3 : window.colors?window.colors.gnFrameColor3:"#CFB3CD",
    gnFrameColor4 : window.colors?window.colors.gnFrameColor4:"#DFC2F2",
    gnAccentColor : window.colors?window.colors.gnAccentColor:"#C300EA",
    gnAccentColor2 : window.colors?window.colors.gnAccentColor2:"#B7D3F2",
    gnAccentColor3 : window.colors?window.colors.gnAccentColor3:"#84AFE6",
    gnBadgeColor : window.colors?window.colors.gnBadgeColor:"#E59F29"
};

export default colors;
