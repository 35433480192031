import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import service from '../../public/service'
import { Modal, Button } from 'react-bootstrap';




class Alert extends Component {

    constructor(props) {
        super(props)

        this.state = { 

        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
      return (
        <Modal show={  this.props.showAlert } onHide={ this.props.closeAlert } centered>
          <Modal.Header closeButton>
            <Modal.Title>{  this.props.message.header }</Modal.Title>
          </Modal.Header>
          <Modal.Body>{  this.props.message.body  }</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.props.closeAlert}>
              { this.props.gi18n[this.props.language]['close_btn'] }
            </Button>
            
          </Modal.Footer>
        </Modal>
      )
    }
}


export default Alert;

/*
<Button variant="primary" onClick={this.props.closeAlert}>
              Save Changes
            </Button>
*/