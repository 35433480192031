import React, { Component } from 'react';
import './index.scss';

import service from '../../public/service'
import logoB from '../../public/img/garlican_blue_logo.png'

import {  Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const pics = ["0","1", "2", "3" , "4",  "5", "6"]

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = { 

        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {

    }


    render() {
        return (
          <div className={['M-Login'].join(" ") }>
            <Carousel fade>
              {
                pics.map( (v,k) => {
                  var url = window.location.origin + "/sources/" + v + ".jpg"
                  return(
                    <Carousel.Item key={k}>
                      <div className={'cover'}></div>
                      <img
                        className="d-block w-100"
                        src={url}
                        alt={k}
                      />
                    </Carousel.Item>
                  )
                } )
              }
            </Carousel>
          </div>
        )
    }
}


export default Login;

/*
            <Carousel fade> 
              {
                pics.map( (v,k) => {

                } )
              }
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="holder.js/800x400?text=First slide&bg=373940"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
*/