import React, { Component } from 'react';
import './index.scss';

import service from '../../public/service'

import { OverlayTrigger, Tooltip, Button, FormControl, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlay, faCog  } from '@fortawesome/free-solid-svg-icons'

import gservice from '../../Garlican/GarlicanLibrary/gservice'
import Emoji from 'react-emojis';

import flags from '../../public/flags.js';
//import Api from '../../Garlican/GarlicanLibrary/apis'

//import gcolor from '../../public/style.scss';

const include_dividends = true

class TemplateDetail extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          orginal : {},
          initial_capital : 1000000,
          transaction_fee : 0,
          locked : null,
        };
        this.getBacktestObject = this.getBacktestObject.bind(this)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {
    }

    updateTemplate( type, value ) {
      var t = JSON.parse( JSON.stringify( this.props.selectedStrategy ) )
      for( var i in type) {
        t[ type[i] ] = value[i]
      }
      this.props.updateParent({
        selectedStrategy : t,
      })
    }

    getBacktestObject() {
      var g = gservice.createGroupObject(  )
      var s = gservice.createStrategyObject( this.props.code )
      var f = JSON.parse( JSON.stringify(  this.props.filters  )   )
      s.filters = this.props.abjustFilter( f )
      g.partition[ s.id ] = "100"
      //g.input[ s.id ] = this.props.input
      g.input[ s.id ] = {}
      g.strategy_list = [ s ]
      g.user_id = service.user.user_id
      g.initial_capital = this.state.initial_capital

      var val = 0
      try{
        val = parseFloat( this.state.transaction_fee , 10)
      } 
      catch(e) {
        val = 0
      }
      g.transaction_fee = val
      g.region = this.props.selectedStrategy.region
      this.props.updateParent({
        selectedBotfolio : g
      })
      var s = this.props.regions.filter( (v,k)=>{return v.region === this.props.selectedStrategy.region} )
      try {
        this.props.backtest( this.state.initial_capital, val, false, g, this.props.selectedStrategy.region , include_dividends , s[0].demo  )
      } catch(e){

      }

      
    }

    render() {
        return (
          <div className={['TemplateDetail'].join(" ") }>
            <div className={['Control'].join(" ") }>
              <Button 
                className={["Btn",'left'].join(" ")}
                onClick={
                  (e)=>this.getBacktestObject()
                }
                style={ 
                  { 
                    //display : "none" 
                } }
               > 
                <FontAwesomeIcon icon={faPlay} className={["Fa"].join(" ")}/>
                { this.props.gi18n[ this.props.language]['runbacktestT'] } 
              </Button>
              <Button className={["Btn",'right', 'config'].join(" ")} onClick={
                (e) => {
                  var t = JSON.parse(  JSON.stringify( this.props.selectedStrategy )  )
                  //var _filters = this.props.transfromFilter( t.filters )
                  this.props.updateParent({
                    //selectedIndex : 1,
                    //selectedBoard : 'filter',
                    //input : t.input,
                    input : {},
                    code : t.code,
                    //filters : _filters,
                    filters : t.filters,
                    showFilter : true,
                    region : t.region,
                    //selectedBotfolio : t
                    //isFilter: false
                  })
                }
              }> 
                <FontAwesomeIcon icon={faCog} className={["Fa"].join(" ")}/>
                { this.props.gi18n[ this.props.language]['edit'] } 
              </Button>
            </div>

            <div className={['Holder'].join(" ") }>
              <div className={['Docker', "H"].join(" ") }>   

                <DropdownButton 
                    className={["Regions"].join(" ")} title={ 
                              <div className={["Scope"].join(" ")} >
                                <div className={['Region'].join(" ") }>  
                                        {
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                (
                                                  this.props.selectedStrategy.region?
                                                  (
                                                    flags[ this.props.selectedStrategy.region ]
                                                  ):
                                                    flags["usa"]
                                                )
                                              }
                                          />
                                        }
                                </div>
                                { this.props.selectedStrategy.region?this.props.selectedStrategy.region.toUpperCase():"HKG" }
                              </div>
                            } >
                              {
                                this.props.regions.map( (v,k) => {
                                  return(
                                    <Dropdown.Item 
                                      className={["Line"].join(" ")}
                                      eventKey={v.region} 
                                      key={k} 
                                      onSelect={ (e)=> {
                                        var key = e
                                        //if(e==="hk") {
                                        //  key = "hkg"
                                        //}
                                        this.updateTemplate( ['region'], [ key.toLowerCase() ] )
                                    } } > 
                                      <div className={['Region'].join(" ") }>  
                                        {
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                v.region?
                                                flags[v.region]:
                                                flags['usa']
                                              }
                                          />
                                        }
                                      </div>
                                      { v.region } 
                                    </Dropdown.Item>
                                  )
                                } )
                              }
                              
                </DropdownButton>

                <Button className={["Btn",'left'].join(" ")} 
                  onClick={
                    (e) => {
                      this.props.saveTemplate()
                    }
                  }
                > 
                  <FontAwesomeIcon icon={faPen} className={["Fa"].join(" ")}/>
                  { this.props.gi18n[ this.props.language]['update'] } 
                </Button>
              </div>

              <div className={['Docker'].join(' ')}>
                        <ButtonGroup aria-label="Basic example" className={['Mode'].join(' ')}>
                          <OverlayTrigger
                            placement={"bottom"}
                            overlay={
                              <Tooltip bsPrefix={"TP"}>
                                {  this.props.gi18n[this.props.language]['contribute_msg_public'] }
                              </Tooltip>
                            }
                          >
                            <Button 
                              onClick={ (e)=>{
                                this.updateTemplate(  ['access_mode'], ["public"]    )
                              } }
                              className={[(this.props.selectedStrategy.access_mode!=="public")?'Disabled':""].join(' ')}> 
                              { this.props.gi18n[this.props.language]['public_mode'] } 
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={"bottom"}
                            overlay={
                              <Tooltip bsPrefix={"TP"}>
                                {  this.props.gi18n[this.props.language]['contribute_msg_protected'] }
                              </Tooltip>
                            }
                          >
                            <Button 
                              onClick={ (e)=>{
                                this.updateTemplate(  ['access_mode'], ["protected"]    )
                              } }
                              className={[(this.props.selectedStrategy.access_mode==="public")?'Disabled':""].join(' ')} > 
                              { this.props.gi18n[this.props.language]['protected_mode'] } 
                            </Button>
                          </OverlayTrigger>
                        </ButtonGroup>
              </div>

              <div className={['Docker', "Two"].join(' ')}>
                <div className={['Input'].join(' ')}> 
                  <div className={['TTitle'].join(' ')}> { this.props.gi18n[this.props.language]['initial'] } </div>
                      <FormControl 
                        className={['TValue', 'TInput', (this.state.locked === "initial_capital")?"Locked":""].join(' ')} 
                      onFocus={
                        (e) => this.setState({ locked : "initial_capital" })
                      }
                      onChange={  
                        (e) => {
                          this.setState( {
                            'initial_capital' : e.target.value
                          })
                        }
                      }
                      onBlur={
                        (e) => {
                          var val = (e.target.value.length>0)?parseFloat( e.target.value.replace( /,/g,"" ) , 10):0
                          this.setState( {
                            'initial_capital' : val,
                            locked : null
                          })
                        }
                      }
                      value={ 
                        this.state.initial_capital
                        } />
                    <span className={['TValue', (this.state.locked === "initial_capital")?"Hide":""].join(' ')}> {"$" + this.state.initial_capital.toLocaleString("en-US")} </span>
                  </div>
                  <div className={['Input'].join(' ')}> 
                    <div className={['TTitle'].join(' ')}> { this.props.gi18n[this.props.language]['transaction_fee'] } </div>
                        <FormControl 
                          className={['TValue','TInput', (this.state.locked === "transaction_fee")?"Locked":""].join(' ')} 
                          onFocus={
                            (e) => this.setState({ locked : "transaction_fee" })
                          }
                          onChange={  
                            (e) => {
                              //console.log( e.target.value )
                              //var val = (e.target.value.length>0)?parseFloat( e.target.value , 10):0
                              //console.log(val)
                              this.setState( {
                                'transaction_fee' : e.target.value,
                              })
                            }
                          }
                          onBlur={
                            (e) => {
                              var val = (e.target.value.length>0)?parseFloat( e.target.value , 10):0
                              console.log(val)
                              this.setState( {
                                'transaction_fee' : val,
                                locked : null
                              })
                            }
                          }
                          value={ 
                            this.state.transaction_fee
                          } />
                      <span className={['TValue', (this.state.locked === "transaction_fee")?"Hide":""].join(' ')}> { "$" + this.state.transaction_fee.toLocaleString("en-US")} </span>
                  </div>
              </div>

              <div className={['Docker'].join(' ')}>
                <div className={['Title'].join(' ')}> { this.props.gi18n[this.props.language]['strategy_name'] } </div>
                <FormControl  
                  placeholder={  this.props.gi18n[this.props.language]['strategy_name']  }
                  className={["Value"].join(" ")} 
                  onChange={ (e)=> {    
                    this.updateTemplate(  ['name'], [ e.target.value ]    )
                  } }
                  value={  this.props.selectedStrategy.name?this.props.selectedStrategy.name:""  } />
              </div>
              
              <div className={['Docker'].join(' ')}>
                <div className={['Title'].join(' ')}> { this.props.gi18n[this.props.language]['strategy_desc']  } </div>
                <FormControl  
                  as="textarea"
                  placeholder={  this.props.gi18n[this.props.language]['strategy_desc']  }
                  className={["Text"].join(" ")} 
                  onChange={ (e)=> {   
                    this.updateTemplate(  ['description'], [ e.target.value ]    )
                  } }
                  value={  this.props.selectedStrategy.description?this.props.selectedStrategy.description:""  } />
              </div> 


            </div>

          </div>
        )
    }
}


export default TemplateDetail;
