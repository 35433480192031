import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import './index.scss';
import service from '../../public/service'
import Api from '../../Garlican/GarlicanLibrary/apis'

import { Image, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEdit, faBowlRice, faCrown  } from '@fortawesome/free-solid-svg-icons'
import Login from '../Login'
import ActivitiesLog from '../../Components/ActivitiesLog';
import Select from 'react-select'
import _default from 'react-bootstrap/esm/CardColumns';
//import { symbol } from 'd3';

const botfolio_labels = [
    {key : 'public', value : 'public'},
    {key : 'private', value : 'private'},
    {key : 'subscribed', value : 'subscribed'},

]

const tabs = [
    "preformance", 
    "onhold", 
    "automate"
]

class Profile extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            user : service.user?service.user:null,
            lv_obj : ( service.user?(  service.calcExp( service.user.point )  ):{} ),
            current : 0,
            original : 0,
            change : 0,
            private : 0,
            public : 0,
            purchased : 0,
            onhold : 0,
            selectedTab : this.props.showAutomate?2:0,
            price : {},
            automate : [],
            selectedAutomate : [],
        };
        this.navigation = this.navigation.bind(this)
    }

    componentDidMount() {
        this.calc( this.props.forwardGroup  )

        //console.log( this.props.forwardGroup )
        var _fg = JSON.parse( JSON.stringify(this.props.forwardGroup) )
        //var _auto= JSON.parse( JSON.stringify(this.props.forwardGroup) )

        _fg = _fg.sort( (a,b) => {
            var _a = (( a.group.access_mode === "public" )?1:-1)
            var _b = (( b.group.access_mode === "public" )?1:-1)
            b['label'] = "["+(b.group.subscription_id?"Purcahsed":b.group.access_mode)+"]"+ b.group.name + "(" + b.onhold.length + ")"
            b['id'] = b.group.id
            b['value'] = b.group.id
            a['label'] = "["+(a.group.subscription_id?"Purcahsed":a.group.access_mode)+"]"+ a.group.name + "(" + a.onhold.length + ")"
            a['id'] = a.group.id
            a['value'] = a.group.id
            return (((b.onhold.length - a.onhold.length))) || (_b -_a)
        }  )
        
        var _auto = _fg.filter(  (v,k) => {
            return v.group.automated
        })

        this.setState({
            automate : _fg,
            selectedAutomate : _auto,
            originalAutomate : _auto,
        })

    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {
        //console.log( props )
        if( props.updated.includes("isLogin") || props.updated.includes("forwardGroup") ) {
            this.setState({
                user : service.user,
                lv_obj : ( service.user?(  service.calcExp( service.user.point )  ):{} )
            })
            if( props.forwardGroup ) {
                this.calc( props.forwardGroup  )
            }
        }
    }

    calc(gp) {
        //console.log( gp )
        var current = 0, original = 0, change = 0, onhold = 0, _private = 0, _public = 0, purchased = 0
        gp = JSON.parse(  JSON.stringify( gp )  )
        var _gp = gp.filter( (v,k) => (v['onhold'].length > 0 )  )
        var syms = []
        //subscription_id
        _public = gp.filter( (v,k) => ( (v['group'].access_mode === "public") && (!v['group'].subscription_id)  )  )
        _private = gp.filter( (v,k) => ( (v['group'].access_mode === "private") && (!v['group'].subscription_id)  )  )
        purchased = gp.filter( (v,k) => ( v['group'].subscription_id)  )
        _public = _public.length
        _private = _private.length
        purchased = purchased.length

        var symbols = {}

        for( var i in _gp ) {
            var region = _gp[i].group.region
            if( !(region in symbols) ) {
                symbols[region] = []
            }
            for (var j in _gp[i].onhold ) {
                if(  !symbols[region].includes( _gp[i].onhold[j].symbol)  ) {
                    symbols[region].push( _gp[i].onhold[j].symbol )
                    syms.push( _gp[i].onhold[j].symbol  )
                }
            }
        }
        if( syms.length > 0 ) {

            Api.getprices(  symbols, "latest"  ).then(
                (res) => {
                    var prices = {}, sum = 0
                    for (var i in res.value) {
                        prices[ res.value[i].symbol ] = res.value[i].close
                        //console.log( res.value[i]  )
                    }

                    //sum = 
                    //console.log( prices )

                    for(i in _gp) {
                        var _list = _gp[i].onhold
                        for( var j in _list ) {
                        current  += ( prices[_list[j].symbol] * _list[j].board_lot * _list[j].unit )
                        original += ( _list[j].price * _list[j].board_lot * _list[j].unit )
                        onhold += 1
                        }
                    }
                    change = ((original>0)?((current - original)/original):0)

                    this.setState({
                        change : Math.round(change*10000)/100,
                        current : Math.round(current*100)/100,
                        original : Math.round(original*100)/100,
                        onhold : onhold,

                        public : _public,
                        private : _private,
                        subscribed : purchased,
                        price : prices,
                    })


                }    
            )
        }
    }

    navigation() {
        window.location.href = window.location.origin + "/manager"
        
    }

    updateAutomate(){
        
        var _updates = [], _agroups = {}, _ogroups = {}

        _ogroups =  this.state.originalAutomate.map( (v,k) => v.group.id )
        _agroups =  this.state.selectedAutomate.map( (v,k) => v.group.id )

        console.log( _ogroups )
        console.log( _agroups )

        for ( var i in this.state.selectedAutomate ) {
            if( _ogroups.includes(this.state.selectedAutomate[i].group.id) === false   ) {
                var _o = {}
                _o.id = this.state.selectedAutomate[i].group.id
                _o.enable = true
                _updates.push( _o )
            }
        }
        for ( var i in this.state.originalAutomate ) {
            if( _agroups.includes(this.state.originalAutomate[i].group.id ) === false  ) {
                var _o = {}
                _o.id = this.state.originalAutomate[i].group.id
                _o.enable = false
                _updates.push( _o )
            }
        }

        //console.log( _updates  )

        this.props.updateValue({
            showLoading : true
        })

        var _this = this
        function _update(obj) {
            service.updateAutomate( obj.id, obj.enable ).then(
                (res) => {
                    _updates.pop()
                    if( _updates.length > 0 ) {
                        _update( _updates[ _updates.length - 1 ]  )
                    } else {
                        _this.props.updateValue({
                            showLoading : false
                        })
                        _this.setState({
                            originalAutomate : this.state.selectedAutomate
                        })
                    }
                }
            ).catch( (e) => {
                _this.props.updateValue({
                    showLoading : false
                })
            } )
        }
        
        _update( _updates[ _updates.length - 1 ] )

        
        //console.log( _updates )
    }

    render() {
        if(this.props.isLogin)  {
            return (
                <div className={ [ 'Profile'  ].join(' ') }>

                <div className={ [ 'UFrame'  ].join(' ') }>
                    {
                                                [120, 500, 200, 60, 90, 90, 300, 75, 275].map( (v, k) => {
                                                    var x = ( Math.random() * 100)
                                                    return(
                                                        <div
                                                            key={k} 
                                                            className={['Circle'].join(" ")} 
                                                            style={{
                                                                left : ( Math.random() * 100) + "%",
                                                                top : ( Math.random() * 100) + "%",
                                                                width : v + "px",
                                                                height : v + "px",
                                                                zindex : k
                                                            }}
                                                        > 
                                                        </div>
                                                    )
                                                } )
                    }
                </div>

                <div className={ [ 'Holder'  ].join(' ') } >

                        <div className={['User'].join(" ")}> 

                            {
                                service.user.groups && (!service.user.groups.includes("professional")  ) &&
                                <div className={['Upgrade'].join(" ")}
                                    onClick={ (e)=> {
                                        this.props.updateValue({
                                            isPayment : true,
                                        })
                                    } }
                                > { "Upgrade"} 
                                </div>
                            }

                            <div className={['Pic'].join(" ")}>

                                {
                                    service.user.groups && (service.user.groups.includes("professional")  ) &&
                                    <FontAwesomeIcon className={['Crown'].join(" ")} icon={faCrown} />
                                }

                                <div className={['Wrapper', service.user.groups && (service.user.groups.includes("professional")  )?"Professional":"" ].join(" ")}>
                                    {   
                                        this.state.user.avatar?
                                        <Image className={['Img'].join(" ")} src={this.state.user.avatar} />
                                        :
                                        <FontAwesomeIcon className={['Fa'].join(" ")} icon={faUser}/>
                                    }
                                </div>
                            </div>
                            <div className={['PDetail'].join(" ")}>
                                <div className={['Name'].join(" ")}>
                                    <div className={['First'].join(" ")}>{ this.state.user.first_name.toUpperCase() }</div>
                                    <div className={['Level'].join(" ")}>{ "LV" + this.state.lv_obj.lv  }</div>
                                </div>
                                <div className={['Exp'].join(" ")}>
                                    <div className={['Wrapper'].join(" ")}>
                                        <div className={['Bar'].join(" ")} style={
                                            {
                                                width : (100-((this.state.lv_obj.exp - this.state.lv_obj.current_exp)*100/(this.state.lv_obj.next_exp - this.state.lv_obj.current_exp))) + "%"
                                            }
                                        }></div>
                                    </div>
                                    <div className={['Next'].join(" ")}>
                                        { this.props.gi18n[ this.props.language ]['t_exp_message'].replace( '<exp>', this.state.lv_obj.exp ).replace( '<remain>', (this.state.lv_obj.next_exp-this.state.lv_obj.exp) ) }
                                    </div>
                                </div>
                                
                            </div>
                            <Button className={['Edit'].join(" ")} onClick={ (e)=>{
                                    this.props.updateValue({
                                        showUser : true,
                                    })
                                } 
                            }>
                                <FontAwesomeIcon icon={faEdit} className={['Fa'].join(' ')}/>
                                <div className={['Text'].join(" ")}> {
                                    this.props.gi18n[ this.props.language ]['t_edit']
                                } </div>
                            </Button>
                        </div>
                        
                        <div className={['Tab'].join(" ")}>

                            <div className={['Control'].join(" ")}>   
                                {
                                    tabs.map( 
                                        (v,k) => {
                                            return(
                                                <div 
                                                    onClick={ (e) => {

                                                        this.setState({
                                                            selectedAutomate : this.state.originalAutomate,
                                                            selectedTab : k,
                                                        })
                                                    } }
                                                    className={['Select', (this.state.selectedTab===k)?"Selected":""].join(" ")} 
                                                    key={k}>   
                                                    {  this.props.gi18n[this.props.language][v]  }
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>

                            { this.state.selectedTab === 0 &&
                                <div className={['Cont', 'Performance'].join(" ")}>
                                <div className={['Onhold'].join(" ")}>
                                    <div className={['Header'].join(" ")}>
                                        { this.props.gi18n[ this.props.language ][ 't_my_paper_trade']  }
                                    </div>
                                    <div className={['Holder', ].join(" ")}>
                                        <div className={['Wrapper', 'Original'].join(" ")}>
                                            <div className={['Title'].join(" ")}>{  this.props.gi18n[ this.props.language ][ 't_paper_trade_holding']  }</div>
                                            <div className={[
                                                'Value',
                                                ((this.state.change>0)?"Gain":((this.state.change<0)?"Lost":""))
                                            ].join(" ")}>{ "$" + this.state.current.toLocaleString("en-US") }</div>
                                            <div className={[
                                                'Change',
                                                ((this.state.change>0)?"Gain":((this.state.change<0)?"Lost":""))
                                            ].join(" ")}>
                                                <div className={['Amount'].join(" ")}>{ "$" + (this.state.current - this.state.original).toLocaleString("en-US") }</div>
                                                <div className={['Percent'].join(" ")}>{ (this.state.change) + "%" }</div>
                                            </div>
                                        </div>
                                        <div className={['Wrapper', 'Stock'].join(" ")}>
                                            <div className={['Title'].join(" ")}>{ this.props.gi18n[ this.props.language ][ 't_stock_count'] }</div>
                                            <div className={['Value'].join(" ")}>{  this.state.onhold?this.state.onhold:0  }</div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.user.report_summary &&
                                    <div className={['Onhold'].join(' ')}>
                                        <div className={['Header'].join(" ")}>
                                            { this.props.gi18n[ this.props.language ]['t_avg_lifetime_profit']  }
                                        </div> 
                                        <div className={['Holder', 'Row-Holder' ].join(" ")}>
                                            {
                                                this.state.user.report_summary.map( (v,k) => {
                                                    
                                                    var avg = Math.round(  ((v.trade_count>0)?(v.profit/v.trade_count):0) * 100 ) /100
                                                    return (
                                                        <div className={['Wrapper', 'Half' ].join(" ")} key={k}>  
                                                            <div className={['Title'].join(" ")}>{  this.props.gi18n[this.props.language][ v.report_type ] }</div>
                                                            <div className={['Value',
                                                                ((avg>0)?"Gain":((avg<0)?"Lost":""))
                                                            ].join(" ")}>{
                                                                    "$"+avg.toLocaleString("en-US") 
                                                                }
                                                            </div>
                                                            <div className={[
                                                                'Change',
                                                            ].join(" ")}>
                                                                <div className={['Amount'].join(" ")}>{ 
                                                                (this.props.language in this.props.gi18n)?
                                                                    this.props.gi18n[ this.props.language ]['t_trade_count'].replace("<count>", v.trade_count  ):"0"
                                                                    //v.trade_count 
                                                                }</div>
                                                            </div>
                                                        </div>
                                                    )
                                                } )
                                            }
                                        </div>   
                                    </div>
                                }
                                
                                <div className={['Botfolio'].join(" ")}>
                                    <div className={['Header', ].join(" ")}>
                                        {   this.props.gi18n[ this.props.language ]['t_my_botfolios'].replace( "<value>" ,( this.state.public + this.state.private + this.state.subscribed ) )   }
                                    </div>
                                    <div className={['Holder', ].join(" ")}>
                                        {
                                            botfolio_labels.map( (v,k) => {
                                                return (
                                                    <div className={['Wrapper', ].join(" ")} key={k}>  
                                                        <div className={['Title'].join(" ")}>{ this.props.gi18n[ this.props.language ][ 't_' + v.value ]  }</div>
                                                        <div className={['Value'].join(" ")}>{ this.state[v.value] }</div>
                                                    </div>
                                                )
                                            } )
                                        }
                                    </div>
                                </div>
                                </div>
                            }
                            
                            { this.state.selectedTab === 1 &&
                                <div className={['Cont', 'Onhold'].join(" ")}>
                                    <div className={['Title'].join(" ")}>
                                        <div className={['Text', "Middle"].join(" ")}>{ this.props.gi18n[this.props.language]["onhold"]  + " : $" + this.state.current.toLocaleString("en-US") } </div>
                                    </div>

                                    <div className={['Container'].join(" ")}> 
                                        {
                                            this.props.forwardGroup.map( (v,k) => {
                                                
                                                if( v.onhold.length > 0 ) {
                                                    //console.log(   this.props.sym_names['3759.HK'] )
                                                    return (
                                                        v.onhold.map( (v1, k1) => {
                                                            var _o = JSON.parse( JSON.stringify( v1 ) )
                                                            _o.action = "buy"
                                                            _o.user = service.user
                                                            return(
                                                                <ActivitiesLog 
                                                                    
                                                                    {...this.props}
                                                                    obj={ _o }
                                                                    dark={true}
                                                                    record={true}
                                                                    price={ this.state.price }
                                                                    isPortfolio={true}
                                                                    className={['Row'].join(" ")} 
                                                                    key={k+"_"+k1} />
                                                            )
                                                        }  )
                                                    )
                                                } else{
                                                    return(
                                                        null
                                                    )
                                                }
                                            } )
                                        }
                                    </div>                                
                                </div>
                            }

                            { this.state.selectedTab === 2 &&
                                <div className={['Cont', 'Automate'].join(" ")}>
                                    <div className={['Title'].join(" ")}> 
                                        <div className={['Text',"Middle"].join(" ")}> { this.props.gi18n[this.props.language]["automate"] } </div>
                                        <div className={['Btn'].join(" ")} onClick={ (e)=> this.updateAutomate() }> { this.props.gi18n[this.props.language]["update"] }</div>
                                    </div>

                                    <div className={['Container'].join(" ")}> 
                                        <Select
                                            defaultValue={this.state.selectedAutomate}
                                            isMulti
                                            name={"label"}
                                            isSearchable={false}
                                            options={ this.state.automate }
                                            className={ ["basic-multi-select", "Frame"].join(" ") }
                                            classNamePrefix={ ["Select-Box", "select"].join(" ") }
                                            //value={"id"}
                                            closeMenuOnSelect={false}
                                            value={this.state.selectedAutomate}
                                            onChange={
                                                (e) => {
                                                    //console.log()
                                                    //console.log(e)
                                                    this.setState({
                                                        selectedAutomate : e
                                                    })
                                                }
                                            }
                                        />
                                    </div>                                
                                </div>
                            }

                        </div>

                        <div className={['Manager'].join(" ")}>
                            { 
                                <Link className={ ["Btn", "btn"].join(" ") } to={"/manager"}>{this.props.gi18n[ this.props.language ]['create']}</Link>

                            }
            
                            { 
                            /*
                            <div className={ ["Btn", "btn"].join(" ") } 
                                onClick={
                                    (e) => {
                                        this.navigation()
                                    }
                                }
                            >
                            
                            
                                {this.props.gi18n[ this.props.language ]['create']}
                            </div>
                            */
                            }
                        </div>


                </div>
                </div>
            )
        } else {
            return (
                <div className={ [ 'Profile', 'Login-Holder'  ].join(' ') }>
                    
                    <div className={ [ 'UFrame'  ].join(' ') }>
                    {
                                                [120, 500, 200, 60, 90, 90, 300, 75, 275].map( (v, k) => {
                                                    var x = ( Math.random() * 100)
                                                    return(
                                                        <div
                                                            key={k} 
                                                            className={['Circle'].join(" ")} 
                                                            style={{
                                                                left : ( Math.random() * 100) + "%",
                                                                top : ( Math.random() * 100) + "%",
                                                                width : v + "px",
                                                                height : v + "px",
                                                                zindex : k
                                                            }}
                                                        > 
                                                        </div>
                                                    )
                                                } )
                    }
                </div>

                    <div className={ [ 'Login-Body'  ].join(' ') }>
                        <div className={['Logo-Wrapper'].join(" ")}>
                            {
                                this.props.logosrc && 
                                <Image className={['Logo-Img'].join(" ")} src={
                                    window.contrast_logosrc?window.contrast_logosrc:this.props.logosrc
                                } />  
                            }
                            { !this.props.logosrc && 
                                <FontAwesomeIcon icon={faBowlRice } className={['Logo-Fa'].join(" ")} />
                            }
                            <div className={['Logo-Title'].join(" ")} >
                                {
                                (this.props.language in this.props.gi18n)?
                                this.props.gi18n[ this.props.language ]['title']:
                                "Garlican"
                                }
                            </div>
                        </div>

                        {
                            <Login {...this.props} noModal={true} />
                        }
                    </div>

                    

                </div>
            )
        }
    }
}


export default Profile;

//onHide={() => this.props.updateValue( {  showLogin  : false, retrieve_key : null  }  )}

/*
<Button className={ ["Btn"].join(" ") } onClick={
                                (e) => {
                                    this.navigation()
                                }
                            }>
                                {
                                    this.props.gi18n[ this.props.language ]['create']
                                }
                            </Button>
                            */