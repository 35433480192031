import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import logoB from '../../public/img/garlican_blue_logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBowlRice, faPen, faCopy } from '@fortawesome/free-solid-svg-icons'
import service from '../../public/service'
import flags from '../../public/flags'
import GService from '../../Garlican/GarlicanLibrary/gservice';
import { Modal, ButtonGroup, Button, Image, Jumbotron, Form, ListGroup, InputGroup, FormControl, Dropdown, DropdownButton } from 'react-bootstrap';
import Emoji from 'react-emojis';

import Content from './Content'
import Confirm from "../../Components/Confirm"

//import gcolor from '../../public/style.scss';

const MAX = 5
class CreatePanel extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          option : {} ,
          strategy_list : [],
          selected : {},
          partition : {},
          input : {},
          //name : (props.gi18n[ props.language]['my_new_portfolio'].replace("<name>",service.user.first_name) + "_" + timestamp) ,
          name : '',
          showAlert : false,
			    alertMsg : "",
          showAlertBtn : null,
          params : [],
          callBack : null,
			
          region : props.regions[0].region,
          isTemplate : false,

        };        
        this.create = this.create.bind(this)
        this.select = this.select.bind(this)
        this.remove = this.remove.bind(this)

        //this.addStrategy = this.addStrategy.bind(this)
        this.insertTemplate = this.insertTemplate.bind(this)
        this.removeTemplate = this.removeTemplate.bind(this)
        this.changePartition = this.changePartition.bind(this)
        this.changeName = this.changeName.bind(this)

        this.close = this.close.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
        this.modify = this.modify.bind(this)
        this.add = this.add.bind(this)

        this.useTemplate = this.useTemplate.bind(this)
        this.scrollto = this.scrollto.bind(this)
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(props) {
      var opt = {}, obj = {}
      if(  window.templates.selection.length > 0 ) {
        opt = window.templates.selection[0]
      }
      var timestamp = GService.getTimestamp()
      var name = ""

      if( props.isLogin ) {
        //if( props.isCreate || !props.selectedBotfolio.name  ) {  
        if( props.isCreate && (this.state.name.length === 0)  ) {  
          name = (props.gi18n[ props.language]['my_new_portfolio'].replace("<name>",service.user.first_name) + "_" + timestamp)  
          obj = {
            option : opt,
            //partition : opt.object.partition,
            //input : opt.object.input,
            region : props.regions[0].region,
            name : name
          }
          this.add()
        } else if ( ! props.isCreate && ("id" in props.selectedBotfolio)  ){
          var botfolio = JSON.parse( JSON.stringify( props.selectedBotfolio ) )
          name = 
          obj = {
            name : botfolio.name,
            partition : botfolio.partition,
            input : botfolio.input,
            strategy_list : botfolio.strategy_list,
            region : botfolio.region,
            option : opt,
          }
        }
        //obj.originalBotfolio 
      }

      this.setState(  obj )

    }

    componentWillUnmount() {
    }

    create(v) {
      //console.log(v)
      var b = JSON.parse( JSON.stringify( v )  )
      b = b.object
      
      if( this.state.strategy_list.length > 0 ) {
        b.strategy_list = JSON.parse(  JSON.stringify( this.state.strategy_list ))
      } else {
        b.strategy_list = []
      }
      
      b.input = JSON.parse(  JSON.stringify( this.state.input ))
      b.partition = JSON.parse(  JSON.stringify( this.state.partition ))
      b.name = this.state.name
      b.region = this.state.region


      var _e = this.check(b)
      if( _e ) {
        this.props.updateValue({
          showAlert : true,
          alertMessage : _e
        })
        return
      }
      var timestamp = GService.getTimestamp()
      var g = GService.createGroupObject(b, b.name, null)
      var strategy = []
      this.props.updateValue({
        showLoading : true
      })
      g = JSON.parse( JSON.stringify(g) )
      delete g.input
      delete g.partition
      g.input = {}
      g.partition = {}
      g.access_mode = "private"
      var oldKey = Object.keys(  b.input  )
      var _this = this
      function _call(newObj) {
        newObj.name = g.name
        newObj.description = g.description
        newObj.initial_capital = g.initial_capital
        newObj.transaction_fee = g.transaction_fee
        newObj.access_mode = "private"
        service.updatePortfolio( g.id,newObj ).then(
          (r) => {
            g.input = newObj[ 'input' ]
            g.partition = newObj[ 'partition' ]
            service.getPortfoilioById( g.id ).then(
              (res) => {
                _this.props.updateValue({
                  showLoading : false
                })
                _this.props.updateParent({
                  showCreate : false,
                })
                _this.props.getBotfolioList(true)

                  _this.setState({
                    strategy_list : [],
                    selected : {},
                    partition : {},
                    input : {},
                    region : _this.props.regions[0].region,
                    isTemplate : false,
                    name : ""
                  })

              }
            )
          }
        )
      }
      function _create(s, id,  newObj ) {
        var strat = {}
        strat.portfolio_id = id
        var keys = ["id" ,"name", "description", "code", "filters", "strategy_template_id" ]
        for(var k in keys) {
          if( g.strategy_list[s][ keys[k] ] ) {
            strat[ keys[k] ] = g.strategy_list[s][ keys[k] ]
          }
          if(  keys[k] === "description" ) {
            if( !strat[ keys[k] ] ) {
              strat[ keys[k] ] = ""
            }
          }
        }
        service.createStrategy( strat ).then(
          (response) => {
            var oid = oldKey[s]
            newObj.input[ response.id ] = b.input[ oid ]	
            newObj.partition[ response.id ] = b.partition[ oid ]
            g.strategy_list[ s ].id = response.id
            g.strategy_list[ s ].portfolio_id = id
            g.strategy_list[ s ].user_id = service.user.user_id
            s+=1
            if( s === g.strategy_list.length ) {
              _call( newObj )
            } else {
              _create(s, id, newObj)
            }
          }
        ).catch( (e)=> {
          _this.props.updateValue({
            showLoading : false
          })
          _this.props.updateParent({
            showCreate : false,
          })
        })	
      
      }
      //console.log( g )
      //g.partition

      service.createPortfolio( g ).then(
        (res)=> {
          if(res['detail']) {
            var obj = {
              header : "Upgrade Requested.",
              body : "Maximum number of portfolios can be created by basic user is 2."
            }
            _this.props.updateValue({
              showLoading : false,
              alertMessage : obj,
              showAlert : true,
            })
            _this.props.updateParent({
              showCreate : false,
            })
          } else {
            g.user = service.user
            g.id = res.id
            var newObj ={ input : {}, partition : {} }
            _create(0, res.id, newObj)
          }
        }
      ).catch( (e)=> {
        //console.log(e)
          var obj = {
            header : "Unique Name",
            body : "The Botfolio must be unqiue."
          }
          _this.props.updateValue({
            showLoading : false,
            alertMessage : obj,
            showAlert : true,
          })
          _this.props.updateParent({
            showCreate : false,
          })
      })   
      
    }

    select(v) {
      var b = JSON.parse( JSON.stringify( v )  )
      b = b.object
      var timestamp = GService.getTimestamp()
      var name = ""
      if( this.props.isLogin ) {
        name = (this.props.gi18n[ this.props.language]['my_new_portfolio'].replace("<name>",service.user.first_name) + "_" + timestamp) 
      }
      this.setState({
        option : v,
        partition : b.partition,
        input : b.input,
        strategy_list : b.strategy_list,
        name : name
      })
    }

    remove(v) {
      var list = JSON.parse( JSON.stringify( this.state.strategy_list )  )
      list.splice( v, 1 )
      var p = JSON.parse( JSON.stringify( this.state.partition )  )
      delete p[ v.id ]
      var i = JSON.parse( JSON.stringify( this.state.input )  )
      delete i[ v.id ]
      if( this.props.isCreate ) {
        this.setState( {
          strategy_list : list,
          partition : p,
          input : i,
        })
      } else {
        var b = JSON.parse( JSON.stringify( this.props.selectedBotfolio ) )
        b.input = i
        b.strategy_list = list
        b.partition = p
        this.props.updateParent({
          selectedBotfolio : b
        })
      }
    }

    updateValue() {
    }

    insertTemplate(id, v) {
      var list = JSON.parse( JSON.stringify( this.state.strategy_list )  )
      //if( list.length < MAX ) {
        v = JSON.parse( JSON.stringify( v  )  )
        v.strategy_template_id = v.id
        for( var i in list ) {
          if( list[i].id === v.id ) {
            return
          }
        }
        //list.push(  v )
        if( list.length === 0 ) {
          list.push(  v )
        } else {
          list[0] = v
        }
        //var p = JSON.parse( JSON.stringify( this.state.partition )  )
        var  p = {}
        p[ v.id ] = "100"
        //var i = JSON.parse( JSON.stringify( this.state.input )  )
        var  i = {}
        i[ v.id ] = v.input

        if( this.props.isCreate ) {
          this.setState( {
            strategy_list : list,
            input : i,
            partition : p,
          })
        } else {
          var b = JSON.parse( JSON.stringify( this.props.selectedBotfolio ) )
          b.input = i
          b.strategy_list = list
          b.partition = p
          this.props.updateParent({
            selectedBotfolio : b
          })
        }
      /*
      } else {
          this.setState({
            params : [],
            callback : null,
            showAlertBtn : null,
            showAlert : true,
            alertMsg :  ((this.props.language in this.props.gi18n)?this.props.gi18n[ this.props.language ]['backtesting_limit_error'].replace("<limit>", MAX):"Excceed Strategy Limit" ) ,
          })
      }
      */
      
    }

    removeTemplate(v) {
      console.log(v)
    }

    changePartition(v, value) {
      if( this.props.isCreate ) {
        var p = JSON.parse( JSON.stringify( this.state.partition )  )
        p[ v.id ] = value
        this.setState( {
          partition : p,
        })
      } else {
        var b = JSON.parse( JSON.stringify( this.props.selectedBotfolio )  )
        b.partition[ v.id ] = value
        this.props.updateParent({
          selectedBotfolio : b
        })
      }
    }

    changeName(v, idx, value) {
      if( this.props.isCreate ) {
        var s = JSON.parse( JSON.stringify( this.state.strategy_list )  )
        s[idx].name = value
        this.setState( {
          strategy_list : s,
        })
      } else {
        var b = JSON.parse( JSON.stringify( this.props.selectedBotfolio )  )
        b.strategy_list[ idx ].name = value
        this.props.updateParent({
          selectedBotfolio : b
        })
      }
    }

    close() {
    }

    modify() {
      var _this = this
      var o = JSON.parse(  JSON.stringify( this.props.originalBotfolio  )  )
      var b = JSON.parse(  JSON.stringify( this.props.selectedBotfolio )  )

      var _e = this.check(b)
      
      if( _e ) {
        this.props.updateValue({
          showAlert : true,
          alertMessage : _e
        })
        return
      }

      if(  JSON.stringify( o ) !== JSON.stringify(  b )  ){
        var a = {}
        var ids = this.props.originalBotfolio.strategy_list.map( (v,k)=>{
          a[ v.id ] = v
          return v.id
        } )
        var updates = []
        for(var i in b.strategy_list ) {
          if(  ids.includes(  b.strategy_list[i].id  ) ){
            if(  JSON.stringify( a[b.strategy_list[i].id ] ) !== JSON.stringify( b.strategy_list[i] )  ) {
              updates.push(  { type : "update", value : b.strategy_list[i] } ) 
            }
          } else {
            updates.push(  { type : "create", value : b.strategy_list[i] } )  
          }
        }
        var nids = this.props.selectedBotfolio.strategy_list.map( (v,k)=>{
          return v.id
        } )
        for(var i in o.strategy_list ) {
          if( !nids.includes( o.strategy_list[i].id  ) ) {
            updates.push(  { type : "remove", value : o.strategy_list[i] } ) 
          }
        }
        function _update(b) {
          service.updatePortfolio(  b.id, b ).then(
            (res) => {
              _this.props.updateParent({
                showCreate : false,
                selectedBotfolio : b,
                originalBotfolio : b,
              })
              _this.props.getBotfolioList(true)
            }
          )
        }

        function _call( idx, updates , b ) {
          var _oid = updates[idx].value.id
          if(  updates[idx].type === "create" ) {
            updates[idx].value.portfolio_id = b.id
            service.createStrategy( updates[idx].value ).then(
              (res) => {
                updates[idx].value.id = res.id
                var _p = b.partition[ _oid ]
                b.partition[  res.id ] = _p
                delete b.partition[ _oid ]
                var _i = b.input[ _oid ]
                b.input[  res.id ] = _i
                delete b.input[ _oid ]
                if( idx === updates.length-1 ) {
                  _update(b)
                } else {
                  _call(  idx+1, updates, b )
                }
              }
            )
          } else if(  updates[idx].type === "update" ) {
            service.updateStrategy( updates[idx].value.id, updates[idx].value ).then(
              (res) => {
                if( idx === updates.length-1 ) {
                  _update(b)
                } else {
                  _call(  idx+1, updates, b )
                }
              }
            )
          } else if(  updates[idx].type === "remove" ) {
            service.deleteStrategy( updates[idx].value.id ).then(
              (res) => {
                delete b.partition[ _oid ]
                delete b.input[ _oid ]
                if( idx === updates.length-1 ) {
                  _update(b)
                } else {
                  _call(  idx+1, updates, b )
                }
              }
            )
          }

        }

        if( updates.length > 0 ) {
            _call(0, updates, b)
        } else {
          if(  JSON.stringify( o ) !== JSON.stringify(  b )  ){
            _update( b )
          }
        }


      }


    }

    check(b) {
      var obj = {}
      b = JSON.parse( JSON.stringify(b) )
      var _p = 0
      for (var i in b.partition) {
        _p += parseInt( b.partition[i] , 10 )
      }
      if( (_p > 100) || ( _p < 0 ) || ( !_p )  ) {
        obj.header = "Partition"
        obj.header = this.props.gi18n[ this.props.language ]['partition']
        obj.body = this.props.gi18n[ this.props.language ]['error_partition']
        return obj
      }
      //strategy name not null
      for (var i in b.strategy_list) {
        if(  (b.strategy_list[i].name === "") || (!b.strategy_list[i].name) ) {
          obj.header = this.props.gi18n[ this.props.language ]['strategy_name']
          obj.body = this.props.gi18n[ this.props.language ]['error_strategy_name']
          return obj
          break;
        }
      }
      //group name not null
      if(  ( b.name === "" ) || ( !b.name ) ) {
        obj.header = this.props.gi18n[ this.props.language ]['group_name']
        obj.body = this.props.gi18n[ this.props.language ]['error_botfolio_name']
        return obj
      }

      return null
    }

    add() {
      var filters = null
      if( !this.props.hasFilter ) {
        //filters = [  { key: "symbol" , value : JSON.parse(  JSON.stringify( this.props.original_symbol_list) )  }  ]
        filters = []
      }

      var strat = GService.createStrategyObject( this.props.tmp_code, null, null, this.props.tmp_input, filters )
      var botfolio = JSON.parse( JSON.stringify( this.props.selectedBotfolio ) )
      if( !this.props.isCreate ) {
        
        if( botfolio.strategy_list.length === 0 ) {
          botfolio.strategy_list.push(strat)
        } else {
          botfolio.strategy_list[0] = strat
        }

        var i = {}
        i[  strat.id ] = strat.input
        var p = {}
        p[ strat.id ] = "100"
        botfolio.input = i
        botfolio.partition = p
        this.props.updateParent({
          selectedBotfolio : botfolio
        })
      } else {
        var _s = JSON.parse( JSON.stringify( this.state.strategy_list ) )
        
        if( _s.length === 0 ) {
          _s.push( strat )
        } else {
          _s[0] = strat
        }
        //var i = JSON.parse( JSON.stringify(  this.state.input ) )
        var i = {}
        i[ strat.id ] = this.props.tmp_input
        //var p = JSON.parse( JSON.stringify(  this.state.partition ) )
        var p = {}
        p[ strat.id ] = "100"
        this.setState({
          strategy_list : _s,
          input : i,
          partition : p,
        })
      }
    }
    
    closeAlert() {
      this.setState({
        params : [],
        callback : null,
        showAlertBtn : null,
        showAlert : false,
        alertMsg :  null ,
      })
    }

    useTemplate( bool ) {
      //strategy_list
      if( !bool ) {
        this.add()
      }
      this.setState({
        isTemplate : bool
      })
    }

    scrollto(id) {
      var element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }

    render() {
        return (
          <Modal 
            centered 
            size="lg"
            show={this.props.showCreate} 
            onHide={(e)=>{
              this.setState({
                strategy_list : [],
                selected : {},
                partition : {},
                input : {},
                region : this.props.regions[0].region,
                isTemplate : false,
                name : ""
              })
              this.props.updateParent({
                showCreate : false,
                selectedBotfolio : this.props.originalBotfolio,
              })
            }}
            backdropClassName="CreateBackdrop"
            dialogClassName="CreatePanel"
            className={ ['create-app'].join(' ') }>
            <Modal.Header className={ ['app-Selection-Header'].join( " " ) } closeButton>
              <div className={['app-Selection-Header-Frame'].join(" ")}>
                { 
                  this.props.logosrc &&
                  <Image className={['app-Selection-Header-Logo'].join(" ")} src={this.props.logosrc} />
                }
                { !this.props.logosrc && 
                    <FontAwesomeIcon icon={ faBowlRice } className={['app-Selection-Header-Fa'].join(" ") } />
                }
                <div className={['app-Selection-Header-Title'].join(" ")} > { this.props.isCreate?this.props.gi18n[this.props.language]['create_portfolio']:this.props.gi18n[this.props.language]['bot_manager']  }  </div>
              </div>
            </Modal.Header>
            <Modal.Body>

              <div className={["Left"].join(" ")}>
                <div className={["Partition"].join(" ")} id="1" onClick={ (e)=>this.scrollto("1") }>
                    <div className={["Title"].join(" ")}> 
                       { "1. " + this.props.gi18n[ this.props.language ]['group_name'] }
                    </div>
                    <div className={["Content"].join(" ")}> 
                        <FormControl 
                          placeholder={ this.props.gi18n[ this.props.language ]['group_name'] } 
                          className={["Input"].join(" ")}
                          value={this.state.name} 
                          onChange={
                          (e) => {
                            this.setState({
                              name : e.target.value
                            })
                          }
                        }/>
                    </div>
                </div>

                <div className={["Partition"].join(" ")} id="2" onClick={ (e)=>this.scrollto("2") }>
                    <div className={["Title"].join(" ")}> 
                       { "2. " + "Select a region" }
                    </div>
                    <div className={["Content"].join(" ")}> 
                            <DropdownButton className={["Regions"].join(" ")} title={ 
                              <div className={["Scope"].join(" ")} >
                                <div className={['Region'].join(" ") }>  
                                        {
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                (
                                                  this.state.region?
                                                  (
                                                    flags[ this.state.region ]
                                                  ):
                                                  flags['usa']
                                                )
                                              }
                                          />
                                        }
                                </div>
                                { this.state.region?this.state.region:"usa" }
                              </div>
                            }  >
                              {
                                this.props.regions.map( (v,k) => {
                                  return(
                                    <Dropdown.Item 
                                      className={["Line"].join(" ")}
                                      eventKey={v.region} 
                                      key={k} 
                                      onSelect={ (e)=> {
                                        var key = e
                                        //if(e==="hkg") {
                                        //  key = "hkg"
                                        //}
                                        this.setState({
                                          region :  key.toLowerCase()
                                        })
                                        //this.props.updateBotfolio( ['region'], [ key.toLowerCase() ] )
                                    } } > 
                                      <div className={['Region'].join(" ") }>  
                                        {
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                v.region?
                                                flags[v.region]:
                                                flags['usa']
                                              }
                                          />
                                        }
                                      </div>
                                      { v.region } 
                                    </Dropdown.Item>
                                  )
                                } )
                              }
                              
                            </DropdownButton>
                    </div>
                </div>

                <div className={["Partition"].join(" ")} id="3" onClick={ (e)=>this.scrollto("3") }>
                    <div className={["Title"].join(" ")}> 
                       { "3. " + "Select strategy" }
                    </div>
                    <div className={["Content"].join(" ")}> 
                      <div className={["Strategy"].join(" ")}>
                          <div className={["Box", !this.state.isTemplate?"selected":"" ].join(" ")} onClick={ (e) => {
                            this.useTemplate( false )
                          }  }>
                            <FontAwesomeIcon icon={ faPen } className={['Fa'].join(" ") } />
                            <div className={["Text"].join(" ")}>
                              { "Create from stretch" }
                            </div>
                          </div>
                          <div className={["Box", this.state.isTemplate?"selected":"" ].join(" ")} onClick={ (e) => {
                            this.useTemplate( true )
                          }  }>
                            <FontAwesomeIcon icon={ faCopy } className={['Fa'].join(" ") } />
                            <div className={["Text"].join(" ")}>
                              { "Copy from Template" }
                            </div>
                          </div>
                      </div>
                    </div>
                </div>

                <div className={["Partition", 'Gen'].join(" ")} id="4" onClick={ (e)=>this.scrollto("4") }>
                    <div className={["Title"].join(" ")}> 
                       { "4. " + "Generate" }
                    </div>
                    <div className={["Content"].join(" ")}> 
                            { this.props.isCreate &&
                              <Button 
                                onClick={
                                  (e)=>{
                                    //console.log( this.state.option )
                                    var opt = {}
                                    if(  window.templates.selection.length > 0 ) {
                                      opt = JSON.parse( JSON.stringify( window.templates.selection[0] ) )
                                      //console.log(opt)
                                      opt.object.strategy_list.push(  window.templates.long_strategy[0]  )
                                      //opt.partition[ opt.strategy[0]["id"] ]
                                    }
                                    //console.log(opt)

                                    this.create( this.state.option )
                                    //this.create( opt )
                                  }
                                }
                                className={ ['Generate'].join(" ") }>
                                  { this.props.gi18n[this.props.language][ "generate"]  }
                              </Button>
                            }

                            { !this.props.isCreate &&
                              <Button 
                                onClick={
                                  (e)=>{
                                    this.create( this.state.option )
                                  }
                                }
                                className={ ['Generate'].join(" ") }>
                                  { "Modify"  }
                              </Button>
                            }
                    </div>
                </div>
              </div>

              <div className={["Right", this.state.isTemplate?"Show":"" ].join(" ")}>
                  <Content 
                    region={ this.state.region }
                    inBoard={true}
                    addStrategy={this.insertTemplate} 
                    remove={ this.removeTemplate  } 
                    add={false} 
                    strategy_list={this.state.strategy_list}
                    selectedBotfolio={this.props.selectedBotfolio}
                    convertToTemplate={ this.insertTemplate }
                    updateValue={this.updateValue} 
                    showTemplates={true}  
                    updateParent={ this.props.updateParent }
                    language={ this.props.language }
                    gi18n={ this.props.gi18n }
                  />
              </div>

            </Modal.Body>

            <Confirm msg={this.state.alertMsg} show={this.state.showAlert} secondary_btn_text={this.state.showAlertBtn} scope={'create'} callback={this.state.callback} params={this.state.params} language={this.props.language} gi18n={this.props.gi18n} replacement_func={ this.close } close={ this.closeAlert }  />	
          
          </Modal>
        )
    }
}


export default CreatePanel;

