import React, { Component } from 'react';
import './index.scss';

import service from '../../public/service'

import { Button,ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import Emoji from 'react-emojis';
//import gservice from '../../Garlican/GarlicanLibrary/gservice'
//import Api from '../../Garlican/GarlicanLibrary/apis'
import flags from '../../public/flags.js';
//import gcolor from '../../public/style.scss';


const scores = ['profit_rate', 'sharpe_ratio', 'turnover_rate', 'win_rate' ]


class TemplateList extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          templates : [],
          page : 0,
          next : false,
          display : [],
          access_mode : "all",
          selectedRegion : props.regions[0].region,
        };
        this.getMyTemplates = this.getMyTemplates.bind(this)
        this.get = this.get.bind(this)
        this.create = this.create.bind(this)
        this.filter = this.filter.bind(this)
    }

    componentDidMount() {
      this.getMyTemplates()
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {

    }

    getMyTemplates(id) {
      var obj = { user_id : service.user.user_id, page : this.state.page }
      service.getTemplates(obj).then(
        (res) => {
          var obj = {}
          obj.templates = res.list
          //obj.display = res.list
          obj.page = res.page
          if( res.length >= 30 ) {
            obj.next = true
          }  
          this.setState(obj)
          var _this = this
          setTimeout( 
            (e) => {
              _this.get( this.state.access_mode , id )
            } , 300
           )
          
        }
      )
    }

    removeTemplate(v) {
      service.deleteTemplate(v.id).then(
        (res) => {
          this.props.updateParent({
            selectedStrategy : {}
          })
          this.getMyTemplates()
        }
      )
    }

    get(type, id) {
      var d = JSON.parse(  JSON.stringify( this.state.templates ) )
      if( type!== "all" ) {
        d = d.filter( (v,k) => {
          if( type === "public" ) {
            return v.access_mode === "public"
          } else {
            return v.access_mode !== "public"
          }
        } )
      }
      //this.setState({
        //display : d,
      //  access_mode : type,
      //})
      this.filter( d, this.state.selectedRegion , type   )
      if(id) {
        service.getTemplateById( id ).then(
          (res)=> {
            this.props.updateParent({
              selectedStrategy : res,
            })
          }
        )
      }

    }

    filter(d, value, type) {
      d = d?d:JSON.parse(  JSON.stringify( this.state.templates ) )
      value = (value!=="all")?value:null 
      d = d.filter( (v,k) => {
        if( value ) {
          return v.region === value
        } else {
          return true
        }
      } )
      this.setState({
        selectedRegion : value,
        display : d,
        access_mode : type,
      })
    }

    create() {
      var obj = JSON.parse( JSON.stringify( window.templates.default_template )  )
      obj.input = {}
      //if( !this.props.hasFilter ) {
      //  obj.filters = [ { key : "symbol", value : JSON.parse(  JSON.stringify( this.props.original_symbol_list) ) } ]
      //}
      obj.filters = []

      service.createTemplate( obj ).then(
        (res) => {
          this.getMyTemplates( res.id )
        }
      )
    }

    render() {
        return (
          <div className={['TemplateList'].join(" ") }>

            <div className={['panel'].join(" ") }>
              <ButtonGroup>
                <Button className={[ (this.state.access_mode==="all")?"active":null  ]} onClick={ (e)=>this.get("all")  }>{ this.props.gi18n[ this.props.language ]['all']  }</Button>
                <Button className={[ (this.state.access_mode==="public")?"active":null  ]} onClick={ (e)=>this.get("public")  }>{ this.props.gi18n[ this.props.language ]['public_mode']  }</Button>
                <Button className={[ (this.state.access_mode==="private")?"active":null  ]} onClick={ (e)=>this.get("private")  }>{ this.props.gi18n[ this.props.language ]['protected_mode']  }</Button>
              </ButtonGroup>

              <div className={['Lower'].join(" ") }>
              <DropdownButton className={["Regions"].join(" ")} title={ 
                              <div className={["Scope"].join(" ")} >

                                <div className={['Region'].join(" ") }>  
                                    { this.state.selectedRegion &&
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                (
                                                  this.state.selectedRegion?
                                                  (
                                                    flags[ this.state.selectedRegion ]
                                                  ):
                                                  flags['usa']
                                                )
                                              }
                                          />
                                        }
                                </div>
                                { this.state.selectedRegion?this.state.selectedRegion:this.props.gi18n[this.props.language]['all'] }
                              </div>
                            }  >
                              {
                                [{region : "all"}].concat( JSON.parse( JSON.stringify(this.props.regions) )) .map( (v,k) => {
                                  return(
                                    <Dropdown.Item 
                                      className={["Line"].join(" ")}
                                      eventKey={v.region} 
                                      key={k} 
                                      onSelect={ (e)=> {
                                        //var key = e
                                        this.filter( null, e, "all" )
                                        //if(e==="hkg") {
                                        //  key = "hkg"
                                        //}
                                        //this.setState({
                                          //region :  key.toLowerCase()
                                        //})
                                        //this.props.updateBotfolio( ['region'], [ key.toLowerCase() ] )
                                    } } > 
                                      <div className={['Region'].join(" ") }>  
                                        { v.region !== "all" &&
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                v.region?
                                                flags[v.region]:flags['usa']
                                              }
                                          />
                                        }
                                      </div>
                                      { (v.region in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v.region]:v.region } 
                                    </Dropdown.Item>
                                  )
                                } )
                              }
                              
                </DropdownButton>
                <Button className={['Add'].join(" ")} onClick={
                  (e) => {
                    this.create()
                  }
                } >
                  <FontAwesomeIcon icon={faPlus} className={['Fa'].join(" ")} />
                  { this.props.gi18n[this.props.language]['create_templates'] }
                </Button>
              </div>
            </div>

            <div className={['holder'].join(" ") } >
              { (this.state.display.length > 0) &&
                this.state.display.map( (v,k) => {
                  return(
                    <div id={ v.id } className={['item' , this.props.selectedStrategy.id?((this.props.selectedStrategy.id===v.id)?"selected":"noselected"):"" ].join(" ") } key={k} onClick={
                      (e)=> {
                        try {
                          service.getTemplateById( v.id ).then(
                            (res) => {
                              var t = JSON.parse(  JSON.stringify( res )  )
                              //var _filters = this.props.transfromFilter( t.filters )  
                              var _filters = JSON.parse(  JSON.stringify(  t.filters ) )
                              this.props.updateParent({
                                selectedStrategy : res,
                                input : t.input,
                                code : t.code,
                                filters : _filters,
                              })
                            }
                          )
                        } catch(e) {
                          
                        }

                      }
                    }> 

                      
                      <div className={['control'].join(" ") } > 

                        <div className={['Region'].join(" ") }>  
                          {
                            <Emoji className={['Emoji'].join(" ") } 
                                emoji={
                                  v.region?
                                  flags[v.region]:flags['usa']
                                }
                            />
                          }
                        </div>

                        <span className={['badge', (v.access_mode==="public")?"public":'private'  ].join(" ") }>  { (v.access_mode==="public")?this.props.gi18n[ this.props.language ]['public_mode']:this.props.gi18n[ this.props.language ]['protected_mode']  }  </span>
                        <Button  
                          onClick={  
                            (e) => {
                              this.removeTemplate( v )
                            }
                          }
                          className={['remove'].join(" ")}>
                          <FontAwesomeIcon icon={faTimes} className={['fa'].join(" ")} />
                        </Button>
                      </div>

                      <div className={['upper'].join(" ") } > 
                       <span>{ v.name } </span>
                      </div>
                      <div className={['middle'].join(" ") }>
                        <span>
                          { this.props.gi18n[this.props.language]["powered"] + v.user.first_name } 
                        </span>
                      </div>


                    </div>


                  )
                } )
              }
              {
                (this.state.display.length === 0) &&
                <div> {  this.props.gi18n[this.props.language]['no_template']  } </div>
              }
            </div>

          </div>
        )
    }
}


export default TemplateList;


/*
                      <div className={['lower'].join(" ") }> 
                       <span className={['desc' ].join(" ") }>{ v.description?v.description:this.props.gi18n[this.props.language]['no_desc'] } </span>
                      </div>
*/