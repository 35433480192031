var language = {
  "professional_name" : {
    "eng" : "deMI Professional",
    "zhtc" : "得米專業版",
    "zhcn" : "得米专业版"
  },
  "regular_name" : {
    "eng" : "deMI Regular",
    "zhtc" : "得米",
    "zhcn" : "得米"
  },
  "marketplace_access_right" : {
    "eng" : "Marketplace Access Right",
    "zhtc" : "組合大賣場權限",
    "zhcn" : "组合大卖场权限"
  },
  "yes" : {
    "eng" : "Yes",
    "zhtc" : "可",
    "zhcn" : "可"
  },
  "no" : {
    "eng" : "No",
    "zhtc" : "不可",
    "zhcn" : "不可"
  },
  "unlimited" : {
    "eng" : "unlimited",
    "zhtc" : "無限",
    "zhcn" : "旡限"
  },
  "full" : {
    "eng" : "comprehensive",
    "zhtc" : "全面報告",
    "zhcn" : "全面报告"
  },
  "partial" : {
    "eng" : "Partial",
    "zhtc" : "部分報告",
    "zhcn" : "部分报告"
  },
  "day" : {
    "eng" : "day",
    "zhtc" : "日",
    "zhcn" : "日"
  },
  "share_botfolio" : {
    "eng" : "Share Bot-folio",
    "zhtc" : "分享機器組合",
    "zhcn" : "分享机器组合"
  },
  "suppport_hk_market" : {
    "eng" : "Support HKG market",
    "zhtc" : "分持港股市場",
    "zhcn" : "支持港股市场"
  },
  "suppport_us_market" : {
    "eng" : "Support USA market",
    "zhtc" : "支持美股市场",
    "zhcn" : "支持美股市场"
  },
  "purchase_botfolio" : {
    "eng" : "Purchase Bot-folio",
    "zhtc" : "購買機器組合",
    "zhcn" : "购买机器组合"
  },
  "create_trading_botfolio" : {
    "eng" : "Create Trading Bot-folio",
    "zhtc" : "創建您的機器組合",
    "zhcn" : "创建您的机器组合"
  },
  "create_botfolio" : {
    "eng" : "Create Boitfolio",
    "zhtc" : "創建機器組合",
    "zhcn" : "创建机器组合"
  },
  "create_templates" : {
    "eng" : "Create Templates",
    "zhtc" : "創建模板",
    "zhcn" : "创建模板"
  },
  "run_backtesting_report" : {
    "eng" : "Run backtesting report",
    "zhtc" : "執行回測報告",
    "zhcn" : "执行回测报告"
  },
  "run_forwardtesting_report" : {
    "eng" : "Real trade signals",
    "zhtc" : "自動實時交易信號",
    "zhcn" : "自动实时交易信号"
  },
  "publish_on_marketplace" : {
    "eng" : "Publish Bot-folio on Marketplace",
    "zhtc" : "在組合大賣場上發佈組合",
    "zhcn" : "在组合大卖场上发布组合"
  },
  "revenue_sharing" : {
    "eng" : "Revenue sharing",
    "zhtc" : "收入分成",
    "zhcn" : "收入分成"
  },

  "notification" : {
    "eng" : "Notifications",
    "zhtc" : "實時通訊功能",
    "zhcn" : "实时通讯功能"
  },
  "intra_day" : {
    "eng" : "Hourly-based notifications",
    "zhtc" : "每小時通知",
    "zhcn" : "每小时通知"
  },
  "messager" : {
    "eng" : "Whatsapp/ SMS content",
    "zhtc" : "Whatsapp/短信内容",
    "zhcn" : "Whatsapp/短信内容"
  },
  "dayend_report" : {
    "eng" : "Dayend Report",
    "zhtc" : "日終報告",
    "zhcn" : "日终报告"
  },


  "free" : {
    "eng" : "Free",
    "zhtc" : "免費",
    "zhcn" : "免费"
  },
  "purchase" : {
    "eng" : "Purchase",
    "zhtc" : "購買",
    "zhcn" : "购买"
  },

  "purchased" : {
    "eng" : "Already Purchased",
    "zhtc" : "已購買",
    "zhcn" : "已购买"
  },

  "hkd" : {
    "eng" : "HKD",
    "zhtc" : "港元",
    "zhcn" : "港元"
  },

}


export default language