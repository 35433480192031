import React, { Component } from 'react';
import './index.scss';

import service from '../../public/service'
import logoB from '../../public/img/garlican_blue_logo.png'

import {  Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPlay } from '@fortawesome/free-solid-svg-icons'

import gservice from '../../Garlican/GarlicanLibrary/gservice'

import Api from '../../Garlican/GarlicanLibrary/apis'

//import gcolor from '../../public/style.scss';

import ActivitiesLog from "../../Components/ActivitiesLog"



class TradeLog extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            sum : 0,
            botfolio : {},
            logs : [],
            price : {},
        };
        this.getTradeLog = this.getTradeLog.bind(this)
        this.getAsset = this.getAseset.bind(this)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {
        if(  props.selectedBotfolio.id !== this.state.botfolio.id ) {
            this.getTradeLog(  props.selectedBotfolio  )
            this.setState( {
                botfolio : props.selectedBotfolio 
            } )
        }
    }

    getTradeLog(portfolio) { 
        if( portfolio.id  ) {
            var obj = { portfolio_id : portfolio.id }
            service.getAssetList( obj ).then(
                (res) => {
                    var act = [], sum = 0, _symbols= []
                    for (var i in res.list) {
                        if( res.list[i].unit !== 0 ) {
                            _symbols.push( res.list[i].symbol ) 
                        }
                    }
                    var _obj = {}
                    _obj[ portfolio.region ] = _symbols

                    if( _symbols.length > 0 ) {

                        Api.getprices(  _obj, "latest"  ).then(
                            (response) => {
                                console.log(response)
                                var prices = {}
                                for (var i in response.value) {
                                    prices[ response.value[i].symbol ] = response.value[i].close
                                }
                                for (var i in res.list) {
                                    if( res.list[i].unit !== 0 ) {
                                        res.list[i].action = "buy"
                                        res.list[i].portfolio = portfolio
                                        res.list[i].user = portfolio.user
                                        act.push(  res.list[i]  )
                                        //sum += (  res.list[i].board_lot *  res.list[i].unit * res.list[i].price  )
                                        sum += (  res.list[i].board_lot *  res.list[i].unit * prices[res.list[i].symbol]  )
                                    }
                                }
                                
                                //console.log( res.list )
            
                                this.setState({
                                    logs : act,
                                    sum : sum,
                                    price : prices,
                                })

                            }
                        ).catch(
                            (e)=> {
                                console.log(e)
                            }
                        )
                        
                    }




                }
            )
        }
    }

    getAseset(b) {
        var obj = {}
        obj.portfolio_id = b.id
        service.getAssetList( obj ).then(
            (res) => {
                this.props.run( res.list )
            }
        )
    }
  
    render() {
        return (
            <div className={['Trade-Log'].join(" ") }>
                <div className={['Control'].join(" ") }>
                    <div className={['Sum'].join(" ") }>
                        <div className={['Title'].join(" ") }> {  this.props.gi18n[this.props.language]['current_value'] } </div>
                        <div className={['Value'].join(" ") }> { "$" + this.state.sum.toLocaleString("en-US") } </div>
                    </div>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">{ this.props.gi18n[this.props.language]['forward_msg'] }</Tooltip>}>
                        <Button className={['Btn'].join(" ") } onClick={
                            (e) => {
                                this.getAseset( this.props.selectedBotfolio )
                                //console.log(  this.state.logs )
                                //console.log( this.props.selectedBotfolio )
                                //this.props.forwardtest()
                            }
                        }> 
                            <FontAwesomeIcon icon={faPlay} className={["Fa"].join(" ")} />
                            {  this.props.gi18n[this.props.language]["buysell"]   } 
                        </Button>
                    </OverlayTrigger>
                </div>
                
                <div className={['Header'].join(" ") }>
                    { this.props.gi18n[this.props.language]['botfolio_manage'] }
                </div>
                

                <div className={['Holder'].join(" ") }>
                    { (this.state.logs.length > 0 ) &&
                        this.state.logs.map( (v,k) => {
                            return(
                                <ActivitiesLog 
                                    key={k} 
                                    obj={v} 
                                    updateParentValue={this.updateValue} 
                                    language={this.props.language} 
                                    gi18n={this.props.gi18n} 
                                    isPortfolio={true} 
                                    sym_names={ this.props.sym_names }
                                    price={this.state.price}
                                     dark={false}/>
                            )
                        } )
                    }
                    { (this.state.logs.length === 0 ) && 
                        <span className={['Msg'].join(" ")} >  {  this.props.gi18n[this.props.language]['no_stock']  }  </span>
                    }
                </div>
            </div>
        )
    }
}


export default TradeLog;
