import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import service from '../public/service'
import gservice from '../Garlican/GarlicanLibrary/gservice';
//import gcolor from '../public/style.scss';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter ,faFunnelDollar, faSortAmountDown, faInfoCircle, faUser, faCartArrowDown, faThLarge, faThList, faChartArea, faCaretDown, faCaretUp, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import { Button, ButtonGroup, OverlayTrigger, Tooltip, Table, Image } from 'react-bootstrap'
import PortfolioList from "../Components/PortfolioList"
import PortfolioCard from "../Components/PortfolioCard"
import PortfolioPoint from "../Components/PortfolioPoint"

//import ActivitiesLog from "../Components/ActivitiesLog"
import Trader from '../Components/Trader2'

import Confirm from "../Components/Confirm"
import Picker from "../Components/Picker"

import Profile from "../User/Profile"

//import LazyLoad from 'react-lazyload';
/*
const pickeroptions = [
  { key : 'initial_capital' , value : 'initial' , range : [0, 500000], step : 10000 ,label : "$<value>+" , demostrator : 1 , type : 0  },
  { key : 'profit_rate' , value : 'profit_rate', range : [0, 100], step : 1, label : "<value>%+" , demostrator : 100 , type : 1 },
  { key : 'win_rate' , value : 'win_rate', range : [0, 100], step : 1, label : "<value>%+" , demostrator : 100 , type : 1 },
  { key : 'sharpe_ratio' , value : 'risk_rate', range : [0, 500], step : 1, label : "<value>%+" , demostrator : 100 , type : 1 },
  { key : 'turnover_rate' , value : 'quantity_rate', range : [0, 1000], step : 1, label : "<value>%+" , demostrator : 100 , type : 1 },
]
*/
const pickeroptions = [
  { key : 'initial_capital' , value : 'initial' , range : [0, 500000], step : 25000 , type : 0  },
  { key : 'profit_rate' , value : 'profit_rate', range : [0, 100], step : 20 , type : 1 },
  { key : 'win_rate' , value : 'win_rate', range : [0, 100], step : 20,  type : 1 },
  { key : 'sharpe_ratio' , value : 'risk_rate', range : [0, 100], step : 20, type : 1 },
  { key : 'turnover_rate' , value : 'quantity_rate', range : [0, 100], step : 20, type : 1 },
]

const scores = [
  { key :  "profit_rate_rank", value : "profit_rate" },
  { key :  "sharpe_ratio_rank", value : "risk_rate" },
  { key :  "win_rate_rank", value : "win_rate" },
  {key :  "turnover_rate_rank", value : "quantity_rate" },
]

const selections = [
  { key : 'all' , value : 'all'},
  { key : 'public' , value : 'ispublic'},
  { key : 'private' , value : 'isprivate'},
  //{ key : 'subscribed' , value : 'isPurchase'},
  { key : 'onhold' , value : 't_onhold'},
]

const view = [
  { key : 'scatter', value : 'scatter', icon :faChartArea},
  { key : 'grid', value : 'grid', icon :faThLarge },
  { key : 'list', value : 'list', icon :faThList},
]

const Legend_win_rate = [
  25,
  50,
  75,
  100
]

var TRIAL = 0
const MAX_TRIAL = 3

class Overview extends Component {
    constructor(props) {
        super(props)
        var filter = [
          {key : 'all' , value : 'all', 'type' : 'all'},
          {key : 'bookmark' , value : 'bookmarked' , 'type' : 'bookmarked' },
        ]

        var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		    var mode = storage.getItem("garlican@mode" )

        this.state = { 
          page : 0,
          card : [],
          temp_card : [],
          activities : [],
          a_page : 0,
          selectedUser : null,
          selectedType : filter[0].value,
          filter : filter,
          isMe : false,
          selectedObj : { 'order_by' : '-profit_rate' },
          isMore : false,
          myportType : selections[0].key,

          msg : null,
          showConfirm : false,
          secondary_btn_text : "",
          callback : null,
          params : [],
          replacement_func : null,

          showFilter : false,
          filtertype : 'profit',

          initial_capital_range : {},
          profit_rate_range : {},
          win_rate_range : {},
          sharpe_ratio_range : {},
          turnover_rate_range : {},

          profit_rate_rank_range : {},
          win_rate_rank_range : {},
          sharpe_ratio_rank_range : {},
          turnover_rate_rank_range : {},

          rank_message : [],

          isUser : false,
          user : {},
          hasRead : false,
          type : "",

          purchased : {},

          mode : mode?mode:'grid',
          compare : null,
          showView : mode?false:true,

          overview_updates : [],

          hasLogin : props.isLogin,

          showContent : this.props.showAutomate?true:false,
          upgrade : this.props.upgrade,
          trade : this.props.trade,
          //total_portfolio : 0,
          
        };
        this.updateValue = this.updateValue.bind(this)
        this.deletePortfolio = this.deletePortfolio.bind(this)
        this.updateFilter = this.updateFilter.bind(this)
        this.getPortfoilioList = this.getPortfoilioList.bind(this)
        this.getPortfolio = this.getPortfolio.bind(this)
        this.selectPublicType = this.selectPublicType.bind(this)
        this.showDeletePorfolio = this.showDeletePorfolio.bind(this)
        this.close = this.close.bind(this)
        this.getTrade = this.getTrade.bind(this)
        this.searchPortfolios = this.searchPortfolios.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.reset = this.reset.bind(this)
        this.getRankInfo = this.getRankInfo.bind(this)

        this.updatePurchase = this.updatePurchase.bind(this)
        this.pickMode = this.pickMode.bind(this)
    }

    componentDidMount() {
      document.title = this.props.gi18n[this.props.language]['robo_advisor_title']
      window.document.overview = this
      this.updatePurchase( this.props )
      this.getPortfolio(this.props)

      if( this.state.upgrade && this.props.isLogin ) {
        this.props.updateValue({
          isPayment : true
        })
        this.setState({
          upgrade : false
        })
      }

      if( this.state.trade && this.props.isLogin ) {
        this.props.updateValue({
          showTrader : true
        })
        this.setState({
          trade : false
        })
      }


    }

    componentWillUnmount() {
    }

    shouldComponentUpdate(props, state){
      return true
    }

    componentDidUpdate(props, state) {
      //console.log("REFRESH")
      //if( (props.showGarlican === false) && (props.showPortfolio === false) && (props.showUser === false) && ( props.showTrader===false) ) {
        //this.getPortfolio(props) 
      //}
      if( props.hasLogin !== state.hasLogin ) {
        this.setState( {
          hasLogin : props.hasLogin
        })
        this.getPortfolio(props) 
      }

      if( this.state.upgrade && props.isLogin ) {
        //console.log("HERE")
        this.props.updateValue({
          isPayment : true
        })
        this.setState({
          upgrade : false
        })
      }

      if( this.state.trade && props.isLogin ) {
        this.props.updateValue({
          showTrader : true
        })
        this.setState({
          trade : false
        })
      }

    }

    getRankInfo() {
      var message = []
      var arr = []
      var pscore = 100
      for (var s in service.score_scale ) {
        arr = []
        var msg = ""
        msg = (pscore) + " - " + (service.score_scale[s].score+1)
        pscore = service.score_scale[s].score
        arr.push( msg )
        arr.push( service.score_scale[s].rank )
        arr.push( service.score_scale[s].rate?service.score_scale[s].rate:"" )
        message.push( arr )
      }
      return(
        <Table 
          className={["g-table"].join(" ")} 
          size="sm"
          striped bordered hover variant="dark" >
          <thead>
            <tr> 
              <th> {this.props.gi18n[this.props.language]['score']} </th>
              <th> {this.props.gi18n[this.props.language]['rank']} </th>
              <th> {this.props.gi18n[this.props.language]['rank']} </th>
            </tr>
          </thead>
          <tbody>
            {
              message.map( (v,k) => {
                return(
                  <tr key={k}>
                    <th> {v[0]} </th>
                    <th> {v[1]} </th>
                    <th> {v[2]} </th>
                  </tr>
                )
              } )
            }
          </tbody>
        </Table>
      )

    }

    getTrade(obj) {
      //obj.creation_time = "-creation_time"
      service.getRecentTradeList(obj).then(
        (res) => {
          //console.log(res)
          if( "list" in res ) {

            var arr = res.list.filter( (v,k) => {
              return v.unit !== 0
            }  )

            arr = arr.map( (v,k) => {
              v.profit = v.profit * v.board_lot * v.unit
              return v
            }  )

            this.setState( {
              activities : arr,
              a_page : res.page
            })
          }

        }
      )
    }

    getPortfolio(props) {
      props = props?props:this.props
      this.updateFilter( props )
      if( props.match && (Object.keys(props.match.params).length > 0) && !this.state.hasRead ) {
        var user_id =  gservice.decodeObject(   props.match.params.user_id   ).replace("user", "")
        this.setState({
          hasRead : true
        })
        service.getUserProfile( user_id ).then(
          (res) => {
            this.setState({
              user : res
            })
          }
        )
        var isShow = false
        if( props.match.params.portfolio_id  ) {
          isShow = true
          var portfolio_id =  gservice.decodeObject(   props.match.params.portfolio_id   ).replace("portfolio", "")
          service.getPortfoilioById(  portfolio_id ).then(
            (res) => {
              this.props.updateValue({ selectedPortfolio : res, showPortfolio :true })   
            }
          )
        }
        var obj = { user_id : user_id }
        this.getPortfoilioList( obj, false, isShow  )
        this.setState({
          isUser : true,
          selectedObj : obj,
        })
        this.getTrade( { user_id : user_id } )
      } else if( window.location.pathname === "/forget_password" ) {
          var obj = JSON.parse(  JSON.stringify( this.state.selectedObj  ) )
          this.getPortfoilioList( obj, true )
          var key = window.location.search?window.location.search.replace("?key=", ""):""
          this.props.updateValue( { showLogin : true, retrieve_key : key  }  )
          this.getTrade( { action : "sold"} )
      } else if( window.location.pathname === "/me")  {
          if(props.isLogin) {
            this.reset()
            this.setState({ selectedType : 'myportfolio' , isMe : true, type : 'me' })
            this.getPortfoilioList(  {  user_id : 'me'  }  )
            this.getTrade( { user_id : service.user.user_id } )
          }
      } else if( window.location.pathname === "/bookmark")  {
        if(props.isLogin) {
          this.reset()
          this.setState({ selectedType : 'bookmarked' , isMe : false, type : 'bookmarked' })
          this.getPortfoilioList(  {  bookmarked : 'true' }  )
          this.getTrade( {  bookmarked : 'true' }  )
        }
      } else if( window.location.pathname === "/purchased")  {
        if(props.isLogin) {
          this.reset()
          this.setState({ selectedType : 'purchased' , isMe : false, type : 'purchased' })
          this.getPortfoilioList(  {  user_id : 'me' , with_subscription : true }  )
        }
      } else {
        var obj = JSON.parse(  JSON.stringify( this.state.selectedObj  ) )
        this.getPortfoilioList( obj, true )
        this.getTrade( { action : "sell"} )
      }
    }

    updateFilter(props) {
      var filter = []
      if( props.isLogin) {
        filter = [
          {key : 'all' , value : 'all', 'type' : 'all'},
          {key : 'myportfolio' , value : 'myportfolio', 'type' : 'user_id' },
          {key : 'bookmark' , value : 'bookmarked' , 'type' : 'bookmarked' },
        ]
      } else {
        filter = [
          {key : 'all' , value : 'all', 'type' : 'all'},
          {key : 'bookmark' , value : 'bookmarked' , 'type' : 'bookmarked' },
        ]
      }
      this.setState({
        filter : filter
      })
    }

    getPortfoilioList(obj, append, isShow=false, isPurchase=false){
      var _this = this
      service.getPortfoilioList(obj).then(
        (res) => {  
            TRIAL = 1   
            if('list' in res) {
              if( append ) {
                var list = this.state.card
                list = list.concat(  res['list'])
                if(list.length > 0 ) {
                  list = Array.from(new Set(list.map(a => a.id)))
                    .map(id => {
                      return list.find(a => a.id === id)
                  })
                }
              } else {
                var list = res['list']
              }
              var isMore = (  (list.length % 30 ) === 0  )
              if(  res.list.length === 0 ) {
                isMore = false
              }
              var _o = {}
              if( isPurchase ) {
                var _l = {}
                for (var i in list) {
                  try {
                    _l[ list[i].subscription.portfolio_id ] = list[i]
                    
                  } catch(e){}
                }
                _o.purchased = _l
                _this.setState( _o )
              } else {
                var tempname = gservice.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
                list = list.filter( (v,k) => {
                  v.hasPurchased = (v.id in _this.state.purchased)
                  return v.name !== tempname
                } )
                _o = { card : list,  page : parseInt(res['page'], 10), isMore : isMore, selectedObj : obj  }
                _this.setState( _o )
                _this.selectPublicType( this.state.myportType )
                _this.props.updateValue({ showPortfolio : isShow })
              }
            } else {
              _this.props.updateValue({ isLogin : false, showLoading : false }) 
            }
        }
      ).catch( (e) => {
        if( MAX_TRIAL > TRIAL ) {
          setTimeout( () => {
            _this.getPortfoilioList()
            _this.props.updateValue({ isLogin : false, isLoading : false })
            TRIAL += 1 
          }, 1000 )
        }
      } )
    }

    updatePurchase(props) {
      props = props?props:this.props
      //console.log("HERE")
      this.getPortfoilioList(  {  user_id : 'me' , with_subscription : true }, false, false, true  )
    }

    updateValue(obj) {
      obj.overview_updates = Object.keys( obj )
      this.setState(obj)
    }

    showDeletePorfolio(obj) {
      this.setState({
        msg : this.props.gi18n[this.props.language]["confirm_remove_portfolio"] ,
        showConfirm : true,
        secondary_btn_text : this.props.gi18n[this.props.language]["remove"] ,
        callback : 'deletePortfolio',
        params : [obj],
        replacement_func : null
      })
    }

    deletePortfolio(obj) {
      this.props.updateValue({
        showLoading : true
      })
      service.deletePortfolio( obj.id ).then(
        (res) => {
          this.close()
          this.getPortfoilioList( {user_id : service.user.user_id} )
          //this.selectPublicType( this.state.myportType )
          this.props.updateValue({
            showLoading : false
          })
        }
      )
    }

    selectPublicType(type) {
      var newArray = JSON.parse(  JSON.stringify( this.state.card ) )
      if( type!=='all' ) {
        if( type !== "onhold" ) {
          newArray = newArray.filter(
            (x) => {
              return (x.access_mode === type)
            }
          ) 
        } else {
          newArray = newArray.filter(
            (x) => {
              return (x.id in this.props.onhold)
            }
          ) 
        }
      } 
      //console.log( type )
      this.setState( { myportType : type, temp_card : newArray } ) 
    }

    close() {
      this.setState({
        msg : null,
        showConfirm : false,
        secondary_btn_text : "",
        callback : null,
        params : [],
        replacement_func : null
      })
    }

    searchPortfolios(_obj) {
      var keys = [
          "region",
          "initial_capital", 
          "profit_rate", "win_rate", "sharpe_ratio","turnover_rate",
          "profit_rate_rank", "win_rate_rank", "sharpe_ratio_rank","turnover_rate_rank"
      ]
      var obj = this.state.selectedObj?JSON.parse( JSON.stringify(this.state.selectedObj) ):{}

      if( _obj ) {
        for (var i in keys) {
          if( keys[i] !== "region" ) {
            if(  (keys[i] + "_range") in _obj ) {
              if(  ("min" in _obj[keys[i] + "_range"]) || ("max" in _obj[keys[i] + "_range"]) ) {
                //console.log(  _obj[keys[i] + "_range"  ]   )
                for (var j in pickeroptions) {
                  if( pickeroptions[j].key  === keys[i].replace('_rank', "")  ) {

                    if( "min" in _obj[keys[i] + "_range"] ) {
                      obj[ keys[i] + "_min" ] = _obj[keys[i] + "_range"].min
                    }

                    if ( pickeroptions[j].range[1] > _obj[keys[i] + "_range"].max ) {
                      obj[ keys[i] + "_max" ] = (_obj[keys[i] + "_range"].max)
                    } else {
                      if (   keys[i] + "_max" in obj ) {
                        delete obj[ keys[i] + "_max" ]
                      }
                    }


                    

                    break
                  } 
                  /*else {
                    if (   keys[i] + "_max" in obj ) {
                      delete obj[ keys[i] + "_max" ]
                    }
                    if (   keys[i] + "_min" in obj ) {
                      delete obj[ keys[i] + "_min" ]
                    }
                  }*/
                }
              }
            } else {
                delete obj[(keys[i] + "_max" )]
                delete obj[(keys[i] + "_min" )]
            }
          } else {
            if( "region" in _obj ) {
              obj['region'] = _obj["region"]
            }
          }
        }
      }
      obj.page = 0
      //console.log( obj )

      this.setState({ selectedObj : obj })
      this.getPortfoilioList( obj )

    }

    resetFilter() {
      var obj = this.reset()
      if(obj) {
        this.getPortfoilioList( obj )
      }
    }

    reset() {
      var obj = JSON.parse( JSON.stringify(this.state.selectedObj) )
      var keys = [
        'initial_capital_min', 'initial_capital_max', 
        'win_rate_min', 'win_rate_max', 
        'sharpe_ratio_min', 'sharpe_ratio_max', 
        'profit_rate_min', 'profit_rate_max', 
        'turnover_rate_min' , 'turnover_rate_max',
        'win_rate_rank_min', 'win_rate_rank_max', 
        'sharpe_ratio_rank_min', 'sharpe_ratio_rank_max', 
        'profit_rate_rank_min', 'profit_rate_rank_max', 
        'turnover_rate_rank_min' , 'turnover_rate_rank_max'
      ]
      if( obj ) {
        for (var i in keys) {
          if( keys[i] in obj ) {
            delete obj[ keys[i] ]
          }
        }
        this.setState({
          page : 0,
          selectedObj : obj,
          initial_capital_range : {},
          profit_rate_range : {},
          win_rate_range : {},
          sharpe_ratio_range : {},
          turnover_rate_range : {},

          profit_rate_rank_range : {},
          win_rate_rank_range : {},
          sharpe_ratio_rank_range : {},
          turnover_rate_rank_range : {},
        })
      }
      return obj
    }

    pickMode(key) {
      var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		  storage.setItem("garlican@mode", key )
      this.setState({
        mode : key
      })
    }

    render() {
        return (
          <div className={ ['Overview-Holder'].join( " " ) } style={
            {
              transform : "translateX( " + (this.state.showFilter?((document.getElementById("right")?document.getElementById("right").offsetWidth:320) + "px"):"0px") + " )"
            }
          } >

            <Confirm msg={this.state.msg} show={this.state.showConfirm} secondary_btn_text={this.state.secondary_btn_text} scope={'overview'} callback={this.state.callback} params={this.state.params} language={this.props.language} gi18n={this.props.gi18n} replacement_func={this.state.replacement_func} close={this.close}  />
            <Picker 
              show={this.state.showFilter} 
              regions={this.props.regions}
              pickeroptions={pickeroptions} 
              language={this.props.language} 
              gi18n={this.props.gi18n} 
              updateValue={this.updateValue} 
              initial_capital_range={this.state.initial_capital_range} 
              profit_rate_range={this.state.profit_rate_range} 
              win_rate_range={this.state.win_rate_range} 
              turnover_rate_range={this.state.turnover_rate_range} 
              sharpe_ratio_range={this.state.sharpe_ratio_range} 
              profit_rate_rank_range={this.state.profit_rate_rank_range} 
              win_rate_rank_range={this.state.win_rate_rank_range} 
              turnover_rate_rank_range={this.state.turnover_rate_rank_range} 
              sharpe_ratio_rank_range={this.state.sharpe_ratio_rank_range} 
              searchPortfolios={this.searchPortfolios} 
              resetFilter={this.resetFilter} 
              logosrc={this.props.logosrc} 
              getRankInfo={this.getRankInfo} />

            <div className={ ['Overview-Right', this.state.showContent?"Show":""].join( " " ) } id="right">
                <div className={ ["Show-Btn"].join( " " ) } 
                 onClick={
                  (e) => {
                    this.setState({
                      showContent : !this.state.showContent
                    })
                  }
                 }
                >
                  <FontAwesomeIcon icon={  !this.state.showContent?faCaretLeft:faCaretRight} className={ ['Fa'].join( " " ) } />
                </div>
                <div className={ ["Show-Content"].join( " " ) } >
                  <Profile {...this.props} updateParentValue={this.updateValue}  />
                </div>
            </div>
            
            <div className={ ['Overview-Left'].join( " " ) } id="left">
                <div className={ ['Overview-Left-Top-Panel'].join( " " ) } >
                      { !this.state.isUser &&  (this.state.selectedType === 'bookmarked') && !this.props.showTrader &&
                        <div className={ ['Overview-Left-Top-Title'].join( " " ) } > 
                          <div className={['Pagename'].join(" ")}>{ this.props.gi18n[this.props.language]['bookmark'] } </div>
                          <div className={['FHolder'].join(" ")}> 
                            <FontAwesomeIcon  
                                icon={ this.state.mode==='grid'?faThLarge:( (this.state.mode==='list')?faThList:faChartArea) } 
                                onClick={
                                  (e) => {
                                    var obj = {}
                                    /*obj.mode = (this.state.mode==='grid')?'list':'grid'
                                    if( obj.mode==='list' ) {
                                      obj.compare = null
                                      obj.initial_capital_range = {}
                                      obj.win_rate_range = {}
                                      obj.sharpe_ratio_range = {}
                                      obj.turnover_rate_range = {}
                                      obj.profit_rate_range = {}
                                    }*/
                                    obj.showView = !this.state.showView
                                    this.setState( obj)
                                  }
                                }
                                className={['Overview-Left-Top-Fa'].join(' ')}/>
                            <FontAwesomeIcon  icon={ this.state.showView?faCaretUp:faCaretDown } className={["SFa"].join(" ")} />
                          </div>
                        </div>
                      }
                      { !this.state.isUser &&  (this.state.selectedType === 'purchased') && !this.props.showTrader &&
                        <div className={ ['Overview-Left-Top-Title'].join( " " ) } > 
                            <div className={ ['Pagename'].join( " " ) }> { this.props.gi18n[this.props.language]['subscribed'] }  </div>
                              <div className={ ['FHolder'].join( " " ) }>
                                <FontAwesomeIcon  
                                  icon={ this.state.mode==='grid'?faThLarge:( (this.state.mode==='list')?faThList:faChartArea) } 
                                  onClick={
                                    (e) => {
                                      var obj = {}
                                      /*
                                      obj.mode = (this.state.mode==='grid')?'list':'grid'
                                      if( obj.mode==='list' ) {
                                        obj.compare = null
                                        obj.initial_capital_range = {}
                                        obj.win_rate_range = {}
                                        obj.sharpe_ratio_range = {}
                                        obj.turnover_rate_range = {}
                                        obj.profit_rate_range = {}
                                      }*/
                                      obj.showView = !this.state.showView
                                      this.setState( obj)
                                    }
                                  }
                                  className={['Overview-Left-Top-Fa'].join(' ')}/>
                                <FontAwesomeIcon  icon={ this.state.showView?faCaretUp:faCaretDown } className={["SFa"].join(" ")} />
                            </div>
                        </div>
                      }
                      { !this.state.isUser &&  (this.state.selectedType === 'all') && !this.props.showTrader &&
                        <div className={ ['Overview-Left-Top-Title'].join( " " ) } >

                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip id="button-tooltip-2">{ this.props.gi18n[this.props.language]['filter_favorite_portfolio'] }</Tooltip>}
                            >
                              <div className={['FHolder', this.state.showFilter?"selected":""].join(" ")}>
                                <FontAwesomeIcon 
                                  onClick={
                                    (e) => {
                                      this.setState({
                                        showFilter : !this.state.showFilter,
                                      })
                                    }
                                  } 
                                  icon={  (("min" in this.state.initial_capital_range) || ("min" in this.state.profit_rate_range) ||  ("min" in this.state.win_rate_range) || ("min" in this.state.sharpe_ratio_range) || ("min" in this.state.turnover_rate_range ) )?faFunnelDollar:faFilter } 
                                  className={['Overview-Left-Top-Fa'].join(' ')}/>
                                <FontAwesomeIcon  icon={ this.state.showFilter?faCaretRight:faCaretLeft } className={["SFa"].join(" ")} />
                              </div>
                            </OverlayTrigger>

                          <div className={["FHolder"].join(" ")}>
                            <FontAwesomeIcon  
                              icon={faSortAmountDown} 
                              className={['Overview-Left-Top-Fa'].join(' ')}
                              onClick={
                                (e) => {
                                  this.setState({
                                    filtertype : (this.state.filtertype==="date")?"profit":"date"
                                  })                           
                                  var _this = this
                                  setTimeout( (e) => {
                                    var obj = {}
                                    var str = ( (_this.state.filtertype==="date")?"-modification_time":"-profit_rate" )
                                    obj['order_by'] = str
                                    _this.setState({ selectedObj : obj, page : 0 })
                                    _this.getPortfoilioList(  obj  )
                                  } )
                                }
                              }
                            />
                            <div className={["Text"].join(" ")}>{ this.props.gi18n[this.props.language][ "sort_"+this.state.filtertype] }</div>
                          </div>
                            <div className={['FHolder'].join(" ")}> 
                              <FontAwesomeIcon  
                                icon={ this.state.mode==='grid'?faThLarge:( (this.state.mode==='list')?faThList:faChartArea) } 
                                onClick={
                                  (e) => {
                                    var obj = {}
                                    /*obj.mode = (this.state.mode==='grid')?'list':'grid'
                                    if( obj.mode==='list' ) {
                                      obj.compare = null
                                      obj.initial_capital_range = {}
                                      obj.win_rate_range = {}
                                      obj.sharpe_ratio_range = {}
                                      obj.turnover_rate_range = {}
                                      obj.profit_rate_range = {}
                                    }*/
                                    obj.showView = !this.state.showView
                                    this.setState( obj)
                                  }
                                }
                                className={['Overview-Left-Top-Fa'].join(' ')}/>
                              <FontAwesomeIcon  icon={ this.state.showView?faCaretUp:faCaretDown } className={["SFa"].join(" ")} />
                            </div>
                          <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 250 }}
                                overlay={<Tooltip id="button-tooltip-2" className={["Over-Table"].join(" ")}>{ this.getRankInfo() }</Tooltip>}
                              >
                                  <FontAwesomeIcon  
                                    icon={ faInfoCircle  } 
                                    className={['Overview-Left-Top-Fa'].join(' ')}/>

                            </OverlayTrigger>

                          
                        </div>
                      }
                      { !this.state.isUser && (this.state.selectedType === 'myportfolio') && !this.props.showTrader &&
                        [
                          <ButtonGroup key={0} aria-label="" className={ ['Overview-Left-Top-Button-Gp'].join( " " ) }>
                            {
                              selections.map( (v, k) => {
                                return(
                                  <Button 
                                    onClick={ (e)=>{
                                      this.selectPublicType( v.key  )
                                    }}
                                    className={[ (v.key!==this.state.myportType)?"noselect":""].join(' ') } variant="secondary" key={k}  > 
                                    { this.props.gi18n[this.props.language][v.value] } 
                                  </Button>
                                )
                              } )
                            }
                          </ButtonGroup>,
                          <div key={1} className={ ['Overview-Left-Top-Title'].join( " " ) } >
                            <div className={['FHolder'].join(" ")}> 
                              <FontAwesomeIcon  
                                icon={ this.state.mode==='grid'?faThLarge:( (this.state.mode==='list')?faThList:faChartArea) } 
                                onClick={
                                  (e) => {
                                    var obj = {}
                                    //obj.mode = (this.state.mode==='grid')?'list':'grid'
                                    obj.showView = !this.state.showView
                                    /*
                                    if( obj.mode==='list' ) {
                                      obj.compare = null
                                      obj.initial_capital_range = {}
                                      obj.win_rate_range = {}
                                      obj.sharpe_ratio_range = {}
                                      obj.turnover_rate_range = {}
                                      obj.profit_rate_range = {}
                                    }
                                    */
                                    //console.log(obj)
                                    this.setState( obj)
                                  }
                                }
                                className={['Overview-Left-Top-Fa'].join(' ')}/>
                              <FontAwesomeIcon  icon={ this.state.showView?faCaretUp:faCaretDown } className={["SFa"].join(" ")} />
                            </div>
                          </div>
                        ]
                      }
                      { this.state.isUser && !this.props.showTrader &&
                        <div className={ ['Overview-Left-Top-Title'].join( " " ) } >
                          { !('avator' in this.state.user) && 
                            <FontAwesomeIcon  icon={faUser} className={['fa'].join(' ')}/>  
                          }
                          { ('avator' in this.state.user) && 
                              <Image className={['fa'].join(' ')} src={ this.state.user.avatar  } />
                          }
                          { ('first_name' in this.state.user) && 
                            <span className={ ['name'].join( " " ) } >{ 
                              this.props.gi18n[ this.props.language ][ 'user_title' ].replace("<user>", this.state.user.first_name )
                            }</span> 
                          }
                        </div>
                      }

                      { this.props.isLogin &&
                        <Button className={['Cart' ].join(' ')} 
                            onClick={
                                (e) => {
                                  var obj = {}
                                  obj['showTrader'] = !this.props.showTrader
                                  if( this.props.showTrader ) {
                                    obj['selectedPortfolio'] = null
                                    obj['showPortfolio'] = false
                                  }

                                  this.props.updateValue(obj)
                                }
                        }>
                            <FontAwesomeIcon 
                              icon={ faCartArrowDown}  
                              className={['Fa' ].join(' ')} >
                            </FontAwesomeIcon>
                            {  this.props.gi18n[this.props.language][ "trader" ]  }
                        </Button>
                      }

                      { 
                        this.state.compare &&
                        <div className={ ['Compare-Wrapper'] } 
                          onClick={
                            (e) => {
                              var element = document.getElementById(  this.state.compare.id  );
                              element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
                            }
                          }
                        >
                          {  this.props.gi18n[this.props.language]['compare_with'].replace( "<botfolio>",this.state.compare.name) }
                        </div>
                      }

                </div>
                
                <div className={['Picker', this.state.showView?"Appear":""].join(" ")}>
                  {
                    view.map(
                      (v,k) => {
                        return (
                          <div key={k} className={['Holder'].join(" ")} onClick={
                            (e) => {
                              this.pickMode(v.key)
                            }
                          }>
                            <FontAwesomeIcon icon={ v.icon } className={["Fa"].join(" ")} />
                            <div className={ ['Name'].join(" ") }> {  this.props.gi18n[this.props.language][ v.key ] } </div>
                          </div>
                        )
                      }
                    )
                  }
                </div>
                
                {
                  this.state.mode==="scatter" && <div className={ ['Statement'].join(" ") }>
                    { this.props.gi18n[ this.props.language ]['t_pick_botfolio'] }
                  </div>
                }

                {
                  (this.state.mode==="scatter" ) && 
                  <div className={ ['Legend'].join(" ") }>
                    <div className={ ['Holder'].join(" ") }>
                      <div className={ ['Title'].join(" ") }> { this.props.gi18n[ this.props.language ]['win_rate'] } </div>
                      <div className={ ['Low'].join(" ") }> { this.props.gi18n[this.props.language]["t_low_short"] }  </div>
                      {
                        Legend_win_rate.map(  (v,k) => {
                          return(
                            <div className={ ['Win'].join(" ") } key={k} style={
                              {
                                width : (( 30 * v/100 -4 ) + "px") ,
                                height : (( 30 * v/100 -4 ) + "px")
                              }
                            }> 
                            </div>
                          )
                        } )
                      }
                      <div className={ ['High'].join(" ") }> { this.props.gi18n[this.props.language]["t_high_short"] }  </div>
                    </div>
                    <div className={ ['Holder'].join(" ") }>
                      <div className={ ['Title'].join(" ") }> { this.props.gi18n[ this.props.language ]['turnover_rate'] } </div>
                      <div className={ ['Low'].join(" ") }> { this.props.gi18n[this.props.language]["t_low_short"] } </div>
                      <div className={ ['Turnover'].join(" ") }> </div>
                      <div className={ ['High'].join(" ") }> { this.props.gi18n[this.props.language]["t_high_short"] } </div>
                    </div>
                  </div>
                }
                

                
                <div className={ [  
                    (this.state.mode==="grid"?'Overview-Left-Content-1':""),
                    (this.state.mode==="list"?'Overview-Left-Content':""),
                    (this.state.mode==="scatter"?'Diagram':""),
                  ].join( " " ) } id={"o_container"} >
                  { this.state.mode==="haha" && 
                    <div className={["lazyload-wrapper"].join(" ")}  > 
                      <div className={['Portfolio-List', "title"].join(" ")} >
                        <div className={['box', 'profit' ].join(" ")} >
                          { this.props.gi18n[this.props.language][ "t_profit_p" ] }
                        </div>
                        <div className={['box', 'initial' ].join(" ")} >
                          { this.props.gi18n[this.props.language][ "initial" ] }
                        </div>
                        <div className={['box', 'name' ].join(" ")} >
                          { this.props.gi18n[this.props.language][ "group_name" ] } 
                        </div>
                        {
                          scores.map( (v,k) => {
                            var rank = "--"
                            return(
                              <div className={['box', 'score' ].join(" ")} key={k} 
                                style={ { backgroundColor : rank.color } }>
                                { this.props.gi18n[this.props.language][ v.value ] } 
                              </div>
                            )
                          } )
                        }
                        <div className={['box', 'onhold' ].join(" ")} >
                          { this.props.gi18n[this.props.language][  "t_onhold" ] }
                        </div>
                      </div>
                    </div>
                  }
                  { ( this.state.mode==="list" || this.state.mode==="grid" ) && (this.state.temp_card.length > 0) &&
                      this.state.temp_card.map( (v,k) => {
                        const Elem = (this.state.mode==='grid')?PortfolioCard:PortfolioList                        
                        var _id = this.props.selectedPortfolio?this.props.selectedPortfolio.id:null
                        return(
                          <div className={["lazyload-wrapper", ( (this.state.mode==='grid') && (v.id === _id ) )?"selected":""  ].join(" ")} key={k} id={v.id} >
                            <Elem obj={v}  updateValue={this.updateValue} language={this.props.language} updateParentValue={this.props.updateValue} selectedType={this.state.selectedType} deletePortfolio={this.deletePortfolio} isMe={this.state.isMe} gi18n={this.props.gi18n} showDeletePorfolio={this.showDeletePorfolio} colors={this.props.colors} scores={scores} selectedPortfolio={this.props.selectedPortfolio} onhold={this.props.onhold} sym_names={this.props.sym_names} purchased={this.state.purchased } compare={this.state.compare} pickeroptions={pickeroptions} overview_updates={this.state.overview_updates} />
                          </div>
                        )
                      })
                  }


                  { this.state.mode==="scatter" && (this.state.temp_card.length > 0) &&
                    <div className={ ['Wrapper'].join( " " ) }>
                      <div className={['Risk'].join(' ')}> { this.props.gi18n[this.props.language]["risk_rate"] } </div>
                      <div className={['Profit'].join(' ')}> { this.props.gi18n[this.props.language]["profit_rate"] } </div>
                      <div className={['Horizontal'].join(' ')}></div>
                      <div className={['Vertical'].join(' ')}></div>
                      <div className={['High'].join(' ')}> { this.props.gi18n[this.props.language]["t_high"] }  </div>
                      <div className={['Low'].join(' ')}>{ this.props.gi18n[this.props.language]["t_low"] } </div>
                        {
                          this.state.temp_card.map( (v,k) => {
                            const Elem = PortfolioPoint                       
                            var _id = this.props.selectedPortfolio?this.props.selectedPortfolio.id:null
                            return(
                              <div className={["lazyload-wrapper", ( (this.state.mode==='grid') && (v.id === _id ) )?"selected":""  ].join(" ")} key={k} id={v.id} >
                                <Elem obj={v}  updateValue={this.updateValue} language={this.props.language} updateParentValue={this.props.updateValue} selectedType={this.state.selectedType} deletePortfolio={this.deletePortfolio} isMe={this.state.isMe} gi18n={this.props.gi18n} showDeletePorfolio={this.showDeletePorfolio} colors={this.props.colors} scores={scores} selectedPortfolio={this.props.selectedPortfolio} onhold={this.props.onhold} sym_names={this.props.sym_names} purchased={this.state.purchased } compare={this.state.compare} pickeroptions={pickeroptions} overview_updates={this.state.overview_updates}/>
                              </div>
                            )
                          })
                        }
                      </div>
                    }

                  {
                    (this.state.temp_card.length === 0) &&
                    <div> { this.props.gi18n[this.props.language]["no_botfolio"] } </div>
                  }

                </div>
                

                { this.state.isMore && 
                      <Button 
                        className={ ['Overview-Left-More'].join(" ") } 
                        onClick={ (e)=>{
                          var _page = parseInt(this.state.page, 10)
                          var selectedObj = this.state.selectedObj 
                          if(!selectedObj) {
                            selectedObj = {}
                          }
                          selectedObj['page'] = (_page + 1) 
                          this.setState({ selectedObj : selectedObj })
                          this.getPortfoilioList( selectedObj, true )     
                        } }> 
                        {  this.props.gi18n[ this.props.language ]['more']  } 
                      </Button>
                }
                
            </div>

            <div 
              className={ ['Overview-Drawer', this.props.showTrader?"Appear":""].join( " " ) }
              style={
                {
                  
                  width : this.props.showTrader?( document.getElementById("left")?(document.getElementById("left").offsetWidth + 10):300 ):0
                }
              }
            >
              <Trader {...this.props} ref={this.props.trader_ref} showTrader={ this.props.showTrader  } host={'client'} overview_updates={this.state.overview_updates}/>
            </div>

          </div>
        )
    }
}


export default Overview;




/*
<div className={ ['Overview-Right'].join( " " ) }>
                {
                  <div className={ ['Overview-Right-Top-Panel'].join( " " ) } >
                      <div className={ ['Overview-Right-Top-Title'].join( " " ) } > { this.props.gi18n[this.props.language]['portfolio_activities'] } </div>
                  </div>
                }
                { this.props.isLogin &&
                  <div className={ ['Overview-Right-Content'].join( " " ) } >
                      { (this.state.activities.length > 0 ) &&
                        this.state.activities.map(  (v,k) => {
                          return(
                            <ActivitiesLog key={k} obj={v} updateValue={this.updateValue} language={this.props.language} updateParentValue={this.props.updateValue} enableClick={true} gi18n={this.props.gi18n} sym_names={ this.props.sym_names } />
                          )
                        }  )
                      }
                      { (this.state.activities.length == 0 ) &&
                        <div> {"No recent activities."} </div>
                      }
                  </div>
                }
                { !this.props.isLogin &&
                  <Button onClick={
                    (e)=> {
                      this.props.updateValue({
                        showLogin : true
                      })
                    }
                  }> 
                    {  this.props.gi18n[this.props.language]['login']  } 
                  </Button>
                }
              </div>
*/