import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
//import i18n from '../public/i18n'
import logoB from '../public/img/garlican_blue_logo.png'
import { Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartArrowDown  } from '@fortawesome/free-solid-svg-icons'


import { Link } from 'react-router-dom'

const links = [
  {url : '/privacy', name : 'privacy'},
  {url : '/terms', name : 'terms'},
  //{url : '/support', name : 'support'}
]

class Footer extends Component {
    
  constructor(props) {
        super(props)
        this.state = { 
          
        };
    }

    componentDidMount() {
      

    }

    componentWillUnmount() {
      
    }

    render() {
        return (
          <div className={ ['Footer', (this.props.frame==="dark")?"dark":""  ].join( " " ) } >
            <div className={ ['Footer-Frame'].join( " " ) }  >

            { this.props.isLogin &&
                    <Button 
                      style={ {
                        display : "none"
                      } }
                      className={['Footer-Trader' , (this.props.frame==="dark")?"dark":""].join(" ")} 
                      onClick={
                        (e)=> {
                          //console.log( this.props.showTrader  )
                          if( this.props.isLogin ) {
                            if(!this.props.child) {
                              this.props.updateValue({
                                showTrader : !this.props.showTrader,
                                //forwardGroup : [],
                              })
                            } else{
                              this.props.pop()
                            }
                          }
                        }
                      }
                    > <FontAwesomeIcon  icon={faCartArrowDown}  className={['fa'].join(" ")} />
                      <span className={['text'].join(" ")}> {  this.props.gi18n[this.props.language]['trader'] } </span>
                     </Button>
                }

                <Image className={['Footer-Logo'].join(" ")} src={logoB} />
                <div className={['Footer-Title', (this.props.frame==="dark")?"dark":""].join(" ")} >2021 &copy; GARLICAN TECH LIMITED</div>
            



            </div>
          </div>
        )
    }
}


export default Footer;



/*
              <div className={ ['Footer-Frame-Control'].join( " " ) }  >
                  {
                    links.map( (v,k) => {
                      return(
                        [ (k!==0)?(<span key={99 }>|</span>):null , 
                          <Link key={k} to={v.url} className={ ['Footer-Frame-Control-a'].join( " " ) } >
                            { this.props.gi18n[this.props.language][v.name] }
                          </Link>
                        ]
                      )
                    } )
                  }
              </div>
*/