import React, { Component } from 'react';
//import ReactDOM from 'react-dom';

import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import service from '../public/service'
import gservice from '../Garlican/GarlicanLibrary/gservice'

import Emoji from 'react-emojis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUser,faArrowCircleUp, faArrowCircleDown, faMinusCircle, faChevronUp, faChevronDown, faBookmark, faShareNodes, faArrowLeft, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Modal, Jumbotron, Button, Container, Accordion, Card, Col, Row, ButtonGroup, FormControl, InputGroup, OverlayTrigger, Tooltip, Alert, Spinner } from 'react-bootstrap';

import { VictoryChart, VictoryTheme , VictoryPolarAxis, VictoryLabel, VictoryArea, VictoryPie, VictoryLegend, VictoryGroup } from 'victory';
//import gcolor from '../public/style.scss';
import gcolor from '../public/color.js';

import ActivitiesLog from "../Components/ActivitiesLog"
import GarlicanReport from '../Garlican/GarlicanReport';

import Api from '../Garlican/GarlicanLibrary/apis'
import flags from '../public/flags'
//import sptrader from '../Components/Trader/service/sp';

const defaultColor = gcolor.gnBadgeColor
var colors = []
const MAX_ROW = 5

const types = [
  { key : 'backtest', value : 'backtest' },
  { key : 'forwardtest', value : 'forwardtest' },
  { key : 'realtrade', value : 'realtrade' }
]


const portfolio_frame = [
  {key : 'view_count' , value : 'view_ct'},
  {key : 'bookmark_count' , value : 'subscribe_ct'},
]

const gd = [
  {
    "risk_rate" : 0,
    'win_rate' : 0,
    "quantity_rate" : 0 ,
    "profit_rate" : 0 
  },
  {
    "risk_rate" : 50,
    'win_rate' : 50,
    "quantity_rate" : 50 ,
    "profit_rate" : 50 
  },
  {
    "risk_rate" : 25,
    'win_rate' : 25,
    "quantity_rate" : 25 ,
    "profit_rate" : 25 
  },
  {
    "risk_rate" : 75,
    'win_rate' : 75,
    "quantity_rate" : 75 ,
    "profit_rate" : 75 
  },
  {
    "risk_rate" : 100,
    'win_rate' : 100,
    "quantity_rate" : 100 ,
    "profit_rate" : 100 
  },
]

const frame = {
  "risk_rate" : 100,
  'win_rate' : 100,
  "quantity_rate" : 100 ,
  "profit_rate" : 100 
}


const gdOj = {
  'risk_rate' : 0,
  'win_rate' :  0,
  'quantity_rate' : 0,
  'profit_rate' : 0
}

class Portfolio extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          obj : {},
          data:  this.processData( gd.reverse() ),
          maxima: frame,
          partition : [],
          selected : null,
          strategy_list : [],
          activities : {},
          selectedStrategy : {},
          isOwner : false,
          pieArea : 0,
          showReport : false,
          gdOj : gdOj,
          total_portfolio : 0,
          type : 'backtest',
          isShare : false,
          feature : [],
          total : 0,

          showRemove : false,
          showSymbol : false,
          symbol_list : [],
          symbolLoading : false,
        };
        
        this.updateValue = this.updateValue.bind(this)
        this.selectedStrategy = this.selectedStrategy.bind(this)
        this.createBookmark = this.createBookmark.bind(this)
        this.removeBookmark = this.removeBookmark.bind(this)
        this.getPortfolioDetail = this.getPortfolioDetail.bind(this)
        this.getMyAsset = this.getMyAsset.bind(this)
        this.getOwnerBS = this.getOwnerBS.bind(this)

        this.forward = this.forward.bind(this)
        //this.forwardtesting = this.forwardtesting.bind(this)
        this.subscribePortfolio = this.subscribePortfolio.bind(this)
        this.selectTab = this.selectTab.bind(this)

        this.removeBotfolio = this.removeBotfolio.bind(this)

        this.searchSymbol = this.searchSymbol.bind(this)

        this.processData = this.processData.bind(this)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    updateValue(obj) {
      this.setState(obj)
    }

    selectedStrategy(obj) {
      if( obj.id === this.state.selectedStrategy.id  ) {
        this.setState(  { selectedStrategy : {}, selected:null }  )
      } else {
        this.setState(  { selectedStrategy : obj, selected : obj.id }  )
      }
    }

    UNSAFE_componentWillReceiveProps(props) {
      var _this = this
      setTimeout( (e) => {
        var _obj = {}
          if( document.getElementById("Portfolio-Pie-Holder") ) {
            var rect = document.getElementById("Portfolio-Pie-Holder").getBoundingClientRect()
            var height = rect.height
            var width = rect.width
            _obj.pieArea = Math.min( height, width )
          }
          _obj.type = "backtest"
          _this.setState(_obj)
      } , 500 )
      if(   (props.selectedPortfolio !== null) && (props.showGarlican === false) ) {
        if( !this.state.obj || (props.selectedPortfolio.id !== this.state.obj.id) ) {

          if(  props.selectedPortfolio.subscription   ) {
            
            service.getPortfoilioById( props.selectedPortfolio.subscription.portfolio_id ).then(
              (res) => {
                this.getPortfolioDetail(props, res)
              }
            )
          } else{
            this.getPortfolioDetail(props)

            

          }
          this.setState( { type : "backtest", showRemove : false, symbolLoading:false, showSymbol:false, symbol_list:[] } )
          
        }
      } 
    }

    createBookmark(cb) {
      service.createBookmark(this.state.obj.id).then(
        (res) => {
          this.getPortfolioDetail(this.props)
          //this.getPortfolioDetail(this.props)
          if(cb) {
            cb(this)
          } 
            var obj = {
              header : '',
              body : this.props.gi18n[this.props.language][  "bookmarked" ]
            }
            this.props.openAlert( obj )

          
        }
      )
    }

    removeBookmark() {
      service.removeBookmark(this.state.obj.id).then(
        (res) => {
          this.getPortfolioDetail(this.props)
          var obj = {
            header : '',
            body : this.props.gi18n[this.props.language][  "remove_bookmarked" ]
          }
          this.props.openAlert( obj )
        }
      )
    }

    getPortfolioDetail(props, sub) {
      var _this = this
      if(props.selectedPortfolio ) {
        function _call( res ) {
          //console.log( res )
          res.hasPurchased = ( res.id in props.purchased )

          //res.user = props.selectedPortfolio.user
          if( sub ) {
            res.subscription.obj = sub
            var keys = [
              //'backtest_report', 'forwardtest_report', 'realtrade_report',
              'backtest_report', 
              'feature', 'profit_rate', 'profit_rate_rank', 'sharpe_ratio', 'sharpe_ratio_rank',
              'turnover_rate', 'turnover_rate_rank', 'win_rate', 'win_rate_rank',
              'initial_capital', 'transaction_fee', 'total_portfolios',
              'bookmark_count', 'view_count', 'partition', 'description'
            ]

            for(var k in keys) {
              res[  keys[k] ] = sub[  keys[k]  ]
            }

          } 
          var checkers = [  
              { key : 'strategy_list', value : [] }, 
              { key : 'input', value : {} }, 
              { key : 'partition', value : {} },
              { key : 'transaction_fee', value : 0 },
          ]
          for (var c in checkers) {
              if( !( checkers[c].key in res) ) {
                res[  checkers[c].key   ] = checkers[c].value
              }
          }
          var _gd = [], _gdOj={}
          if(res['total_portfolios']!==0) {
            /*
            _gd = [
              {  x: "risk_rate" , y: Math.round( (res['total_portfolios'] - res['sharpe_ratio_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: 'win_rate' , y: Math.round( (res['total_portfolios'] - res['win_rate_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: "quantity_rate" , y: Math.round( (res['total_portfolios'] - res['turnover_rate_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: "profit_rate" , y: Math.round( (res['total_portfolios'] - res['profit_rate_rank'] + 1)/res['total_portfolios']*10000)/100  },
            ]
            _gdOj = {
                'risk_rate' : Math.round( (res['total_portfolios'] - res['sharpe_ratio_rank'] + 1)/res['total_portfolios']*10000)/100,
                'win_rate' :  Math.round( (res['total_portfolios'] - res['win_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
                'quantity_rate' : Math.round( (res['total_portfolios'] - res['turnover_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
                'profit_rate' : Math.round( (res['total_portfolios'] - res['profit_rate_rank'] + 1)/res['total_portfolios']*10000)/100  
            }
            */
            _gd = [
              {
                'risk_rate' : Math.round( (res['total_portfolios'] - res['sharpe_ratio_rank'] + 1)/res['total_portfolios']*10000)/100,
                'win_rate' :  Math.round( (res['total_portfolios'] - res['win_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
                'quantity_rate' : Math.round( (res['total_portfolios'] - res['turnover_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
                'profit_rate' : Math.round( (res['total_portfolios'] - res['profit_rate_rank'] + 1)/res['total_portfolios']*10000)/100
              },
              {
                "risk_rate" : 50,
                'win_rate' : 50,
                "quantity_rate" : 50 ,
                "profit_rate" : 50 
              },  
              {
                "risk_rate" : 25,
                'win_rate' : 25,
                "quantity_rate" : 25 ,
                "profit_rate" : 25 
              },
              {
                "risk_rate" : 75,
                'win_rate' : 75,
                "quantity_rate" : 75 ,
                "profit_rate" : 75 
              },
              frame,
            ]

            _gdOj = {
              'risk_rate' : Math.round( (res['total_portfolios'] - res['sharpe_ratio_rank'] + 1)/res['total_portfolios']*10000)/100,
              'win_rate' :  Math.round( (res['total_portfolios'] - res['win_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
              'quantity_rate' : Math.round( (res['total_portfolios'] - res['turnover_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
              'profit_rate' : Math.round( (res['total_portfolios'] - res['profit_rate_rank'] + 1)/res['total_portfolios']*10000)/100  
            }

          } else {
              _gd = gd
              _gdOj = gdOj
          }

          //console.log( _gd  )

          //console.log( _this.processData( _gd.reverse() )  )
        
          var isOwner = service.isOwner( parseInt(res.user.user_id)  )
          var partition = []
          var cash = 100, strategy_list = []
          for (var i in res.strategy_list) {
              var obj = {}
              obj.name = res.strategy_list[i].name
              obj['gid'] = res.id
              obj['id'] = res.strategy_list[i].id
              obj['x'] = res.strategy_list[i].name
              obj['y'] = parseInt(res.partition[  res.strategy_list[i].id  ])/100
              cash -= parseInt(res.partition[  res.strategy_list[i].id  ])
              partition.push( obj )
              var _obj = JSON.parse(   JSON.stringify( res.strategy_list[i] )  )
              _obj['partition'] = res.partition[  res.strategy_list[i].id  ]
              strategy_list.push(_obj)
          }
          var _o = {}
          _o.name = _this.props.gi18n[ _this.props.language]['cash']
          _o.partition = cash.toString()					
          strategy_list.splice( 0, 0, _o)
          partition.splice( 0, 0, {id : "-1" , x : _this.props.gi18n[ _this.props.language]['cash'], y : cash / 100, name: _this.props.gi18n[ _this.props.language]['cash']} )

          var _feature = []
          if(  props.selectedPortfolio.feature   ) {
            var _f = JSON.parse(  JSON.stringify( props.selectedPortfolio.feature )  )
            for( var i in _f.filter ) {
              var _o = {}
              _o.name = _f.filter[i].name
              _o.filter = Object.values( _f.filter[i].value )
              _o.filter = _o.filter.map( (v,k) => {
                return v.lang
              } )

              if( i in _f.buy  ) {
                _o.buy = Object.values( _f.buy[i].value )
              } else {
                _o.buy = []
              }
              if( i in _f.sell  ) {
                _o.sell = Object.values( _f.sell[i].value )
              } else {
                _o.sell = []
              } 
              _feature.push(  _o  )
            }
          }
          _this.setState( { 
              feature : _feature,
              isOwner: isOwner, 
              bookmarked : res.bookmarked,
              obj : res, 
              data : _this.processData( _gd.reverse() ),  
              gdOj : _gdOj,
              partition : partition, 
              strategy_list : strategy_list,
              total_portfolio : res['total_portfolios'],
          } )
        }

          service.getPortfoilioById( props.selectedPortfolio.id ).then(
            (res) => {
              _call(res)
            }
          )
      }
    }

    getRank(score) {

      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      
      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }

      var s = "F-"
      var maxColor = hexToRgb( gcolor.gnFrameColor )
      var minColor = hexToRgb( gcolor.gnAccentColor )

      var obj = { r: 0, g:0, b:0}
      for (var c in obj) {
        obj[c] = Math.round( (maxColor[c] - minColor[c] ) * score/100 + minColor[c] )
      }

      var color = rgbToHex( obj.r, obj.g, obj.b )

      for( var i in service.score_scale ) {
        if(  score > service.score_scale[i].score ) {
          s = service.score_scale[i].rank
          break;
        }
      }
      
      return { rank : s, color : color }
    }


    getMyAsset(pid, key) { 
      //HERE
        var obj = { portfolio_id : pid }
        if( key === "realtrade" ) {
          obj.asset_type = "real"
        } else if( key === "forwardtest" ) {
          obj.asset_type = "paper"
        }
        
          service.getAssetList( obj ).then(
          //service.getRecentTradeList(obj).then(
            (res) => {

              var k = JSON.parse(  JSON.stringify( this.props.selectedPortfolio ) )
              var act = []
              if( !k[ key + "_report"] ) {
                k[ key + "_report"]  = {}
              }
              k[ key + "_report"].asset_list = res.list
              var total = 0

              act = res.list.filter( (v,k)=> {
                  v.profit = v.profit * v.unit * v.board_lot
                  v.action = "buy"
                  total += v.price * v.unit * v.board_lot
                  return v.unit !== 0
              } )


              this.setState( {
                total : total, 
                activities : act,
                selectedID : this.props.selectedPortfolio.id
              } )
            }
          ).catch(
            (e)=>{
              
            }
          )
          //HERE

    }

    getOwnerBS(pid, key) { 
      //HERE
          var obj = { portfolio_id : pid }

          
          if( key === "realtrade" ) {
            obj.asset_type = "real"
          } else if( key === "forwardtest" ) {
            obj.asset_type = "paper"
          }
          

          //service.getAssetList( obj ).then(
          service.getRecentTradeList(obj).then(
            (res) => {
              var k = JSON.parse(  JSON.stringify( this.props.selectedPortfolio ) )
              var act = []
              if( k.forwardtest_report ) {
                
                k.forwardtest_report.asset_list = res.list
                act = res.list.filter( (v,k)=> {
                  v.profit = v.profit * v.unit * v.board_lot
                  //v.action = "buy"
                  return v.unit !== 0
                } )
              }
              this.setState( {
                activities : act,
                selectedID : this.props.selectedPortfolio.id
              } )
            }
          ).catch(
            (e)=>{
              
            }
          )
          //HERE

    }

    forward() {
      //console.log( this.props.selectedPortfolio )
      //this.props.updateValue({ showCanvas : true })
        //this.props.updateValue({
        //  showTrader : true
      //})
      var _this = this
      function _run(res, group) {
        var params = {}

        for (var i in res.asset_list) {
          if(  "unit" in  res.asset_list[i] ) {
            res.asset_list[i].strategy = res.asset_list[i].strategy_id.toString()
            //console.log( res.asset_list[i]   )
            if(  !(res.asset_list[i].symbol in params)  ){
              params[ res.asset_list[i].symbol ] = {}
            }
            if(  !( res.asset_list[i].strategy_id in params[ res.asset_list[i].symbol ])  ) {
              params[ res.asset_list[i].symbol ][   res.asset_list[i].strategy_id   ] = {}
            }
            if(  "unit" in  res.asset_list[i] ) {
              var d = '27070740', duration = 0
              if( res.asset_list[i].last_purchase_time ) {
                d = service.formatDate( res.asset_list[i].last_purchase_time, null, true  )
                d = d.getTime()/1000
                var now = new Date()
                now = now.getTime()/1000
                duration = (now - d)
              }
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy] = {}
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['betsizeonhold'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['betsize'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['shortbetsize'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['onstock'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['marketposition'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['onmarket'] = res.asset_list[i].unit.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['entryprice'] = res.asset_list[i].price.toString()
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['entrytime'] = d/60/60/24
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['last_purchase_time'] = res.asset_list[i].last_purchase_time
              params[ res.asset_list[i].symbol ][ res.asset_list[i].strategy]['barssinceentry'] = duration.toString()
            }
          }
        }
        _this.props.forwardtesting( group, params, group.transaction_fee, res )
      }

      var group = JSON.parse(  JSON.stringify(this.state.obj) )
      if( group.forwardtest_report ) {
        var obj = { portfolio_id : group.id }
        service.getAssetList( obj ).then(
          (res) => {
            var k = JSON.parse(  JSON.stringify( group ) )
            k.forwardtest_report.asset_list = res.list
            _run( k.forwardtest_report , group)
          }
        ).catch( (e) => {
          this.props.updateValue({
            showLoading : false,
            showAlert : true,
            alertMessage : {
              header :  this.props.gi18n[this.props.language]['error'] ,
              body : this.props.gi18n[this.props.language]['backtesting_error']
            },
          })
        })
      } else {
        var  list = { asset_list : [] }
        group.forwardtest_report = list
        _run( list, group)
      }  
    }

    subscribePortfolio() {
      var id = this.state.obj.id
      //console.log( id )
      service.subscribePortfolioById(id).then(
        (res) => {
          console.log(  res )


          this.props.updateValue( {
            showAlert : true,
            alertMessage : {
              header :  this.props.gi18n[this.props.language]['success_purchased'] ,
              body : this.props.gi18n[this.props.language]['success_purchased_msg']
            },
          } )

        }
      )
    }

    selectTab(key) {
      this.setState({  
        type : key
      }) 
      if( key !== "backtest" ) {
        var group = JSON.parse(  JSON.stringify(this.state.obj) )
        if( this.state.isOwner   ) {
          this.getMyAsset(  group.id, key  )
        } else {
          this.getOwnerBS(  group.id, key  )
        }

      } 
    }

    removeBotfolio( pid ) {
      //confirm_remove_portfolio
      service.deletePortfolio( pid ).then(
        (res) => {
          //console.log( res  )
          this.setState( {showRemove : false} ) 
          this.props.callList()
        }
      )
    }

    searchSymbol() {

      if( this.state.obj.symbol_list  ) {
        var array = []
        for (var p in this.state.obj.symbol_list) {
            array = array.concat(  this.state.obj.symbol_list[p].symbol )
        }
        this.setState( {
          symbol_list : array,
        })
      } else {
        this.setState( {
          symbolLoading : true,
        })
        var region = this.state.obj.region?this.state.obj.region:this.props.region
        Api.getFilterTable( null, "all", null, null, this.state.obj.id, false, region, this.props.demo  ).then(
          (res) => {
            var array = []
            for (var i in res) {
              array = array.concat(  res[i].symbol )
            }
            this.setState( {
              symbol_list : array,
              symbolLoading : false,
            })
          }
        ).catch((e)=> {
          this.setState( {
            symbolLoading : false,
          })
        })
      }


    }

    processData(data) {
      //const maxByGroup = this.getMaxima(data);
      const makeDataArray = (d) => {
        return Object.keys(d).map((key) => {
          //return { x: key, y: d[key] / maxByGroup[key] };
          return { x: key, y: d[key]};
        });
      };
      var oo = data.map((datum) => makeDataArray(datum))
      return oo;
    }

    getMaxima(data) {
      const groupedData = Object.keys(data[0]).reduce((memo, key) => {
        memo[key] = data.map((d) => d[key]);
        return memo;
      }, {});
      return Object.keys(groupedData).reduce((memo, key) => {
        memo[key] = Math.max(...groupedData[key]);
        return memo;
      }, {});
    }

    render() {
        return (
            <Modal key={"k1"}
                backdropClassName={"hidden"}
                enforceFocus={false}
                show={   this.props.showPortfolio && !this.props.showGarlican  }
                onHide={() => {
                  this.props.updateValue( {  showPortfolio  : false, selectedPortfolio : null  }  ) 
                  this.setState( { 
                    obj :  {},
                    showSymbol : false,
                    symbol_list : [],
                    symbolLoading : false,
                  } )
                  if( this.props.showGarlican === false ) {
                    this.props.callList()
                  }
                }}
                dialogClassName="modal-95w"
                aria-labelledby="example-custom-modal-styling-title"
                className={ ['Portfolio-Holder'].join( " " ) }
            >
                <Modal.Header id="example-custom-modal-styling-title" className={ ['Portfolio-Header'].join( " " ) } >
                  <div className={['Portfolio-Header-Frame'].join(" ")}>
                      <div className={ ['app-tri-holder'].join(' ') } onClick={
                          (e)=>{
                            this.props.updateValue( {  showPortfolio  : false, selectedPortfolio : null  }  ) 
                            this.setState( { obj : {} } )
                            if( this.props.showGarlican === false ) {
                              this.props.callList()
                            }
                          }}>
                        <FontAwesomeIcon icon={faArrowLeft} className={["Fa"].join(" ")}/>
                      </div>
                    
                    { (this.state.obj && !('subscription' in this.state.obj)) &&
                      <Button className={ ['share'].join(' ') } variant="primary" 
                        onClick={
                          (e)=>{
                            this.setState({
                              isShare : !this.state.isShare
                            })
                          }
                      }> 
                        <FontAwesomeIcon icon={faShareNodes } className={["Fa"].join(" ")}/>
                      </Button>
                    }

                    { (this.state.obj && ('subscription' in this.state.obj)) &&
                      <Button className={ ['remove'].join(' ') } variant="primary" 
                        onClick={
                          (e)=>{
                            this.setState( {showRemove : !this.state.showRemove} )
                          }
                      }> 
                        <FontAwesomeIcon icon={ faTrashCan } className={["Fa"].join(" ")}/>
                      </Button>
                    }

                    <div className={[
                        'onhold',  
                        ((("id" in this.state.obj?((this.state.obj.id in this.props.onhold)?this.props.onhold[this.state.obj.id].length:0):0)> 0)?"":"nohold")
                      ].join(" ") 
                    } > 
                      <span> { this.props.gi18n[this.props.language]['t_onhold'] } </span>
                      <span> { (this.state.obj)?((this.state.obj.id in this.props.onhold)?this.props.onhold[this.state.obj.id].length:0):0   } </span>
                    </div>
                  </div>

                  <div className={['Portfolio-Header-Title'].join(" ")} > 
                    { (this.state.obj && ('subscription' in this.state.obj)) &&
                      <span className={['Badge'].join(" ")}>
                        { "P" }
                      </span>
                    }

                    <div className={['Region']}>
                      {
                        <Emoji className={['Emoji'].join(" ") } 
                            emoji={
                              this.state.obj.region?
                              flags[ this.state.obj.region ]:
                              flags['usa']
                            }
                        />
                      }
                   </div>

                    { (this.state.obj)?this.state.obj.name:"GARLICAN"  } 
                  </div>
                    
                  { ('id' in this.state.obj) &&
                    <div className={['share-board', this.state.isShare?"show":'hide'].join(" ")} >
                      <InputGroup className="mb-3">
                        <FormControl className={['input'].join(" ")} id={"mylink"}
                          readOnly={true}
                          value={  window.location.origin + "/" + gservice.encodeObject( "user" + this.state.obj.user.user_id ) + "/" +  gservice.encodeObject( "portfolio" + this.state.obj.id )  }
                        />
                        <Button className={['btn'].join(" ")} id="button-addon2" onClick={
                          (e)=>{
                            //var link = window.location.origin + "/" + gservice.encodeObject( "user" + this.state.obj.user.user_id ) + "/" +  gservice.encodeObject( "portfolio" + this.state.obj.id ) 
                            var copyText = document.getElementById("mylink");
                            copyText.select();
                            copyText.setSelectionRange(0, 99999);
                            document.execCommand("copy");
                          }
                        }>
                          { this.props.gi18n[this.props.language]['copy'] }
                        </Button>
                      </InputGroup>
                    </div>
                  }
                </Modal.Header>

                <Alert show={this.state.showRemove} variant="warning" className={['Portfolio-Remove']}>
                  <Alert.Heading> { this.props.gi18n[this.props.language]['confirm_remove_portfolio'] }</Alert.Heading>
                  <hr />
                  <div className={["d-flex justify-content-end"].join(" ")}>
                    <Button className={["Btn"].join(" ")} 
                      onClick={() =>{ this.setState( {showRemove : false} ) } } 
                      variant="outline-success">
                        {   this.props.gi18n[this.props.language]['cancel']  }
                    </Button>
                    <Button className={["Btn", 'Remove'].join(" ")} 
                      onClick={() =>{ 
                          this.removeBotfolio( this.state.obj.id )
                          //this.setState( {showRemove : false} ) 
                      } } 
                      variant="outline-success">
                        {   this.props.gi18n[this.props.language]['remove']  }
                    </Button>
                  </div>
                </Alert>

                { ('user' in this.state.obj) &&
                  <div className={['Portfolio-User-Holder'].join(' ')}>
                    <div className={['container'].join(' ')}>
                        <div className={ ["Portfolio-User-Holder-Left"].join(' ')  }>
                            <div className={ ["Portfolio-User-Name"].join(' ')  } style={{ fontWeight : 'bolder'}}>  { this.props.gi18n[this.props.language]['powered']  } </div>
                            { this.state.obj.user.avatar && 
                              <Card.Img variant="top" src={ 
                                  ('subscription' in this.state.obj)?this.state.obj.subscription.obj.user.avatar:this.state.obj.user.avatar  } 
                              className={['Portfolio-User-Pic'].join(' ')} />
                            }
                            { !(('subscription' in this.state.obj)?this.state.obj.subscription.obj.user.avatar:this.state.obj.user.avatar) && 
                              <FontAwesomeIcon  icon={faUser} className={['Portfolio-User-Pic'].join(' ')}/>
                            }
                            <div className={ ["Portfolio-User-Name"].join(' ')  }>  {    ('subscription' in this.state.obj)?this.state.obj.subscription.obj.user.first_name:this.state.obj.user.first_name   } </div>
                        </div>
                        <div className={ ['Portfolio-User-Holder-Right'].join(' ') }> 
                            <span> { this.props.gi18n[this.props.language]['update_at']} </span>
                            <span>{ service.formatDate( ((this.state.obj.modification_time!==null)?this.state.obj.modification_time:this.state.obj.creation_time ) )   } </span>
                        </div>
                    </div>
                        
                      
                      
                  </div>
                }

                <div className={['Portfolio-Control']}>  

                          { !this.state.isOwner && 
                            <FontAwesomeIcon
                              icon={ faBookmark }
                              onClick={ (e)=>{
                                if( this.props.isLogin ) {
                                  if (this.state.bookmarked ) {
                                    this.removeBookmark()
                                  } else {
                                    this.createBookmark()
                                  }
                                
                                } else {
                                  this.props.updateValue({
                                    showLogin : true
                                  })
                                }
                              } }
                              variant="primary" 
                              className={['Portfolio-Jumbotron-Fa', this.state.bookmarked?"bookmarked":""].join(" ")} />
                          }

                          <Button 
                            onClick={ (e)=>{
                                if(this.props.isLogin) {

                                  function _call(_this) {
                                      if( _this.state.obj.backtest_report ) {
                                          _this.setState({  showReport : true })
                                      }
                                  }
                                  if(  this.state.bookmarked || this.state.isOwner ) {
                                    _call(this)
                                  }else {
                                    this.createBookmark(_call)
                                  }


                                } else {
                                  this.props.updateValue( {showLogin : true, showSignup : false} ) 
                                }
                            } }
                            variant="primary" 
                            disabled={ !((this.state.obj)?(this.state.obj[ this.state.type + "_report"]):true)  }
                            className={['Portfolio-Jumbotron-Button', ].join(" ")}  >
                            {  this.props.gi18n[this.props.language][  this.state.type + "ing"]  }
                          </Button>
                          
                          


                          <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  { 
                                    this.state.isOwner?
                                      this.props.gi18n[this.props.language]['forward_msg']:
                                      (  this.state.obj.hasPurchased?this.props.gi18n[this.props.language]['purchased_msg']:this.props.gi18n[this.props.language]['purchase_msg'] )
                                  }
                                </Tooltip>
                              }
                            >
                              <Button 
                                onClick={ (e)=>{
                                  
                                  if( this.props.isLogin ) {
                                    if(this.state.isOwner) {
                                      this.forward()
                                    } else {
                                      if ( !this.state.obj.hasPurchased ) {
                                        this.subscribePortfolio()
                                      } else {

                                        var user_id =  gservice.encodeObject(   "user" + service.user.user_id   )
                                        var p_id =  gservice.encodeObject(   "portfolio" + this.props.purchased[   this.state.obj.id  ].id  )
                                        var url = window.location.origin + "/" + user_id + "/" + p_id
                                        //console.log(url)

                                        window.open( url )

                                      }
                                    }
                                  } else {
                                    this.props.updateValue({
                                      showLogin : true
                                    })
                                  }
                                } }
                                variant="primary" 
                                style={ { /*opacity :  (!this.state.isOwner?0.5:1)*/  } }
                                className={['Portfolio-Jumbotron-Button-forward'].join(" ")}  >
                                  {  this.state.isOwner?
                                        this.props.gi18n[this.props.language]["forward"]:
                                        (this.state.obj.hasPurchased?this.props.gi18n[this.props.language]["subscribed"]:this.props.gi18n[this.props.language]["purchase"])  
                                  }
                              </Button>
                          </OverlayTrigger>

                </div>
                
                <ButtonGroup className={ ['Portfolio-Report-Type-Swith'].join(' ') } key={1}>
                          {
                            types.map( (v,k) => {
                              return(
                                <Button className={ [ (v.key!==this.state.type)?'noselect':"" ].join(' ') } key={k} 
                                  disabled={  !this.state.obj[ v.key + "_report" ]  }
                                  onClick={ (e)=>{

                                    this.selectTab( v.key )

                                }} > { this.props.gi18n[this.props.language][v.value+"ing"] } </Button>
                              )
                            } )
                          }
                </ButtonGroup>

                <Modal.Body className={ ['Portfolio-Body'].join( " " ) }>
                
                    <Jumbotron className={['Portfolio-Jumbotron', "Portfolio-Diagram"].join(" ")} >
                      { (this.state.type === 'backtest') &&
                          
                        <VictoryChart polar
                          className={["Portfolio-Diagram"].join(" ")}
                          theme={VictoryTheme.material}
                          domain={{ y: [ 0, 100 ] }}
                        >
                          <VictoryGroup colorScale={
                            [  
                              gcolor.gnFrameColor3, 
                              gcolor.gnFrameColor, 
                              gcolor.gnFrameColor3, 
                              gcolor.gnFrameColor,
                              gcolor.gnAccentColor, 
                          ]
                          }
                            
                          >
                            {this.state.data.map((data, i) => {
                              return <VictoryArea 
                                key={i} 
                                data={data}
                                style={  { data: { 
                                  fillOpacity:  (i>3)?0.5:( ((i % 2) ==1)?0.5:0.25 ) , 
                                  strokeWidth: (i>3)?3:0,
                                  data : {
                                    stroke : (i>3)?gcolor.gnAccentColor2:gcolor.gnAccentColor2,
                                  }
                                } 
                              }}
                              />;
                            })}
                            </VictoryGroup>
                        
                
                          {
                                                              Object.keys(this.state.maxima).map((key, i) => {
                                                                try{
                                                                  var _data = this.state.data[4]
                                                                  var obj = this.getRank( _data[i].y )
                                                                  var score = obj.rank
                                                                }
                                                                catch(e) {
                                                                  var obj = this.getRank( 0 ).rank
                                                                  var score = obj.rank
                                                                }
                                                                return (
                                                                  <VictoryPolarAxis key={i} 
                                                                    dependentAxis
                                                                    className={ ['Radar'].join(' ') }  
                                                                    style={{
                                                                        axisLabel: { padding: 5, fontSize : 12.5, fill : gcolor.gnFrameColor },
                                                                        tickLabels: { fontSize :0, fill : gcolor.gnFrameColor },
                                                                        axis: { stroke: gcolor.gnWhiteColor+"33" },
                                                                        grid: { stroke: gcolor.gnFrameColor3, strokeWidth: 0.25, opacity: 0.0 }
                                                                    }}                           
                                                                    axisLabelComponent={
                                                                      <VictoryLabel {...this.props}
                                                                        padding
                                                                        style={
                                                                          [
                                                                            { fill: gcolor.gnFrameColor, fontSize: 20 },
                                                                            { fill: gcolor.gnWhiteColor, fontSize: 25 }
                                                                          ]
                                                                        }
                                                                        backgroundPadding={[
                                                                          0,
                                                                          { left: 5, right: 10 },
                                                                        ]}
                                                                        backgroundStyle={[
                                                                          { fill: "white", opacity: 0 },
                                                                          { fill: obj.color, opacity: 1, rx : "0.5rem" },
                                                                        ]}
                                                                      />
                                                                    }
                                                                    labelPlacement={ ((i+1)%2==0)?"perpendicular":"vertical"  }
                                                                    axisValue={i + 1} 
                                                                    tickValues={[25, 50, 75, 100]}
                                                                    label={ 
                                                                          [
                                                                            this.props.gi18n[this.props.language][key].replace("level", "")
                                                                            ,
                                                                            (score?( ""+ score+""):"") 
                                                                          ]                                                      
                                                                      }
                                                                      
                                                                  />
                                                                );
                                                              })
                        }
                        
                                                          <VictoryPolarAxis
                                                            theme={VictoryTheme.material}
                                                            labelPlacement="parallel"
                                                            tickValues={[25, 50, 75, 100]}
                                                            tickFormat={ (k)=>"" }
                                                            style={{
                                                                  axis: { stroke: "none" },
                                                                  grid: { stroke: gcolor.gnFrameColor3+"00", opacity: 0.0 }
                                                            }}
                                                          />
                
                
                        </VictoryChart>


                      }

                      <div className={['Portfolio-Chart-Holder'].join(' ') }>
                          <Modal.Title className={['Portfolio-Chart-Title'].join(' ') }>
                            { this.props.gi18n[this.props.language]["quick_summary"] }
                          </Modal.Title>
                          { this.state.obj && 
                            this.state.obj[ this.state.type + "_report"] && 
                            <div className={ ['Portfolio-Chart-Holder-Billboard'].join(' ') }>
                                  <Col >
                                    <Row sm className={['two'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['profit'] } </div>
                                      <div className={['value' , (this.state.obj[ this.state.type + "_report"].profit > 0)?'up':((this.state.obj[ this.state.type + "_report"].profit < 0)?'down':'') ].join(' ')} > 
                                        
                                        {  
                                          //((this.state.obj[ this.state.type + "_report"].profit>0)?"+$":"-$") +  (Math.round(  Math.abs(this.state.obj[ this.state.type + "_report"].profit) ) )  
                                        }
                                        <span>
                                          <FontAwesomeIcon  icon={  (this.state.obj[ this.state.type + "_report"].profit>0)?faArrowCircleUp:((this.state.obj[ this.state.type + "_report"].profit>0)?faArrowCircleDown:faMinusCircle)  } className={[  ].join(' ')}/>
                                          <span>{ "$" + Math.abs((Math.round(  Math.abs(this.state.obj[ this.state.type + "_report"].profit) ) )) }</span>
                                        </span>
                                      </div>
                                      <div className={['sub' , (this.state.obj[ this.state.type + "_report"].profit > 0)?'up':((this.state.obj[ this.state.type + "_report"].profit < 0)?'down':'') ].join(' ')} > 
                                        {  
                                          //((this.state.obj[ this.state.type + "_report"].profit>0)?"+":"") +  (Math.round(this.state.obj[ this.state.type + "_report"].profit/this.state.obj[ this.state.type + "_report"].initial_capital * 10000)/100) + "%"  
                                        } 
                                        <span>
                                          <FontAwesomeIcon  icon={  (this.state.obj[ this.state.type + "_report"].profit>0)?faArrowCircleUp:((this.state.obj[ this.state.type + "_report"].profit>0)?faArrowCircleDown:faMinusCircle)  } className={[  ].join(' ')}/>
                                          <span>{ Math.abs(  (Math.round(this.state.obj[ this.state.type + "_report"].profit/this.state.obj[ this.state.type + "_report"].initial_capital * 10000)/100)) + "%"  }</span>
                                        </span>
                                      </div>
                                    </Row>
                                    <Row sm className={['two'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['trade_count'] } </div>
                                      <div className={['value'].join(' ')}> {  this.state.obj[ this.state.type + "_report"].trade_count } </div>
                                    </Row>
                                  </Col>
                                  <Col>
                                    <Row sm className={['three'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['initial'] } </div>
                                      <div className={['value', 'capital'].join(' ')}> {  "$" + service.getNumberFormat(this.state.obj[ this.state.type + "_report"].initial_capital) } </div>
                                    </Row>
                                    <Row sm className={['three'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['win_rate'] } </div>
                                      <div className={['value', 'capital'].join(' ')}> {  (Math.round(this.state.obj[ this.state.type + "_report"].win_count*10000/(this.state.obj[ this.state.type + "_report"].win_count + this.state.obj[ this.state.type + "_report"].even_count + this.state.obj[ this.state.type + "_report"].loss_count))/100) + "%" } </div>
                                    </Row>
                                    <Row sm className={['three'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['sharpe_ratio'] } </div>
                                      <div className={['value', 'capital'].join(' ')}> {  Math.round(this.state.obj[ this.state.type + "_report"].sharpe_ratio * 10000)/100 + "%"} </div>
                                    </Row>
                                  </Col>
                            </div>
                          }
                          { !(this.state.obj &&
                            this.state.obj[ this.state.type + "_report"]) &&
                            <div className={ ['Portfolio-Chart-Holder-Billboard', 'empty'].join(' ') }>
                              { this.props.gi18n[this.props.language]["no_report"] }
                            </div>
                          }
                      </div>

                      { (this.state.type === 'backtest') && 
                        <div className={['Portfolio-Chart-Supporter'].join(' ')}>
                            {
                              portfolio_frame.map( (v,k) => {
                                return(
                                  <div className={['Portfolio-Chart-Supporter-Holder'].join(' ')} key={k}>
                                    <div className={['Portfolio-Chart-Supporter-Title'].join(' ')}>
                                      { this.props.gi18n[this.props.language][v.value] }
                                    </div>
                                    <div className={['Portfolio-Chart-Supporter-Content'].join(' ')}>
                                      { (v.key in this.state.obj)?this.state.obj[ v.key ]:"" }
                                    </div>

                                  </div>
                                )
                              }  )
                            }
                        </div>
                      }

                      { (this.state.type === 'backtest') && 
                        <div className={ ["Feature"].join(' ') }>
                              <div className={["FTitle"].join(" ")}> { this.props.gi18n[this.props.language]["features"] } </div>
                              {
                                this.state.feature.length > 0 &&
                                this.state.feature.map( (v,k) => {
                                  return(
                                    <div className={["Docker"].join(" ")} key={k}>  
                                      <div className={["DTitle"].join(" ")}> { v.name } </div>
                                      {
                                        ['filter', 'buy' , 'sell'].map( (v1, k1) => {
                                          return(
                                            <div className={["DHolder"].join(" ")} key={k1}>  
                                              <div className={["HTitle"].join(" ")}> { this.props.gi18n[this.props.language][v1+"_label"] } </div>
                                              { v[v1].length > 0 &&
                                                v[v1].map( (v3,k3) => {
                                                  return(
                                                    <li key={k3} className={['HList'].join(" ")}> { v3[this.props.language]  } </li>
                                                  )
                                                } )
                                              }
                                              { v[v1].length === 0 &&
                                                <li className={['Statement'].join(" ")}> { (v1==="filter")?this.props.gi18n[this.props.language]['no_filter_label']:this.props.gi18n[this.props.language]['no_strategy_label'] } </li>
                                              }
                                            </div>
                                          )
                                        } )
                                      }
                                    </div>
                                  )
                                } )
                              }
                              {
                                this.state.feature.length === 0 &&
                                <div className={["Docker"].join(" ")} >
                                  <div className={["DTitle"].join(" ")}> { this.props.gi18n[this.props.language]["no_features"] } </div>
                                </div>
                              }
                        </div>
                      }   

                    </Jumbotron>  

                  
                  { (this.state.type === 'backtest') && 
                    <Jumbotron className={['Portfolio-Jumbotron-Bottom'].join(" ")} >
                      <div className={["Desc-Title"].join(" ")}> { this.props.gi18n[this.props.language]["group_desc"] } </div>
                      <p className={['desc'].join(" ")}>
                          { (this.state.obj)?
                            ((this.state.obj.description&&this.state.obj.description.length>0)?this.state.obj.description:this.props.gi18n[this.props.language]['noDesc'] ):
                            this.props.gi18n[this.props.language]['noDesc']
                          }
                      </p>
                    </Jumbotron>  
                  }

                  


                  { 
                    /*
                    this.props.isLogin && (this.state.type === 'backtest') && 
                      <div className={ ['Portfolio-Pie-Holder'].join(' ') } id={"Portfolio-Pie-Holder"} >
                          <Modal.Title className={['Portfolio-Pie-Title'].join(' ') }>
                            { this.props.gi18n[this.props.language]['component_message'].replace( "<portfolio>" ,(  ("name" in this.state.obj)?this.state.obj.name:"")) }
                          </Modal.Title>
                                              
                          <div style={{
                            position : 'relative',
                            width : this.state.pieArea,
                            height : this.state.pieArea
                          }}>
                          <VictoryChart 
                            style={{
                              parent: {
                                border: "0px solid #ccc"
                              }
                            }}
                            polar>
                            
                            <VictoryPolarAxis style={{ 
                              axis: {stroke: "transparent"}, 
                              ticks: {stroke: "transparent"},
                              tickLabels: { fill:"transparent"} 
                            }} />

                            <VictoryLegend 
                              standalone={false}
                              x={   180 - ( Math.ceil( this.state.partition.length/MAX_ROW) - 1 ) * 55    } 
                              y={  95   }
                              itemsPerRow={  MAX_ROW  }
                              orientation="vertical"
                              gutter={35}
                              rowGutter={{ top: 0, bottom: 0 }}
                              style={ {
                                title: {fontSize: 10},
                                labels: {fontSize: 10}
                              }}
                              colorScale={ this.props.colors  }
                              data={  this.state.partition }
                              events={[{
                                target: "data",
                                eventHandlers: {
                                  onMouseDown : () => {
                                    return [
                                      {
                                        target: "data",
                                        mutation: ({datum}) => {
                                          if ("id" in this.state.selectedStrategy) {
                                            if( this.state.selectedStrategy.id === datum.id ) {
                                              this.setState({selectedStrategy:{}, selected : null})
                                              
                                            } else {
                                              this.selectedStrategy(datum)
                                            }
                                          } else {
                                            this.selectedStrategy(datum)
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              }, 
                              {
                                target: "labels",
                                eventHandlers: {
                                  onMouseDown : () => {
                                    return [
                                      {
                                        target: "labels",
                                        mutation: ({datum}) => {
                                          if ("id" in this.state.selectedStrategy) {
                                            if( this.state.selectedStrategy.id === datum.id ) {
                                              this.setState({selectedStrategy:{}, selected : null})
                                            } else {
                                              this.selectedStrategy(datum)
                                            }
                                          } else {
                                            this.selectedStrategy(datum)
                                          }
                                        }
                                      }
                                    ]
                                  },
                                  onMouseEnter : () => {
                                    return [
                                      {
                                        target: "labels",
                                        mutation: ({datum}) => {
                                          if ("id" in this.state.selectedStrategy) {
                                            if( this.state.selectedStrategy.id === datum.id ) {
                                              this.setState({selectedStrategy:{}, selected : null})
                                            } else {
                                              this.selectedStrategy(datum)
                                            }
                                          } else {
                                            this.selectedStrategy(datum)
                                          }
                                        }
                                      },
                                      {
                                        target: "labels",
                                        mutation: (props) => ({ style: Object.assign({}, props.style, { fill: gcolor.gnFrameColor}) })
                                      }
                                    ]
                                  },
                                  onMouseLeave : () => {
                                    return [
                                      {
                                        target: "labels",
                                        mutation: ({datum}) => {
                                          this.setState({selectedStrategy:{}, selected : null})
                                        }
                                      }
                                    ]
                                  }
                                }
                              }]}
                            />

                            <VictoryPie
                                standalone={false}
                                radius={ ({datum}) => (this.state.selected===datum.id)?170:145}
                                innerRadius={ ({datum}) => (this.state.selected===datum.id)?150:125}
                                style={{ 
                                  labels: { 
                                    pointerEvents: 'none',
                                    fontFamily : gcolor.gnFont,
                                    fill: 'transparent', 
                                    fontSize: 10,
                                    fontWeight : 500
                                  } ,
                                  data : { 
                                    filter: "drop-shadow( 1px 1px 1px rgba(0, 0, 0, .25))"
                                  }
                                }}
                                colorScale={ this.props.colors  }
                                animate={{
                                  duration: 2000,
                                  onLoad: { duration: 1000 }
                                }}
                                data={  this.state.partition }
                                cornerRadius={ 50 }
                                padAngle={ ({ datum }) => (this.state.selected===datum.id)?5:2.5  }                          
                            />
                          </VictoryChart>
                          </div>
                      </div>
                    */
                  }
                  
                  { 
                    /*
                    this.props.isLogin && (this.state.type === 'backtest') && 
                    <Container className={['Portfolio-Activities-Holder'].join(' ')}>
                      <Accordion 
                        activeKey={  (('id' in this.state.selectedStrategy)?this.state.selectedStrategy.id:"-1")  }
                        defaultActiveKey={ -1 }  >
                        {
                          this.state.strategy_list.map(  (v,k) => {
                            if( v.id ) {
                              return (
                                <Card key={k} >
                                    <Accordion.Toggle 
                                      as={Card.Header} 
                                      variant="link" eventKey={ v.id } className={['Portfolio-Activities-Header'].join(' ')} 
                                      onClick={ (e)=>{
                                        this.selectedStrategy(v)  
                                      }} >
                                      <div 
                                        style={ {  backgroundColor : this.props.colors[k] } }
                                        className={ ['Portfolio-Activities-Header-Partition'].join(' ') } >  
                                        {v.partition + " %"}
                                      </div>
                                      <div className={ ['Portfolio-Activities-Header-Name'].join(' ') }>
                                        {v.name}
                                      </div>

                                    </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={ v.id }>
                                    <Card.Body>
                                      <div className={['Portfolio-Activities-Desc'].join(' ')}>{ v.description }</div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              )
                            } else {
                              return(null)
                            }
                          })
                        }

                      
                      </Accordion>
                    </Container>
                    */
                  }

                  {
                    this.props.isLogin && (this.state.type !== 'backtest') && 
                    <Container className={['Portfolio-Activities-Holder'].join(' ')}>               
                      { !this.state.isOwner &&
                        <div className={ ['activities-title'].join(" ") }  >
                          {  this.props.gi18n[this.props.language]['portfolio_activities']  }
                        </div>
                      }

                      { this.state.isOwner &&
                        <div className={ ['activities-title'].join(" ") }  >
                          {  this.props.gi18n[this.props.language]["d_total_cost"] + " : "  }
                          <span className={ ['Value'].join(" ") }  >
                            { "$" + (Math.round( this.state.total * 100 )/100) }
                          </span>
                        </div>
                      }


                      <div className={ ['activities'].join(" ") }  >
                        { (this.state.activities.length > 0) &&
                          this.state.activities.map(  (v,k) => {
                            return(
                              <ActivitiesLog key={k} obj={v} updateValue={this.updateValue} language={this.props.language} gi18n={this.props.gi18n} isPortfolio={true} sym_names={ this.props.sym_names }/>
                            )
                          })
                        }
                        { (this.state.activities.length === 0) &&
                          <span>  { this.props.gi18n[ this.props.language ]["no_stock"] } </span>
                        }
                      </div>
                    </Container>
                  }

                </Modal.Body>
              
                <Jumbotron className={['Portfolio-Jumbotron', 'Symbol-Wrapper'].join(" ")} >
                      <div className={['Symbol', this.state.showSymbol?"Show":"Hide"].join(" ")}>
                        <div 
                          className={["STitle"].join(" ")}
                          onClick={ (e)=>{
                              var BOOL = !this.state.showSymbol
                              this.setState( { showSymbol : BOOL } )
                              if( BOOL ) {
                                
                                if( this.state.symbol_list.length === 0 ) {
                                  this.searchSymbol()
                                }
                              }
                            }}> 
                            <span>{  ( this.props.gi18n[ this.props.language ]['symbol_watchlist'] + ((this.state.symbol_list.length>0)?("("+this.state.symbol_list.length+")"):"") )  } </span>
                            <FontAwesomeIcon className={["SFa"].join(" ")} icon={ this.stateshowSymbol?faChevronUp:faChevronDown }  />
                        </div>
                        <div className={["Docker"].join(" ")}>
                          { !this.state.symbolLoading && (this.state.symbol_list.length === 0) &&
                            <span>{ this.props.gi18n[ this.props.language ][ "no_sym" ] } </span>
                          }
                          { this.state.symbolLoading &&
                            <div className={ ['SLoading'].join(" ") }>
                              <Spinner animation="border" className={ ['Spinner'].join(" ") }/>
                              <span> { this.props.gi18n[ this.props.language ]['loading']  } </span>
                            </div>
                          }
                          { !this.state.symbolLoading && (this.state.symbol_list.length > 0) &&
                            this.state.symbol_list.map( (v,k) => {
                              return(
                                <div className={ ['Symbol-Holder'].join(" ") } key={k}>
                                  <div className={["Symbol-Name"]}> { v } </div>
                                  { v in this.props.sym_names &&
                                    <div className={["Symbol-Company"]}> { this.props.sym_names[v][this.props.language] } </div>
                                  }
                                </div>
                              )
                            } )
                          }
                        </div>
                      </div>
                  </Jumbotron> 



              { this.state.obj  && 
                <GarlicanReport 
                  sym_names={ this.props.sym_names }
                  readonly={true} 
                  updateValue={this.updateValue} 
                  showReport={this.state.showReport} 
                  language={this.props.language} 
                  gi18n={this.props.gi18n} 
                  group={this.state.obj} 
                  isOwner={false} type={this.state.type}/>
              }

            </Modal>
        )
    }
}


export default Portfolio;


/*
 <Button 
                            onClick={ (e)=>{
                                //console.log( this.props.isOwner )
                                if(this.props.isLogin) {
                                    if( this.state.bookmarked || this.state.isOwner ) {
                                      if( this.state.obj.backtest_report ) {
                                          this.setState({  showReport : true })
                                      }
                                    } else {
                                      this.createBookmark()
                                    }
                                } else {
                                  this.props.updateValue( {showLogin : true, showSignup : false} ) 
                                }
                            } }
                            variant="primary" 
                            disabled={ !((this.state.obj)?(this.state.obj[ this.state.type + "_report"]):true)  }
                            className={['Portfolio-Jumbotron-Button', ].join(" ")}  >
                            {  ( (this.state.bookmarked || this.state.isOwner )?this.props.gi18n[this.props.language][  this.state.type + "ing"]:this.props.gi18n[this.props.language]["subscribe"])   }
                          </Button>



                                                    { !this.state.isOwner && !this.state.bookmarked &&
                            <Button 
                              onClick={ (e)=>{
                                if( this.props.isLogin ) {

                                  this.createBookmark()

                                } else {
                                  this.props.updateValue({
                                    showLogin : true
                                  })
                                }
                              } }
                              variant="primary" 
                              className={['Portfolio-Jumbotron-Button', 'Portfolio-Jumbotron-Button-Edit'].join(" ")}  >
                                {  this.props.gi18n[this.props.language]["subscribe"]   }
                            </Button>
                          }

                          { !this.state.isOwner && this.state.bookmarked &&
                            <Button 
                              onClick={ (e)=>{
                                this.removeBookmark()
                              } }
                              variant="primary" 
                              className={['Portfolio-Jumbotron-Button', 'Portfolio-Jumbotron-Button-Edit'].join(" ")}  >
                                {  this.props.gi18n[this.props.language]["unbookmarked"]   }
                            </Button>
                          }
*/