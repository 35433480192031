import React, { Component } from 'react';
import './index.scss';

import service from '../../public/service'
//import logoB from '../public/img/garlican_blue_logo.png'

import { Modal, Image, Form, Button,  FormControl} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUser, faBowlRice, faImage, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import gservice from '../../Garlican/GarlicanLibrary/gservice'

import getCroppedImg from './cropImage'

import Cropper from 'react-easy-crop'

import { Link } from 'react-router-dom'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactInputVerificationCode from "react-input-verification-code";

const links = [
  {url : '/privacy', name : 'privacy'},
  {url : '/terms', name : 'terms'},
  //{url : '/support', name : 'support'}
]


const settings = [
  { key : 'first_name', value : 'first_name', type : 'input' , size:'half', input_type : "input"},
  { key : 'last_name', value : 'last_name',type : 'input', size:'half', input_type : "input" },

  { key : 'phone', value : 'phone',type : 'phone', size:'full', input_type : "phone" },

  //{ key : 'email_name', value : 'email', type : 'input' , size:'full' , input_type : "email"},
  { key : 'description', value : 'description', type : 'textarea', size:'full'},
  
]

const COUNT_DOWN = 60


class User extends Component {

    constructor(props) {
        super(props)

        this.state = { 
          image : null,
          zoom : 1,
          crop : { x: 0, y: 0 },
          rotation : 0,
          isCrop : false,
          tempImage : null,
          showVertify : false,
          phone : ("phone" in props.user)?props.user.phone:"",
          first_name : ("first_name" in props.user)?props.user.first_name:"",
          last_name : ("last_name" in props.user)?props.user.last_name:"",
          description : ("description" in props.user)?props.user.description:"",
          vertication_code : "",
          isVertify : props.user.verified,
          count : 0,
        };

        window.document.user = this;
        this.handleChange = this.handleChange.bind(this)
        this.onCropComplete = this.onCropComplete.bind(this)
        this.updateInfo = this.updateInfo.bind(this)
        this.savePhone = this.savePhone.bind(this)

        this.hiddenFileInput = React.createRef()

        this.interval = null
    }

    componentDidMount() {
      //console.log( this.props.user  )
    }

    componentWillUnmount() {

    }

    UNSAFE_componentWillReceiveProps(props) {
      //console.log( props.user.avatar )
      var obj = {}
      if( props.user.avatar ) {
          obj.image =props.user.avatar
      }
      obj.first_name = props.user.first_name
      obj.last_name = props.user.last_name
      //console.log(obj)
      this.setState( obj )
    }

    async useCallback() {
      try {
        const croppedImage = await getCroppedImg(
          this.state.tempImage,
          this.state.croppedAreaPixels,
          this.state.rotation
        )
        service.postUserPicture( croppedImage ).then(
          (res)=> {
            this.setState({
              image : croppedImage,
              isCrop : false,
              tempImage : null,
            })
          }
        )
      } catch (e) {
        console.error(e)
      }
    }

    handleChange(e) {
      try {
        //console.log( URL.createObjectURL(e.target.files[0]) )
        this.setState({
          isCrop : true,
          tempImage: URL.createObjectURL(e.target.files[0])
        })
      }
      catch(e){

      }
    }

    onCropComplete(e, pix) {
      this.setState({
        croppedAreaPixels : pix
      })
    }

    updateInfo() {
      service.postUserProfile(this.state.first_name, this.state.last_name, this.state.description, "").then(
        (res)=> {
          //console.log("Update")
        }
      )
    }

    savePhone() {
      if ( this.state.phone.length > 0 ) {
        service.updatePhoneNumber( this.state.phone ).then(
          (res) => {
            if( res.type === "success" ) {
                service.user.phone = this.state.phone

                var _this = this
                this.interval = setInterval( () => {
                  
                  if( _this.state.count <= COUNT_DOWN  ) {
                    _this.setState({
                      count : (_this.state.count + 1)
                    })
                  } else {
                    _this.setState({
                      count : 0,
                      showVertify : false,
                    })
                  }
                }, 30000 )

                _this.setState({
                  showVertify : true
                })
            }
          }
        )
      }
    }

    vertifyPhone(code) {
      service.vertifyPhone(code).then(
        (res) => {
          if( res.type === "success" ) {

            clearInterval( this.interval )

            this.interval = null
            service.user.verified = true
            this.setState({
              isVertify : true,
              showVertify : false,
              count : 0,
            })
          } 
        }
      )
    }

    render() {
        return (
          <Modal
              centered
              size="lg"
              show={this.props.showUser}
              onHide={() => this.props.updateValue( {  showUser  : false  }  )}
              aria-labelledby="example-modal-sizes-title-lg"
              className={ ['User-Holder'].join( " " ) }>
              <Modal.Header className={ ['User-Title'].join( " " ) } closeButton>
                <div className={['User-Title-Frame'].join(" ")}>
                  {
                    this.props.logosrc && 
                    <Image className={['User-Title-Logo'].join(" ")} src={this.props.logosrc} />  
                  }
                  { !this.props.logosrc && 
                    <FontAwesomeIcon icon={ faBowlRice } className={['User-Title-Logo'].join(" ")}  />
                  }
                  <div className={['User-Title-Title'].join(" ")} >{  this.props.gi18n[this.props.language]['controller_greeting'].replace('<user>', service.user.first_name)  }</div>
                </div>
              </Modal.Header>
  
              <Modal.Body className={ ['User-Body'].join( " " ) }>

              { this.state.tempImage && this.state.isCrop && 
                <div className={['User-Body-Image-Container'].join(' ')}>
                  <Cropper
                    image={this.state.tempImage}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    rotation={this.state.rotation}
                    aspect={  4/4 }
                    cropSize={ { width: 100, height: 100 } }
                    minZoom={0.1}
                    onRotationChange={(e) => {
                      this.setState({ rotation : e })   
                    } }
                    onCropChange={  (e) => {
                      this.setState({ crop : e })   
                    } }
                    onCropComplete={   this.onCropComplete   }
                    onZoomChange={  (e) =>  {
                      this.setState({ zoom : e })  
                    }}
                  />
                  <Button 
                    className={['User-Body-Image-Btn'].join(' ')}
                    onClick={ (e)=>{
                        this.useCallback()
                    }}>
                          {  this.props.gi18n[this.props.language]["save"] }
                  </Button>
                </div>
              }

              { (this.state.image && !this.state.isCrop) && 
                <Image src={ this.state.image} className={['User-Body-Image'].join(' ')}/>
              }
              { (!this.state.image && !this.state.isCrop) && 
                <FontAwesomeIcon  icon={faUser} className={['User-Body-Image', "Fa"].join(' ')}/>
              }                       

                  <div className={['User-Body-Upload'].join(' ')}>
                        <FontAwesomeIcon onClick={ (e)=>{
                            this.hiddenFileInput.current.click()
                          }} 
                          icon={faImage }
                          className={["Fa"].join(" ")}
                        />
                        <Form.File id="upload" label="" onChange={this.handleChange} ref={this.hiddenFileInput} style={{ display : 'none'}} accept="image/png, image/jpg, image/jpeg"/>
                  </div>

                  <div className={['User-Body-Info'].join(' ')}>              
                      {
                        settings.map( (v,k) => {
                          if( v.type === "textarea") {
                            return(
                              <div key={k} className={[(v.size==="half")?"Half":"Full", "Wrapper"].join(" ")} >
                                <div className={['WLabel'].join(" ")}>{ this.props.gi18n[this.props.language][v.value] }</div>
                                <FormControl
                                  className={["Textarea"].join(" ")}
                                  as="textarea"
                                  value={ this.state[ v.key ] } placeholder={ this.props.gi18n[this.props.language]['description_msg'] }  
                                  onChange={
                                    (e)=> {
                                      var obj = {}
                                      obj[v.key] = e.target.value
                                      this.setState( obj )
                                    }
                                  }
                                />
                              </div>
                            )
                          } else if( v.type === "phone") {
                            if( this.props.isOwner ) {
                              return(
                                <div key={k} className={[(v.size==="half")?"Half":"Full", "Wrapper", "PWrapper"].join(" ")} >
                                  <div className={['PLabel'].join(" ")}>{ this.props.gi18n[this.props.language][v.value] }</div>
                                  { !this.state.showVertify &&
                                    <div className={['PBody'].join(" ")}>
                                      <PhoneInput
                                        country={'hk'}
                                        value={ this.state[v.value] }
                                        onChange={ (phone)=>{
                                          var obj = {}
                                          obj[v.key] = phone

                                          if( (phone === service.user.phone) && ( service.user.verified ) ) {
                                            obj.isVertify = true
                                          } else {
                                            obj.isVertify = false
                                          }
                                          this.setState( obj )
                                        } } 
                                      >
                                      </PhoneInput>
                                      
                                      <FontAwesomeIcon className={["Vertify", !this.state.isVertify?"Unvertify":""  ].join(" ")} icon={ this.state.isVertify?faCheckCircle:faTimesCircle } />

                                      <Button
                                        className={['Send-Btn', "btn"].join(" ")}
                                        onClick={ (e) => this.savePhone(  )  }
                                        disabled={ this.state.isVertify || ( this.state.count > 0) }
                                      >
                                        {"Send" + (!this.state.isVertify?("("+ (COUNT_DOWN - this.state.count) +")"):"")  }
                                      </Button>
                                    </div>
                                  }
                                  {
                                    this.state.showVertify &&
                                    <div className={['PVertify'].join(" ")}>
                                      <ReactInputVerificationCode 
                                        type={"text"}
                                        onChange={(e)=>{
                                          console.log(e)
                                          this.setState({
                                            vertication_code : e
                                          })
                                        }} 
                                        length={6}/>
                                      
                                      <div className={['PVertify-Wrapper'].join(" ")}> 
                                        <Button className={['PVertify-Btn', "btn", "Left"].join(" ")}
                                          onClick={ (e)=>{
                                            this.setState({
                                              showVertify : !this.state.showVertify
                                            })
                                          }
                                      }>
                                          {"Back"}
                                        </Button>
                                        <Button className={['PVertify-Btn', "btn", "Right"].join(" ")}
                                          onClick={ (e)=>{
                                            console.log( this.state.vertication_code )
                                            //this.vertifyPhone(  this.state.vertication_code  )
                                          } }
                                        > 
                                          {"Vertify"}
                                        </Button>
                                      </div>
                                    </div>
                                  }
                                </div>
                              )
                            } else {
                              return null
                            }
                          } else {
                            return(
                              <div key={k} className={['Half', "Wrapper"].join(" ")} >
                                <div className={['WLabel'].join(" ")} >{this.props.gi18n[this.props.language][v.value] } </div>
                                <FormControl
                                  type={v.input_type} value={ this.state[ v.key ] }  
                                  className={['Value'].join(" ")}
                                  placeholder={  this.props.gi18n[this.props.language]['input_msg'].replace("<val>",   this.props.gi18n[this.props.language][v.value]  ) } 
                                  onChange={
                                    (e)=> {
                                      var obj = {}
                                      obj[v.key] = e.target.value
                                      this.setState( obj )
                                  }}
                                  aria-label="Default"
                                  aria-describedby="inputGroup-sizing-default"
                                />

                            </div>
                            )
                          }
                        }  )
                      }
                  </div>

                  <div className={ ['LWrapper'].join( " " ) }>
                    <Form.Control id={"mylink"} className={ [ 'Link'].join( " " ) } readOnly={true} value={  window.location.origin + "/" + gservice.encodeObject( "user" + this.props.user.user_id ) }  
                      onChange={
                        (e)=> {
                        }
                      }
                    />
                    <Button onClick={ (e)=>{
                      //var link = window.location.origin + "/" + gservice.encodeObject( "user" + this.props.user.user_id )
                      var copyText = document.getElementById("mylink");
                      copyText.select();
                      copyText.setSelectionRange(0, 99999);
                      document.execCommand("copy");

                    }  }>  { this.props.gi18n[this.props.language]['copy'] }   </Button>
                  </div>

                  <div className={ ['User-Btn-Frame'].join( " " ) }>
                    <Button className={ ['User-Btn'].join( " " ) } onClick={ (e)=>this.updateInfo() } > { this.props.gi18n[this.props.language]['update']  }</Button>
                    <Button className={ ['User-Btn'].join( " " ) } onClick={ (e)=>this.props.logout() } > { this.props.gi18n[this.props.language]['logout']  }</Button>
                  </div>


                  <div className={ ['User-Bottom-Frame'].join( " " ) }>
                    <div className={['Copyright'].join(" ")} >2023 &copy; GARLICAN TECH LIMITED </div>
                    {
                      links.map( (v,k) => {
                        return(
                          [ (k!==0)?(<span key={99 }>|</span>):null , 
                            <Link key={k} to={v.url} className={ ['Footer-Frame-Control-a'].join( " " ) } >
                              { this.props.gi18n[this.props.language][v.name] }
                            </Link>
                          ]
                        )
                      } )
                    }

                    
            

                  </div>


            </Modal.Body>
          </Modal>
        )
    }
}


export default User;

/*                              
                              <Form.Group key={k} className={["mb-3", (v.size==="half")?"half":""].join(" ")} >
                                <Form.Label>{ this.props.gi18n[this.props.language][v.value] }</Form.Label>
                                <Form.Control 
                                  className={["text"].join(" ")}
                                  as="textarea" rows={3} value={ this.state[ v.key ] } placeholder={ this.props.gi18n[this.props.language]['description_msg'] }  
                                  onChange={
                                    (e)=> {
                                      var obj = {}
                                      obj[v.key] = e.target.value
                                      this.setState( obj )
                                    }
                                  }
                                />
                              </Form.Group>
*/             

//onHide={() => this.props.updateValue( {  showUser  : false  }  )}