import React from "react";
//import gglobal from '../GarlicanLibrary/gvars'

import './index.scss'

import GService from '../../../Garlican/GarlicanLibrary/gservice'
import Api from '../../../Garlican/GarlicanLibrary/apis'
import service from '../../../public/service'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser, faCaretUp, faCaretDown, faCog } from '@fortawesome/free-solid-svg-icons'
import {  Button, Image, Badge, InputGroup, FormControl, Toast, Modal   } from 'react-bootstrap';

const SIZE = 30

export default class Content extends React.Component {

  constructor(props) {
    super(props);  
    
    this.state = {
      serachTemplate : '',
      templates : [],
      page : 0,
      obj : {},
      hasNext : false,
      show : false,
    } 
    
    this.reset = this.reset.bind(this)
    this.search = this.search.bind(this)
    this.getTemplates = this.getTemplates.bind(this)
    this.more = this.more.bind(this)

    //this.remove = this.remove.bind(this)
    //this.addStrategy = this.addStrategy.bind(this)
    
  }

  componentDidMount() {
    //if(this.props.add) {
      var obj = {}
      obj.region = this.props.region
      this.getTemplates(obj)
    //}
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
    if( props.region !== this.props.region ) {
      //console.log( this.props.region )
      this.getTemplates({ region : this.props.region })
    }
  }

  componentWillReceiveProps(props) {
    //console.log(  props.showTemplates  )
    if( props.showTemplates === true ) {    
        //this.getTemplates()
    }
  }

  getTemplates(obj, append) {
    service.getTemplates( obj ).then(
      (res) => {
        var list = res.list, hasNext = false
        if( list.length >= SIZE ) {
          hasNext = true
        }
        if(append) {
          var templates = JSON.parse( JSON.stringify( this.state.templates ) )
          list = templates.concat( list )
        }
        var o = {
          templates : list,
          page : res.page,
          hasNext : hasNext,
        }
        if(obj) {
          o.obj = obj
        }
        this.setState(o)
      }
    )
  }

  reset() {
    this.setState({
      obj : {},
      serachTemplate : "",
    })
    this.getTemplates()
  }

  search(str){
    var obj = JSON.parse(  JSON.stringify( this.state.obj ) )
    obj.page = 0
    if( str.length > 0) {
      obj['search'] = str
    }
    else {
      delete obj['search']
    }
    this.setState( { obj : obj} )
    this.getTemplates(obj)
  }

  more(){
    var page = this.state.page
    var obj = JSON.parse(  JSON.stringify( this.state.obj ) )
    obj.page = page +1
    this.setState({
      obj : obj,
      page : (page +1)
    })
    this.getTemplates(obj,true)

  }

  render() {


    return (

        <div className={['Garlican-Content-Template-Container' ,  "show"].join(' ')}>   

            <Button className={['Manage'].join(" ")} onClick={
              (e) => {
                this.props.updateParent({
                  selected : 'template',
                  selectedIndex : 0,
                  selectedStrategy : {},
                  selectedBotfolio : {},
                  showCreate : false,
                })
              }
            }>
              <FontAwesomeIcon icon={faCog} className={['Fa'].join(" ")} />
              { this.props.gi18n[ this.props.language]['my_template'] }
            </Button>

            <h4 className={['Garlican-Content-List-Title'  ].join(' ')}> 
              { this.props.gi18n[this.props.language]['insert_template'] }
            </h4>
            
            { this.props.add && 
              <Button className={['Garlican-Content-List-Add'  ].join(' ')} onClick={
                (e)=>{
                  //console.log(   )
                  //var code = GService.createFirstCode(this.props.language  )
                  //var obj = GService.createStrategyObject(code, this.props.gi18n[ this.props.language ]['new_strargey'])
                  this.props.addStrategy(  )
                  //this.props.updateValue( {  showTemplates : false   } )
                }
              }>{ this.props.gi18n[this.props.language]['blank'] }</Button>
            }

            <InputGroup className={["Garlican-Content-Template-Search", "mb-3"].join(' ')}>
							<FormControl
								placeholder={ this.props.gi18n[this.props.language]['search_template'] }
								aria-label={ this.props.gi18n[this.props.language]['search_template'] }
								aria-describedby="basic-addon2"
								value={ this.state.serachTemplate }
								onChange={ (e)=>{
										this.setState({
											serachTemplate : e.target.value
										})
								}}
							/>
								<Button variant="outline-secondary" 
									className={["fa", 'reset'].join(' ')}
										onClick={
											(e) => {
												this.reset(  )
											}
										}>
									{ this.props.gi18n[this.props.language]['reset_template'] }
								</Button>
								<Button variant="outline-secondary" 
									onClick={
										(e) => {
											this.search( this.state.serachTemplate)
										}
									}>
									<FontAwesomeIcon 
											className={["fa"].join(' ')}
											icon={ faSearch } 
									/>
								</Button>
						</InputGroup>
            <div className={['Garlican-Content-List-Panel'  ].join(' ')}>
                <div 
                  className={['Item', (("user_id" in this.state.obj)?"":"selected")   ].join(' ')}
                  onClick={ (e)=> {
                    var obj = JSON.parse(  JSON.stringify( this.state.obj ) )
                    obj['user_id'] = service.user.user_id
                    obj.page = 0
                    if( "user_id" in obj) {
                      delete obj['user_id']
                      this.setState({ obj : obj })
                    }  
                    this.getTemplates(obj)
                }}> {  this.props.gi18n[this.props.language]['all_template']  } </div>
                <div 
                  className={['Item', (("user_id" in this.state.obj)?"selected":"")   ].join(' ')}
                  onClick={ (e)=> {
                    var obj = JSON.parse(  JSON.stringify( this.state.obj ) )
                    obj['user_id'] = service.user.user_id
                    obj.page = 0
                    this.setState({ obj : obj })
                    this.getTemplates(obj)
                }}> {  this.props.gi18n[this.props.language]['my_template']  }  </div>
            </div>
            <div className={['Garlican-Content-List-Container'  ].join(' ')}>
              { (this.state.templates.length > 0) &&
                this.state.templates.map( (v,k) => {
                  return(
                    <Toast
                      className={[ this.props.inBoard?'Garlican-Content-List-Full':"" , 'Garlican-Content-List'  ].join(' ')}
                      key={k}
                      onClose={
                        (e)=>{
                          this.props.remove( v )
                          this.getTemplates( this.state.obj )
                          //service.isOwner( v.user_id )
                        }
                      }
                    > 
                      <Toast.Header closeButton={ service.isOwner( v.user_id ) } > 
                        <strong className="me-auto">{  v.name }</strong> 
                      </Toast.Header>
                      <Toast.Header className={['name'].join(" ")} closeButton={false}> 
                        <strong className={["me-auto"].join(" ")} >
                          <span> {this.props.gi18n[this.props.language]['powered']  }</span>
                          <span>
                              { v.user.avatar &&
                                <Image variant="top" src={  v.user.avatar  } className={['pic'].join(' ')} />
                              }
                              { !v.user.avatar && 
                                <FontAwesomeIcon  icon={faUser} className={['pic'].join(' ')}/>
                              }
                          </span>
                          <span> { v.user.first_name } </span>
                        </strong> 
                      </Toast.Header>
                      <Toast.Body className={['content', (this.state.show===v.id)?"active":"" ].join(" ")}>
                          <Badge className={[ ('badge-'+((v.access_mode==="private")?'protected':v.access_mode) )   ].join(' ')} > {   this.props.gi18n[this.props.language][ ((v.access_mode==="private")?'protected':v.access_mode) +"_mode"]    } </Badge>
                          <div className={['description'].join(" ")}>{  v.description  }</div>
                      </Toast.Body>
                      <Toast.Body className={['control'].join(" ")} onClick={
                        (e) => {
                          if( this.state.show !== v.id ) {
                            this.setState({ show : v.id })
                          } else {
                            this.setState({ show : null })
                          }
                        }
                      }>
                          <FontAwesomeIcon className={['fa'].join(" ")} icon={ this.state.show?faCaretUp:faCaretDown } />
                      </Toast.Body>
                      <Toast.Body className={ ["Use-Wrapper"].join(" ") }>
                          <Button 
                            className={ ["Use", 
                                (this.props.strategy_list.length > 0)?
                                (
                                  (this.props.strategy_list[0].strategy_template_id === v.id )?
                                    "Used":""
                                ):""
                            ].join(" ") }
                            onClick={ (e)=>{
                              v.strategy_template_id = v.id
                              if(this.props.add) {
                                this.props.addStrategy(v)
                              } else {
                                this.props.convertToTemplate( this.props.id, v )
                              }
                            }}>
                            {  
                                (this.props.strategy_list.length > 0)?
                                  (
                                    (this.props.strategy_list[0].strategy_template_id === v.id )?
                                      "Used":this.props.gi18n[this.props.language]['insert'] 
                                  ):this.props.gi18n[this.props.language]['insert'] 
                            } 
                          </Button>
                      </Toast.Body>
                    </Toast>
                  )
                })
              }
              {
                (this.state.templates.length === 0) &&
                <div>{  this.props.gi18n[this.props.language]['no_template']  } </div>
              }
              {
                (this.state.templates.length !== 0) && (this.state.hasNext) &&
                <Button 
                  onClick={
                    (e)=>{
                      this.more()
                    }
                  }
                  className={['Garlican-Content-List-More'].join(' ')}>
                    { this.props.gi18n[this.props.language]['more'] }
                </Button>
              }
            </div>

        </div>

    );
  }
}



