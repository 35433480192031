import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faArrowCircleUp, faArrowCircleDown, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import service from '../../public/service'

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import gcolor from '../../public/color.js';

const gd = [
  {  x: "risk_rate" , y: 0 },
  {  x: 'win_rate' , y: 0 },
  {  x: "quantity_rate" , y: 0 },
  {  x: "profit_rate" , y: 0  },
]
const frame = {
  "risk_rate" : 100,
  'win_rate' : 100,
  "quantity_rate" : 100 ,
  "profit_rate" : 100 
}

const MAX_SIZE = 30;

class PortfolioPoint extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          showBoard : false,
          data : gd,
          cdata : gd,
          maxima : frame,
          isOwner : false,
        };        
        this.getRank = this.getRank.bind(this)
        this.compareGD = this.compareGD.bind(this)
    }

    componentDidMount() {
      //console.log( this.props.obj )
      var obj = {}
      var showBoard = this.props.obj.backtest_report?( (Object.keys(this.props.obj.backtest_report).length > 0)?true:false ):false  
      var _gd = this.compareGD( this.props.obj )
      obj = { 
        data : _gd,
        showBoard : showBoard,
        isOwner : service.isOwner(  this.props.obj.user.user_id   ),
      }
      if( this.props.compare ) {
        obj.cdata = this.compareGD( this.props.compare )
      }

      this.setState(  obj  )
    }

    UNSAFE_componentWillReceiveProps(props) {
      if( props.overview_updates.includes('cdata') ) {
        var showBoard = props.obj.backtest_report?( (Object.keys(props.obj.backtest_report).length > 0)?true:false ):false
        var obj = {
          showBoard : showBoard,
          isOwner : service.isOwner(  props.obj.user.user_id   ),
        }
        if(  props.compare ) {
          obj.cdata = this.compareGD( props.compare )
        }
        var _this = this
        this.setState( obj )
      }      
    }

    componentWillUnmount() {
    }

    compareGD( c ) {
      var _gd = gd
      if( c ) {
        _gd = [
        {  x: "risk_rate" , y: Math.round( (c['total_portfolios'] - c['sharpe_ratio_rank'] + 1)/c['total_portfolios']*10000)/100 },
          {  x: 'win_rate' , y: Math.round( (c['total_portfolios'] - c['win_rate_rank'] + 1)/c['total_portfolios']*10000)/100 },
          {  x: "quantity_rate" , y: Math.round( (c['total_portfolios'] - c['turnover_rate_rank'] + 1)/c['total_portfolios']*10000)/100 },
          {  x: "profit_rate" , y: Math.round( (c['total_portfolios'] - c['profit_rate_rank'] + 1)/c['total_portfolios']*10000)/100  },
        ]
      }
      return _gd
    }

    getRank(score) {

      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      
      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }

      var s = "F-"
      var maxColor = hexToRgb( gcolor.gnFrameColor )
      var minColor = hexToRgb( gcolor.gnWhiteColor )

      var obj = { r: 0, g:0, b:0}
      for (var c in obj) {
        obj[c] = Math.round( (maxColor[c] - minColor[c] ) * score/100 + minColor[c] )
      }

      var color = rgbToHex( obj.r, obj.g, obj.b )

      for( var i in service.score_scale ) {
        if(  score > service.score_scale[i].score ) {
          s = service.score_scale[i].rank
          break;
        }
      }
      
      return { rank : s, color : color }
    }
    
    render() {
        var valid = this.props.obj.backtest_report?(!this.props.obj.subscription_id && this.props.obj.backtest_report && !this.props.obj.backtest_report.overdraft):false
        var overdraft = this.props.obj.backtest_report?this.props.obj.backtest_report.overdraft:false
        var gain = this.props.obj.backtest_report?(this.props.obj.backtest_report.profit>0):false
        var lost = this.props.obj.backtest_report?(this.props.obj.backtest_report.profit<0):false
        var purchased = (this.props.obj.subscription_id!==null) || this.props.obj.hasPurchased
        var onhold = (this.props.obj.id in this.props.onhold)?(this.props.onhold[this.props.obj.id].length):0
        var recent = (this.props.obj.forwardtest_report || this.props.obj.realtrade_report)

        return (
          <OverlayTrigger 
                placement="bottom"
                overlay={
                <Tooltip 
                  bsPrefix={"Glance TP"}> 
                  <div className={['Name']}> { this.props.obj.name  } </div>
                  <div className={['Title'].join(' ')}> 
                    { this.props.gi18n[this.props.language][ "initial" ] } 
                  </div>
                  <div className={['Capital']}> 
                    {
                      overdraft?
                      this.props.gi18n[this.props.language]['invalid_botfolio'] 
                      :
                      ("$" + ((this.props.obj.backtest_report)? service.getNumberFormat(this.props.obj.backtest_report.initial_capital):service.getNumberFormat(this.props.obj.initial_capital)   ) )
                    }
                  </div>
                  <div className={['Profit', gain?'Gain':( lost?"Lost":"Normal") ].join(" ")}> 
                    <FontAwesomeIcon  icon={  
                        (gain)?faArrowCircleUp:((lost)?
                          faArrowCircleDown:faMinusCircle)  
                      } className={[ 'fa' ].join(' ')}/> 
                    <span>
                      {  
                          ("$" + 
                          (this.props.obj.backtest_report?Math.abs(Math.round(this.props.obj.backtest_report.profit)):0)
                          + 
                          " (" +
                          (this.props.obj.backtest_report?(Math.abs(Math.round(this.props.obj.backtest_report.profit/this.props.obj.backtest_report.initial_capital * 10000)/100) + "%"):0)
                          + 
                          ")"
                          )
                      } 
                    </span>
                  </div>
                </Tooltip>
          }>
            <div 
              className={[
                  'Portfolio-Point',  
                  this.props.selectedPortfolio?((this.props.selectedPortfolio.id === this.props.obj.id)?"selected":"noselected"):""  
              ].join(" ")} 
              onClick={ (e)=>{ 
                try {
                  if(e.target.className) {
                    if( (e.target.className.indexOf('remove') === -1) || (e.target.className.indexOf('Star') === -1) ) {
                      if(this.props.selectedPortfolio) {
                        if(  this.props.obj.id !== this.props.selectedPortfolio.id ) {
                          this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                        } else {
                          this.props.updateParentValue({ selectedPortfolio : null, showPortfolio :false, purchased :this.props.purchased  }) 
                        }
                      } else {
                        this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                      }
                    }
                  } else {
                    if(this.props.selectedPortfolio) {
                      if(  this.props.obj.id !== this.props.selectedPortfolio.id ) {
                        this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                      } else {
                        this.props.updateParentValue({ selectedPortfolio : null, showPortfolio :false, purchased :this.props.purchased  }) 
                      }
                    } else {
                      this.props.updateParentValue({ selectedPortfolio : this.props.obj, showPortfolio :true, purchased :this.props.purchased  }) 
                    }
                  }
                }
                catch(e) {
                  
                }
              } }
              style={
                {
                  left : "calc(" + (this.state.data[3].y) + "% - " + (Math.max( this.state.data[1].y/100 * MAX_SIZE , 5 )/2) + "px)",
                  top : "calc(" + (100-this.state.data[0].y) + "% - " + (Math.max( this.state.data[1].y/100 * MAX_SIZE , 5 )/2) + "px)",
                  width : (Math.max( this.state.data[1].y/100 * MAX_SIZE , 5 ) - 4) + "px",
                  height : (Math.max( this.state.data[1].y/100 * MAX_SIZE , 5 ) -4 ) + "px",
                  backgroundColor : this.getRank( this.state.data[2].y  ).color
                }
              }
            >
            </div>
          </OverlayTrigger>
        )
    }
}


export default PortfolioPoint;




/*
<div className={['Chart'].join(' ')}>
                <div className={['Cover'].join(' ')}> </div>
                { (this.state.data.length > 0) &&
                                    <VictoryChart 
                                      polar
                                      width={300}
                                      height={300}
                                      style={{
                                        pointerEvents: 'none',
                                      }}
                                      className={['svgClass', 'Diagram'].join(' ')}
                                      animate={{
                                        duration: 1000,
                                        onLoad: { duration: 500 }
                                      }}>
                                        <VictoryArea data={this.state.data}
                                          className={['svgClass'].join(' ')}
                                          style={{ data: { fill: gcolor.gnAccentColor+"17", stroke: gcolor.gnAccentColor, strokeWidth: 1.00,pointerEvents: 'none',  } }}/>

                                        { this.props.compare &&
                                          <VictoryArea data={this.state.cdata}
                                            className={['svgClass'].join(' ')}
                                            style={{ data: { fill: gcolor.gnBadgeColor+"17", stroke: gcolor.gnBadgeColor, strokeWidth: 1.00,pointerEvents: 'none',  } }}/>
                                        }

                                        {
                                            Object.keys(this.state.maxima).map((key, i) => {
                                              var score = this.getRank( this.state.data[i].y ).rank
                                              return (
                                                <VictoryPolarAxis key={i} 
                                                  dependentAxis
                                                  className={ ['Radar'].join(' ') }  
                                                  style={{
                                                      axisLabel: { padding: 5, fontSize : 12.5, fill : gcolor.gnFrameColor },
                                                      tickLabels: { fontSize :0, fill : gcolor.gnFrameColor },
                                                      axis: { stroke: gcolor.gnFrameColor3 },
                                                      grid: { stroke: gcolor.gnFrameColor3, strokeWidth: 0.25, opacity: 0.25 }
                                                  }}                           
                                                  tickLabelComponent={
                                                      <VictoryLabel labelPlacement="vertical" />
                                                  }
                                                  labelPlacement={ ((i+1)%2==0)?"perpendicular":"vertical"  }
                                                  axisValue={i + 1} 
                                                  tickValues={[25, 50, 75, 100]}
                                                  label={ 
                                                      (
                                                        this.props.gi18n[this.props.language][key] 
                                                        + 
                                                        (score?( "\n("+ score+")"):"") 
                                                      )
                                                    }
                                                    
                                                />
                                              );
                                            })
                                        }
                                        <VictoryPolarAxis
                                          theme={VictoryTheme.material}
                                          labelPlacement="parallel"
                                          tickValues={[25, 50, 75, 100]}
                                          tickFormat={ (k)=>"" }
                                          style={{
                                                axis: { stroke: "none" },
                                                grid: { stroke: gcolor.gnFrameColor3, opacity: 0.25 }
                                          }}
                                        />


                                    </VictoryChart>
                }
              </div>
*/