import React, { Component } from 'react';
import './App.scss';

//import _colors from "./public/color";


//import { w3cwebsocket as W3CWebSocket } from "websocket";

import service from './public/service'
import GService from './Garlican/GarlicanLibrary/gservice';
import Api from './Garlican/GarlicanLibrary/apis'
//import gcolor from './public/style.scss';
import gcolor from './public/color.js';

//import logoB from './public/img/garlican_blue_logo.png'
//import logo from window.logo
//console.log( window.logo )

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlRice  } from '@fortawesome/free-solid-svg-icons'


import Login from './User/Login/'
import User from './User/Edit/'
import Controller from './Controller/'
import Footer from './Footer'

import Alert from './Components/Alert'
import Loading from './Components/Loading'
import Overview from './Overview/'
import Portfolio from './Portfolio/'
import Manager from './Manager/'

import PrivacyScreen from './Other/Privacy/PrivacyScreen'
import TermsScreen from './Other/Terms/TermsScreen'
import SupportScreen from './Other/Support/SupportScreen'

import Stripe from './Components/Stripe'

import { Modal, Spinner, Image } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

//import Automate from './Components/Automate/'
//import Apis from './Garlican/GarlicanLibrary/apis';
//import id from '@xmpp/id';
//import { contours } from 'd3';

//import celebrity from './public/celebrity'
//import { isConstructorDeclaration } from 'typescript';

var MAX_ATTP = 5
const defaultColor = gcolor.gnBadgeColor
var colors = []
var ColorScheme = require('color-scheme');
var scheme = new ColorScheme;
scheme.from_hue(50)        // Start the scheme 
    .distance(0.25)
	.scheme('analogic')     // Use the 'triade' scheme, that is, colors
                             // selected from 3 points equidistant around
                             // the color wheel.
	.variation('soft')   // Use the 'soft' color variation
colors = scheme.colors(defaultColor);
colors.splice( 0, 0 , defaultColor.replace("#", "") )
colors =  Array.from( {length: colors.length  }, (x,i) => "#" + colors[i] )


const RETRY = 60000
const pages = [
	{ key : '/',value : 'overview',comp : Overview },
	{ key : 'me',value : 'overview2', comp : Overview },


	{ key : 'automate',value : 'overview',comp : Overview },
	{ key : 'trade',value : 'overview',comp : Overview },
	{ key : 'upgrade',value : 'overview',comp : Overview },
	{ key : 'forget_password',value : 'overview', comp : Overview },


	{ key : 'bookmark',value : 'overview2', comp : Overview },
	{ key : 'purchased',value : 'overview2', comp : Overview },
	//{ key : 'garlican',value : 'garlican', comp : Garlican, type : 'popup' },
	{ key : 'manager',value : 'manager', comp : Manager /*frame : 'dark'*/  },
	{ key : 'privacy',value : 'privacy', comp : PrivacyScreen},
	{ key : 'terms',value : 'terms', comp : TermsScreen },
	{ key : 'support',value : 'support', comp : SupportScreen },
	//{ key : 'trader',value : 'trader', comp : Trader, type : 'pop' },

	
	{ key : ':user_id',value : 'overview2', comp : Overview },
	{ key : ':user_id/:portfolio_id',value : 'overview2', comp : Overview },
	
	{ key : '/*' , comp : Redirect }
]

const MIN_INTERVAL = 30

class App extends Component {
  	
  	constructor(props) {
  		super(props);
		var isSimulate = true, isHost = true, zcode=null, isCallBack=false
		var language = service.checkSystemLanguage()
		var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
		var _lang  = storage.getItem("garlican@language")
		if ( _lang ) {
			for ( var i in window.config.languages) {
				if( window.config.languages[i].key === _lang ) {
					language = _lang
					break
				}
			}			
		} 
		var refs = {}
		for (var p in pages) {
			refs[ pages[p].value ] = React.createRef()
		}
		this.timeinterval = null
		GService.language = language
		this.state = {
			updated : [],
			gi18n : {},
			languages : [],
			alertMessage : '',
			scrollTop : 0,
			selectedPortfolio : null,
			showLogin : false,
			showSignup : false,
			showUser : false,
			showAlert : false,
			showGarlican : false,
			showPortfolio : false,
			editedPortfolio : {},
			isCallBack : isCallBack,
			isSimulate : isSimulate,
			language : language,
			isLogin :  false,
			display_name : "",
			token : "",
			e_token : "e_token",
			refresh_token : "refresh",
			username : service.detect()?'test1':'test2',
			password : 'password',
			rooms : [],
			roomname : 'test1',
			isHost: isHost,
			jid : null,
			isYoutube : null,
			showLoading : false,
			showSelection : false,
			showCanvas : false,
			selectedUser : null,
			filterconfig : {},
			retrieve_key : null,
			refs : refs,
			showTrader : false,
			child : null,
			tpage : "onhold",
			forwardGroup : [],
			//orginalforwardGroup : [],
			onhold : {},
			sym_names : {},
			board_lot : {},
			dummy : {},
			list : [],

			purchased : {},

			showAutomate : false,
			interval : MIN_INTERVAL,
			notifications : [],
			isNotify : false,
			notifiy_list : [],
			
			//demo : false, 
			//scope : "us",
			//hasFilter : true,
			//trader : false, 
			
			regions : [],
			region : "hkg",
			logosrc : window.logosrc?window.logosrc:null,
			hasLogo : window.config.logo,

			isPayment : false, 

		}
		if( !isCallBack ) {
			var _this = this
			setTimeout(
				(e) => {
					var tokens = storage.getItem("garlican@tokens")
					var obj = {}
					if( tokens ) {
						try {
							tokens = JSON.parse(tokens)
							obj.refresh = tokens.refresh
							obj.username = tokens.username
							obj.id = tokens.id
							if( obj.refresh ) {
								_this.getRefresh( obj.username , obj.refresh, obj.id )
							}
						}
						catch(e) {
							//console.log(e)
						}
					}
				}, 500
			)
			

		}
		this.updateValue = this.updateValue.bind(this)
		this.connected = this.connected.bind(this)
		this.hasLogin = this.hasLogin.bind(this)
		this.getRefresh = this.getRefresh.bind(this)
		this.scroll = this.scroll.bind(this)
		this.logout = this.logout.bind(this)
		this.openAlert = this.openAlert.bind(this)
		this.closeAlert = this.closeAlert.bind(this)
		this.getPortfolioDetailById = this.getPortfolioDetailById.bind(this)
		this.createLangaugePack = this.createLangaugePack.bind(this)
		this.callList = this.callList.bind(this)
		this.app = React.createRef()
		this.trader = React.createRef()
		this.pop = this.pop.bind(this)
		this.unpop = this.unpop.bind(this)
		this.call_parent = this.call_parent.bind(this)

		this.getOnholdAsset = this.getOnholdAsset.bind(this)
		this.rebuildAsset = this.rebuildAsset.bind(this)
		this.updateFG = this.updateFG.bind(this)

		this.get = this.get.bind(this)

		this.subscribePortfolio = this.get.bind(this)

		this.forwardtesting = this.forwardtesting.bind(this)

		this.notifiy = this.notifiy.bind(this)
		this.runNotfication = this.runNotfication.bind(this)

		this.retrieve_notfication = this.retrieve_notfication.bind(this)

		this.postNotifcation = this.postNotifcation.bind(this)
		this.createParams = this.createParams.bind(this)

		this.comp = this.comp.bind(this)
  	}

	componentDidMount() {
		/*
		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification");
		} else {
			Notification.requestPermission().then( 
				(permission) => { 
					console.log('permiss', permission)
				}
			);
		}
		*/
		var _this = this
		setTimeout(
			() => {
				//var scope = ('region' in window.config )?window.config.region[0].scope:"hk"
				if( window.config ) {
					var regions = window.config.region
					var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
					var obj = {}
					function _load(ct , date) {
						var region = regions[ct].region
						Api.getFilterConfig( "name" ,  region,  date  ).then(
							(names) => {
								
								var sym_names = names['names']
								for (var i in sym_names) {
									sym_names[i].region = region
									obj[ i ] = ( sym_names[i] )
								}
								ct += 1
								if(  ct === (regions.length)   ) {
									_this.setState({ sym_names : obj })
									var s = { names : obj, date : names.date  } 
									storage.setItem(  "garlican@symbols",  JSON.stringify(  s  )  )
									_this.createLangaugePack( {},  0)

								} else {
									_load( ct, date )
								}

							}
						)
					}

					try {
						
						var sym_name = storage.getItem("garlican@symbols")
						sym_name  = JSON.parse( sym_name )
						obj = sym_name.names
						if( sym_name.date ) {
							_load( 0 , sym_name.date )
						}  else {
							_load(0)
						}
						
					} catch(e) {
						storage.setItem("garlican@symbols" , "")
						obj = {}
						_load( 0 )
					}

					

				}
			}, 300
		)
		window.document.page = this
		window.addEventListener("beforeunload", (ev) => {  
			ev.preventDefault();
			if( this.state.child ) {
				this.state.child.close()
			}
			return ev.returnValue = 'Are you sure you want to close?';
		});

		//https://lambda.garlican.com/api/getpattern
		
		//Apis.test().then(
		//	(res) => {
		//		return res
		//	}
		//)


	}

	call_parent(msg, bool) {
		alert(msg)
		//if(this.state.child) {
		//	this.state.child.call_app("trader", "show", "Connected")
		//}
	}

	componentWillUnmount() {
		//this.xmpp.destroyRoom(this.state.roomname + "_room" );
		//this.disconnect()
	}
	 
	createLangaugePack( g,  attempt) {
		if(  Object.keys( g ).length !== window.config.languages.length ) {
			g = {}
			if( attempt < MAX_ATTP ) {
				var _this = this
				setTimeout( (e)=>{
					if ("user_api" in window.config) {
						service.url = (window.config?( window.config.api?window.config.api.user:null ):null)
						//console.log( "API : " + service.url )
					} 
					for (var l in window.config.languages) {
						if( window.config.languages[l].key in window )
							g[ window.config.languages[l].key ] = window[ window.config.languages[l].key ]
					}
					if(  Object.keys( g ).length > 0 ) {
						for (var m in window.config.languages ) {
							//var txt = ""
							for (var f in window.func) {
								var key = Object.keys( window.func[f].desc )
								var o_obj = window.func[f].desc[  key[0]  ]
								if(  !( window.config.languages[m].key in window.func[f].desc)  ) {
									window.func[f].desc[  window.config.languages[m].key] = o_obj
								}
							}
							var typ = ['long_strategy', 'short_strategy', 'full_strategy']
							for( var t in typ) {
								for (f in window.templates[typ[t]] ) {	
									key = Object.keys( window.templates[typ[t]][f].description )
									var o_obj = window.templates[  typ[t]  ][f].description[  key[0]  ]
									if(  !( window.config.languages[m].key in window.templates[typ[t]][f].description)  ) {
										window.templates[typ[t]][f].description[  window.config.languages[m].key] = o_obj
									}
								}
							}

						}
						
					
						GService.gi18n = g
						//var language = service.checkSystemLanguage()
						service.url = (window.config?( window.config.api?window.config.api.user:null ):null)

						_this.setState({
	
							languages : window.config.languages,
							//language : language,
							gi18n : g,
							regions : ("region" in window.config)?window.config.region:[],
							region : "hkg",
							//hasFilter : (('region' in window.config)?window.config.region[0].filter:true) ,
							//demo : (('region' in window.config)?window.config.region[0].demo:false),
							//scope : ( ('region' in window.config)?window.config.region[0].scope:"hk"),
							//trader : ("region" in window.config)?window.config.region[0].trader:false,
						})
						
					}else {
						_this.createLangaugePack( g, attempt )
					}
				} , 500 )
			} else {
				return 
			}
		} else {
			return 
		}
	}

	retrieve_notfication(user_id) {
		var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 

		var _noti  = storage.getItem("garlican@notifcations")

		if ( _noti !== "null" ) {
			try {
				_noti = JSON.parse( _noti )
				_noti = _noti[ user_id ]
				if( 'interval' in _noti ) {
					this.setState({
						notifications : _noti.notifcations,
						notifiy_list : _noti.notifiy_list ,
						interval : _noti.interval, 
					})
				}
			} catch(e) {
				storage.setItem("garlican@notifcations", "null")
			}

		} 
	}

	getPortfolioDetailById(obj) {
		// //this.setState({ editedPortfolio : this.state.obj, showGarlican : true  })
		/*
		service.getPortfolioDetailById(  obj.id  ).then(
			(res)=> {
				console.log( res )
				this.setState({ editedPortfolio : res, showGarlican : true  })
			}
		)
		*/
		var ed = JSON.parse( JSON.stringify(obj) )
		this.setState({ editedPortfolio : ed, showGarlican : true  })
	}

	getRefresh(username, token, id) {
		service.refreshtoken(token).then(
			(res)=> {
				if(  'access' in res ) {
					var obj = {}
					service.user.user_id = parseInt(id, 10)
					obj.refresh = token
					obj.access = res.access
					obj.username = username
					service.token = res.access
					service.getUserProfile().then(
								(user) => {
									//obj.userObj = user
									obj.id = user.user_id
									var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
									storage.setItem("garlican@tokens", JSON.stringify(obj) )
									service.user = user
									obj.isLogin = true
									obj.jid = user.user_id
									obj.id = user.user_id
									obj.showLogin = false
									obj.selectedUser = user
									this.hasLogin(obj.username, obj.e_token)
									this.setState( obj ) 
								}
					)
				} else {
					service.logout()
					this.setState({
						isLogin : false,
						showLogin : false
					})
				}
			}
		).catch(
			(e) => {
				service.logout()
				this.setState({
					isLogin : false,
					showLogin : false
				})
			}
		)
	}

	hasLogin(username, password) {
		//WAIT
		//this.connect( username , password )
		//this.discoverChatRooms()
		//console.log("A")
		this.connected(true)
		//console.log("Here")
	}

	disconnect() {
		if(this.xmpp !== undefined) {
			this.xmpp.destroyRoom(this.state.roomname );
			this.xmpp.stop();
			//this.setState({xmpp : this.xmpp } )
		}
	}

	updateValue(obj) {
		if ( 'language' in obj ) {
			var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
			storage.setItem( "garlican@language" , obj['language'] )
			GService.language = obj['language']
		}
		if( 'showTrader' in obj ) {
			if(obj.showTrader === false) {
				//obj.selectedPortfolio = null
			}
		}
	
		obj.updated = Object.keys( obj )

		this.setState(obj)
	}

	connect(username, password) {
		//this.xmpp = new Client("wss://xmpp.garlican.com:5280/ws", "xmpp.garlican.com", username, password, this.connected);
		//this.xmpp.start();  
		//var a = new Client()
	}

	connected(BOOL) {
		if(BOOL) {
			this.setState( { showLoading : false  } )
			this.get()
			this.retrieve_notfication(  service.user.user_id   )
			//this.rebuildAsset()
		} else {
			
		}
	}

	scroll(e) {
		/*if( (e.currentTarget.scrollTop <= 160) || this.state.scrollTop>=160  ) {
			console.log( e.currentTarget.scrollTop  )
			var val = (e.currentTarget.scrollTop>=160)?160:e.currentTarget.scrollTop
			this.setState({ scrollTop : val })
		}*/
	}

	logout() {
		service.logout()
		this.openAlert( { header : this.state.gi18n[this.state.language]['logout'], body : this.state.gi18n[this.state.language]['logout_message']  } )
		this.setState({ 
			isLogin : false, 
			showTrader : false, 
			forwardGroup : [],
			onhold : {} }
		)
	}

	openAlert(msg) {
		this.setState({ showAlert : true,  alertMessage : msg })
	}

	closeAlert() {
		this.setState({ showAlert : false, alertMessage : '' })
	}

	callList() {
		var keys = ['overview', 'overview2']
		for (var i in keys ) {
			if( this.state.refs[  keys[i]  ] ) {
				if( this.state.refs[ keys[i] ].current ) {
					this.state.refs[ keys[i] ].current.updatePurchase()
					this.state.refs[ keys[i] ].current.getPortfolio()
				}
			}
		}
	}

	pop(){
		if( !this.state.child ) {
			this.setState({showTrader:false})
			var windowFeatures = "directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=yes,copyhistory=no,width=800,height=600"
			var url = window.location.origin + "/trader"
			var child = window.open( url, "garlican" , windowFeatures   )
			this.setState({
				child : child
			})
		} else {
			this.state.child.close()
			this.setState({
				child : null
			})
		}

		//setTimeout( () => {
		//	console.log( child )
		//}, 2000 )
    }

	unpop() {
		this.setState({
			showTrader:false,
			forwardGroup : [],
		})
	}

	rebuildAsset(BOOL) {

		service.rebuildAsset().then( 
			(res) => {
			
			  var obj = {}
			  for (var i in res.list) {
				if( !(res.list[i].portfolio_id in obj) ) {
					obj[res.list[i].portfolio_id] = []
				}
				obj[res.list[i].portfolio_id].push(  res.list[i]   )
			  }
			  this.updateFG(obj, BOOL, true)

			}
		).catch( (e) => {
			//var obj = {}
			//this.updateFG(obj, BOOL)
			this.getOnholdAsset()
		} )
	}

	getOnholdAsset(BOOL) {
		service.getAssetList().then( 
			(res) => {
				//console.log(res)
				var obj = {}
				for (var i in res.list) {
					if( !(res.list[i].portfolio_id in obj) ) {
						obj[res.list[i].portfolio_id] = []
					}
					obj[res.list[i].portfolio_id].push(  res.list[i]   )
				}
				this.updateFG(obj, BOOL)
			}
		)
	}

	updateFG( obj , BOOL , isRefresh ) {
		var _this = this
		var keys = this.state.list.map( (v,k) => { return v.id.toString() } )
		var list = JSON.parse( JSON.stringify(this.state.list) )
		var fg = []
		if( !isRefresh ) {
			fg = JSON.parse(   JSON.stringify( _this.state.forwardGroup ) )
		}

		var noman = fg.filter( (v,k) => {
			return v.group.id === -1
		} )
		
		fg = fg.filter( (v,k) => {
			return v.group.id !== -1
		} )

		function _read(idx) {
			var pid = parseInt( keys[idx] , 10 )
				var o = {}, onhold = [], summary = []
				if( keys[idx] in obj ) {
					onhold = obj[  keys[idx] ].filter( (v,k) => {return v.asset_type === "paper"} )
					summary = obj[  keys[idx] ].filter( (v,k) => {return v.asset_type === "real"} )
				}
				for (var i in summary) {
					  var a = []
					  a = summary[i].detail.map( (v, k ) => {
						  return JSON.stringify( { order_number : v.order_number.toString() , unit : v.unit } )
					  })
					  a = new Set(a)
					  
					  a = Array.from(a)
					  a = a.map( (v,k) => {
						  return JSON.parse( v )
					  } )
				  
					  summary[i].detail = a
				  }
  
				  o['action'] = []
				  o['onhold'] = onhold
				  o['summary'] = summary
				  o['group'] = list[ idx ]
				  var valid = true
				  for (var i in fg) {
					  if( (fg[i].group.id === pid) || (fg[i].group.id ===  keys[idx]) ) {
					  	valid = false
					  	break
					  }
				  }
				  if( valid ) {
					  fg.push(  o )
				  }
				  if( idx < keys.length-1 ) {
					  idx += 1
					  _read(  idx  )
				  } else {  
					  if( noman.length > 0 ) {
						  fg.splice( 0, 0, noman[0] )
					  }
					  _this.setState({
						  forwardGroup : fg,
						  onhold : obj,
						  tpage : "onhold"
					  })
					  if( _this.trader ) {
						  if ( _this.trader.current ) {
							  if( BOOL) {
								  _this.trader.current.getSPOrders()
							  }
						  }
					  }
				  }
				 
		}
		
		_read(0)

	}

	create(v, filters, cb) {
        var b = JSON.parse( JSON.stringify(v)  )
        var g = JSON.parse( JSON.stringify(v) )
        this.setState({
          showLoading : true
        })
        delete g.input
        delete g.partition
        g.input = {}
        g.partition = {}
        g.access_mode = "private"
        var oldKey = Object.keys(  b.partition  )
        var _this = this
        function _call(newObj) {
          newObj.name = g.name
          newObj.description = g.description
          newObj.initial_capital = g.initial_capital
          newObj.transaction_fee = g.transaction_fee
          newObj.access_mode = "private"
          service.updatePortfolio( g.id,newObj ).then(
            (r) => {
              g.input = newObj[ 'input' ]
              g.partition = newObj[ 'partition' ]
              service.getPortfoilioById( g.id ).then(
                (res) => {
                  _this.setState({
                    showLoading : false
                  })
                  _this.setState({
                    botfolio : res
                  })
                  _this.rebuildAsset()
                }
              )
            }
          )
        }
        function _create(s, id,  newObj ) {
          var strat = {}
          strat.portfolio_id = id
          var keys = ["id" ,"name", "description", "code", "filters", "strategy_template_id" ]
          for(var k in keys) {
            if( g.strategy_list[s][ keys[k] ] !== null ) {
              strat[ keys[k] ] = g.strategy_list[s][ keys[k] ]
            }
          }
          service.createStrategy( strat ).then(
            (response) => {
              var oid = oldKey[s]
              newObj.input[ response.id ] = b.input[ oid ]	
              newObj.partition[ response.id ] = b.partition[ oid ]
              g.strategy_list[ s ].id = response.id
              g.strategy_list[ s ].portfolio_id = id
              g.strategy_list[ s ].user_id = service.user.user_id
              s+=1
              if( s === g.strategy_list.length ) {
                _call( newObj )
              } else {
                _create(s, id, newObj)
              }
            }
          ).catch( (e)=> {
            _this.setState({
              showSelection : false,
              showLoading : false
            })
          })	
          
        }
        service.createPortfolio( g ).then(
          (res)=> {
            g.user = service.user
            g.id = res.id
            var newObj ={ input : {}, partition : {} }
            _create(0, res.id, newObj)
          }
        ).catch( (e)=> {
            _this.setState({
              showLoading : false
            })
        })     
      
    }

	get(skip) {
		var _this = this
		function _finalize(list, skip) {
			var code = "price=close #Targeted Price/指標價\nscycle = 5 #MACD Short cycles/採用短周期\nlcycle = 10 #MACD Long cycles/採用長周期\nsignal = 9 #MACD Signal Line/訊號線\nvar1 = macd(price, scycle, lcycle, signal)\n(c3, c2, c1) = close[-3:]\n(r3, r2, r1) = var1[-3:]\ncond1 = (c3 > c2) & (c3 > c1)\ncond2 = (r3 > r2) & (r3 > r1)\ncondition_long = (cond1 & cond2)\n#####EXIT CONDITION#####\nup_value=0.10 #止賺(%)\ndown_value=0.05 #止損(%)\nupper_profit_exit = (close >= (entryprice*( 1+up_value) ) ) #止賺賣出\nlower_profit_exit = (close <= (entryprice*( 1-down_value)) ) #止損賣出\nCondition_short =  (upper_profit_exit | lower_profit_exit)"
			var a = GService.createStrategyObject( code, "tempstrategy" , "", {} )
			a.id = "temp_1"
			a.filters = [ {key: 'data', type: 'symbol', opr: 'in', value:'0700.HK'} ]
			//console.log( service )
			var name = GService.encodeObject(  "TEMPBOTFOLIO:" + service.user.user_id )
			var temp = list.filter( (v,k) => {
				return v.name === name
			} )
			_this.setState( {
				list : list
			} )
			if(!skip) {
				if( temp.length === 0 ) {
					var gp = GService.createGroupObject( null, name , "" )
					gp.strategy_list = [a]
					gp.partition = { "temp_1" : "100" }
					gp.input = { "temp_1" : {} }
					_this.create(gp )				
				} else {
					service.getPortfoilioById( temp[0].id ).then(  
						(res) => {
							_this.setState({
								dummy : res
							})
							_this.rebuildAsset( )
						}
					) 
				}
			}
		}

		function _get(page, list) {
			service.getPortfoilioList( {user_id : "me", page : page} ).then(
				(res) => {
					if( 'list' in res ) {
						list = list.concat( res.list )
						if( res.list.length < 30 ) {
							_finalize( list, skip )
						} else {
							_get(page+1, list)
						}
					}
				}
			)
		}

		_get(0, [])

		
		
	}

	notifiy(notifies, isStart) {

		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification");
		} else {
			Notification.requestPermission().then( 
				(permission) => { 
					console.log('permiss', permission)
				}
			);
		}

		if (notifies.length > 0) {
			var _p = JSON.parse(  JSON.stringify( this.state.forwardGroup ) )
      		var l = _p.filter( (v,k) => {
        		if( notifies.includes( k.toString() ) ) { 
          			return v
        		}
      		} )
			this.setState({
				notifiy_list : notifies,
				isNotify : isStart,
				notifications : l,
			})

			var noti = {}
			noti[ service.user.user_id ] = {
				notifiy_list : notifies,
				interval : this.state.interval, 
				notifications : l,
			}
			var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
			storage.setItem("garlican@notifcations", JSON.stringify( noti ) )

			if( isStart ) {
				if( this.interval ) {
					clearInterval( this.interval )
				}
				this.interval = setInterval( this.runNotfication, this.state.interval * 60 * 1000 )
				//this.interval = setInterval( this.runNotfication, 0.5 * 60 * 1000 )
			} else {

				clearInterval( this.interval )
				this.interval = null
			}
		} else {
			if ( (notifies.length > 0 || !isStart) ) {
				this.setState({
					isNotify : isStart,
				})
			}
			clearInterval( this.interval )
			this.interval = null
		}
	}

	postNotifcation(g, r) {
			var options = {
				body: g.name + " has (" + r.value.length + ") suggesetions.",
				//icon: logoB,
				//dir: "ltr"
			};
			var notification = new Notification("deMi AUTOMATE", options);
	}

	runNotfication() {
		var _this = this
		if( _this.state.notifications.length > 0 ) {
			_this.createParams( 0 )
		}
		/*
		for ( var i in this.state.notifications ) {
			service.getPortfoilioById( this.state.notifications[i].group.id ).then(
				(res) => {
					var group = res
					var asset = (group.id in this.state.onhold)?this.state.onhold[  group.id  ]:[]
					var params = {}
					for (var i in asset) {
						if(  "unit" in  asset[i] ) {
						  asset[i].strategy = asset[i].strategy_id.toString()
						  if(  !(asset[i].symbol in params)  ){
							params[ asset[i].symbol ] = {}
						  }
						  if(  !( asset[i].strategy_id in params[ asset[i].symbol ])  ) {
							params[ asset[i].symbol ][   asset[i].strategy_id   ] = {}
						  }
						  if(  "unit" in  asset[i] ) {
							var d = '27070740', duration = 0
							if( asset[i].last_purchase_time ) {
							  d = service.formatDate( asset[i].last_purchase_time, null, true  )
							  d = d.getTime()/1000
							  var now = new Date()
							  now = now.getTime()/1000
							  duration = (now - d)
							}
							params[ asset[i].symbol ][ asset[i].strategy] = {}
							params[ asset[i].symbol ][ asset[i].strategy]['betsizeonhold'] = asset[i].unit.toString()
							params[ asset[i].symbol ][ asset[i].strategy]['betsize'] = asset[i].unit.toString()
							params[ asset[i].symbol ][ asset[i].strategy]['shortbetsize'] = asset[i].unit.toString()
							params[ asset[i].symbol ][ asset[i].strategy]['onstock'] = asset[i].unit.toString()
							params[ asset[i].symbol ][ asset[i].strategy]['marketposition'] = asset[i].unit.toString()
							params[ asset[i].symbol ][ asset[i].strategy]['onmarket'] = asset[i].unit.toString()
							params[ asset[i].symbol ][ asset[i].strategy]['entryprice'] = asset[i].price.toString()
							params[ asset[i].symbol ][ asset[i].strategy]['entrytime'] = d/60/60/24
							params[ asset[i].symbol ][ asset[i].strategy]['last_purchase_time'] = asset[i].last_purchase_time
							params[ asset[i].symbol ][ asset[i].strategy]['barssinceentry'] = duration.toString()
						  }
						}
		
					}
					var asset_list = {}
					asset_list.asset_list = asset
					_this.forwardtesting( group, params, 0, asset_list, _notify )
				}
			)
		}
		*/
	}

	forwardtesting(group, params, transaction_fee, asset_list, cb, run, idx ) {
		var strategy = {}, partition = 100
		for (var s in group.strategy_list) {
		  strategy[ group.strategy_list[s].id ] = group.strategy_list[s]
		  partition -= parseFloat(  group.partition[ group.strategy_list[s].id ] , 10  )
		}
		if( partition > 0) {
		  strategy['cash'] = { id:'cash', name:this.state.gi18n[ this.state.language ]['cash']}
		}
		var _obj = {}
		_obj.showLoading = true
		_obj.tpage = "action"
		this.setState(_obj)
		Api.forwardtestingS( group, params, parseFloat(transaction_fee, 10), this.state.region, this.state.demo ).then(
		  (res) => {
			var obj = {}
			obj['action'] = []
			if( typeof(res) !== "string" ) {
			  if( 'value' in res ) {
				for (var s in res['value']) {
					res['value'][s]['tmp_decision'] = res['value'][s]['decision']
					res['value'][s]['strategy_id'] = res['value'][s]['group']
                	res['value'][s]['action'] = (res['value'][s]['decision']>0)?"buy":"sell"
				}
				obj['action'] = res['value']
			  } 
			}
			obj['group'] = group
			obj['onhold'] = asset_list.asset_list
			
			var valid = true
			var fg = JSON.parse(  JSON.stringify( this.state.forwardGroup ) )
			
			for( var i in fg) {
			  if( fg[i].group.id === obj.group.id ) {
				fg[i] = obj
				valid = false
				break
			  }
			}
			if( valid ) {
			  fg.push( obj )
			}
			var alertMessage = {}
			if( res.value.length === 0 ) {
			  alertMessage = {
				header : this.state.gi18n[this.state.language]['no_sym'],
				body : this.state.gi18n[this.state.language]['no_sym_msg']
			  }
			}
  
			this.setState({
			  forwardGroup : fg,
			  showLoading : false,
			  showTrader : true,
			  showAlert : ("header" in alertMessage)?true:false,
			  alertMessage :alertMessage
			})
			//this.state.rebuildAsset()

			if(cb) {
				if(  res.value.length > 0  ) {
					cb( group, res )
				}
			}

			if( run ) {
				run(idx)
			}

		  }
		).catch( (e) => {
		  this.setState({
			showLoading : false,
			showAlert : true,
			alertMessage : {
			  header :  this.state.gi18n[this.state.language]['error'] ,
			  body : this.state.gi18n[this.state.language]['backtesting_error']
			},
		  })
		} )
	}

	createParams( ct ) {
		var _this = this
		service.getPortfoilioById( _this.state.notifications[ct].group.id ).then(
			(res) => {
				var group = res
				var asset = (group.id in _this.state.onhold)?_this.state.onhold[  group.id  ]:[]
				var params = {}
				for (var i in asset) {
					if(  "unit" in  asset[i] ) {
					  asset[i].strategy = asset[i].strategy_id.toString()
					  if(  !(asset[i].symbol in params)  ){
						params[ asset[i].symbol ] = {}
					  }
					  if(  !( asset[i].strategy_id in params[ asset[i].symbol ])  ) {
						params[ asset[i].symbol ][   asset[i].strategy_id   ] = {}
					  }
					  if(  "unit" in  asset[i] ) {
						var d = '27070740', duration = 0
						if( asset[i].last_purchase_time ) {
						  d = service.formatDate( asset[i].last_purchase_time, null, true  )
						  d = d.getTime()/1000
						  var now = new Date()
						  now = now.getTime()/1000
						  duration = (now - d)
						}
						params[ asset[i].symbol ][ asset[i].strategy] = {}
						params[ asset[i].symbol ][ asset[i].strategy]['betsizeonhold'] = asset[i].unit.toString()
						params[ asset[i].symbol ][ asset[i].strategy]['betsize'] = asset[i].unit.toString()
						params[ asset[i].symbol ][ asset[i].strategy]['shortbetsize'] = asset[i].unit.toString()
						params[ asset[i].symbol ][ asset[i].strategy]['onstock'] = asset[i].unit.toString()
						params[ asset[i].symbol ][ asset[i].strategy]['marketposition'] = asset[i].unit.toString()
						params[ asset[i].symbol ][ asset[i].strategy]['onmarket'] = asset[i].unit.toString()
						params[ asset[i].symbol ][ asset[i].strategy]['entryprice'] = asset[i].price.toString()
						params[ asset[i].symbol ][ asset[i].strategy]['entrytime'] = d/60/60/24
						params[ asset[i].symbol ][ asset[i].strategy]['last_purchase_time'] = asset[i].last_purchase_time
						params[ asset[i].symbol ][ asset[i].strategy]['barssinceentry'] = duration.toString()
					  }
					}
	
				}
				var asset_list = {}
				asset_list.asset_list = asset
				
				if( ct < _this.state.notifications.length ) {
					_this.forwardtesting( group, params, 0, asset_list, _this.postNotifcation , _this.createParams, ct+1 )
				}

				
			}
		)
	}

	comp() {
		return (
			<div 
			onContextMenu={
				(e)=>{
					//e.preventDefault();
				}
			}
			ref={this.app} className={["App", "App-Container"].join(' ')} 
			id={"app-content"} 
			onScroll={(e)=>{this.scroll(e)} } style={  { /*top : Math.max(120-this.state.scrollTop, 50) + "px"*/  }  }>

		{ 
			//Object.keys(  this.state.gi18n  ).length > 0 && (Object.keys(  this.state.filterconfig  ).length > 0) &&
			Object.keys(  this.state.gi18n  ).length > 0 &&	
			<Router >
				<Switch>
						{ pages.map(  (v,k) => { 
							if( v.key !== "/*" ) {
								return(
									<Route key={k} exact path={ "/" + v.key }  
										render={ (p) => {
										return([
											( v.type !== "pop" ) && 
												<Controller 
													key={99} 
													type={ v.key } 
													updateValue={this.updateValue} 
													language={ this.state.language } 
													languages={this.state.languages} 
													disconnect={this.disconnect} 
													scrollTop={this.state.scrollTop} 
													isLogin={this.state.isLogin} 
													showUser={this.state.showUser} 
													gi18n={this.state.gi18n} 
													frame={ v.frame } 
													manager={ v.key==="manager"} s
													isNotify={this.state.isNotify } 
													logosrc={ this.state.logosrc }/>
											,
											(v.type !== 'popup') && 
												<div key={k+1000} className={[ (  (v.key === "garlican") | (v.type==="pop") | (v.key === "manager") )?"app-holder-garlican":"app-holder"].join(' ')} >
													<v.comp 
														//page={v.key}
														regions={ this.state.regions }
														ref={ this.state.refs[v.value] } 
														callList={this.callList} 
														language={ this.state.language }
														languages={ this.state.languages}
														xmpp={this.xmpp} 
														username={this.state.username} 
														isSimulate={this.state.isSimulate} 
														jid={this.state.jid} 
														updateValue={this.updateValue} 
														isLogin={this.state.isLogin} 
														showLogin={this.state.showLogin}
														openAlert={this.openAlert}  
														showGarlican={this.state.showGarlican} 
														gi18n={this.state.gi18n} 
														showPortfolio={this.state.showPortfolio} 
														showUser={this.state.showUser} 
														colors={colors} 
														selectedPortfolio={this.state.selectedPortfolio} 
														{...p}  
														disable={ v.type==="pop" } 
														tpage={ this.state.tpage?this.state.tpage:"onhold"} 
														onhold={this.state.onhold} 
														sym_names={this.state.sym_names} 
														board_lot={this.state.board_lot} 
														list={this.state.list} 
														getBotfolioList={this.get} 
														forwardGroup={this.state.forwardGroup} 
														forwardtesting={ this.forwardtesting }
														showTrader={this.state.showTrader}  
														logosrc={ this.state.logosrc } 
														getOnholdAsset={ this.getOnholdAsset }
														rebuildAsset={this.rebuildAsset}
														pop={this.pop} 
														refs={this.state.refs} 
														trader_ref={ this.trader }
														dummy={ this.state.dummy }
														updated={ this.state.updated }
														hasLogin={this.hasLogin} 
														showSignup={this.state.showSignup} 
														retrieve_key={this.state.retrieve_key} 
														get={this.get} 
														showAutomate={ v.key==="automate" }
														upgrade={ v.key==="upgrade" }
														trade={ v.key==="trade" }
													/>	
												</div>	
											,
											this.state.showLogin && !this.state.isLogin && ( v.type !== "pop" ) &&
												<Login key={100} updateValue={this.updateValue} language={this.state.language} hasLogin={this.hasLogin} showSignup={this.state.showSignup} showLogin={this.state.showLogin} openAlert={this.openAlert} gi18n={this.state.gi18n} retrieve_key={this.state.retrieve_key} get={this.get} logosrc={ this.state.logosrc }/> 
											,
											this.state.isLogin && this.state.showUser && ( v.type !== "pop" ) &&
												<User key={101} updateValue={this.updateValue} language={this.state.language} hasLogin={this.hasLogin} showUser={this.state.showUser} logout={this.logout} openAlert={this.openAlert} gi18n={this.state.gi18n} user={ this.state.selectedUser } logosrc={ this.state.logosrc } isOwner={  service.isOwner(this.state.selectedUser.user_id) } /> 
											,
											( v.type !== "pop" ) && <Loading key={102} showLoading={ this.state.showLoading } language={this.state.language} gi18n={this.state.gi18n} logosrc={this.state.logosrc} hasLogo={this.state.hasLogo} />
											,
											( v.type !== "pop" ) && <Portfolio key={104} language={ this.state.language } callList={this.callList}  xmpp={this.xmpp} username={this.state.username} isSimulate={this.state.isSimulate} jid={this.state.jid} updateValue={this.updateValue} isLogin={this.state.isLogin} showLogin={this.state.showLogin} openAlert={this.openAlert} getPortfolioDetailById={this.getPortfolioDetailById} showGarlican={this.state.showGarlican} showPortfolio={this.state.showPortfolio} selectedPortfolio={this.state.selectedPortfolio} colors={colors} gi18n={this.state.gi18n} showCanvas={this.state.showCanvas} forwardGroup={this.state.forwardGroup} onhold={this.state.onhold} sym_names={this.state.sym_names} board_lot={this.state.board_lot} purchased={ this.state.purchased } forwardtesting={ this.forwardtesting } hasFilter={ this.state.hasFilter } demo={this.state.demo} regions={this.state.regions} region={this.state.region} logosrc={ this.state.logosrc }/>
											,
											( v.type !== "pop" ) &&<Alert key={105} showAlert={this.state.showAlert} closeAlert={this.closeAlert} message={ this.state.alertMessage} language={this.state.language} gi18n={this.state.gi18n} manager={ v.key==="manager"} logosrc={ this.state.logosrc }/>
											,
											( v.type !== "pop" ) &&<Footer key={106} type={ this.state.type } updateValue={this.updateValue} language={ this.state.language } gi18n={this.state.gi18n} showTrader={this.state.showTrader} child={this.state.child} pop={this.pop} isLogin={this.state.isLogin} frame={ v.frame } manager={ v.key==="manager"} logosrc={ this.state.logosrc }/> 
													
										])
									}}>
									</Route>
								)
							} else {
								return(
									<Route key={k} path="/*">
										<Redirect to="/" /> 
									</Route>
								)
							}
						})
					  }
				</Switch>
			</Router>
		}

		{ 
			Object.keys(  this.state.gi18n  ).length === 0 &&	
			<Loading showLoading={true} language={"eng"} gi18n={ null }/>
		}


		<Stripe  
			language={ this.state.language }
			updateValue={ this.updateValue } 
			isPayment={ this.state.isPayment }/>
			


		</div>
		)
	}

  	render() {
		try {
  			return (
				this.comp()
	  		);
		} 
		catch(e) {
			return (
				this.comp()
			)
		}
  	}

}

export default App;

//filterconfig={this.state.filterconfig} 
/*
<v.comp 
															//demo={this.state.demo}
															hasFilter={this.state.hasFilter}
															//scope={this.state.scope}
															ref={ this.state.refs[v.value] } 
															callList={this.callList} 
															language={ this.state.language }
															languages={ this.state.languages}
															xmpp={this.xmpp} 
															username={this.state.username} 
															isSimulate={this.state.isSimulate} 
															jid={this.state.jid} 
															updateValue={this.updateValue} 
															isLogin={this.state.isLogin} 
															showLogin={this.state.showLogin}
															openAlert={this.openAlert}  
															showGarlican={this.state.showGarlican} 
															gi18n={this.state.gi18n} 
															showPortfolio={this.state.showPortfolio} 
															showUser={this.state.showUser} 
															colors={colors} 
															selectedPortfolio={this.state.selectedPortfolio} 
															{...p}  
															disable={ v.type==="pop" } 
															page={ this.state.tpage?this.state.tpage:"onhold"} 
															onhold={this.state.onhold} 
															
															sym_names={this.state.sym_names} 
															board_lot={this.state.board_lot} 
															list={this.state.list} 
															getBotfolioList={this.get} 
															forwardGroup={this.state.forwardGroup} 
															forwardtesting={ this.forwardtesting }
															showTrader={this.state.showTrader}  
															logosrc={ this.state.logosrc } 

															getOnholdAsset={ this.getOnholdAsset }
															rebuildAsset={this.rebuildAsset}
															pop={this.pop} 
															refs={this.state.refs} 
															trader_ref={ this.trader }
															dummy={ this.state.dummy }
															trader={this.state.trader}

															updated={ this.state.updated }

															hasLogin={this.hasLogin} 
															showSignup={this.state.showSignup} 
															retrieve_key={this.state.retrieve_key} 
															get={this.get} 
														/>	
														*/