import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

//import logoB from '../../../public/img/garlican_blue_logo.png'

import { Dropdown, DropdownButton, Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import {   } from '@fortawesome/free-solid-svg-icons'

import service from '../../../../public/service'
//import Api from '../../../../Garlican/GarlicanLibrary/apis'
//import sptrader from '../../service/sp'
//import { indexDependencies } from 'mathjs';



class TInsert extends Component {

    constructor(props) {
        super(props)
        this.state = {
            new_symbol : [],
            botfolios : [],
            bot : [],
            selectedBotfolio : {},
            selectedBot : {},
            symbols : [],
            props : {},
        }
        this.select = this.select.bind(this)
        this.insert = this.insert.bind(this)
        this.updateSymbolListByRegion = this.updateSymbolListByRegion.bind(this)
        this.updateBotfolioList = this.updateBotfolioList.bind(this)
    }

    componentDidMount() {    
        this.updateBotfolioList( this.props )
    }

    shouldComponentUpdate(props, state) {
        try {
            var o = {}
            var exlucdes = ["refs", "history", "location","match", "trader_ref"]
            for (var i in props) {
                if( (props[i] !== undefined) && ( typeof(props[i]) !== "function")  ) {
                    if( !exlucdes.includes( i )  ) {
                        o[i] = props[i]
                    }
                }   
            }
            if( JSON.stringify( state.botfolios ) !== JSON.stringify( this.state.botfolios ) ) {
                this.updateBotfolioList( props )
                return true
            } else if ( JSON.stringify( state ) !== JSON.stringify( this.state ) ) {
                this.forceUpdate()
                return true
            } else if ( JSON.stringify( o ) !== JSON.stringify( state.props ) ) {
                this.setState({  props : o  })
                this.updateBotfolioList( props )
                this.forceUpdate()
                return true
            } else {
                return false
            }
        } catch(e) {
            console.log(e)
            return false
        }

    }

    componentDidUpdate(props, prevstate){

    }

    componentWillUnmount() {
    }

    updateBotfolioList(props) {
        var botfolios = []
        var obj = {}

        if( props.isTLogin ) {
            obj.id = -1
            obj.name = props.gi18n[props.language]['t_none']
            obj.index = -1
            botfolios.push( obj ) 
        }

        for (var i in props.group) {
            if( (props.group[i].group.id!== -1) && (  props.group[i].group.id !== this.props.dummy.id )   ) {
                obj = {}
                obj.id = props.group[i].group.id
                obj.name = props.group[i].group.name
                obj.index = i
                obj.group = props.group[i].group
                botfolios.push( obj )  
            }
        }
        this.setState({
            botfolios : botfolios,
        })
        this.forceUpdate()
    }

    updateSymbolListByRegion( region ) {
        if(region) {
            var obj = []
            //console.log( this.props.sym_names )
            for (var i in this.props.sym_names) {
                if( this.props.sym_names[ i ].region === region ) {
                    var o = { key : i , name : ( i + " | " + this.props.sym_names[ i ][this.props.language] )   } 
                    obj.push( o )
                }
            }

            this.setState( {
                symbols : obj
            })
        } 
        this.forceUpdate()     
    }

    select(index) {
        if( index >= 0 ) {
            //index += 1
            var botfolios = this.state.botfolios
            var selectedBotfolio = null, bot=[]
            if( botfolios.length > 0 ) {
                selectedBotfolio = botfolios[index]
                service.getPortfoilioById(  selectedBotfolio.id  ).then(
                    (res) => {
                        var sl = res.strategy_list
                        var region = res.region?res.region:"hkg"    
                        this.updateSymbolListByRegion(region)

                        for (var i in  sl ) {
                            var obj = {}
                            obj.id = sl[i].id
                            obj.name = sl[i].name
                            obj.index = i
                            bot.push( obj )
                        }
                        var _o = {
                            bot : bot,
                            selectedBotfolio : selectedBotfolio,
                        }
                        if( bot.length > 0 ) {
                            _o.selectedBot = bot[i]
                        }
                        this.setState(_o)
                        var fg = JSON.parse(  JSON.stringify( this.props.forwardGroup ) )
                        for (var i in fg) {
                            if( fg[i].group.id === res.id  ) {
                                fg[i].group = res
                                this.props.updateValue( {
                                    forwardGroup : fg,
                                })
                                break
                            }
                        }

                    }
                )
            }
        } else {
            var _o = {
                bot : [],
                selectedBotfolio : {},
                selectedBot : {}
            }
            this.setState(_o)
        }
        this.forceUpdate()
    }

    insert(){
        if(  this.state.new_symbol.length > 0 ) {
            var o = {
                symbol : this.state.new_symbol[0].key,
                botfolio : this.state.selectedBotfolio,
                bot : this.state.selectedBot
            }

            console.log( o )

            this.props.insert( o )


            this.setState({
                new_symbol : [],
                selectedBotfolio : {},
                selectedBot : {},
            })
        }
    }

    render() {
        //console.log("b")
        return (
          <div className={['trade-insert-2', this.props.isAdd?'iShow':""].join(" ")} >

            <div className={ ['holder'].join(" ") }>
                <span className={ ['title'].join(' ') } >{  this.props.gi18n[this.props.language]['t_manage_botfolio']  }</span>
                <DropdownButton
                        variant="outline-secondary"
                        title={  this.state.selectedBotfolio.name?this.state.selectedBotfolio.name:this.props.gi18n[this.props.language]['t_pick_botfolio']  }
                        id="input-group-dropdown-1"
                        className={ ['dropdown'].join(' ') } 
                >
                    {
                            this.state.botfolios.map((v, k) => {
                            return(
                                <Dropdown.Item key={k} href="#"
                                onClick={ (e)=>{
                                    this.select( k )
                                }}
                                >{ v.name}</Dropdown.Item>
                                )
                            })
                    }
                </DropdownButton>
            </div>
            { (this.state.bot.length > 0) &&
                <div className={ ['holder'].join(" ") }>
                        <span className={ ['title'].join(' ') } >{ this.props.gi18n[this.props.language]['t_select_bot'] }</span>
                        <DropdownButton
                                variant="outline-secondary"
                                title={  this.state.selectedBot.name?this.state.selectedBot.name:this.props.gi18n[this.props.language]['t_pick_bot']   }
                                id="input-group-dropdown-1"
                                className={ ['dropdown'].join(' ') } 
                        >
                            {
                                    this.state.bot.map((v, k) => {
                                    return(
                                        <Dropdown.Item key={k} href="#"
                                        onClick={ (e)=>{
                                            this.setState( { selectedBot : v })
                                        }}
                                        >{ v.name}</Dropdown.Item>
                                        )
                                    })
                            }
                        </DropdownButton>
                </div>
            }
            <Typeahead
                id="symbol_picker"
				className={ ['input'].join(" ") }
                labelKey={"name"}
				//labelKey={this.props.gi18n[this.props.language]['t_symbol']}
                multiple={  false }
                paginate={ true }
                onChange={(e)=>{ 
                    this.setState({
                        new_symbol : e
                    })
                } }
                selected={ this.state.new_symbol  }
				options={  this.state.symbols }
                placeholder={ this.props.gi18n[this.props.language]['choose_symbol'] }
			/>
            <div className={ ['holder'].join(" ") }>
                <Button disabled={ (this.state.new_symbol.length === 0) } className={["btn"].join(" ")} onClick={ (e)=>this.insert() } >{ this.props.gi18n[this.props.language]['t_insert'] }</Button>
            </div>
          </div>
        )
    }
}

export default TInsert;


