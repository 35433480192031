import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Dropdown, DropdownButton, Button  } from 'react-bootstrap'


import Docker from '../docker'

class Onhold extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedSort : 0,
            group : [],
            prices : props.prices
        }
        this.getCT = this.getCT.bind(this)
        this.sort = this.sort.bind(this)
    }

    componentDidMount() {    
        this.sort( this.state.selectedSort )  
    }

    shouldComponentUpdate(props, state) {
        return true
    }

    componentDidUpdate(props, state){
        if( JSON.stringify(state.group) !== JSON.stringify(this.state.group)  ) {
            this.sort( this.state.selectedSort )  
        } 
        if( JSON.stringify(state.prices) !== JSON.stringify(props.prices)  ) {
            //console.log( props.prices )
            this.sort( this.state.selectedSort ) 
        }
    }

    componentWillUnmount() {
    }

    getCT(fg) {
        var ln = fg, ct=0 
        for (var i in ln ) {
            if( "onhold"  in ln[i] ) {
                    if( "onhold" in ln[i]) {
                    ct += ln[i][ "onhold" ].length
                }
            }
        }
        return ct
    }

    sort(idx) {
        var gp =  JSON.parse( JSON.stringify( this.props.forwardGroup )  )
        var key = this.props.selections[ "onhold" ][idx].key
        if( key !== "all" ) {
          if( key === "onhold"  ) {
            gp = gp.filter(  (v,k) => {
                v.gindex = k
                v.list = v.onhold
                var cond =  ((v.list?(v.list.length > 0):false) || (v.onhold?(v.onhold.length > 0):false))  
                if ( cond ) {
                    for (var i in v.list) {
                        v.list[i].action = "buy"
                        v.list[i].buy = v.list[i].price
                        v.list[i].entryprice = v.list[i].price
                        v.list[i].price = this.props.prices[ v.list[i].symbol ]?this.props.prices[ v.list[i].symbol ]:null
                        v.list[i].change = v.list[i].buy?( (v.list[i].price - v.list[i].buy)/v.list[i].buy   ):0
                    }
                }
                return cond
            }  )
          } else if ( (key === "private") || (key === "public") ) {
            gp = gp.filter(  (v,k) => {
                v.gindex = k
                v.list = v.onhold
                var cond =  (v.group.access_mode === key)
                if ( cond ) {
                    for (var i in v.list) {
                        v.list[i].action = "buy"
                        v.list[i].buy = v.list[i].price
                        v.list[i].entryprice = v.list[i].price
                        v.list[i].price = this.props.prices[ v.list[i].symbol ]?this.props.prices[ v.list[i].symbol ]:null
                        v.list[i].change = v.list[i].buy?( (v.list[i].price - v.list[i].buy)/v.list[i].buy   ):0
                    }
                }
                return cond
            }  )
          } else {
            gp = gp.filter(  (v,k) => {
                v.gindex = k
                v.list = v.onhold
                var cond =  (v.group.subscription_id !== null)
                if ( cond ) {
                    for (var i in v.list) {
                        v.list[i].action = "buy"
                        v.list[i].buy = v.list[i].price
                        v.list[i].entryprice = v.list[i].price
                        v.list[i].price = this.props.prices[ v.list[i].symbol ]?this.props.prices[ v.list[i].symbol ]:null
                        v.list[i].change = v.list[i].buy?( (v.list[i].price - v.list[i].buy)/v.list[i].buy   ):0
                    }
                }
                return cond
            }  )
          }
        } else {
            gp = gp.map(  (v,k) => {
                v.gindex = k
                v.list = v.onhold
                var cond =  ((v.list?(v.list.length > 0):false) || (v.onhold?(v.onhold.length > 0):false))  
                if ( cond ) {
                    for (var i in v.list) {
                        v.list[i].action = "buy"
                        v.list[i].buy = v.list[i].price
                        v.list[i].entryprice = v.list[i].price
                        v.list[i].price = this.props.prices[ v.list[i].symbol ]?this.props.prices[ v.list[i].symbol ]:null
                        v.list[i].change = v.list[i].buy?( (v.list[i].price - v.list[i].buy)/v.list[i].buy   ):0
                    }
                }
                return v
            }  )
        }
        this.setState({
          group : gp,
          selectedSort : idx,
          prices : this.props.prices,
        })
        
    }

    render() {
        //console.log("b")
        return (
            <div className={["Onhold"].join(" ")} >
                <div className={["Top"].join(" ")}>
                    <div className={["Frame"].join(" ")}></div>
                    <div className={["Banner"].join(" ")}>
                        <div className={['Ttitle'].join(" ")}>
                            { this.props.gi18n[ this.props.language][ "trader_onhold"].replace( "<num>", this.getCT(this.props.forwardGroup  ) )  }
                        </div>
                        <div className={ [ "Picker"  ].join(" ") } >
                            <DropdownButton 
                                title={ this.props.gi18n[this.props.language][ this.props.selections["onhold"][ this.state.selectedSort  ].value]  }
                                className={['Sort'].join(" ")} >
                                {
                                    this.props.selections["onhold"].map( (v,k) => {
                                        return(
                                            <Dropdown.Item 
                                            className={['Item'].join(" ")} 
                                            key={k} 
                                            onClick={
                                                (e) => {
                                                this.sort( k )
                                                }
                                            }
                                            active={  this.state.selectedSort === k }
                                            eventKey={v.key} > 
                                                { this.props.gi18n[this.props.language][v.value] } 
                                            </Dropdown.Item>
                                        )
                                    } )
                                }
                            </DropdownButton>
                            { this.props.trader &&
                                <Button 
                                    onClick={ (e)=> {
                                        if( !this.props.isTLogin ) {
                                        this.props.updateValue({
                                            showLogin : !this.props.showLogin
                                        })
                                        } else {
                                        this.logout()
                                        }
                                    } }
                                    className={ [   "login"  ].join(" ") }> 
                                    {
                                        !this.props.isTLogin?(this.props.showLogin?this.props.gi18n[this.props.language]["t_hide"]:this.props.gi18n[this.props.language]["trade_login"]):this.props.gi18n[this.props.language]["logout"]
                                    } 
                                </Button>
                            }
                        </div>
                    </div>

                    <div className={['TGlance'].join(" ")} >
                        {
                            this.props.board[ "onhold" ].map( (v,k) => {
                                return(
                                        <div className={ ['Wrapper'] } key={k}>
                                            <div className={ ['Title'] }> {  this.props.gi18n[ this.props.language ][ "t_" + v ] } </div>
                                            <div className={ ['Value',
                                                (
                                                    ( (v==="change") || (v==="current") || (v==='sell_value') || (v==='buy_value') )?( 
                                                        ( (this.props["change"]>0) || (v==='sell_value') )?"Gain":
                                                        ( (this.props["change"]<0) || (v==='buy_value' ) )?"Lost":""
                                                        ):""  
                                                )  ].join(" ") }> { 
                                                    (v==="change")?(this.props[v] + "%"):("$"+this.props[v].toLocaleString("en-US") )
                                                } 
                                            </div>
                                        </div>
                                    )
                                    
                            } )
                        }
                        <div className={['WFrame'].join(" ")} >
                            {
                                [120, 500, 200, 60, 90, 90, 300, 75, 275].map( (v, k) => {
                                    var x = ( Math.random() * 100)
                                    return(
                                        <div
                                            key={k} 
                                            className={['Circle'].join(" ")} 
                                            style={{
                                                left : ( Math.random() * 100) + "%",
                                                top : ( Math.random() * 100) + "%",
                                                width : v + "px",
                                                height : v + "px",
                                                zindex : k
                                            }}
                                        > 
                                        </div>
                                    )
                                } )
                            }
                        </div>
                    </div>

                </div>

                
                
                <div className={ ["Docker-Holder"].join(" ") }>
                    
                    <div className={ ["tTitle"].join(" ") }> {"Paper trade holdings"} </div>

                    <div className={ ["tHolder", this.props.showOnhold?"appear":""].join(" ") }>
                        { 
                            this.state.group.map( (v1,k1) => {
                                var cond1 = true
                                if( cond1 ) {  
                                    return(
                                        <Docker 
                                            key={ k1 }
                                            {...this.props} 
                                            group={this.state.group}
                                            selectKey={this.props.selectKey}
                                            vobj={v1} 
                                            isReading={this.props.isReading} 
                                            type={"onhold"} 
                                            list={v1.list} 
                                            gindex={v1.gindex} 
                                            botfolio={v1.group} 
                                            updateList={this.props.updateList} 
                                            column={ false } 
                                            deleteOrder={ this.props.deleteOrder }
                                            selected={ this.props.selected } 
                                            updateValue={this.props.updateValue} 
                                            updateGroup={this.props.updateGroup} 
                                            isTLogin={this.props.isTLogin} 
                                            moveToAction={ this.props.moveToAction }
                                            getGroup={ this.props.getGroup }
                                            trader_updates={this.props.trader_updates}
                                            run={this.props.run} 
                                        />
                                    )
                                } else {
                                    
                                    return null
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Onhold;


