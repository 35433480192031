"use strict";

//import { ifError } from 'assert';
import service from '../../public/service'

require('es6-promise').polyfill();
require('isomorphic-fetch');

var Apis =  {
    usrt_id : 1, 

    test : () => {
        fetch(
            "https://lambda.garlican.com/api/getpattern", 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    transformUTF : function(s, e) {
        if(e) {
            return unescape(encodeURIComponent( s ) );
        } else {
            return decodeURIComponent(escape(s));
        }
    },
    apil : (window.config?( window.config.api?window.config.api.lambda:null ):null) , 
    api : (window.config?( window.config.api?window.config.api.config:null ):null) , 

    getTreemapNewFromCode : function(code, isConnectedCpp) {
        var _this = this
        if( !isConnectedCpp ) {
            var url = Apis.apil + "treemapping"
            var form = {}
            if(code != null & code.length > 0) {
                form = {  "code" : code  }
            }
            return fetch(
                url, 
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        //'Authorization' : 'Bearer ' + service.token
                    },
                    body: JSON.stringify( form )
                }
            ).then(
                (response) => response.json()  
            )
        } else {
            return fetch(
                "", 
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
              ).then(
                (response) => {
                    var obj = null
                    if( 'getMap' in window.external ) {
                        var txt = window.external.getMap( code )
                        obj = JSON.parse(   _this.transformUTF(txt,false)  )
                    }
                   return obj;
                }
            )
        }
    },
    
/*
    updateCodeFromSignalID : function(id, code) {
        var form = {}
        if (id != null) {
            form = { "stra" :id, "code" : code}
        } else {
            //FOR TESING 'SAMPLE'
            form = { "stra" :'SAMPLE', "code" : code}
        }
        var url = Apis.api  + "updatesignal"
        return fetch(
                url, 
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'content-Type': 'application/json',
                    },
                    body: JSON.stringify(form),
                }
        ).then(
            (response) => response.json()
        ) 
    },
*/
/*  
    transplier: function(info, params, symbols, code) {
        var url = Apis.api + "transplier"
        if (info.country==='CHN') {
            info = {"country":"CHN","exchange":"A","start_date":"","end_date":"","resolution":"m1" }
        }


        var form = { information : info, params : params, symbols: symbols, code : code }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },
*/
/*
    backtesting: function(info, params, symbols, code, ords) {
        var url = Apis.api + "backtesting2"
        if (info.country==='CHN') {
            info = {"country":"CHN","exchange":"A","start_date":"","end_date":"","resolution":"m1" }
        }

        info['initial'] = 100000
        info['safety'] = 0.3

        var form = { information : info, params : params, symbols: symbols, code : code }

        if (ords) {
            form['overrides'] = ords
        }

        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    backtesting3: function(info, params, symbols, code, ords) {
        var url = Apis.api + "backtesting3"
        if (info.country==='CHN') {
            info = {"country":"CHN","exchange":"A","start_date":"","end_date":"","resolution":"m1" }
        }
        params['initial'] = ('initial' in params)?params['initial']:100000
        params['safety'] = ('safety' in params)?params['safety']:0.3

        var form = { information : info, params : params, symbols: symbols, code : code }
        if (ords) {
            form['overrides'] = ords
        }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },
*/
    //backtestingS: function( group ,info, params) {
    backtestingS: function( group , transaction_fee, region="hkg", include_dividends=false, demo=false) {
        //var url = Apis.api + "backtestingS"
        var url = Apis.apil + "backtesting"
        var gp = JSON.parse( JSON.stringify(group) )
        var info = {"country": "HK", "exchange": "HKEX", "start_date": "20170101", "end_date": "20171231", "resolution": "d1" }
        var params = {"transaction_fee": parseFloat(transaction_fee) , "leverage":1,"boardlot":100,'scale' :60000000000, 'initial':100000, 'safety':0.3 }
        var form = { information : info, params : params, group : gp, demo : demo?true:false, include_dividends: include_dividends }
        if(region) {
            form.region = region
        }


        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    forwardtestingS: function( group , symbol_params ,transaction_fee, region="hkg", demo=false) {
        var url = Apis.apil + "forwardtesting"
        var gp = JSON.parse( JSON.stringify(group) )
        var info = {"country": "HK", "exchange": "HKEX", "start_date": "20170101", "end_date": "20171231", "resolution": "d1" }
        var params = {"transaction_fee": parseFloat(transaction_fee) , "leverage":1,"boardlot":100,'scale' :60000000000, 'initial':100000, 'safety':0.3 }
    
        var form = { information : info, params : params, group : gp, symbol_params : symbol_params, diff : 400 }

        if( region ) {
            form.region = region
        }

        if( demo ) {
            form.demo =demo
        }

        if(  gp.subscription_id  ) {
            form['portfolio_id'] = gp['id']
            delete form['group']
        }
        
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getprices : (symbol, type, date) => {
        var url = Apis.apil + "getprices"
        //var url = "https://wjz9zd7zwd.execute-api.ap-southeast-1.amazonaws.com/getprices"
        var form = {}
        if( type === "history" ) {
            form = { symbol: symbol, type : type }
            if (date) {
                form.date = date
            }
        } else {
            form = { symbols: symbol, type : type }
        }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getFilterConfig: ( typ, region, date ) => {
        var url = Apis.apil + "getfilterconfig"
        //var url = "https://wjz9zd7zwd.execute-api.ap-southeast-1.amazonaws.com/default/" + "getfilterconfig"
        ///var url = Apis.apil + "getfilterconfig"
        typ= typ?typ:"config"
        var form = { type : typ, region : region }
        if (date) {
            form.date = date
        }

        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getsymbolfromfilter : function(filter) {
        //SPOTLIGHT
        var url = Apis.api + "getsymbolfromfilter"
        var form = { 
            filter : filter, 
            report_type: "all",
            symbol: [],
            table: "summaryprofile" 
        }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getdatafromsymbol : (symbol, date) => {
        var url = Apis.api + "getdatafromsymbol"
        var form = { symbol : symbol, date : date }
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    getFilterTable: ( filter, report_type, symbol, table, portfolio_id=null ,full=true, region="hkg", demo=false) => {
        var url = Apis.apil + "filter"
        var form = {  }
        form.full = full

        if( filter ) {
            form.filter = filter 
        }
        if( portfolio_id ) {
            form.portfolio_id = portfolio_id
        }
        if(report_type) {
            form.report_type = report_type
        } else {
            form.report_type = "f"
        }

        if( demo ) {
            form.demo = demo
        }

        console.log( "REGION : " + region)
        if(region) {
            form.region = region
        } else {
            form.region = "hkg"
        }

        form.symbol = []
        if(symbol) {
            form.symbol = symbol
        }
        if(table) {
            form.table = table
        }

        //console.log( url )
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    //'Authorization' : 'Bearer ' + service.token
                },
                body: JSON.stringify( form ),
            }
            
        ).then(
            (response) => response.json()  
        )
    },

    

}


export default Apis