import React, { Component } from 'react';
import './index.scss';

//import service from '../../public/service'
//import logoB from '../../public/img/garlican_blue_logo.png'

import { Button,Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faTimes, faChartLine } from '@fortawesome/free-solid-svg-icons'
import gservice from '../../../Garlican/GarlicanLibrary/gservice'

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Api from '../../../Garlican/GarlicanLibrary/apis'
import service from '../../../public/service';


class Template extends Component {

    constructor(props) {
      super(props)
      this.state = {
        isLogin : this.props.isLogin,
        templates : [],
        page : 0,
        showModal : false,
        selected : null,
        //selectedTemplate : {},
      }
            
    }

    componentDidMount() {
      var  obj = {}
      if( this.props.selectedBotfolio.region ) {
        obj.region = this.props.selectedBotfolio.region 
      }
      this.getStrategy(obj)
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {

      /*
        setTimeout(  (e) => {
          if(document.getElementById(props.selectedTemplate.id ) ) {
            console.log(  findPos( document.getElementById(props.selectedTemplate.id )  ) )
            document.getElementById( "board" ).scroll(0,findPos( document.getElementById(props.selectedTemplate.id ) ));
          }
        } , 500  )   
        function findPos(obj) {
          var curtop = 0;
          if (obj.offsetParent) {
              do {
                  curtop += obj.offsetTop;
              } while (obj = obj.offsetParent);
          return curtop;
          }
        }
      */
    }

    getStrategy( obj ) {
      service.getTemplates(obj).then(
        (res) => {
          if( 'code' in res ) {
            this.props.updateValue({
              isLogin : false
            })
          } else {

            var result = JSON.parse( JSON.stringify( res.list )  )
            result = result.filter( ( v, k ) => ((v.access_mode === "public") || ( service.isOwner( v.user_id ) ) ) )
            
            this.setState({
              //templates : res.list,
              templates: result,
              page : res.page,
            })
          }
          
        }
      )
    }

    render() {
        return (
          <div className={['Template', this.props.showTemplate?"appear":"" ].join(" ") }>  
            { this.props.isFilter &&
              <div className={['panel'].join(" ") }>
                <Button onClick={
                  (e)=> {
                    this.setState({
                      showModal : true
                    })
                  }
                }>  { this.props.gi18n[this.props.language]['create'] }  </Button>
              </div>
            }
            
            <div className={['board'].join(" ") } id={"board"}>
              <div className={["title"].join(" ")} >{  this.props.gi18n[this.props.language]['pick_botfolio']   }</div>
              { this.props.isLogin && (this.state.templates.length > 0) &&
                this.state.templates.map( (v,k) => {
                  return (
                    <div id={ v.id } className={['item', (this.props.selectedTemplate.id===v.id)?"selected":"" ].join(" ") } key={k} > 
                      <div className={['upper'].join(" ") } onClick={
                        (e)=> {
                          if(  this.props.selectedTemplate.id !== v.id  ) {
                            this.props.updateCode({
                              selectedTemplate : v
                            })
                            this.props.updateParent({
                              input : v.input,
                            })
                          } else {
                            this.props.updateCode({
                              selectedTemplate : {}
                            })
                            this.props.updateParent({
                              input : {},
                            })
                          }
                        }
                      }> 
                       <span>{ v.name } </span>
                      </div>
                      <div className={['middle'].join(" ") } onClick={
                        (e)=> {
                          if(  this.props.selectedTemplate.id !== v.id  ) {
                            this.props.updateCode({
                              selectedTemplate : v
                            })
                            this.props.updateParent({
                              input : v.input,
                            })
                          } else {
                            this.props.updateCode({
                              selectedTemplate : {}
                            })
                            this.props.updateParent({
                              input : {},
                            })
                          }
                        }
                      }>
                        <span className={['badge', (v.access_mode==="public")?"public":'private'  ].join(" ") }>  { (v.access_mode==="public")?this.props.gi18n[ this.props.language ]['public']:this.props.gi18n[ this.props.language ]['private']  }  </span> 
                       <span>
                         { this.props.gi18n[this.props.language]["powered"] + v.user.first_name } 
                        </span>
                      </div>
                      <div className={['lower'].join(" ") } onClick={
                        (e)=> {
                          //this.props.selected( v )
                          if( this.props.selectedTemplate.id !== v.id ) {
                            this.props.updateCode({
                              selectedTemplate : v
                            })
                            this.props.updateParent({
                              input : v.input,
                            })
                          } else {
                            this.props.updateCode({
                              selectedTemplate : {}
                            })
                            this.props.updateParent({
                              input : {},
                            })
                          }
                        }
                      }> 

                       <span className={['desc', this.props.selectedTemplate?((this.props.selectedTemplate.id===v.id)?'show':""):"" ].join(" ") }>{ v.description } </span>
                      </div>
                      {
                        <div className={['panel', (this.props.selectedTemplate.id===v.id)?"show":""  ].join(" ") }> 
                          <Form>
                            {
                              this.props.input && (true === false) &&
                              <Form.Group controlId="inputs" >
                                  <Form.Label>  { this.props.gi18n[this.props.language]['group_input'] }  </Form.Label>
                                  <div className={['form-holder'].join(" ")} >
                                  {
                                    Object.values( this.props.input ).map(
                                      (v, k) => {
                                        return (
                                          <div className={['input-holder'].join(" ")} key={k}> 
                                            <span className={['name'].join(" ")}> { v.name } </span>  
                                            <FormControl 
                                              onChange={ (e) => {
                                                var _input = JSON.parse(  JSON.stringify( this.props.input )  )
                                                _input[ v.key ] = v
                                                _input[ v.key ].value = e.target.value
                                                this.props.updateParent({
                                                  input : _input
                                                })
                                              }}
                                              className={['input'].join(" ")} 
                                              value={v.value} />
                                          </div>
                                        )
                                      }
                                    )
                                  }
                                  </div>
                              </Form.Group>
                            }

                            <Button variant="primary" onClick={
                              (e) => {
                                //console.log(v)
                                this.props.selected( v )
                              }
                            }>
                                { this.props.gi18n[this.props.language]['t_pickup'] } 
                            </Button>
                          </Form>  
                             
                        </div>
                      }
                    </div>
                  )
                } )
              }

              {
                this.props.isLogin && (this.state.templates.length === 0) &&
                <div>{  this.props.gi18n[this.props.language]['no_template']  }</div>
              }


              {
                !this.props.isLogin &&
                <Button 
                  onClick={ (e)=>{
                    this.props.updateValue({
                      showLogin : true,
                    })
                  }}
                  className={['login'].join(" ") } >  
                  {this.props.gi18n[this.props.language]['login']} 
                </Button>
              }
            </div>
          </div>
        )

    
    }
}


export default Template;



/*
                          <Form>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Strategy Name</Form.Label>
                              <Form.Control type="text" placeholder="Please type a name" onChange={ (e)=>{
                                this.props.updateParent( {
                                  name : e.target.value
                                } )
                              } } />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Description</Form.Label>
                              <Form.Control as="textarea" rows={5} onChange={ (e)=>{
                                this.props.updateParent( {
                                  description : e.target.value
                                } )
                              } }/>
                            </Form.Group>
*/