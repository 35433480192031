var support = {
  zhtc: {
    header : '歡迎與我們聯繫。',
    first_message : "此私隱政策聲明（“聲明”）應用於GARLIAN財經網站的用戶。",
    notification : "*請注意，本聲明事前毋須另行通知即可不時修訂，閣下應定期查閱最新版本。",
    title_placeholder : "主題/標題",
    message_placeholder : "請把您的想法，想法或意見發送給我們。",
    send : "發送",
 
  } , 
  zhcn: {
    header : '欢迎与我们联系。',
    first_message : "此私隐政策声明（“声明”）应用于GARLIAN财经网站的用户。",
    notification : "*请注意，本声明事前毋须另行通知即可不时修订，阁下应定期查阅最新版本。",
    title_placeholder : "主题/标题",
    message_placeholder : "请把您的想法，想法或意见发送给我们。",
    send : "发送",
  },
  eng:{
    header : 'Feel free to contact us.',
    first_message : "This Privacy Policy Statement (\"Statement\") applied to the user GARLIAN financial Web site.",
    notification : "*Please note that this statement prior to revision from time to time without notice, you should regularly check the latest version.",
    title_placeholder : "Topic/title",
    message_placeholder : "Please send us your throughts, ideas or opinions.",
    send : "SEND"

  }
}



export default support