import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import service from '../../public/service'
//import gservice from '../../Garlican/GarlicanLibrary/gservice'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown, faMinusCircle, faChevronDown, faChevronUp, faCog, faRobot, faPlay, faPen, faNewspaper, faEye } from '@fortawesome/free-solid-svg-icons'
import { Jumbotron, Button, Accordion, Card, Col, Row, OverlayTrigger, Tooltip, Tabs, Tab, ButtonGroup, FormControl, DropdownButton, Dropdown, NavItem } from 'react-bootstrap';

import { VictoryChart, VictoryTheme , VictoryPolarAxis, VictoryLabel, VictoryArea, VictoryPie, VictoryLegend } from 'victory';
//import gcolor from '../../public/style.scss';
import gcolor from '../../public/color.js';
import flags from '../../public/flags.js';
import Emoji from 'react-emojis';

//import GarlicanReport from '../../Garlican/GarlicanReport';
//import Api from '../../Garlican/GarlicanLibrary/apis'
//import sptrader from '../../Components/Trader/service/sp';

const defaultColor = gcolor.gnBadgeColor
var colors = []
const MAX_ROW = 5

const include_dividends = true

const types = [
  { key : 'backtest', value : 'backtest' },
  { key : 'forwardtest', value : 'forwardtest' }
]

const frame = {
  "risk_rate" : 100,
  'win_rate' : 100,
  "quantity_rate" : 100 ,
  "profit_rate" : 100 
}

const portfolio_frame = [
  {key : 'view_count' , value : 'view_ct'},
  {key : 'bookmark_count' , value : 'subscribe_ct'},
]

const gd = [
  {  x: "risk_rate" , y: 0 },
  {  x: 'win_rate' , y: 0 },
  {  x: "quantity_rate" , y: 0 },
  {  x: "profit_rate" , y: 0  },
]
const gdOj = {
  'risk_rate' : 0,
  'win_rate' :  0,
  'quantity_rate' : 0,
  'profit_rate' : 0
}
var temp_obj = {}

class Detail extends Component {

    constructor(props) {
        super(props)
        this.state = { 
          obj : null,
          data: gd,
          maxima: frame,
          partition : [],
          selected : null,
          strategy_list : [],
          activities : {},
          selectedStrategy : {},
          isOwner : false,
          pieArea : 0,
          showReport : false,
          gdOj : gdOj,
          total_portfolio : 0,
          isShare : false,
          activeKey : "0",
          inputs : [],
          selectTab : "config",
          type : 'backtest',
          feature : [],
          focus : null,
        };

        this.getPortfolioDetail = this.getPortfolioDetail.bind(this)
        this.goPublic = this.goPublic.bind(this)
        this.selectTab = this.selectTab.bind(this)
        this.selectType = this.selectType.bind(this)
     
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {
      var _this = this
      setTimeout( (e) => {
          if( document.getElementById("tab") ) {
            var rect = document.getElementById("tab").getBoundingClientRect()
            var height = rect.height
            var width = rect.width
            _this.setState({
              pieArea :  Math.min( height, width ) ,
            })
          }
      } , 500 )
      if(   ("id" in props.selectedBotfolio) && (props.showGarlican === false) ) {
        if( !this.state.obj || (  JSON.stringify(props.selectedBotfolio) !== JSON.stringify(this.state.obj) ) ) {
          this.getPortfolioDetail(props)
        }
      } 
    }

    getPortfolioDetail(props) {
      var _this = this
      if(props.selectedBotfolio ) {
        function _call( res ) {
          var _res = JSON.parse( JSON.stringify(res) )
          //console.log( _res )
          res.user = props.selectedBotfolio.user 
          var checkers = [  
              { key : 'strategy_list', value : [] }, 
              { key : 'input', value : {} }, 
              { key : 'partition', value : {} },
              { key : 'transaction_fee', value : 0 },
          ]
          for (var c in checkers) {
              if( !( checkers[c].key in res) ) {
                res[  checkers[c].key   ] = checkers[c].value
              }
          }
          var _gd = [], _gdOj={}
          if(res['total_portfolios']!==0) {
            _gd = [
              {  x: "risk_rate" , y: Math.round( (res['total_portfolios'] - res['sharpe_ratio_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: 'win_rate' , y: Math.round( (res['total_portfolios'] - res['win_rate_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: "quantity_rate" , y: Math.round( (res['total_portfolios'] - res['turnover_rate_rank'] + 1)/res['total_portfolios']*10000)/100 },
              {  x: "profit_rate" , y: Math.round( (res['total_portfolios'] - res['profit_rate_rank'] + 1)/res['total_portfolios']*10000)/100  },
            ]
            _gdOj = {
                'risk_rate' : Math.round( (res['total_portfolios'] - res['sharpe_ratio_rank'] + 1)/res['total_portfolios']*10000)/100,
                'win_rate' :  Math.round( (res['total_portfolios'] - res['win_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
                'quantity_rate' : Math.round( (res['total_portfolios'] - res['turnover_rate_rank'] + 1)/res['total_portfolios']*10000)/100,
                'profit_rate' : Math.round( (res['total_portfolios'] - res['profit_rate_rank'] + 1)/res['total_portfolios']*10000)/100  
            }
          } else {
              _gd = gd
              _gdOj = gdOj
          }  
          var isOwner = service.isOwner( parseInt(res.user.user_id)  )
          var partition = []
          var cash = 100, strategy_list = [], inputs = [], _temp = {}
          for (var i in res.strategy_list) {
              var obj = {}
              obj.name = res.strategy_list[i].name
              obj['gid'] = res.id
              obj['id'] = res.strategy_list[i].id
              obj['x'] = res.strategy_list[i].name
              //obj['y'] = parseInt(res.partition[  res.strategy_list[i].id  ])/100
              
              if( parseInt(res.partition[  res.strategy_list[i].id  ])  ) {
                cash -= parseInt(res.partition[  res.strategy_list[i].id  ])
                obj['y'] = parseInt(res.partition[  res.strategy_list[i].id  ])/100
              } else {
                //console.log( "IN" )
                obj['y'] = 0
              }

              partition.push( obj )
              var _obj = JSON.parse(   JSON.stringify( res.strategy_list[i] )  )
              _obj['partition'] = res.partition[  res.strategy_list[i].id  ]?res.partition[  res.strategy_list[i].id  ]:"0"
              strategy_list.push(_obj)
              _temp[ res.strategy_list[i].id ] = res.strategy_list[i]
          }
          for (var i in res.input){
            var _o = {}
            _o.id = i
            _o.strategy = _temp[i]
            _o.value = res.input[i]
            inputs.push( _o )         
          }
          var _o = {}
          _o.name = _this.props.gi18n[ _this.props.language]['cash']
          _o.partition = cash.toString()					
          strategy_list.splice( 0, 0, _o)
          partition.splice( 0, 0, {id : "-1" , x : _this.props.gi18n[ _this.props.language]['cash'], y : cash / 100, name: _this.props.gi18n[ _this.props.language]['cash']} )
        
          var _feature = []
          if(  props.selectedBotfolio.feature   ) {
            var _f = JSON.parse(  JSON.stringify( props.selectedBotfolio.feature )  )
            for( var i in _f.filter ) {
              var _o = {}
              _o.name = _f.filter[i].name
              _o.filter = Object.values( _f.filter[i].value )
              _o.filter = _o.filter.map( (v,k) => {
                return v.lang
              } )

              if( i in _f.buy  ) {
                _o.buy = Object.values( _f.buy[i].value )
              } else {
                _o.buy = []
              }
              if( i in _f.sell  ) {
                _o.sell = Object.values( _f.sell[i].value )
              } else {
                _o.sell = []
              } 
              _feature.push(  _o  )
            }
          }

          var d_key = "config"
          if( props.selectedBotfolio.subscription_id!== null ) {
            d_key = "glance"
          }

          _this.setState( { 
              selectTab : d_key,
              feature : _feature,
              isOwner: isOwner, 
              bookmarked : res.bookmarked,
              obj : _res, 
              data : _gd,  
              gdOj : _gdOj,
              partition : partition, 
              strategy_list : strategy_list,
              total_portfolio : res['total_portfolios'],
              inputs : inputs,
          } )

          
          if( temp_obj.id !== props.selectedBotfolio.id ) {
            //console.log( "in" )
            //console.log( _res )
            //console.log( props.selectedBotfolio )
            setTimeout( (e) => {
            _this.selectType( _this.state.type ) 
            }, 500 )
          }
          temp_obj = _res
        
        }
        _call(props.selectedBotfolio)

      }
    }

    goPublic(feature) {
      this.props.updateBotfolio( ['access_mode', 'feature'], ['public', feature] )
    }

    selectTab(e) {
      this.setState({activeKey:"0", selectTab : e})
    }

    selectType(type) {
      this.setState({
        type : type
      })
      if( type === "forwardtest" || type === "realtrade" ) {
        //var v = JSON.parse( JSON.stringify( this.props.selectedBotfolio )   )
        var v = this.state.obj
        var obj = {
          report_type : type,
          initial_capital : v.initial_capital,
          transaction_fee : v.transaction_fee,
        }
        service.createReport(  this.props.selectedBotfolio.id , obj  ).then(
          (res) => {
            //console.log( res )
            
            v[ type + "_report" ] = res
            //var o = this.state.obj
            this.setState({
              obj : v,
            })
            //this.props.updateParent( {
            //  selectedBotfolio : v
            //} )
          }
        )
      }
    }

    render() {
        return (
          <div className={['Detail'].join(' ')} >

              <div className={['Detail-Control']}>     
                { (this.state.selectTab === "glance") &&
                  [
                    <Button 
                      key={ "q1" }
                      onClick={ (e)=>{
                        if(this.props.isLogin) {
                          if( this.state.bookmarked || this.state.isOwner ) {
                            if( this.state.obj) {
                              if( this.state.obj.backtest_report ) {
                                //this.setState({  showReport : true })
                                this.props.getReport( this.state.type )
                              }
                            }
                          } else {
                            this.createBookmark()
                          }
                        } else {
                          this.props.updateValue( {showLogin : true, showSignup : false} ) 
                        }
                      } }
                      variant="primary" 
                      disabled={ !((this.state.obj)?(this.state.obj[ this.state.type + "_report"]):true)  }
                      className={['Detail-Jumbotron-Button', 'left' , 'config' ].join(" ")}  >
                        <FontAwesomeIcon icon={faNewspaper} className={["Fa"].join(" ")} />
                      {  ( (this.state.bookmarked || this.state.isOwner )?this.props.gi18n[this.props.language][  this.state.type + "ing"]:this.props.gi18n[this.props.language]["subscribe"])   }
                    </Button>
                  ]
                }
                { ( (this.state.selectTab === "config") || (this.state.selectTab === "strategy") ) && (this.props.selectedBotfolio.subscription_id === null) && 
                  [
                    <Button 
                      key={ "p1" }
                      onClick={ (e)=>{
                        this.props.backtest( this.props.selectedBotfolio.initial_capital, this.props.selectedBotfolio.transaction_fee, true, null, include_dividends )
                      } }
                      variant="primary" 
                      className={['Detail-Jumbotron-Button', "left", 'config' ].join(" ")}  >
                      <FontAwesomeIcon icon={faPlay} className={["Fa"].join(" ")} />
                      {  this.props.gi18n[this.props.language]['runbacktest']   }
                    </Button>
                  ]
                }  
                { !this.props.selectedBotfolio.subscription_id && 
                  <Button
                        disabled={ this.props.selectedBotfolio.strategy_list?!this.props.selectedBotfolio.strategy_list[0].filters:false }
                        onClick={ (e)=>{
                          if(this.props.isLogin) {

                            var strat = JSON.parse(  JSON.stringify( this.props.selectedBotfolio.strategy_list ) )
                            this.props.updateParent({
                              showCreate : false,
                              selectedStrategy : strat[0],
                              showFilter : true,
                              filters : strat[0].filters,
                              code : strat[0].code
                            })


                          }
                        } }
                        variant="primary" 
                        className={['Detail-Jumbotron-Button', 'right' , 'config' ].join(" ")}  >
                          <FontAwesomeIcon icon={faCog} className={["Fa"].join(" ")} />
                        { this.props.selectedBotfolio.strategy_list?(this.props.selectedBotfolio.strategy_list[0].filters?this.props.gi18n[this.props.language]["edit"]:this.props.gi18n[this.props.language]["protected_mode"]):this.props.gi18n[this.props.language]["edit"]  }
                  </Button>   
                }               
              </div>
              
              <div className={ ['Detail-Body'].join( " " ) }>         
                <Tabs 
                  defaultActiveKey={  (this.props.selectedBotfolio.subscription_id === null)?"config":"glance" } 
                  id="tab" 
                  activeKey={ this.state.selectTab  }
                  onSelect={ (e)=>{
                    this.selectTab(e)
                 }}>
                    { (this.props.selectedBotfolio.subscription_id === null) &&
                      <Tab eventKey="config" title={ 
                        <div className={ ["Tab-Title"].join(" ")  }> 
                          <FontAwesomeIcon icon={faCog} className={ ["Fa"].join(" ")  }/>
                          { this.props.gi18n[this.props.language]["config"] }
                        </div>
                      } className={ ['Holder'].join(" ") }  >

                        { (this.props.selectedBotfolio.subscription_id === null) &&
                          <div className={['Docker', "H"].join(' ')}>
                            
                            <DropdownButton className={["Regions"].join(" ")} title={ 
                              <div className={["Scope"].join(" ")} >
                                <div className={['Region'].join(" ") }>  
                                        {
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                (
                                                  this.props.selectedBotfolio.region?
                                                  (
                                                    flags[this.props.selectedBotfolio.region]
                                                  ):
                                                  flags['usa']
                                                )
                                              }
                                          />
                                        }
                                </div>
                                { this.props.selectedBotfolio.region?this.props.selectedBotfolio.region.toUpperCase():"hkg" }
                              </div>
                            } >
                              {
                                this.props.regions.map( (v,k) => {
                                  return(
                                    <Dropdown.Item 
                                      className={["Line"].join(" ")}
                                      eventKey={v.region} 
                                      key={k} 
                                      onSelect={ (e)=> {
                                        var key = e
                                        //if(e==="hk") {
                                        //  key = "hkg"
                                        //}
                                        this.props.updateBotfolio( ['region'], [ key.toLowerCase() ] )
                                    } } > 
                                      <div className={['Region'].join(" ") }>  
                                        {
                                          <Emoji className={['Emoji'].join(" ") } 
                                              emoji={
                                                v.region?
                                                flags[v.region]:
                                                flags['hkg']
                                              }
                                          />
                                        }
                                      </div>
                                      { v.region } 
                                    </Dropdown.Item>
                                  )
                                } )
                              }
                              
                            </DropdownButton>

                            <Button 
                              key={ "p2" }
                              onClick={ (e)=>{
                                this.props.updateSaveBotfolio()
                              } }
                              variant="primary" 
                              className={['Save'].join(" ")}  >
                              <FontAwesomeIcon icon={faPen} className={["Fa"].join(" ")} />
                              {  this.props.gi18n[this.props.language]['update']  }
                            </Button>
                          </div>
                        }
                          <div className={['Docker'].join(' ')}>
                            <ButtonGroup aria-label="Basic example" className={['Mode'].join(' ')}>
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip bsPrefix={"TP"}>
                                    {  this.props.gi18n[this.props.language]['public_msg'] }
                                  </Tooltip>
                                }
                              >
                                <Button 
                                  onClick={ (e)=>{
                                    if( this.props.selectedBotfolio.backtest_report ) {
                                      this.props.getFeatures( this.props.selectedBotfolio , this.goPublic  )
                                      //this.props.updateBotfolio( ['access_mode'], ['public'] )
                                    } else {
                                      //console.log( "Run backtesting" )
                                    }
                                  } }
                                  className={[(this.props.selectedBotfolio.access_mode!=="public")?'Disabled':""].join(' ')}> 
                                  { this.props.gi18n[this.props.language]['public'] } 
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip bsPrefix={"TP"}>
                                    {  this.props.gi18n[this.props.language]['private_msg'] }
                                  </Tooltip>
                                }
                              >
                                <Button 
                                  onClick={ (e)=>{
                                    this.props.updateBotfolio( ['access_mode'], ['private'] )
                                  } }
                                  className={[(this.props.selectedBotfolio.access_mode==="public")?'Disabled':""].join(' ')} > 
                                  { this.props.gi18n[this.props.language]['private'] } 
                                </Button>
                              </OverlayTrigger>
                            </ButtonGroup>
                          </div>
                          
                          <div className={['Docker'].join(' ')}>
                              <div className={['Title'].join(' ')}> { this.props.gi18n[this.props.language]['group_name'] } </div>
                              <FormControl  
                                disabled={ this.props.selectedBotfolio.subscription_id!==null }
                                placeholder={  this.props.gi18n[this.props.language]['group_name']  }
                                className={["Value"].join(" ")} 
                                onChange={ (e)=> { this.props.updateBotfolio( ['name'], [e.target.value]  )   } }
                                value={  this.state.obj?this.state.obj.name:""  } />
                          </div>

                          <div className={['Docker', "Two"].join(' ')}>
                            <div className={['Input'].join(' ')}> 
                              <div className={['TTitle'].join(' ')}> { this.props.gi18n[this.props.language]['initial'] } </div>
                              <FormControl 
                                className={['TValue', 'TInput' , (this.state.focus==="initial")?"prior":"" ].join(' ')} 
                                disabled={ this.props.selectedBotfolio.subscription_id!==null }
                                onFocus={ (e)=>{
                                  var _this = this
                                  setTimeout(  () => {
                                    _this.setState( { focus : "initial" } ) 
                                  }, 500 )
                                }}
                                onBlur={ (e)=>this.setState( { focus : null } ) }
                                onChange={  
                                  (e) => {
                                    var val = (e.target.value.length>0)?parseFloat( e.target.value.replace( /,/g,"" ) , 10):0
                                    this.props.updateBotfolio( ['initial_capital'], [  val ] )
                                  }
                                }
                                value={ 
                                  this.props.selectedBotfolio.initial_capital?this.props.selectedBotfolio.initial_capital:""
                                } />
                              <span className={['TValue',  (this.state.focus==="initial")?"noprior":""].join(' ')} > {"$" + (this.props.selectedBotfolio.initial_capital?this.props.selectedBotfolio.initial_capital.toLocaleString("en-US"):"") } </span>
                            </div>
                            <div className={['Input'].join(' ')}> 
                              <div className={['TTitle'].join(' ')}> { this.props.gi18n[this.props.language]['transaction_fee'] } </div>
                              <FormControl 
                                className={['TValue', 'TInput', (this.state.focus==="fee")?"prior":"" ].join(' ')} 
                                disabled={ this.props.selectedBotfolio.subscription_id!==null }
                                onFocus={ (e)=>{
                                  var _this = this
                                  setTimeout(  () => {
                                    _this.setState( { focus : "fee" } ) 
                                  }, 500 )
                                }}
                                onBlur={ (e)=>this.setState( { focus : null } ) }
                                onChange={  
                                  (e) => {
                                    var val = (e.target.value.length>0)?parseFloat( e.target.value.replace( /,/g,"" ) , 10):0
                                    this.props.updateBotfolio( ['transaction_fee'], [  val ] )
                                  }
                                }
                                value={ 
                                  this.props.selectedBotfolio.transaction_fee?this.props.selectedBotfolio.transaction_fee:"0"
                                } />
                                <span className={['TValue' ,  (this.state.focus==="fee")?"noprior":""].join(' ')} > {"$" + (this.props.selectedBotfolio.transaction_fee?this.props.selectedBotfolio.transaction_fee.toLocaleString("en-US"):"0") } </span>
                            </div>
                          </div> 

                          {/*Description*/}
                          <div className={['Docker'].join(' ')}>
                              <div className={['Title'].join(' ')}> { this.props.gi18n[this.props.language]['group_desc']  } </div>
                              <FormControl  
                                as="textarea"
                                disabled={ this.props.selectedBotfolio.subscription_id!==null }
                                placeholder={  this.props.gi18n[this.props.language]['group_desc']  }
                                className={["Text"].join(" ")} 
                                onChange={ (e)=> { this.props.updateBotfolio( ['description'], [e.target.value]  )   } }
                                value={  this.state.obj?this.state.obj.description:""  } />
                          </div> 

                          

                      </Tab>
                    }
                    { (this.props.selectedBotfolio.subscription_id === null) &&
                      <Tab eventKey="strategy" title={ 
                        <div className={ ["Tab-Title"].join(" ")  }> 
                          <FontAwesomeIcon icon={faRobot} className={ ["Fa"].join(" ")  }/>
                          { this.props.gi18n[this.props.language]["strategy"] }
                        </div>
                      } className={ ['Holder'].join(" ") }  >
                             
                          <div className={['Docker', "Strategy"].join(' ')}>
                              <div className={['Title'].join(' ')}> { this.props.gi18n[this.props.language]['group_input'] } </div>

                              <div className={['AHolder'].join(' ')}>
                                  <Accordion 
                                    activeKey={  this.state.activeKey  }
                                    defaultActiveKey={  this.state.activeKey  } 
                                  >
                                  { this.state.inputs.map( (v,k) => {
                                      if( v.strategy ) {
                                        return (
                                          <Card key={k} >
                                            <FontAwesomeIcon icon={faPen} className={['Fa'].join(' ')} onClick={
                                              (e) => {
                                                var _filters = []
                                                _filters = this.props.transfromFilter( v.strategy.filters )
                                                this.props.updateParent({
                                                  showCreate : false,
                                                  selectedStrategy : v.strategy,
                                                  //selectedBoard : 'filter',
                                                  //selectedIndex : 2,
                                                  filters : _filters,
                                                  code : v.strategy.code
                                                })
                                                if( this.props.filter.current ) {
                                                  this.props.filter.current.showBoard( false ) 
                                                }
                                              }
                                            }/>
                                            <Accordion.Toggle 
                                              as={Card.Header} 
                                              eventKey={  k.toString()   } 
                                              onClick={ (e)=>{
                                                if( this.state.activeKey !== k.toString() ) {
                                                  this.setState({  
                                                    activeKey :  k.toString()
                                                  })
                                                } else {
                                                  this.setState({  
                                                    activeKey :  "-1"
                                                  })
                                                }
                                              } 
                                            } >
                                              <FontAwesomeIcon className={['fa'].join(" ")} icon={ (this.state.activeKey===k)?faChevronUp:faChevronDown } />
                                              <span className={['Name'].join(" ")}>{  v.strategy.name   }</span>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={ k.toString() }>
                                              <Card.Body>
                                                { v.value &&
                                                  Object.keys( v.value ).map(  (v1, k1) => {
                                                      return(
                                                        <div className={['IHolder'].join(" ")} key={k1}> 
                                                          <div className={['Name'].join(" ")}> {  v.value[ v1 ].name!==""?v.value[ v1 ].name:v1 } </div>
                                                          <FormControl 
                                                            className={['Input'].join(" ")} 
                                                            value={ v.value[ v1 ].value  }  
                                                            onChange={ (e) => {
                                                              var _input = {}
                                                              for(var i in this.state.inputs) {
                                                                _input[  this.state.inputs[i].id  ] = this.state.inputs[i].value
                                                                if( v.id === this.state.inputs[i].id.toString() ) {
                                                                  _input[  v.id  ][v1].value = e.target.value
                                                                } 
                                                              }
                                                              this.props.updateBotfolio( ["input"], [_input]  )
                                                            } }/>
                                                        </div>
                                                      )
                                                  })
                                                } 
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                        )
                                      } else {
                                        return null
                                      }

                                  } )
                                  }
                                  </Accordion>
                              </div>

                          </div>
                      </Tab>
                    }
                    <Tab eventKey="glance" title={ 
                      <div className={ ["Tab-Title"].join(" ")  }> 
                        <FontAwesomeIcon icon={faEye} className={ ["Fa"].join(" ")  }/>
                        { this.props.gi18n[this.props.language]["glance"] }
                      </div>
                    } >                  
                      <ButtonGroup className={ ["Options"].join(" ")  }>
                        <Button variant="secondary" className={ [ (this.state.type==="backtest")?"selected":"" ].join(" ") } onClick={(e)=>{this.selectType("backtest")}}  > { this.props.gi18n[this.props.language]['backtesting'] } </Button>
                        <Button variant="secondary" className={ [ (this.state.type==="forwardtest")?"selected":"" ].join(" ") } onClick={(e)=>{this.selectType("forwardtest")}} > { this.props.gi18n[this.props.language]['forwardtesting'] } </Button>
                        <Button variant="secondary" className={ [ (this.state.type==="realtrade")?"selected":"" ].join(" ") } onClick={(e)=>{this.selectType("realtrade")}}> { this.props.gi18n[this.props.language]['realtradeing'] } </Button>
                      </ButtonGroup>
                      <Jumbotron className={['Detail-Jumbotron'].join(" ")} >
                        { (this.state.type === 'backtest') &&
                            <div className={['Detail-Radar-Holder'].join(' ') }>
                              <div className={['Detail-Radar-Title'].join(' ') }>
                                { this.props.gi18n[this.props.language]["aGlance"].replace("<num>", this.state.total_portfolio) }
                              </div>
                              { (this.state.data.length > 0) &&
                                    <VictoryChart polar
                                      width={300}
                                      height={300}
                                      style={{
                                        pointerEvents: 'none',
                                      }}
                                      className={['svgClass'].join(' ')}
                                      animate={{
                                        duration: 1000,
                                        onLoad: { duration: 500 }
                                      }}>
                                        <VictoryArea data={this.state.data}
                                          className={['svgClass'].join(' ')}
                                          style={{ data: { fill: gcolor.gnAccentColor+"50", stroke: gcolor.gnAccentColor, strokeWidth: 1.00,pointerEvents: 'none',  } }}/>
                                        {
                                            Object.keys(this.state.maxima).map((key, i) => {
                                              return (
                                                <VictoryPolarAxis key={i} 
                                                  dependentAxis
                                                  className={ ['Detail-Radar'].join(' ') }  
                                                  style={{
                                                      axisLabel: { padding: 10, fontSize : 15, fill : gcolor.gnBlackColor+"AA" },
                                                      tickLabels: { fontSize : 0, fill : gcolor.gnBlackColor },
                                                      axis: { stroke: "none" },
                                                      grid: { stroke: gcolor.gnBlackColor+"33", strokeWidth: 0.25, opacity: 0.5 }
                                                  }}                           
                                                  tickLabelComponent={
                                                      <VictoryLabel labelPlacement="vertical" />
                                                  }
                                                  
                                                  labelPlacement={ ((i+1)%2==0)?"perpendicular":"vertical"  }
                                                  axisValue={i + 1} 
                                                  tickValues={[25, 50, 75, 100]}
                                                  label={ this.props.gi18n[this.props.language][key] + "\n" +  ((this.state.gdOj)?( this.state.gdOj[key] ):"")  }
                                                />
                                              );
                                            })
                                        }
                                        <VictoryPolarAxis
                                          theme={VictoryTheme.material}
                                          labelPlacement="parallel"
                                          tickValues={[25, 50, 75, 100]}
                                          tickFormat={ (k)=>"" }
                                          style={{
                                                axis: { stroke: "none" },
                                                grid: { stroke: gcolor.gnBlackColor+"33", opacity: 0.5 }
                                          }}
                                        />
                                    </VictoryChart>
                              }
                            </div>
                          }
                        <div className={['Detail-Chart-Holder'].join(' ') }>
                          <div className={['Detail-Chart-Title'].join(' ') }>
                            { this.props.gi18n[this.props.language]["quick_summary"] }
                          </div>
                          { this.state.obj && 
                            this.state.obj[ this.state.type + "_report"] && 
                            <div className={ ['Detail-Chart-Holder-Billboard'].join(' ') }>
                                  <Col >
                                    <Row sm className={['two'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['profit'] } </div>
                                      <div className={['value' , (this.state.obj[ this.state.type + "_report"].profit > 0)?'up':((this.state.obj[ this.state.type + "_report"].profit < 0)?'down':'') ].join(' ')} > 
                                        
                                        {  
                                          //((this.state.obj[ this.state.type + "_report"].profit>0)?"+$":"-$") +  (Math.round(  Math.abs(this.state.obj[ this.state.type + "_report"].profit) ) )  
                                        }
                                        <span>
                                          <FontAwesomeIcon  icon={  (this.state.obj[ this.state.type + "_report"].profit>0)?faCaretUp:((this.state.obj[ this.state.type + "_report"].profit<0)?faCaretDown:faMinusCircle)  } className={[ "Fa" ].join(' ')}/>
                                          <span>{ "$" + Math.abs((Math.round(  Math.abs(this.state.obj[ this.state.type + "_report"].profit) ) )) }</span>
                                        </span>
                                      </div>
                                      <div className={['sub' , (this.state.obj[ this.state.type + "_report"].profit > 0)?'up':((this.state.obj[ this.state.type + "_report"].profit < 0)?'down':'') ].join(' ')} > 
                                        {  
                                          //((this.state.obj[ this.state.type + "_report"].profit>0)?"+":"") +  (Math.round(this.state.obj[ this.state.type + "_report"].profit/this.state.obj[ this.state.type + "_report"].initial_capital * 10000)/100) + "%"  
                                        } 
                                        <span>
                                          <FontAwesomeIcon  icon={  (this.state.obj[ this.state.type + "_report"].profit>0)?faCaretUp:((this.state.obj[ this.state.type + "_report"].profit<0)?faCaretDown:faMinusCircle)  } className={[ "Fa" ].join(' ')}/>
                                          <span>{ Math.abs(  (Math.round(this.state.obj[ this.state.type + "_report"].profit/this.state.obj[ this.state.type + "_report"].initial_capital * 10000)/100)) + "%"  }</span>
                                        </span>
                                      </div>
                                    </Row>
                                    <Row sm className={['two'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['trade_count'] } </div>
                                      <div className={['value'].join(' ')}> {  this.state.obj[ this.state.type + "_report"].trade_count } </div>
                                    </Row>
                                  </Col>
                                  <Col>
                                    <Row sm className={['three'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['initial'] } </div>
                                      <div className={['value', 'capital'].join(' ')}> {  "$" + service.getNumberFormat(this.state.obj[ this.state.type + "_report"].initial_capital) } </div>
                                    </Row>
                                    <Row sm className={['three'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['win_rate'] } </div>
                                      <div className={['value', 'capital'].join(' ')}> {  (Math.round(this.state.obj[ this.state.type + "_report"].win_count*10000/(this.state.obj[ this.state.type + "_report"].win_count + this.state.obj[ this.state.type + "_report"].even_count + this.state.obj[ this.state.type + "_report"].loss_count))/100) + "%" } </div>
                                    </Row>
                                    <Row sm className={['three'].join(' ')}>
                                      <div className={['title'].join(' ')}> { this.props.gi18n[this.props.language]['sharpe_ratio'] } </div>
                                      <div className={['value', 'capital'].join(' ')}> {  Math.round(this.state.obj[ this.state.type + "_report"].sharpe_ratio * 10000)/100 + "%"} </div>
                                    </Row>
                                  </Col>
                            </div>
                          }
                          { !(this.state.obj &&
                            this.state.obj[ this.state.type + "_report"]) &&
                            <div className={ ['Detail-Chart-Holder-Billboard', 'empty'].join(' ') }>
                              { this.props.gi18n[this.props.language]["no_report"] }
                            </div>
                          }
                        </div>
                        <div className={['Detail-Chart-Supporter'].join(' ')}>
                            {
                              portfolio_frame.map( (v,k) => {
                                return(
                                  <div className={['Detail-Chart-Supporter-Holder'].join(' ')} key={k}>
                                    <div className={['Detail-Chart-Supporter-Title'].join(' ')}>
                                      { this.props.gi18n[this.props.language][v.value] }
                                    </div>
                                    <div className={['Detail-Chart-Supporter-Content'].join(' ')}>
                                      { this.state.obj?this.state.obj[ v.key ]:"" }
                                    </div>

                                  </div>
                                )
                              }  )
                            }
                        </div> 



                        <div className={ ["Feature"].join(' ') }>
                          <div className={["FTitle"].join(" ")}> { this.props.gi18n[this.props.language]["features"] } </div>
                          {
                            this.state.feature.length > 0 &&
                            this.state.feature.map( (v,k) => {
                              return(
                                <div className={["Docker"].join(" ")} key={k}>  
                                  <div className={["DTitle"].join(" ")}> { v.name } </div>
                                  {
                                    ['filter', 'buy' , 'sell'].map( (v1, k1) => {
                                      return(
                                        <div className={["DHolder"].join(" ")} key={k1}>  
                                          <div className={["HTitle"].join(" ")}> { this.props.gi18n[this.props.language][v1+"_label"] } </div>
                                          { v[v1].length > 0 &&
                                            v[v1].map( (v3,k3) => {
                                              return(
                                                <li key={k3} className={['HList'].join(" ")}> { v3[this.props.language]  } </li>
                                              )
                                            } )
                                          }
                                          { v[v1].length === 0 &&
                                            <li className={['Statement'].join(" ")}> { (v1==="filter")?this.props.gi18n[this.props.language]['no_filter_label']:this.props.gi18n[this.props.language]['no_strategy_label'] } </li>
                                          }
                                        </div>
                                      )
                                    } )
                                  }
                                </div>
                              )
                            } )
                          }
                          {
                            this.state.feature.length === 0 &&
                            <div className={["Docker"].join(" ")} >
                              <div className={["DTitle"].join(" ")}> { this.props.gi18n[this.props.language]["no_features"] } </div>
                            </div>
                          }
                        </div>



                      </Jumbotron>  


                    </Tab>

                


                </Tabs>
              </div>
            


          </div>
        )
    }
}


export default Detail;




/*
                  <Tab eventKey="edit" title={ 
                      <div className={ ["Tab-Title"].join(" ")  }> 
                          <FontAwesomeIcon icon={faPen} className={ ["Fa"].join(" ")  }/>
                          { this.props.gi18n[this.props.language]["edit"] }
                      </div>
                   } className={ ['Holder'].join(" ") }  >
                    { this.props.isLogin && 
                        <div className={ ['Detail-Pie-Holder'].join(' ') } id={"Detail-Pie-Holder"} >
                            <div className={['Detail-Pie-Title'].join(' ') }>
                              { this.props.gi18n[this.props.language]['component_message'].replace( "<portfolio>" ,"") }
                            </div>
                            <div style={{
                              position : 'relative',
                            }}>
                              <VictoryChart 
                                style={{
                                  parent: {
                                    border: "0px solid #ccc"
                                  }
                                }}
                                polar>
                                <VictoryPolarAxis style={{ 
                                  axis: {stroke: "transparent"}, 
                                  ticks: {stroke: "transparent"},
                                  tickLabels: { fill:"transparent"} 
                                }} />
                                <VictoryLegend 
                                  standalone={false}
                                  x={   180 - ( Math.ceil( this.state.partition.length/MAX_ROW) - 1 ) * 55    } 
                                  y={  95   }
                                  itemsPerRow={  MAX_ROW  }
                                  orientation="vertical"
                                  gutter={35}
                                  rowGutter={{ top: 0, bottom: 0 }}
                                  style={ {
                                    title: {fontSize: 10, fill:gcolor.gnWhiteColor },
                                    labels: {fontSize: 10, fill:gcolor.gnWhiteColor}
                                  }}
                                  colorScale={ this.props.colors  }
                                  data={  this.state.partition }
                                  events={[{
                                    target: "data",
                                    eventHandlers: {
                                      onMouseDown : () => {
                                        return [
                                          {
                                            target: "data",
                                            mutation: ({datum}) => {
                                              if ("id" in this.state.selectedStrategy) {
                                                if( this.state.selectedStrategy.id === datum.id ) {
                                                  this.setState({selectedStrategy:{}, selected : null})  
                                                } else {
                                                  //this.selectedStrategy(datum)
                                                }
                                              } else {
                                                //this.selectedStrategy(datum)
                                              }
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  }, 
                                  {
                                    target: "labels",
                                    eventHandlers: {
                                      onMouseDown : () => {
                                        return [
                                          {
                                            target: "labels",
                                            mutation: ({datum}) => {
                                              if ("id" in this.state.selectedStrategy) {
                                                if( this.state.selectedStrategy.id === datum.id ) {
                                                  this.setState({selectedStrategy:{}, selected : null})
                                                } else {
                                                  //this.selectedStrategy(datum)
                                                }
                                              } else {
                                                //this.selectedStrategy(datum)
                                              }
                                            }
                                          }
                                        ]
                                      },
                                      onMouseEnter : () => {
                                        return [
                                          {
                                            target: "labels",
                                            mutation: ({datum}) => {
                                              if ("id" in this.state.selectedStrategy) {
                                                if( this.state.selectedStrategy.id === datum.id ) {
                                                  this.setState({selectedStrategy:{}, selected : null})
                                                } else {
                                                  //this.selectedStrategy(datum)
                                                }
                                              } else {
                                                //this.selectedStrategy(datum)
                                              }
                                            }
                                          },
                                          {
                                            target: "labels",
                                            mutation: (props) => ({ style: Object.assign({}, props.style, { fill: gcolor.gnFrameColor}) })
                                          }
                                        ]
                                      },
                                      onMouseLeave : () => {
                                        return [
                                          {
                                            target: "labels",
                                            mutation: ({datum}) => {
                                              this.setState({selectedStrategy:{}, selected : null})
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  }]}
                                />
                                <VictoryPie
                                    standalone={false}
                                    radius={ ({datum}) => (this.state.selected===datum.id)?170:145}
                                    innerRadius={ ({datum}) => (this.state.selected===datum.id)?150:125}
                                    style={{ 
                                      labels: { 
                                        pointerEvents: 'none',
                                        fontFamily : gcolor.gnFont,
                                        fill: 'transparent', 
                                        fontSize: 10,
                                        fontWeight : 500
                                      } ,
                                      data : { 
                                        filter: "drop-shadow( 1px 1px 1px rgba(0, 0, 0, .25))"
                                      }
                                    }}
                                    colorScale={ this.props.colors  }
                                    animate={{
                                      duration: 2000,
                                      onLoad: { duration: 1000 }
                                    }}
                                    data={  this.state.partition }
                                    cornerRadius={ 50 }
                                    padAngle={ ({ datum }) => (this.state.selected===datum.id)?5:2.5  }                          
                                />
                              </VictoryChart>
                            </div>
                        </div>
                    }
                    { this.props.isLogin && 
                      <Container className={['Detail-Activities-Holder'].join(' ')}>
                        <Button className={['Add'].join(" ")} onClick={
                          (e)=>{
                            this.props.updateParent({
                              showCreate : true,
                              isCreate : false,
                            })
                          }
                        }> 
                          <FontAwesomeIcon icon={faTasks} />
                          <span> { this.props.gi18n[this.props.language]['bot_manager']  }  </span>
                        </Button>
                        <Accordion 
                          activeKey={  this.state.activeKey  }
                          defaultActiveKey={ -1 }  >
                          {
                            this.state.strategy_list.map(  (v,k) => {
                              if( v.id ) {
                                return (
                                  <Card key={k} >
                                      <Card.Header
                                        variant="link" 
                                        className={['Detail-Activities-Header'].join(' ')} >

                                      <FormControl 
                                        className={ ['Detail-Activities-Header-Partition'].join(' ') } 
                                        value={v.partition}
                                        disabled={true}
                                        onChange={ 
                                          (e) => {
                                            var botfolio = JSON.parse(   JSON.stringify(this.props.selectedBotfolio)  )
                                            var partition = botfolio.partition
                                            partition[  v.id  ] = e.target.value
                                            this.props.updateBotfolio( ['partition'], [partition] )
                                          } 
                                        }/>
                                      <span className={ ['Detail-Activities-Header-Percent'].join(' ') } >{"%"}</span>
                                      <FormControl  className={ ['Detail-Activities-Header-Name'].join(' ') }
                                        value={v.name} 
                                        placeholder={  this.props.gi18n[this.props.language]['strategy_name']  }
                                        disabled={true}
                                        onChange={
                                          (e) => {
                                            var botfolio = JSON.parse(   JSON.stringify(this.props.selectedBotfolio)  )
                                            for( var i in botfolio.strategy_list) {
                                              if( botfolio.strategy_list[i].id === v.id ) {
                                                botfolio.strategy_list[i].name = e.target.value
                                                break
                                              }
                                            }
                                            this.props.updateBotfolio( ['strategy_list'], [  botfolio.strategy_list  ] )
                                          }
                                        }/>


                                      { 
                                        (!v.strategy_template_id || v.code) &&
                                        <FontAwesomeIcon 
                                          icon={faCog} 
                                          className={['Update'].join(" ")} 
                                          onClick={ (e)=>{
                                            //this.props.removeStrategy( v )
                                            var _filters = {}
                                            var filters = JSON.parse( JSON.stringify(v.filters) )
                                            //console.log( filters )
                                            for (var f in filters) {
                                              try {
                                                var _v = filters[f].value.split(",")
                                                if( _v.length > 1 ) {
                                                  filters[f].value = _v
                                                }
                                              }
                                              catch(e) {
                                                //console.log(e)
                                              }
                                              if( filters[f].key === "constituent" ) {
                                                filters[f].table = "constituent"
                                                filters[f].type = "constituent"
                                                _filters[  "constituent" ] = filters[f]
                                              } else {
                                                if( filters[f].type === "symbol" ) {
                                                  filters[f].table = "sector"
                                                  _filters[  filters[f].type  ] = filters[f]
                                                } else {
                                                  _filters[  filters[f].type  ] = filters[f]
                                                }
                                              }
                                            }

                                            //Api.getFilterTable( v.filters  ).then(
                                            //  (res) => {
                                            //    console.log(res)
                                            //    
                                            //  }
                                            //)   
                                            
                                            this.props.updateParent( {
                                              selectedStrategy : v,
                                              selectedBoard : 'filter',
                                              selectedIndex : 2,
                                              filters : _filters,
                                              code : v.code
                                            } ) 

                                            

                                            
                                          } } />
                                      }

                                      { 
                                        ( v.strategy_template_id && !v.code ) &&
                                          <div className={['Update', 'disable'].join(" ")} >
                                          </div>
                                      }



                                    </Card.Header>

                                </Card>
                              )
                            } else {
                              return(null)
                            }
                          })
                        }
                      </Accordion>
                      
                    </Container>
                  }
                </Tab>
*/