import { Socket } from "socket.io-client"

var sptrader = {
  url : "http://118.140.87.173:8089",
  key : 'SYSTEM_PRICE_API:A661FA84ACB265FA1ECCB7CE6EBD1',
  obj : {},
  ws : null,


  login : (username, password)=> {
    var url = sptrader.url + "/apiCustomer/accessRight/userLogin"
    var body = {  userId : username, password : password}
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  logout() {
    var url = sptrader.url + "/apiCustomer/accessRight/userLogout"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
    }
    sptrader.obj = {}
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  summary : ()=> {
    var url = sptrader.url + "/apiCustomer/account/accountSummary"
    var body = {  sessionToken : sptrader.obj['sessionToken'], targetAccNo : sptrader.obj['userId']  }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  price : ( symbols )=> {
    if( Object.keys( sptrader.obj ).length > 0 ) {
        var url = sptrader.url + "/apiSystemPrice/price/list"
        var  body = {
          "appId": 3,
          "appRel": 8.77,
          "systemId":  sptrader.obj.systemId ,
          //"mode": 0,  #ticker
          "mode": 2,
          "prodCodeList": symbols.join(","), 
        }
        return fetch(
          url, 
          {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization' : "Basic " + btoa(  sptrader.key),
              },
              body : JSON.stringify(body)
          }
        ).then(
            (response) => response.json()
        )
    } else {
      return fetch(
        "",
      ).then( ()=>null )
    }
  },

  addorder :  (obj) => {
    var url = sptrader.url + "/apiCustomer/order/add"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      accNo : sptrader.obj['userId'],
      buySell : obj['buysell'],
      condType : (obj['condType']?obj['condType']:0) ,
      clOrderId : obj['clOrderId'],
      orderType : (obj['orderType']?obj['orderType']:0),
      priceInDec : obj['priceInDec'],
      prodCode : obj['prodCode'],
      validType : (obj['validType']?obj['validType']:0)  ,
      qty : obj['qty']
    }
    //console.log( body )
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },
  
  changeorder :  (obj) => {
    var url = sptrader.url + "/apiCustomer/order/change"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      accNo : sptrader.obj['userId'],
      buySell : obj['buySell'],
      extOrderNo : obj['extOrderNo'],
      priceInDec : obj['price'],
      prodCode : obj['symbol'],
      validType : obj['validType'],
  
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  deleteorder :  (obj) => {
    var url = sptrader.url + "/apiCustomer/order/delete"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      accNo : sptrader.obj['userId'],
      buySell : obj['buySell'],
      extOrderId : obj['extOrderId'],
      prodCode : obj['prodCode'],
      accOrderNo : obj['accOrderNo'],
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  //GET PENDING TRADES
  getAccountOrder : (obj) => {
    var url = sptrader.url + "/apiCustomer/account/accountOrder"
    var body = {  
      
      sessionToken : sptrader.obj['sessionToken'], 
      targetAccNo : sptrader.obj['userId'],
  
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  getAccountPosition : (obj) => {
    var url = sptrader.url + "/apiCustomer/account/accountPosition"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      targetAccNo : sptrader.obj['userId'],
  
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  //GET DONE TRADES
  getDoneTrade : (obj) => {
    var url = sptrader.url + "/apiCustomer/reporting/doneTrade"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      targetAccNo : sptrader.obj['userId'],
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  getWorkingOrderReport : (obj) => {
    var url = sptrader.url + "/apiCustomer/reporting/workingOrder"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      targetAccNo : sptrader.obj['userId'],
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  getHistoryOrder : () => {
    ///apiCustomer/reporting/orderHist
    var url = sptrader.url + "/apiCustomer/reporting/orderHist"
    var body = {  
      dataRecordTotal : 1000,
      sessionToken : sptrader.obj['sessionToken'], 
      targetAccNo : sptrader.obj['userId'],
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  getUserLog : (obj) => {
    var url = sptrader.url + "/apiCustomer/reporting/userLog"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      targetAccNo : sptrader.obj['userId'],
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  getActiveOrder : () => {
    ///apiCustomer/order/active
    var url = sptrader.url + "/apiCustomer/order/active"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      targetAccNo : sptrader.obj['userId'],
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  getInactiveOrder : () => {
    ///apiCustomer/order/active
    var url = sptrader.url + "/apiCustomer/order/inactive"
    var body = {  
      sessionToken : sptrader.obj['sessionToken'], 
      targetAccNo : sptrader.obj['userId'],
    }
    return fetch(
        url, 
        {
            method: 'POST',
            headers: {
                //'cache-control': 'no-cache',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(body)
        }
    ).then(
        (response) => response.json()
    )
  },

  marginCheckerOpen : (update, onmessage ) => {
    var url = "ws://118.140.87.173:8086/websocketTraderAdmin/accountUpdate?session_token=" + sptrader.obj['sessionToken'] 
    sptrader.ws = new WebSocket( url )
    sptrader.ws.onopen = (evt)=>{
      /*
      dataMask	The operation of the request
      1 : Account summary
      2 : Account bal
      4 : Account position
      8 : Account Order
      32 : Done Trade
      47 : All of the above
      */
      update(true)
      var obj = {
          "dataMask" : 47,
          "event" : "subscribe",
          "accNo" : sptrader.obj['userId'].toUpperCase()
      }
      sptrader.ws.send( JSON.stringify(obj)  )
    }
    sptrader.ws.onclose = ( evt) => {
      //console.log(evt.reason)
      setTimeout( (e)  => {
        update(false)
      }, 300)
    }
    sptrader.ws.onmessage = (evt) => {
      //onmessage
      onmessage(evt)
    }
    sptrader.ws.onerror = (evt) => {
      //console.log(evt)
    }
  },

  marginCheckerClose : () => {
    if( sptrader.ws ) {
      sptrader.ws.close()
      sptrader.ws = null
    }
  }

}



export default sptrader