import React from "react";

import './index.scss'


import GService  from '../../../Garlican/GarlicanLibrary/gservice'
import Opcode from '../../../Garlican/GarlicanLibrary/opcode'
import apis from '../../../Garlican/GarlicanLibrary/apis'

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";
import CustomNode from './customNode.js'
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools';


var options = {}, dictionary=[], tempOpt=[], isPaste = false

const templates = window.templates

export default class IDE extends React.Component {

  constructor(props) {
    super(props);  
    this.editor= React.createRef()
    
    options = {
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      highlightSelectedWord:true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: 2,
    }

    this.state = {
      showTemplate : false,
      type : "",
      code : "",
      ocode : ""
    } 

    this.editor = React.createRef()
    this.updateDistionary = this.updateDistionary.bind(this)
    this.updateAC = this.updateAC.bind(this)
    this.initialCode = this.initialCode.bind(this)
    this.change = this.change.bind(this)

  }

  componentDidMount() {
    this.initialCode(this.props)
  }

  updateDistionary() {
    if(  this.editor ) {
      if( this.editor.current ) {
        const customMode = new CustomNode();
        this.editor.current.editor.getSession().setMode(customMode);
        dictionary=[]
        var obj = {}, i = 0;
        var cases = GService.defaults.concat( GService.exit  )
        for (i in cases) {
          obj={}
          obj.name = GService.defaults[i]
          obj.value = GService.defaults[i]
          obj.caption = GService.defaults[i] + " [" + ((GService.defaults[i] in this.props.gi18n[ this.props.language])?this.props.gi18n[ this.props.language][GService.defaults[i]]:GService.defaults[i]) + "]"
          obj.meta = null
          obj.score = 1000  
          dictionary.push( obj )
        }
        for (i in GService.structure) {
          if(GService.structure[i].display) {
            obj={}
            obj.name = i
            obj.value = GService.structure[i].stm
            obj.caption = i + " [" + ((i in this.props.gi18n[ this.props.language])?this.props.gi18n[ this.props.language][i]:i) + "]"
            obj.meta = null
            obj.score = 1000  
            dictionary.push( obj )
          }
        }

        for (i in  window.func) {
          
          if( window.func[i].display) {
            obj={}
            obj.name =  window.func[i].name
            obj.value =  window.func[i].format
            obj.caption =  window.func[i].name + " [" +  window.func[i]['desc'][ this.props.language] + "]"
            obj.meta = null
            obj.score = 1000 
            dictionary.push( obj )
          }
        }

        for (i in Opcode.op_func ) {
          obj={}
          obj.name = i
          obj.value = Opcode.op_func[i].value
          obj.caption = i + " [" +  Opcode.op_func[i]['desc'][ this.props.language] + "]"
          obj.meta = null
          obj.score = 1000  
          dictionary.push( obj )
        }
        this.updateAC( dictionary )
      }
    }
    //this.setTempOptions()
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
    //console.log( props.code )
  }

  UNSAFE_componentWillReceiveProps(props) {
    var _this = this
    if( (props.selectedIndex  !== this.state.type) || (props.selectedIndex=== 3)  ) {
      _this.initialCode(props)
    }
  }

  initialCode(props) {
    var _this = this
    var obj ={
      showTemplate : true,
      type : props.selectedIndex
    }
    var codes = GService.getBSCode( props.code )
    if( [1].includes( props.selectedIndex )  ) {
      obj.code = codes['buy']
    } else if( [2,3].includes( props.selectedIndex ) ) {
      obj.code = codes['sell']
      
    }
    obj.ocode = obj.code
    //setTimeout( (e) => {
      _this.setState(obj)
    //}, 300 )
    
  }

  updateAC(dict) {
    setCompleters([{
      getCompletions: function(editor, session, pos, prefix, callback) {
          callback(null, dict);
      },
    }]);
  }

  change(e) {
    this.setState( {
      code : e,
    })
    //this.props.onBlur( e ,  this.props.selectedBoard  )
  }

  render() {
    return (
      <div className={[ 'IDE'  ].join(' ')} 
         onMouseLeave={
          (e) => {
            //if( this.state.code !== this.state.ocode  ) {
            //  this.props.onBlur( this.state.code ,  this.props.selectedBoard  )
            //}
          }
      } >
        { this.state.showTemplate &&
          <AceEditor
            width="auto"
            selectionStyle={"line"}
            wrapEnabled={ true }
            onLoad={ (e) => {
              var _this = this
              setTimeout(
                (e)=>{
                  _this.updateDistionary()
                }, 300
              )
            }}
            ref={this.editor} 
            fontSize={12}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            mode="python"
            theme="textmate"
            className={ ['Textarea'].join(' ') } 
            onChange={ (e)=>{
              this.change( e )
            } }
            onBlur={
              (e)=> {
                //if( this.state.code !== this.state.ocode  ) {
                  //this.props.onBlur( this.state.code ,  this.props.selectedBoard  )
                //}
                //console.log( this.props.selectedIndex  )
                this.props.onBlur( this.state.code ,  this.props.selectedIndex   )
              }
            }
            onMouseLeave={
              (e) => {
                //console.log( this.props.selectedIndex  )
                //this.props.onBlur( this.state.code ,  this.props.selectedIndex   )
              }
            }
            name="GARLICAN"
            value={ this.state.code }  
            editorProps={{ $blockScrolling: false }}
            setOptions={this.state.options}
            enableLiveAutocompletion={true}
          />
        }
      </div>
    );
  }
}


