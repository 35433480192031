import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { Modal, Button } from 'react-bootstrap';




class Confirm extends Component {

    constructor(props) {
        super(props)

        this.state = { 

        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
      return (
        <Modal
            onHide={() => {
              this.props.close()  
            }}
            size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
            className={['Confirm-Frame'].join(" ")} 
            show={this.props.show} >
          <Modal.Body>
            { this.props.msg }
          </Modal.Body>
          <Modal.Footer>
            {
              this.props.secondary_btn_text &&
              <Button 
                className={['Confirm-Btn'].join(" ")} 
                onClick={() => {
                  window.document[this.props.scope][this.props.callback].apply( null, this.props.params )
                  this.props.close()
                }}
                variant="outline-success">
                { this.props.secondary_btn_text }
              </Button>
            }
            <Button 
              className={['Confirm-Btn'].join(" ")} 
              onClick={() => {
                if( this.props.callback === 'save' ) {
                  this.props.replacement_func()
                } else {
                  this.props.close()
                }
              }} 
              variant="outline-success">
              { this.props.gi18n[this.props.language]['close_btn'] }
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }
}


export default Confirm;
