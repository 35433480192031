var privacy = {
  zhtc: {
    header : '致力保護你的私隱',
    first_message : "此私隱政策聲明（“聲明”）應用於GARLIAN財經網站的用戶。",
    notification : "*請注意，本聲明事前毋須另行通知即可不時修訂，閣下應定期查閱最新版本。",
    promise_content: [
      { 
        title : "1.保障閣下私隱的承諾",
        content : "GARLICAN TECH LIMITED(下稱「本公司」)矢志妥善處理個人資料以保障客戶的私隱，致力保障及維護讀者及客戶個人資料的安全，並承諾全力執行及遵守《個人資料(私隱)條例》的規定。我們尊重閣下的私隱權利，本聲明特此詳列有本公司有關收集、使用和披露個人資料的守則，讓閣下在提供個人資料時，作出適當的選擇。",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement :""
      }, 
      {
        title : "2.個人資料的收集及使用",
        content : "閣下在登記成為GARLIAN財經網站的用戶時，須提供指定的個人資料，否則我們可能無法處理閣下的申請及提供服務，並或會因此造成延誤；其他個人資料則由閣下自願提供，主要乃作為客戶統計、改進產品、服務及推廣之用。\n\n在未經閣下的同意下，本網站不會使用閣下的個人資料，或向任何其他第三者披露閣下的個人資料，以達致直接銷售的目的。\n\n為配合本公司業務運作，本公司或會從閣下收集及保存下列某部份或所有的個人資料，包括但不限於:",
        sub_content:[
          "進入我們網站和使用我們服務的登入電郵和密碼;",
          "閣下的個人資料(例如: 閣下的姓名、暱稱、Facebook顯示名稱、性別、個人身份號碼、出生日期、地區、子女、行業、職位、教育程度、每月個人收入相關的資料);",
          "閣下的聯絡資料(例如: 閣下的電話號碼、通訊地址、電郵地址及傳真號碼)。"
        ],
        further: "除另行說明，否則閣下瀏覽我們的網站時我們只會收集閣下到訪的記錄及網路協定位址（IP Address），而不會收集閣下任何個人資料。我們會透過提供的服務作記錄，資料將用作分析網站訪客人數、使用狀況及使用者的瀏覽習慣，讓本網站知道哪些內容較受歡迎，及讓訪客享用個人化內容、服務及提示訊息等，營造更理想的線上體驗。我們所採用的記錄技術包括但不限於伺服器瀏覽記錄、網站信標(Page tagging) 、像素標籤 （pixel tags）及寄存客端系統(Cookies)等。Cookies是從伺服器發出並儲存於閣下客端系統內的細小文字檔，功能是獲取配置資料及分析網頁使用者的瀏覽習慣。使用者儲存Cookies後，再次瀏覽網站便不必重新註冊。網站常會用Cookies追蹤使用者喜好的網站主題。閣下可自行在客端系統關閉Cookies設定 ，但有機會不能自動登入、使用某些個人化及其他功能。我們的網站任何部份所設的Cookies（如有）不會用於收集個人資料。此外，我們亦有機會收集IP地址或智能手機/平板電腦的UDID，以提供上述個人化服務。",
        further_list:[],
        last_statement :""
      },
      {
        title : "3.與其他網頁與系統平台連結及廣告",
        content : "本私隱政策聲明只適用於本公司網站。本公司服務所包含的廣告、連結其他網頁的超連結（Hyperlink）及系統帳戶功能，並不在本私隱政策聲明所保障的範圍內。我們亦並不負責保障閣下在瀏覽該廣告、使用其他互聯網服務，或使用其他作業系統平台註冊帳戶(包括︰蘋果公司的iOS、谷歌公司的 Android、微軟公司 Windows 平台等)，所可能洩漏的私隱、個人資料及可能受到 Cookies 的干擾或影響，閣下應自行參閱它們的私隱政策（如有者）。\n\n我們可能收集、保留及使用閣下的個人資料以作如下用途：",
        sub_content:[
          "核實閣下的身份",
          "處理閣下選購我們服務及成為會員的申請；",
          "根據第486章《個人資料(私隱)條例》定義的核對程式",
          "提供閣下所需的服務及會員計劃；",
          "規管和管理該等服務及會員計劃，包括服務合約的續期、收費、帳單及收取欠款；",
          "進行研究或分析，以改善我們的產品及/或服務及會員計劃，以及用於調查，推銷和推廣用途；",
          "回應、處理和執行由閣下提交任何查詢;",
          "維護和開發移動通訊的業務系統和基礎設施，包括測試及更新該等系統；及/或",
          "協助預防、偵查或調查犯罪或潛在犯罪活動，或按照法律規定提供相關協助。"
        ],
        further: "除了上述，本公司會保留用戶在上網瀏覽或查詢時於伺服器自行產生的相關記錄，包括：連線的 IP 位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等。\n\n本公司在收集 閣下的個人資料時，將會：",
        further_list:[
          "向閣下說明本公司正在收集閣下的個人資料以及收集的用途；",
          "給閣下「否決」的機會（即限制本公司使用有關的個人資料作某些指定用途）；及",
          "說明可供閣下查閱、更改及刪除已儲存個人資料的方法。"
        ],
        last_statement : " "
      }, 
      {
        title : "4.個人資料用於推廣",
        content : "當網站接收到閣下的個人資料時，網站系統會即時保存妥當並予以保密，只有得到授權及相關員工才可獲准接觸有關資料。本公司亦會確保僱員遵從在個人資料保安及保密方面的操守。\n\n閣下進行網上登記後，本公司會以電郵確認 閣下會員身份及附上個人密碼。若有需要，閣下可（透過網站或以其他方式）不時要求更改 閣下的密碼。閣下須負責保密上述密碼，並對根據該等密碼所發生的所有活動承擔全部責任。閣下同意：",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement :""
      }, 
      {
        title : "5.個人資料的保安",
        content : "經閣下的同意後，我們或會使用閣下的姓名、會員代號、性別、身份證/護照號碼、出生日期、地區、行業、職位、教育程度、每月個人收入、喜好等相關的資料及有關閣下已選購產品及/或服務的資料，以便向閣下提供我們的產品及/或服務（包括財經資訊、新聞及教育），以及由我們的商業夥伴不時提供的產品及/或服務，其業務類別包括流動通訊、珠寶、社交活動、酒店、旅遊、銀行服務、運動、音樂、娛樂消閒、遊戲、交通、家居生活、服飾、煙酒、飲食、金融、保險、博彩、書籍、教育、健康與保健、美容產品、時尚潮品、電子產品、社交網絡、科技、電子商務、流動應用程式、手機付款、雲端服務、零售、家居裝飾、媒體、物流、拍賣、房地產、禮賓服務、慈善服務、汽車、政府及高端消費產品。",
        sub_content:[
          "如發生任何未經授權使用 閣下的密碼或違反安全的任何其他情況，將立即通知本公司；及",
          "確保 閣下在每次結束瀏覽網站時，至 閣下的帳戶從網站中退出。本公司無法且不會對因閣下未能遵守本文第 3條的規定而產生的任何損失或損害承擔責任。"
        ],
        further : "閣下承認上述密碼屬保密資料，在任何情況下均不得向任何人士披露。閣下違反該機密性的任何言語、行動或行為，無論有意或無意，均會導致閣下就因此引起的所有損失或損害向本公司承擔責任。閣下須採取所有必要的預防措施來維持上述密碼及個人識別代碼的機密性。閣下謹同意及承認，任何人士（無論是否經閣下授權）用該等密碼來使用服務，將構成並視為閣下本身使用服務。倘若發生任何實際或可能未經授權使用該等密碼的情況，閣下須立即通知本公司，並隨即向本公司書面確認上述情況。在本公司收到該等書面確認前，閣下無權向本公司提出索償，且須就本公司因使用任何服務（無論是否經閣下授權）而遭受的所有損失及賠償， 向本公司承擔責任。\n\n閣下承認並同意，本公司的唯一職責僅在於核實密碼，且本公司毋須 ：",
        further_list:[
          "當有關故障是直接或間接因本公司無法控制的情況或事件造成承擔閣下或任何其他人士因未能透過各種電子傳送渠道作出或執行指示或履行有關該等指示的任何義務而遭受的任何損失或損害；或",
          "承擔閣下或任何其他人士因以正確的密碼透過各種電子傳送渠道提供的指示而遭受的任何損失或損害。"
        ],
        last_statement : "閣下承認，鑒於互聯網的性質，本公司無法保證就任何服務對個人識別資料、內容或其他資料進行的任何傳輸均完全安全可靠。透過使用及獲取任何服務及顧及獲取及使用該等服務，閣下承認本公司保安聲明中所載本公司所採納的保安功能足以滿足閣下的所有目的。"
      },
      {
        title : "6.查詢及投訴",
        content : "倘閣下對我們的私隱政策聲明有任何疑問或意見，可電郵至admin@GARLIAN.com與我們聯絡",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement : ""
      }
      
    ]

  } , 
  zhcn: {


    header : '致力保护你的私隐',
    first_message : "此私隐政策声明（“声明”）应用于GARLIAN财经网站的用户。",
    notification : "*请注意，本声明事前毋须另行通知即可不时修订，阁下应定期查阅最新版本。",
    promise_content: [
      { 
        title : "1.保障阁下私隐的承诺",
        content : "GARLICAN TECH LIMITED(下称「本公司」)矢志妥善处理个人资料以保障客户的私隐，致力保障及维护读者及客户个人资料的安全，并承诺全力执行及遵守《个人资料(私隐)条例》的规定。我们尊重阁下的私隐权利，本声明特此详列有本公司有关收集、使用和披露个人资料的守则，让阁下在提供个人资料时，作出适当的选择。",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement :""
      }, 
      {
        title : "2.个人资料的收集及使用",
        content : "阁下在登记成为GARLIAN财经网站的用户时，须提供指定的个人资料，否则我们可能无法处理阁下的申请及提供服务，并或会因此造成延误；其他个人资料则由阁下自愿提供，主要乃作为客户统计、改进产品、服务及推广之用。 \n\n在未经阁下的同意下，本网站不会使用阁下的个人资料，或向任何其他第三者披露阁下的个人资料，以达致直接销售的目的。 \n\n为配合本公司业务运作，本公司或会从阁下收集及保存下列某部份或所有的个人资料，包括但不限于:",
        sub_content:[
          "进入我们网站和使用我们服务的登入电邮和密码;",
          "阁下的个人资料(例如: 阁下的姓名、昵称、Facebook显示名称、性别、个人身份号码、出生日期、地区、子女、行业、职位、教育程度、每月个人收入相关的资料);",
          "阁下的联络资料(例如: 阁下的电话号码、通讯地址、电邮地址及传真号码)。"
        ],
        further: "除另行说明，否则阁下浏览我们的网站时我们只会收集阁下到访的记录及网路协定位址（IP Address），而不会收集阁下任何个人资料。我们会透过提供的服务作记录，资料将用作分析网站访客人数、使用状况及使用者的浏览习惯，让本网站知道哪些内容较受欢迎，及让访客享用个人化内容、服务及提示讯息等，营造更理想的线上体验。我们所采用的记录技术包括但不限于伺服器浏览记录、网站信标(Page tagging) 、像素标签 （pixel tags）及寄存客端系统(Cookies)等。 Cookies是从伺服器发出并储存于阁下客端系统内的细小文字档，功能是获取配置资料及分析网页使用者的浏览习惯。使用者储存Cookies后，再次浏览网站便不必重新注册。网站常会用Cookies追踪使用者喜好的网站主题。阁下可自行在客端系统关闭Cookies设定 ，但有机会不能自动登入、使用某些个人化及其他功能。我们的网站任何部份所设的Cookies（如有）不会用于收集个人资料。此外，我们亦有机会收集IP地址或智能手机/平板电脑的UDID，以提供上述个人化服务。",
        further_list:[],
        last_statement :""
      },
      {
        title : "3.与其他网页与系统平台连结及广告",
        content : "本私隐政策声明只适用于本公司网站。本公司服务所包含的广告、连结其他网页的超连结（Hyperlink）及系统帐户功能，并不在本私隐政策声明所保障的范围内。我们亦并不负责保障阁下在浏览该广告、使用其他互联网服务，或使用其他作业系统平台注册帐户(包括︰苹果公司的iOS、谷歌公司的Android、微软公司Windows 平台等)，所可能泄漏的私隐、个人资料及可能受到Cookies 的干扰或影响，阁下应自行参阅它们的私隐政策（如有者）。\n\n我们可能收集、保留及使用阁下的个人资料以作如下用途：",
        sub_content:[
          "核实阁下的身份",
          "处理阁下选购我们服务及成为会员的申请；",
          "根据第486章《个人资料(私隐)条例》定义的核对程式",
          "提供阁下所需的服务及会员计划；",
          "规管和管理该等服务及会员计划，包括服务合约的续期、收费、帐单及收取欠款；",
          "进行研究或分析，以改善我们的产品及/或服务及会员计划，以及用于调查，推销和推广用途；",
          "回应、处理和执行由阁下提交任何查询;",
          "维护和开发移动通讯的业务系统和基础设施，包括测试及更新该等系统；及/或",
          "协助预防、侦查或调查犯罪或潜在犯罪活动，或按照法律规定提供相关协助。"
        ],
        further: "除了上述，本公司会保留用户在上网浏览或查询时于伺服器自行产生的相关记录，包括：连线的IP 位址、使用时间、使用的浏览器、浏览及点选资料记录等。\n\n本公司在收集阁下的个人资料时，将会：",
        further_list:[
          "向阁下说明本公司正在收集阁下的个人资料以及收集的用途；",
          "给阁下「否决」的机会（即限制本公司使用有关的个人资料作某些指定用途）；及",
          "说明可供阁下查阅、更改及删除已储存个人资料的方法。"
        ],
        last_statement : " "
      }, 
      {
        title : "4.个人资料用于推广",
        content : "当网站接收到阁下的个人资料时，网站系统会即时保存妥当并予以保密，只有得到授权及相关员工才可获准接触有关资料。本公司亦会确保雇员遵从在个人资料保安及保密方面的操守。\n\n阁下进行网上登记后，本公司会以电邮确认阁下会员身份及附上个人密码。若有需要，阁下可（透过网站或以其他方式）不时要求更改阁下的密码。阁下须负责保密上述密码，并对根据该等密码所发生的所有活动承担全部责任。阁下同意：",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement :""
      }, 
      {
        title : "5.个人资料的保安",
        content : "经阁下的同意后，我们或会使用阁下的姓名、会员代号、性别、身份证/护照号码、出生日期、地区、行业、职位、教育程度、每月个人收入、喜好等相关的资料及有关阁下已选购产品及/或服务的资料，以便向阁下提供我们的产品及/或服务（包括财经资讯、新闻及教育），以及由我们的商业伙伴不时提供的产品及/或服务，其业务类别包括流动通讯、珠宝、社交活动、酒店、旅游、银行服务、运动、音乐、娱乐消闲、游戏、交通、家居生活、服饰、烟酒、饮食、金融、保险、博彩、书籍、教育、健康与保健、美容产品、时尚潮品、电子产品、社交网络、科技、电子商务、流动应用程式、手机付款、云端服务、零售、家居装饰、媒体、物流、拍卖、房地产、礼宾服务、慈善服务、汽车、政府及高端消费产品。",
        sub_content:[
          "如发生任何未经授权使用 阁下的密码或违反安全的任何其他情况，将立即通知本公司；及",
          "确保 阁下在每次结束浏览网站时，至 阁下的帐户从网站中退出。本公司无法且不会对因阁下未能遵守本文第 3条的规定而产生的任何损失或损害承担责任。"
        ],
        further : "阁下承认上述密码属保密资料，在任何情况下均不得向任何人士披露。阁下违反该机密性的任何言语、行动或行为，无论有意或无意，均会导致阁下就因此引起的所有损失或损害向本公司承担责任。阁下须采取所有必要的预防措施来维持上述密码及个人识别代码的机密性。阁下谨同意及承认，任何人士（无论是否经阁下授权）用该等密码来使用服务，将构成并视为阁下本身使用服务。倘若发生任何实际或可能未经授权使用该等密码的情况，阁下须立即通知本公司，并随即向本公司书面确认上述情况。在本公司收到该等书面确认前，阁下无权向本公司提出索偿，且须就本公司因使用任何服务（无论是否经阁下授权）而遭受的所有损失及赔偿， 向本公司承担责任。\n\n阁下承认并同意，本公司的唯一职责仅在于核实密码，且本公司毋须：",
        further_list:[
          "当有关故障是直接或间接因本公司无法控制的情况或事件造成承担阁下或任何其他人士因未能透过各种电子传送渠道作出或执行指示或履行有关该等指示的任何义务而遭受的任何损失或损害；或",
          "承担阁下或任何其他人士因以正确的密码透过各种电子传送渠道提供的指示而遭受的任何损失或损害。"
        ],
        last_statement : "阁下承认，鉴于互联网的性质，本公司无法保证就任何服务对个人识别资料、内容或其他资料进行的任何传输均完全安全可靠。透过使用及获取任何服务及顾及获取及使用该等服务，阁下承认本公司保安声明中所载本公司所采纳的保安功能足以满足阁下的所有目的。"
      },
      {
        title : "6.查询及投诉",
        content : "倘阁下对我们的私隐政策声明有任何疑问或意见，可电邮至admin@GARLIAN.com与我们联络",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement :""
      }
      
    ]
  


  },
  eng:{
    
    header : 'We strive to protect your privacy',
    first_message : "This Privacy Policy Statement (\"Statement\") applied to the user GARLIAN financial Web site.",
    notification : "*Please note that this statement prior to revision from time to time without notice, you should regularly check the latest version.",
    promise_content: [
      { 
        title : "1. Protecting your privacy",
        content : "GARLICAN TECH LIMITED (Hereinafter referred to as the \"Company\") are committed to properly handle personal information in order to protect the privacy of customers, committed to the protection and maintenance of readers and customers' personal data security, and pledged full implementation of and compliance with \"the Personal Data (Privacy) Regulations\". We respect your right to privacy, set out in this statement hereby have the collection, use and disclosure of personal information of the Code of the company, so that when you provide personal information to make appropriate choices.",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement :""
      }, 
      {
        title : "2. Collection and use of personal data",
        content : "When you register as a user the financial Web site, GARLIAN, you are required to provide specific personal information, or we may not be able to process your application and provide services, and will therefore cause delays or; other personal information voluntarily provided by you, is a major customer demographics, improved products, services and promotion.\n\nWithout your consent, this site will not use your personal information or disclose your personal information to any other third party, in order to achieve the purpose of direct marketing.\n\nIn line with the Company's business operations, The Company may collect from you and save the following to a part or all of the personal information, including but not limited to the followings",
        sub_content:[
          "Access to our website and use login email and password of our services",
          "Your personal information (for example: your name, nickname, Facebook display name, gender, personal identification number, date of birth, region, children, industry, job, education level, monthly personal income-related information)",
          "Your contact information (for example: your telephone number, mailing address, email address and fax number)"
        ],
        further: "Except where noted otherwise, when you visit our website we will collect your records and visiting the Internet Protocol address (IP Address), and do not collect any of your personal information. We will be recorded through the services provided, the information will be used for analysis of the number of site visitors, usage and user browsing habits and let know what this site more popular, and let visitors enjoy personalized content, services and prompt messages, etc. and create a better online experience. Recording technique we used include, but are not limited to browsing history to the server, Web beacons (Page tagging), pixel tags (pixel tags) and hosting the guest system (Cookies) and so on. Cookies are emitted from the server and stored in small text files inside your guest system, the function is to get the configuration information and analyzes web users' browsing habits. Cookies store users, once again visit the website will not have to re-register. Web site often used to track user preferences Cookies website theme. It may close on their own in the guest system cookies set, but does not automatically have the opportunity to log in using certain personal and other functions. Any part of our Web site established by Cookies (if any) will not be used to collect personal information. In addition, we also have the opportunity to gather IP addresses or smartphone / tablet PC UDID, to provide such personal service.",
        further_list:[],
        last_statement :""
      },
      {
        title : "3. Other pages with links and advertising platform",
        content : "This Privacy Policy applies only to our website. The company's services include advertising, links to other pages of hyperlinks (Hyperlink) and system accounts feature is not within the scope of this Privacy Policy Statement guaranteed. We also protect your browsing is not responsible for the ads using other Internet services, or register for an account other operating system platforms (including Apple's iOS, Google's Android, Microsoft's Windows platform, etc.), the possible leakage of privacy, personal information and may be subject to interference or influence of Cookies, you are advised to refer to their privacy policy (if any).\n\nWe may collect, retain and use your personal data for the following purposes.",
        sub_content:[
          "Verify your identity",
          "Process your application for using our service or becoming a member",
          "According to Chapter 486, \"Personal Data (Privacy) Ordinance\" Defining reconciliation program",
          "Provide your required services and membership program",
          "Regulation and management of such services and membership programs, including a service contract renewal, charges, billing and collection of arrears",
          "Conduct research or analysis to improve our products and / or services and loyalty programs, as well as for research, marketing and promotional purposes",
          "In response, the handling and execution to submit any queries by you",
          "Maintenance and development of mobile communications business systems and infrastructure, including testing and updating of such systems",
          "Assist in the prevention, detection or investigation of criminal or potential criminal activity, or provide assistance in accordance with the law"
        ],
        further: "In addition to the relevant records, the company will retain the user in the Internet browser or self-generated queries to the server, including: IP address connection, use of time, using a browser, the browser and click data records.\n\nThe company in the collection of your personal data, will",
        further_list:[
          "explain to you the company is collecting your personal information and the collection of use",
          "provide you \"veto\" opportunities (ie limiting the company related to the use of personal information for certain specified purposes), and",
          "describe available for you to view, change, and delete personal data storage method。"
        ],
        last_statement : " "
      }, 
      {
        title : "4. Personal data for promotion",
        content : "After your consent, we may use your name, membership code, gender, ID / passport number, date of birth, regions, industries, jobs, education level, monthly income-related personal information, preferences, etc., and about you already buy products and / or information services in order to provide you with our products and / or services (including financial information, news, and education), as well as products and / or services provided by our business partners from time to time, its business categories include mobile communications, jewelry, social events, hotel, tourism, banking, sports, music, entertainment and leisure, gaming, transportation, home life, clothing, tobacco, food, finance, insurance, gaming, books, education, health and health and beauty products, fashion trend products, electronic products, social networks, technology, e-business, mobile applications, mobile payment, cloud services, retail, home decoration, media, logistics, auction, real estate, concierge services, charitable services, car , government and high-end consumer products.",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement :""
      }, 
      {
        title : "5. Security of Personal Information",
        content : "When a site receives your personal data, system will immediately save and protect it, only authorized and relevant staff will be permitted access to relevant information. The company will ensure that employees comply with the security and confidentiality of personal data of conduct.\n\nYour online registration, the Company will confirm your membership and attach personal password by email. If necessary, you can (through its website or otherwise) from time to time required to change your password. You shall be responsible for the confidentiality above passwords and take full responsibility for all activities that occur in accordance with such password. You agree",
        sub_content:[
          "In the event of any unauthorized use of your password or any other breach of security situation, we will immediately notify the Company, and",
          "Ensure that you visit the website at the end of each to your account exit from the site. Any loss of the company could not and will not have by your failure to comply with the provisions of article 3 or damage arising from responsibility."
        ],
        further : "You acknowledge that the above code is confidential information shall not be disclosed to any person in any case. Any words, actions or conduct in violation of the confidentiality of you, either intentionally or unintentionally, will lead to you to thus causing all liability for loss or damage to the Company. It must take all necessary precautions to maintain the encryption and personal identification code of confidentiality. You may wish to agree and acknowledge that any person (whether or not authorized by you) with a password to use these services, and you will be considered to constitute itself using the service. The event of any actual or possible unauthorized use of such passwords situation, you must immediately notify the company, and then confirm this in writing to the above companies. Before the Company received written confirmation of such, you have no right to claim against the Company, and shall indemnify the Company for all losses and the use of any service (whether or not authorized by you) suffered, liability to the Company.\n\nYou acknowledge and agree that the Company's sole responsibility is to verify the password only, and the Company is not required to",
        further_list:[
          "When the fault is related directly or indirectly to bear any losses you or any other person or for failing to carry out the instructions through various electronic delivery channels or perform any of its obligations relating to such instructions suffered due to circumstances beyond the control of the Company or an event or damage, or",
          "be liable for any loss or damage you or any other person as a result of instructions to a correct password provided through various electronic delivery channels suffered."
        ],
        last_statement : "We acknowledge that, given the nature of the Internet, the company can not guarantee that any transport service to any personally identifiable information, content, or other materials are conducted entirely secure. Through the use of and access to any services and take into account access to and use of these services, you acknowledge that the security features contained in the Company's security statement adopted by the Company is sufficient to meet all of your purpose."
      },
      {
        title : "6. Enquiries and Complaints",
        content : "Should you have any questions or comments for our Privacy Policy Statement, please email to contact@garlican.com to contact us.",
        sub_content:[],
        further : "",
        further_list:[],
        last_statement : ""
      }
      
    ]

  }
}



export default privacy